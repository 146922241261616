import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "100vh",
      background: "url('/images/login-background.jpg') no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
      alignItems: "center",
      justifyItems: "center",
    },
    loginContainer: {
      maxWidth: 900,
      width: "95%",
      margin: "0 auto",
      backgroundColor: "white",
      borderRadius: "5px",
      padding: "3rem 2rem",
      "& img": {
        maxWidth: 320,
        margin: "0 auto",
      },
      "& > div:first-child": {
        borderRight: "1px solid #e6e6e6",
      },
    },
  })
);

export default function AuthLayout(props: any) {
  const { children } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={1}
        direction="row"
        className={classes.loginContainer}
      >
        <Grid
          container
          justify="center"
          alignItems="center"
          item
          xs={12}
          md={6}
        >
          <img src="/images/mft-logo-text.jpg" />
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          item
          xs={12}
          md={6}
        >
          {children}
        </Grid>
      </Grid>
    </div>
  );
}
