import React from "react";
import { useLocation } from "react-router-dom";

import GuestLayout from "./GuestLayout";
import MainLayout from "./MainLayout";
import FullScreenLayout from "./FullScreenLayout";

const guest_layouts = /^(\/cognito-callback|\/new_password|\/logout|\/login|\/forgot-password)$/;
const full_screen_layouts = /^(\/preview\/)/;

export default function Layout() {
  const location = useLocation();
  if (guest_layouts.test(location.pathname)) {
    return <GuestLayout />;
  } else if (full_screen_layouts.test(location.pathname)) {
    return <FullScreenLayout />;
  }
  return <MainLayout />;
}
