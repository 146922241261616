import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import { Grid, ListItemSecondaryAction, IconButton } from "@material-ui/core";
import { FormProvider, useForm } from "react-hook-form";
import SelectForm from "components/form/Select";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import FaceIcon from "@material-ui/icons/Face";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  useCreateUserRelationshipMutation,
  useFindUserRelationshipsQuery,
  useUserRelationshipTypesQuery,
  useDeleteUserRelationshipMutation,
  User_Roles_Enum,
  UserFieldsFragment,
  useFindUsersLazyQuery,
} from "../../generated/graphql";

import ErrorToast from "components/toast/ErrorToast";

interface IFormInput {
  from_user_id: string;
  to_user_id: string;
  relationship_type: number;
}

const styles = (theme: Theme) =>
  createStyles({
    card: {
      width: "700px",
    },
    center: {
      alignSelf: "center",
      textAlign: "center",
    },
    list: {
      width: "100%",
      maxWidth: 360,
      maxHeight: 328,
      overflowY: "auto",
      backgroundColor: theme.palette.background.paper,
    },
    relationshipHeading: {
      "& span": {
        display: "inline-block",
        verticalAlign: "middle",
      },
      "& [class*='MuiCardHeader-title']": {
        marginRight: 10,
      },
      "& [class*='MuiCardHeader-subheader']": {
        padding: "6px 8px",
        lineHeight: 1.1,
        borderRadius: 2,
        textTransform: "capitalize",
        fontSize: 14,
        backgroundColor: "#e4e4e4",
      },
    },
  });

const useStyles = makeStyles((theme: Theme) => styles(theme));

interface UserRelationshipProps extends UserFieldsFragment {
  tableRef: any;
  schoolId?: string;
}

const UserRelationship: React.FC<UserRelationshipProps> = ({
  id,
  role,
  first_name,
  last_name,
  tableRef,
  schoolId,
}) => {
  const form = useForm<IFormInput>();

  const classes = useStyles();

  const { data } = useFindUserRelationshipsQuery({
    variables: {
      userId: id,
    },
  });

  const roleMap = {
    [User_Roles_Enum.Student]: [
      User_Roles_Enum.Student,
      User_Roles_Enum.Parent,
      User_Roles_Enum.Teacher,
    ],
    [User_Roles_Enum.Parent]: [User_Roles_Enum.Student],
    [User_Roles_Enum.Teacher]: [User_Roles_Enum.Student],
    [User_Roles_Enum.SchoolAdmin]: undefined,
    [User_Roles_Enum.SystemAdmin]: undefined,
  };

  const roleMaping = {
    child: User_Roles_Enum.Student,
    sibling: User_Roles_Enum.Student,
    parent: User_Roles_Enum.Parent,
    teacher: User_Roles_Enum.Teacher,
    student: User_Roles_Enum.Student,
  };

  const [
    getUserData,
    { data: usersData, loading: usersDataLoading, error: usersDataError },
  ] = useFindUsersLazyQuery({ errorPolicy: "all" });

  const {
    data: relationshipTypeData,
    loading: relationshipTypeLoading,
  } = useUserRelationshipTypesQuery();

  const [createUserRelationshipMutation] = useCreateUserRelationshipMutation({
    errorPolicy: "all",
  });

  const [deleteUserRelationshipMutation] = useDeleteUserRelationshipMutation({
    errorPolicy: "all",
  });

  const getSelectedType = (relationId: number) => {
    form.setValue("to_user_id", "");
    if (relationId) {
      const relation = relationshipTypeData?.user_relationship_types.find(
        (obj) => obj.id == relationId
      );
      getUserData({
        variables: {
          limit: 100,
          offset: 0,
          //@ts-ignore
          roles: { _in: [roleMaping[relation?.details[role.valueOf()]]] },
          school_id: schoolId !== undefined ? { _eq: schoolId } : {},
          // school_id: schoolId,
          // //@ts-ignore
          // roles: [roleMaping[relation?.details[role.valueOf()]]],
        },
      });
    }
  };

  if (
    relationshipTypeLoading ||
    !relationshipTypeData ||
    !relationshipTypeData.user_relationship_types
  ) {
    return null;
  }

  var toUserList = usersData?.users.filter(function (usersObj) {
    return !data?.user_relationship.find(function (removeUsersObj) {
      return (
        usersObj.id === removeUsersObj.to_user.id ||
        usersObj.id === removeUsersObj.from_user.id
      );
    });
  });

  const relationshipTypes = relationshipTypeData.user_relationship_types
    .filter((v) => v.details[role])
    .map((v) => ({ value: `${v.id}`, label: v.details[role] }));

  const onSubmit = async (data: IFormInput) => {
    if (data && data.relationship_type) {
      await createUserRelationshipMutation({
        variables: {
          object: {
            from_user_id: id,
            relationship_type: data.relationship_type,
            to_user_id: data.to_user_id,
          },
        },
        refetchQueries: ["findUserRelationships", "findUsers"],
      });
      tableRef.current && tableRef.current.onQueryChange();
      form.setValue("relationship_type", undefined);
      form.setValue("to_user_id", "");
    }
  };

  return (
    <FormProvider {...form}>
      <ErrorToast
        error={usersDataError}
        processCustomError={() =>
          `Unable to display users - ${usersDataError?.message}`
        }
      />

      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Card className={classes.card}>
          <CardHeader
            className={classes.relationshipHeading}
            title={`${first_name} ${last_name}`}
            subheader={role}
          />

          <List className={classes.list}>
            {data?.user_relationship.map((relationship) => {
              const relationshipUser =
                relationship.to_user.id === id
                  ? relationship.from_user
                  : relationship.to_user;
              const relationshipType =
                relationship.user_relationship_type.details[role];
              return (
                <ListItem
                  key={`relationship-${relationshipUser.id}-${relationshipUser.role}`}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <FaceIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${relationshipUser.first_name} ${relationshipUser.last_name}`}
                    secondary={relationshipType}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={async () => {
                        await deleteUserRelationshipMutation({
                          variables: {
                            id: relationship.id,
                          },
                          refetchQueries: ["findUserRelationships"],
                        });
                        tableRef.current && tableRef.current.onQueryChange();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>

          <hr />

          <Grid container style={{ marginRight: 0, marginLeft: 0 }} spacing={2}>
            <Grid item lg={5} xs={12}>
              <SelectForm
                key={`rel-${relationshipTypes.length}`}
                name={"relationship_type"}
                label={"Relationship Type"}
                options={relationshipTypes}
                onChange={(id) => {
                  getSelectedType(id);
                }}
              />
            </Grid>

            <Grid item lg={5} xs={12}>
              <SelectForm
                name={"to_user_id"}
                label={"User"}
                isDisabled={
                  form.getValues("relationship_type") !== undefined ||
                  usersDataLoading
                    ? false
                    : true
                }
                options={
                  toUserList
                    ? toUserList?.map((v) => ({
                        value: v.id,
                        label: `${v.first_name} ${v.last_name}`,
                      }))
                    : []
                }
              />
            </Grid>

            <Grid item lg={2} xs={12} className={classes.center}>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Grid>

            <pre style={{ visibility: "hidden" }}>
              {JSON.stringify(relationshipTypes[0], null, 2)}
            </pre>
          </Grid>
        </Card>
      </form>
    </FormProvider>
  );
};

export default UserRelationship;
