import React from "react";
import "./App.css";
import {
  split,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
import { BrowserRouter as Router } from "react-router-dom";

import Layout from "./components/layout";
import ThemeHandler from "./components/theme/ThemeHandler";
import { Auth } from "aws-amplify";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
});

console.log(process.env.REACT_APP_GRAPHQL_WS);

const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_GRAPHQL_WS}/graphql`,
  options: {
    reconnect: true,
    connectionParams: async () => {
      const token = await Auth.currentSession();
      return {
        headers: {
          authorization: token
            ? `Bearer ${token.getIdToken().getJwtToken()}`
            : "",
        },
      };
    },
  },
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists

  const token = await Auth.currentSession();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token.getIdToken().getJwtToken()}` : "",
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <ThemeHandler>
        <Router>
          <Layout />
        </Router>
      </ThemeHandler>
    </ApolloProvider>
  );
}

export default App;
