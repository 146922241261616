import React, { FunctionComponent } from "react";
import { useParams, useLocation } from "react-router-dom";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import { colors, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { FormProvider, useForm } from "react-hook-form";
import InputField from "components/form/Input";
import SelectForm from "components/form/Select";
import TextArea from "components/form/TextArea";
import ErrorToast from "components/toast/ErrorToast";
// import { TwitterPicker } from 'react-color';
import ColorPicker from "components/form/ColorPicker";
import AgreementQuestion from "./AgreementQuestions";
import Label from "components/form/Label";
import {
  QuestionTypeEnum,
  QuestionProps,
  QUESTIONS,
  SchoolIdParams,
  motessoriAffiliations,
  parentPermissionTemplate,
  chaperoneRequestTemplate,
  states,
} from "./consts";
import ListUsers from "components/user/ListUsers";
import ListClassRooms from "components/classrooms/ListClassRoom";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";

//@ts-ignore
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Schools_Insert_Input,
  Schools_Set_Input,
  useInsertSchoolMutation,
  useUpdateSchoolMutation,
  School_Types_Enum,
  Secondary_School_Types_Enum,
  useListAgreementQuestionsQuery,
  useSchoolInfoQuery,
  useFindClassRoomsQuery,
  useInsertAgreementQuestionsMutation,
  Agreement_Questions_Insert_Input,
  Exact,
  InsertAgreementQuestionsMutation,
  useDeleteAgreementQuestionMutation,
  useInsertSchoolProductFeatureMutation,
  useDeleteSchoolProductFeatureMutation,
  useProductFeatureQuery,
  useGetSchoolProductFeatureQuery,
  useFindMeQuery,
} from "../../generated/graphql";
import { capitalize } from "utils";
import { MutationFunctionOptions } from "@apollo/client";
import { Element, scroller } from "react-scroll";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "100%",
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    logoUploadBtn: {
      textTransform: "none",
    },
    hide: {
      display: "none",
    },
    logo: {
      marginBottom: "10px",
    },
    logoImage: {
      width: "100%",
      marginBottom: "10px",
    },
    logoContainer: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      minHeight: "160px",
    },
    clearIconContainer: {
      display: "flex",
      justifyContent: "center",
    },
    clearIcon: {
      fontSize: "13px",
      cursor: "pointer",
    },
    tripType: {
      display: "inline-flex",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: 5,
      padding: "15px 10px",
      marginRight: 10,
    },
    tripTypeLabel: {
      "& span": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
      "& img": {
        marginRight: 10,
      },
    },
  })
);

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  school_type: yup.string().required("School Type is required"),
  street_address: yup.string().required("Street Address is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  zip_code: yup.string().required("Zip Code is required"),
});

const cusomizationSchema = yup.object().shape({
  parent_permission_template: yup
    .string()
    .required("Parent Permission Template required"),
  chaperone_request_template: yup
    .string()
    .required("Chaperone Reuqest Template is required"),
});

const SchoolCustomization: FunctionComponent<SchoolInfoProps> = ({
  createdSchoolId,
  insertQuestionsMutation,
  schoolData,
}) => {
  const classes = useStyles();
  const { data, loading } = useListAgreementQuestionsQuery({
    variables: {
      schoolId: createdSchoolId,
      type: {},
    },
  });
  const [logo, setLogo] = React.useState(
    schoolData ? schoolData?.logo_url : null
  );
  const [colorTheme, setColorTheme] = React.useState(
    schoolData ? schoolData?.color_theme : null
  );

  const uploadLogo = () => {
    //@ts-ignore
    cloudinary.openUploadWidget(
      {
        cloud_name: process.env.REACT_APP_CLOUDINARY_NAME,
        upload_preset: process.env.REACT_APP_UPLOAD_PRESET,
        cropping: true,
        sources: ["local", "url"],
      },
      //@ts-ignore
      (err, logo) => {
        if (!err) {
          setLogo(logo[0].secure_url);
        }
      }
    );
  };

  const {
    data: schoolFeatures,
    error: schoolFeatureError,
  } = useProductFeatureQuery();

  const [
    deleteQuestionsMutation,
    { error: deleteQuestionError },
  ] = useDeleteAgreementQuestionMutation({
    errorPolicy: "all",
  });

  const [
    updateSchoolMutation,
    { error: updateSchoolError },
  ] = useUpdateSchoolMutation({
    errorPolicy: "all",
  });

  const [
    insertSchoolProduct,
    { error: insertSchoolProductError },
  ] = useInsertSchoolProductFeatureMutation({
    errorPolicy: "all",
  });

  const [
    deleteSchoolProduct,
    { error: deleteSchoolProductError },
  ] = useDeleteSchoolProductFeatureMutation({
    errorPolicy: "all",
  });

  const {
    data: getSchoolProduct,
    error: eee,
  } = useGetSchoolProductFeatureQuery({
    variables: {
      school_id: createdSchoolId,
    },
  });

  const { data: meData } = useFindMeQuery();

  const form = useForm<Schools_Set_Input>({
    resolver: yupResolver(cusomizationSchema),
    defaultValues: {
      parent_permission_template:
        schoolData?.parent_permission_template || parentPermissionTemplate,
      chaperone_request_template:
        schoolData?.chaperone_request_template || chaperoneRequestTemplate,
    },
  });

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  const getFilteredQuestions = (type: QuestionTypeEnum) =>
    data?.agreement_questions
      .filter((q) => q.type === type.toString())
      .map((q) => ({ label: q.label, type, id: q.id }));

  //  TODO: update local cached as opposed to refetch
  const addQuestion = async (question: QuestionProps) => {
    if (createdSchoolId) {
      await insertQuestionsMutation({
        variables: {
          objects: [getAgreementQuestionObject(createdSchoolId, question)],
        },
        refetchQueries: ["listAgreementQuestions"],
      });
    }
  };

  const deleteQuestion = async (id: string) => {
    if (createdSchoolId) {
      await deleteQuestionsMutation({
        variables: {
          id,
        },
        refetchQueries: ["listAgreementQuestions"],
      });
    }
  };

  const onSubmit = async (data: Schools_Set_Input) => {
    if (createdSchoolId !== undefined) {
      try {
        const { errors } = await updateSchoolMutation({
          variables: {
            id: createdSchoolId,
            object: {
              parent_permission_template: data.parent_permission_template,
              chaperone_request_template: data.chaperone_request_template,
              logo_url: logo,
              color_theme: colorTheme,
            },
          },
          refetchQueries: ["schoolInfo"],
        });
        return;
      } catch (e) {
        // unexpected error, hanlde message
        console.error(e);
      }
    }
  };

  const { handleSubmit } = form;

  function TripType(props: any) {
    const { type, image, featureId } = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked === true) {
        insertSchoolProduct({
          variables: {
            product_feature_id: event.target.name,
            school_id: createdSchoolId,
          },
          refetchQueries: ["ProductFeature", "getSchoolProductFeature"],
        });
      }

      if (event.target.checked === false) {
        deleteSchoolProduct({
          variables: {
            product_feature_id: event.target.name,
            school_id: createdSchoolId,
          },
          refetchQueries: ["ProductFeature", "getSchoolProductFeature"],
        });
      }
    };

    const sss = getSchoolProduct?.school_product_features
      .filter((f) => f.product_feature.id === featureId)
      .map((m) => {
        return true;
      });

    return (
      <>
        <div className={classes.tripType}>
          <FormControlLabel
            control={<Checkbox onChange={handleChange} name={featureId} />}
            disabled={meData?.me[0].role !== "system_admin"}
            className={classes.tripTypeLabel}
            checked={sss !== undefined && sss?.length > 0 ? true : false}
            label={
              <>
                <img src={`/images/${image}.png`} />
                {type}
              </>
            }
          />
        </div>
      </>
    );
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ErrorToast
          error={deleteQuestionError}
          processCustomError={() =>
            `Unable to delete question - ${deleteQuestionError?.message}`
          }
        />
        <ErrorToast
          error={updateSchoolError}
          processCustomError={() =>
            `Unable to update school customizations - ${updateSchoolError?.message}`
          }
        />

        <ErrorToast
          error={schoolFeatureError}
          processCustomError={() =>
            `Unable to load school product feature - ${schoolFeatureError?.message}`
          }
        />
        <ErrorToast
          error={insertSchoolProductError}
          processCustomError={() =>
            `Unable to update Product Feature - ${insertSchoolProductError?.message}`
          }
        />
        <ErrorToast
          error={deleteSchoolProductError}
          processCustomError={() =>
            `Unable to remove Product Feature - ${deleteSchoolProductError?.message}`
          }
        />

        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <AgreementQuestion
              type={QuestionTypeEnum.transportation}
              title="Transportation and Logistics"
              questions={getFilteredQuestions(QuestionTypeEnum.transportation)}
              addQuestion={addQuestion}
              deleteQuestion={deleteQuestion}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <AgreementQuestion
              type={QuestionTypeEnum.grace_courtsey}
              title="Grace and Courtsey"
              questions={getFilteredQuestions(QuestionTypeEnum.grace_courtsey)}
              addQuestion={addQuestion}
              deleteQuestion={deleteQuestion}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <AgreementQuestion
              type={QuestionTypeEnum.reflect_questions}
              title="Reflect Questions"
              questions={getFilteredQuestions(
                QuestionTypeEnum.reflect_questions
              )}
              addQuestion={addQuestion}
              deleteQuestion={deleteQuestion}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={6} xs={12}>
                <ColorPicker setColorTheme={setColorTheme} />
              </Grid>

              <Grid item lg={6} xs={12}>
                <Label title="School Logo">
                  <div className={classes.logoContainer}>
                    <div className={classes.logo}>
                      {logo ? (
                        <div>
                          <img src={logo} className={classes.logoImage} />
                          <div className={classes.clearIconContainer}>
                            <Typography
                              variant="body1"
                              color="primary"
                              className={classes.clearIcon}
                              onClick={() => setLogo(null)}
                            >
                              clear
                            </Typography>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <Button
                      component="span"
                      variant="outlined"
                      size="small"
                      color="primary"
                      className={classes.logoUploadBtn}
                      onClick={uploadLogo}
                    >
                      Upload
                    </Button>
                  </div>
                </Label>
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid item lg={12} xs={12}>
              <Label title="Trip Types">
                {schoolFeatures?.product_features.map((pf) => (
                  <TripType
                    type={`${pf.feature_name}`}
                    image={`${pf.feature_slug}`}
                    featureId={pf.id}
                  />
                ))}
              </Label>
            </Grid>
          </Grid>

          <Grid item lg={6} xs={12}>
            <TextArea
              name="parent_permission_template"
              label="Parent Permission Slip Template"
              rows={20}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <TextArea
              name="chaperone_request_template"
              label="Chaperone Request Template"
              rows={20}
            />
          </Grid>
        </Grid>

        <div className={classes.actionsContainer}>
          <div>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              className={classes.button}
            >
              Update
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

interface SchoolInfoProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setCreatedSchoolId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  createdSchoolId?: string;
  schoolData?: Schools_Set_Input;
  insertQuestionsMutation: (
    options?:
      | MutationFunctionOptions<
          InsertAgreementQuestionsMutation,
          Exact<{ objects: Agreement_Questions_Insert_Input[] }>
        >
      | undefined
  ) => Promise<any>;
}

const getAgreementQuestionObject = (
  schoolId: string,
  questionData: QuestionProps
) => {
  const obj = {
    label: questionData.label,
    type: questionData.type.toString(),
    school_id: schoolId,
  };
  return obj as Agreement_Questions_Insert_Input;
};

const SchoolInfo: FunctionComponent<SchoolInfoProps> = ({
  setActiveStep,
  setCreatedSchoolId,
  createdSchoolId,
  schoolData,
  insertQuestionsMutation,
}) => {
  const classes = useStyles();

  const [
    insertSchoolMutation,
    { loading, error: insertSchoolError },
  ] = useInsertSchoolMutation({ errorPolicy: "all" });

  const [
    updateSchoolMutation,
    { error: updateSchoolError },
  ] = useUpdateSchoolMutation({
    errorPolicy: "all",
  });

  const form = useForm<Schools_Insert_Input | Schools_Set_Input>({
    resolver: yupResolver(schema),
    defaultValues: schoolData,
  });

  const onSubmit = async (data: Schools_Insert_Input | Schools_Set_Input) => {
    if (createdSchoolId !== undefined) {
      //  update school data
      try {
        await updateSchoolMutation({
          variables: {
            id: createdSchoolId,
            object: data,
          },
          refetchQueries: ["schoolInfo"],
        });
        setActiveStep(1);
        return;
      } catch (e) {
        // unexpected error, hanlde message
        console.error(e);
      }
    }

    const { errors, data: createdData } = await insertSchoolMutation({
      variables: {
        object: data,
      },
    });
    if (!errors && setCreatedSchoolId) {
      await insertQuestionsMutation({
        variables: {
          objects: QUESTIONS.map((q) =>
            getAgreementQuestionObject(createdData?.insert_schools_one?.id, q)
          ),
        },
      });
      setActiveStep(1);
      setCreatedSchoolId(createdData?.insert_schools_one?.id);
    }
  };

  Object.keys(Secondary_School_Types_Enum).map((v) => {
    //@ts-ignore
    return {
      //@ts-ignore
      value: Secondary_School_Types_Enum[v].toString(),
      label: v,
    };
  });

  const { register, handleSubmit } = form;

  return (
    <FormProvider {...form} key={schoolData?.id}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ErrorToast
          error={insertSchoolError}
          processCustomError={() =>
            `Unable to create school - ${insertSchoolError?.message}`
          }
        />
        <ErrorToast
          error={updateSchoolError}
          processCustomError={() =>
            `Unable to update school info - ${updateSchoolError?.message}`
          }
        />

        <Grid container spacing={2}>
          <Grid item lg={12} xs={12}>
            <InputField
              name="name"
              label="School Name"
              ref={register}
              required={true}
            />
          </Grid>

          <Grid item lg={4} xs={12}>
            <SelectForm
              name={"school_type"}
              label={"School Type"}
              //@ts-ignore
              options={Object.keys(School_Types_Enum).map((v) => {
                //@ts-ignore
                const value = School_Types_Enum[v].toString();
                return {
                  value,
                  label: capitalize(value, "_"),
                };
              })}
              required={true}
            />
          </Grid>

          <Grid item lg={4} xs={12}>
            <SelectForm
              name={"secondary_school_type"}
              label={"School Type Details"}
              //@ts-ignore
              options={Object.keys(Secondary_School_Types_Enum).map((v) => {
                //@ts-ignore
                const value = Secondary_School_Types_Enum[v].toString();
                return {
                  //@ts-ignore
                  value: value,
                  label: capitalize(value, "_"),
                };
              })}
            />
          </Grid>

          <Grid item lg={4} xs={12}>
            <SelectForm
              name={"montessori_affiliation"}
              label={"Montessori Affiliations"}
              options={motessoriAffiliations.map((v) => ({
                value: v,
                label: v,
              }))}
            />
          </Grid>

          <Grid item lg={12} xs={12}>
            <TextArea name="notes" label="Notes" />
          </Grid>

          <Grid item lg={6} xs={12}>
            <InputField
              name="street_address"
              label="Street Address"
              required={true}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <InputField name="street_address_2" label="Street Address 2" />
          </Grid>

          <Grid item lg={4} xs={12}>
            <InputField name="city" label="City" required={true} />
          </Grid>

          <Grid item lg={4} xs={12}>
            <SelectForm
              name={"state"}
              label={"State"}
              options={states.map((v) => ({
                value: v,
                label: v,
              }))}
              required={true}
            />
          </Grid>

          <Grid item lg={4} xs={12}>
            <InputField name="zip_code" label="Zip Code" required={true} />
          </Grid>
        </Grid>

        <div className={classes.actionsContainer}>
          <div>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              className={classes.button}
            >
              {createdSchoolId === undefined ? "Next" : "Update"}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

function UserSectionScroller() {
  const location = useLocation();
  const params = useParams<SchoolIdParams>();
  React.useEffect(() => {
    if (location.hash.includes("#users")) {
      scroller.scrollTo("users", {
        duration: 1500,
        delay: 100,
        smooth: true,
      });
    }
  }, [params.schoolId, location.hash]);
  return null;
}

export default function CreateSchool() {
  const classes = useStyles();
  const params = useParams<SchoolIdParams>();

  const [activeStep, setActiveStep] = React.useState(0);
  const [createdSchoolId, setCreatedSchoolId] = React.useState<
    string | undefined
  >(params.schoolId);

  const { data, loading } = useSchoolInfoQuery({
    variables: {
      schoolId: params.schoolId,
    },
    skip: params.schoolId === undefined,
  });

  const [
    insertQuestionsMutation,
    { error: insertQuestionError },
  ] = useInsertAgreementQuestionsMutation({
    errorPolicy: "all",
  });

  //  reset school id on page unload and new school selection
  React.useEffect(() => {
    setCreatedSchoolId(params.schoolId);
  }, [params.schoolId]);

  const schoolData = data?.schools_by_pk;

  const {
    loading: classRoomsLoading,
    data: classRoomData,
  } = useFindClassRoomsQuery({
    variables: {
      school_id: createdSchoolId !== undefined ? { _eq: createdSchoolId } : {},
    },
  });

  if (loading || (params.schoolId && !schoolData)) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Card className={classes.root}>
      <ErrorToast
        error={insertQuestionError}
        processCustomError={() =>
          `Unable to insert question - ${insertQuestionError?.message}`
        }
      />
      <CardHeader
        title={`${params.schoolId ? "Update School Info" : "Create School"}`}
      />

      <CardContent>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step expanded>
            <StepLabel>School Info</StepLabel>
            <StepContent>
              <SchoolInfo
                setActiveStep={setActiveStep}
                setCreatedSchoolId={setCreatedSchoolId}
                createdSchoolId={createdSchoolId}
                schoolData={schoolData as Schools_Set_Input}
                insertQuestionsMutation={insertQuestionsMutation}
              />
            </StepContent>
          </Step>

          <Step
            expanded={createdSchoolId !== undefined}
            completed={createdSchoolId !== undefined}
          >
            <StepLabel>School Customizations</StepLabel>
            <StepContent>
              {createdSchoolId !== undefined && (
                <SchoolCustomization
                  setActiveStep={setActiveStep}
                  createdSchoolId={createdSchoolId}
                  schoolData={schoolData as Schools_Set_Input}
                  insertQuestionsMutation={insertQuestionsMutation}
                />
              )}
            </StepContent>
          </Step>

          <Step
            expanded={createdSchoolId !== undefined}
            completed={
              createdSchoolId !== undefined &&
              classRoomData?.class_rooms &&
              classRoomData?.class_rooms.length > 0
            }
          >
            <StepLabel>
              <Element name="users">Classrooms</Element>
            </StepLabel>
            <StepContent>
              {classRoomsLoading ? (
                <Grid container justify="center">
                  <CircularProgress />
                </Grid>
              ) : (
                <ListClassRooms
                  schoolId={createdSchoolId}
                  classRoomData={classRoomData}
                />
              )}
            </StepContent>
          </Step>

          <Step expanded={createdSchoolId !== undefined}>
            <StepLabel>
              <Element name="users">Users</Element>
            </StepLabel>
            <StepContent>
              <UserSectionScroller />
              <ListUsers
                schoolId={createdSchoolId}
                hideTitle={true}
                classRoomData={classRoomData}
              />
            </StepContent>
          </Step>
        </Stepper>
      </CardContent>
    </Card>
  );
}
