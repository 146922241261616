const printCss = {
  root: {
    width: "100%",
    padding: "0 15px",
    "& .mainLogoWrapper, .hide-web": {
      display: "none",
    },
    "@media print": {
      "& .mainLogoWrapper, .hide-web": {
        display: "block",
      },
      boxShadow: "none !important",
      padding: 0,
      "& [class*='sectionSpace'] hr": {
        display: "none",
      },
      "& .hide-print": {
        display: "none",
      },
      "& * ": {
        lineHeight: 1.2,
      },
      "& [class*='MuiPaper-elevation']": {
        boxShadow: "none",
        border: "1px solid rgba(224, 224, 224, 1)",
      },
      "& * h6": {
        fontSize: 18,
        marginBottom: 5,
      },
      "& * p": {
        fontSize: 14,
      },
      "& [class*='MuiCardHeader-content'] > *": {
        fontSize: 20,
      },
      "& [class*='MuiChip-root']": {
        height: 25,
        "& [class*='MuiChip-label']": {
          fontSize: 13,
        },
      },
      "& .mainEventData * ": {
        fontSize: 20,
      },
      "& .timeLinesWrapper": {
        //pageBreakBefore: "always",
        "& ul": {
          display: "block",
        },
        "& .timeLineItem": {
          pageBreakInside: "avoid",
          breakBefore: "avoid",
        },
        // "& .timeLineOposit": {
        //   display: "none",
        // },
        // "& .timeLineSeperator": {
        //   display: "inline-block",
        // },
        "& h1": {
          fontSize: 16,
          marginBottom: 4,
        },
        "& [class*='makeStyles-tripTimeLine'], [class*='MuiTimeline-root']": {
          paddingRight: 0,
          paddingLeft: 0,
          margin: 0,
        },
        "& .timeLineContent * ": {
          "& [class*='MuiGrid-root']": {
            display: "block !important",
            width: "100% !important",
            maxWidth: "none !important",
            paddingBottom: "0px !important",
          },
          "& .actionColumn": {
            display: "block !important",
            textAlign: "unset !important",
            paddingBottom: 5,
          },
          "& [class*='MuiRating-root']": {
            marginTop: 8,
          },
        },
      },
      "& .reflectionWrapper": {
        "& [class*='makeStyles-reflectRow']": {
          pageBreakInside: "avoid",
          breakBefore: "avoid",
        },
      },
      "& .reflectWrapper": {
        //pageBreakBefore: "always",
        "& p": {
          fontSize: 12,
        },
        // "& .printOnly-row": {
        //   display: "table",
        //   tableLayout: "fixed",
        //   height: "100%",
        //   width: "100%",
        // },
        // "& .printOnly-row:before": {
        //   display: "table",
        //   content: '" "',
        // },
        // "& .printOnly-row:after": {
        //   content: '" "',
        //   clear: "both",
        // },
        // "& .printOnly-cell": {
        //   display: "table-cell",
        //   float: "none",
        //   width: "50%",
        //   height: "100%",
        // },
        // "& .printOnly-cell:after": {
        //   content: '""',
        //   clear: "both",
        //   display: "table",
        // },
      },
      "& table": {
        "& th": {
          padding: "8px 10px",
          fontSize: "12px",
        },
        "& td": {
          padding: "8px",
          fontSize: 11,
        },
      },
      "& .mainLogoWrapper": {
        "& img": {
          display: "block",
          maxWidth: "100%",
          width: "250px",
          margin: "0 auto",
        },
      },
    },
  },
};
export default printCss;
