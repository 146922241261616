export const capitalize = (words: string, split: string = " ") =>
  words
    .split(split)
    .map(
      (word) => word[0].toUpperCase() + word.substring(1).toLocaleLowerCase()
    )
    .join(" ");

export const username_regx = /^[A-Za-z0-9]{2,15}(?:[_-][A-Za-z0-9]+)*$/;
export const name_regx = /(^[a-zA-Z]{0,30})+ ?([a-zA-Z]{0,30})+ ?([a-zA-Z])+$/;
