import React from "react";
import { virtualTripTopics } from "./Const";

export default function GetVirtualTripTitle(props: any) {
  const { topic } = props;
  const title = virtualTripTopics
    .filter((f) => f.value === topic)
    .map((m) => {
      return m.label;
    });
  return <>{title.length > 0 ? title : topic}</>;
}
