import React, { useContext } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { FormProvider, useForm, UseFormMethods } from "react-hook-form";
import SelectForm from "components/form/Select";
import Slider, { Mark } from "@material-ui/core/Slider";
import TableRow from "@material-ui/core/TableRow";
import TextArea from "components/form/TextArea";

import { Ratings } from "components/school/consts";
import { FieldTripParams } from "./consts";

import styles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import {
  FieldTripReflectQuery,
  FindMeQuery,
  useFieldTripReflectQuery,
  useFieldTripStudentsQuery,
  useFindMeQuery,
  User_Roles_Enum,
  Field_Trip_Students_Set_Input,
  useUpdateFieldTripStudentMutation,
  FieldTripStudentsQuery,
  useFieldTripStudentReflectQuery,
  useFieldTripLocationsQuery,
  useAddLocationReviewMutation,
  Exact,
  FieldTripStudentReflectQuery,
  FieldTripLocationsQuery,
  useFieldTripLocationReviewsQuery,
  Field_Trip_Location_Reviews,
  useFieldTripReflectQuestionsQuery,
} from "generated/graphql";
import { useParams } from "react-router-dom";
//@ts-ignore
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApolloQueryResult } from "@apollo/client";
import { MachineContext } from "./state";
import ErrorToast from "components/toast/ErrorToast";

const useStyles = makeStyles((theme: Theme) =>
  //@ts-ignore
  createStyles({
    ...styles,
    root: {
      maxWidth: "100%",
      display: "flex",
      alignItems: "center",
    },
    rating: {
      maxWidth: 200,
    },
    headerColumn: {
      fontWeight: 600,
    },
    sliderContainer: {
      width: 350,
    },
    label: {
      width: 40,
      whiteSpace: "break-spaces",
    },
    controls: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "20px",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    textAreaStyle: {
      "& [class*='MuiInputBase-formControl']": {
        paddingTop: 20,
      },
    },
  })
);

const marks: Array<Mark> = Ratings.map((r) => ({
  value: r.value,
  label: r.hiddenLabel ? <></> : <LabelComponent label={r.label} />,
}));

interface LabelProps {
  label: string;
}

function LabelComponent(props: LabelProps) {
  const { label } = props;
  const classes = useStyles();

  return <div className={classes.label}>{label}</div>;
}

interface StudentReflectionProps {
  form: UseFormMethods<IFormInput>;
  questions: any;
}

function StudentReflection(props: StudentReflectionProps) {
  const { readOnly } = useContext(MachineContext);
  const classes = useStyles();

  const { form, questions } = props;
  const surveyValues = form.watch("reflect_survey");

  const watchTripContextUserId = form.watch("student_id");

  function UserReflectData(props: any) {
    const { qId } = props;
    const ee = surveyValues ? Object.entries(surveyValues) : [];

    //@ts-ignore
    const arr: Array<number> = ee.filter((f) => f[0] == qId).map((q) => q[1]);

    return (
      <Slider
        defaultValue={arr[0] || 0}
        aria-labelledby="discrete-slider"
        disabled={readOnly}
        valueLabelDisplay="off"
        step={1}
        marks={marks}
        onChange={(e, v) =>
          form.setValue("reflect_survey", {
            ...surveyValues,
            [qId]: v,
          })
        }
        track={false}
        min={0}
        max={6}
      />
    );
  }

  return (
    <Grid item lg={12} xs={12}>
      <Typography variant="h6">Student Reflection</Typography>
      <Table className={classes.table}>
        <TableBody>
          {questions?.agreement_questions.map((question: any) => (
            <TableRow key={question.label} className={classes.tableRow}>
              <TableCell>{question.label}</TableCell>
              <TableCell>
                <div className={classes.sliderContainer}>
                  <UserReflectData qId={question.id} />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
}

interface StarRatingProps {
  defaultValue: number;
  onChange: (v: number) => void;
}

function StarRating(props: StarRatingProps) {
  const { readOnly } = useContext(MachineContext);
  const { defaultValue, onChange } = props;
  const [value, setValue] = React.useState<number | null>(defaultValue);
  const classes = useStyles();

  return (
    <div className={classes.rating}>
      <Rating
        value={value}
        precision={1}
        disabled={readOnly}
        onChange={(event, newValue) => {
          setValue(newValue);
          onChange(newValue || 0);
        }}
      />
    </div>
  );
}

interface LocationRatingProps {
  locationsData?: FieldTripLocationsQuery;
  userId: string;
  tripId?: number;
}

function LocationRating(props: LocationRatingProps) {
  const classes = useStyles();
  const { locationsData, userId, tripId = -1 } = props;

  const { loading, data } = useFieldTripLocationReviewsQuery({
    variables: {
      tripId: tripId,
      userId: userId,
    },
  });

  const [
    addLocationReview,
    { error: addLocationReviewError },
  ] = useAddLocationReviewMutation({
    errorPolicy: "all",
  });

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  const initLocationMap: { [key: string]: number } = {};

  const locationMap = data?.field_trip_location_reviews.reduce((a, c) => {
    a[c.field_trip_location_id] = c.rating || 0;
    return a;
  }, initLocationMap);

  return (
    <Grid item lg={12} xs={12}>
      <ErrorToast
        error={addLocationReviewError}
        processCustomError={() =>
          `Unable to add rating - ${addLocationReviewError?.message}`
        }
      />

      <Typography variant="h6">Locations Rating</Typography>
      <Table className={classes.table}>
        <TableBody>
          {locationsData?.field_trip_locations.map((tripLocation) => (
            <TableRow
              key={`trip-location-rating-${tripLocation.id}`}
              className={classes.tableRow}
            >
              <TableCell>{tripLocation.location.name}</TableCell>
              <TableCell>
                <a
                  href={tripLocation.location.google_maps_url || "#"}
                  target="_blank"
                >
                  {tripLocation.location.address}
                </a>
              </TableCell>
              <TableCell>
                <StarRating
                  defaultValue={locationMap?.[tripLocation.id] || 0}
                  onChange={(v) => {
                    addLocationReview({
                      variables: {
                        field_trip_id: tripId,
                        rating: v,
                        user_id: userId,
                        field_trip_location_id: tripLocation.id,
                      },
                    });
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
}

interface ReflectProps {
  ownerId?: string;
  tripData?: FieldTripReflectQuery;
  meData?: FindMeQuery;
  studentData?: FieldTripStudentReflectQuery;
  locationsData?: FieldTripLocationsQuery;
  refetch?: (
    variables?:
      | Partial<
          Exact<{
            fieldTripId: any;
            studentId: any;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<FieldTripStudentReflectQuery>>;
}

type ExtraInfoType = {
  tripData?: FieldTripReflectQuery;
  meData?: FindMeQuery;
};

export function withExtraInfo<P>(
  WrappedComponent: React.ComponentType<P & ExtraInfoType>
) {
  const ComponentWithExtraInfo = (props: P) => {
    const params = useParams<FieldTripParams>();
    const { data, loading } = useFieldTripReflectQuery({
      variables: {
        uid: params.tripId,
      },
    });
    const { data: meData, loading: meLoading } = useFindMeQuery();
    return loading || meLoading ? (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    ) : (
      <WrappedComponent {...props} tripData={data} meData={meData} />
    );
  };
  return ComponentWithExtraInfo;
}

export default function Reflect(props: ReflectProps) {
  const params = useParams<FieldTripParams>();

  const Component = params.tripId
    ? withExtraInfo(ReflectComponent)
    : ReflectComponent;

  return <Component {...props} />;
}

const schema = yup.object().shape({
  //reflect_survey: yup.object().required("Reflect Survey is required"),
  enjoyed_most: yup
    .string()
    .required("What did you enjoy the most about this trip is required"),
});

export function ReflectComponent(props: ReflectProps) {
  const params = useParams<FieldTripParams>();
  const { meData } = props;
  const tripData = props.tripData?.field_trips[0];
  const me = meData?.me[0];

  const tripContextUserId =
    me?.role === User_Roles_Enum.Student ? me.id : tripData?.owner.id;

  const {
    loading: loadingStudents,
    data: studentData,
    refetch,
  } = useFieldTripStudentReflectQuery({
    variables: {
      fieldTripId: params.tripId,
      studentId: tripContextUserId,
    },
  });

  const {
    loading: locationsLoading,
    data: locationsData,
  } = useFieldTripLocationsQuery({
    variables: {
      tripId: params.tripId,
    },
  });

  if (loadingStudents || locationsLoading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <ReflectFormComponent
      {...props}
      studentData={studentData}
      locationsData={locationsData}
      refetch={refetch}
    />
  );
}

type IFormInput = Field_Trip_Students_Set_Input & { student_id: string };

export function ReflectFormComponent(props: ReflectProps) {
  const { send, readOnly } = useContext(MachineContext);
  const classes = useStyles();
  const tripData = props.tripData?.field_trips[0];

  const { studentData, meData, locationsData, refetch } = props;

  const me = meData?.me[0];

  const { data: questionsQuery } = useFieldTripReflectQuestionsQuery({
    variables: {
      school_id: meData?.me[0].school?.id,
    },
  });

  const tripContextUserId =
    me?.role === User_Roles_Enum.Student ? me.id : tripData?.owner.id;

  const studentReflectData = studentData?.field_trip_students[0];

  const form = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...studentReflectData,
      reflect_survey: studentReflectData?.reflect_survey || {},
      student_id: tripContextUserId,
    },
  });

  const [
    updateFieldTripStudent,
    { error: updateFieldTripStudentError },
  ] = useUpdateFieldTripStudentMutation({
    errorPolicy: "all",
  });

  const watchTripContextUserId = form.watch("student_id", tripContextUserId);

  const onSubmit = async (data: IFormInput) => {
    const { student_id, ...rest } = data;

    const selectedStudent = tripData?.field_trip_students.find(
      (u) => u.user.id === watchTripContextUserId
    );
    if (selectedStudent?.id !== undefined) {
      const { errors } = await updateFieldTripStudent({
        variables: {
          id: selectedStudent.id,
          object: {
            ...rest,
            stage: "share",
          },
        },
      });
      if (!errors) {
        send("next");
      }
    }
  };

  const changeStudentContext = async (value: any) => {
    if (refetch) {
      const { data } = await refetch({
        fieldTripId: tripData?.uid,
        studentId: value,
      });

      if (data.field_trip_students && data.field_trip_students.length > 0) {
        const {
          enjoyed_most,
          reflect_survey,
          improvements,
        } = data.field_trip_students[0];

        form.setValue("enjoyed_most", enjoyed_most);
        form.setValue("reflect_survey", reflect_survey);
        form.setValue("improvements", improvements);

        form.clearErrors();
      }
    }
  };

  function SelectStudent() {
    if (
      me?.role === User_Roles_Enum.Teacher ||
      me?.role === User_Roles_Enum.SchoolAdmin
    ) {
      return (
        <Grid item lg={12} xs={12}>
          <SelectForm
            name={"student_id"}
            label={"Select Student Context"}
            isClearable={false}
            options={
              tripData?.field_trip_students.map((u) => ({
                value: u.user.id,
                label: `${u.user.first_name} ${u.user.last_name}`,
              })) || []
            }
            onChange={changeStudentContext}
          />
        </Grid>
      );
    } else {
      return <></>;
    }
  }

  return (
    <FormProvider {...form} key={`reflect-form-${watchTripContextUserId}`}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ErrorToast
          error={updateFieldTripStudentError}
          processCustomError={() =>
            `Unable to update field trip - ${updateFieldTripStudentError?.message}`
          }
        />

        <Grid container spacing={2}>
          <SelectStudent />

          <StudentReflection form={form} questions={questionsQuery} />

          <LocationRating
            locationsData={locationsData}
            tripId={tripData?.id}
            userId={watchTripContextUserId}
          />

          <Grid item lg={6} xs={12}>
            <div className={classes.textAreaStyle}>
              <TextArea
                name="enjoyed_most"
                label="What did you enjoy the most about this trip?"
              />
            </div>
          </Grid>

          <Grid item lg={6} xs={12}>
            <div className={classes.textAreaStyle}>
              <TextArea
                name="improvements"
                label="What would you do differently if you could improve this learning experience?"
              />
            </div>
          </Grid>

          {!readOnly && (
            <Grid item container justify="flex-end" lg={12} xs={12}>
              <div className={classes.controls}>
                <Button className={classes.button} onClick={() => send("back")}>
                  Back
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  Next
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </form>
    </FormProvider>
  );
}
