export interface FieldTripParams {
  tripId?: string;
}

export type ProgressObj = {
  percentage: number;
  label: string;
  index: number;
};

export const progressMap: { [key: string]: ProgressObj } = {
  envision: { percentage: 0, label: "Envision", index: 0 },
  plan: { percentage: 20, label: "Plan", index: 1 },
  approve: { percentage: 40, label: "Approve", index: 2 },
  reflect: { percentage: 60, label: "Reflect", index: 3 },
  share: { percentage: 80, label: "Share", index: 4 },
  complete: { percentage: 100, label: "Complete", index: 5 },
};

export const supplyList = [
  { value: "Clipboard / Paper", label: "Clipboard / Paper" },
  { value: "Notebook / Journal", label: "Notebook / Journal" },
  { value: "Pencils", label: "Pencils" },
  { value: "Money", label: "Money" },
  { value: "Rain Gear", label: "Rain Gear" },
  { value: "First aid kit", label: "First aid kit" },
  { value: "Camera", label: "Camera" },
  { value: "Shopping bags", label: "Shopping bags" },
  { value: "Library card", label: "Library card" },
  { value: "Membership / Transit card", label: "Membership / Transit card" },
  {
    value: "Lunch / Snack and Water Bottle",
    label: "Lunch / Snack and Water Bottle",
  },
];

export const transportationOptionsList = [
  { value: "Bus", label: "Bus" },
  { value: "Subway", label: "Subway" },
  { value: "Walk", label: "Walk" },
  { value: "Car", label: "Car" },
];
