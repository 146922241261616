import React, { FunctionComponent } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

interface LabelProps {
  title: string;
  center?: boolean;
  errorMessage?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    center: {
      display: "flex",
      justifyContent: "center",
    },
  })
);

//@ts-ignore
const InputComponent = ({ inputRef, ...other }) => <div {...other} />;

export const Label: FunctionComponent<LabelProps> = ({
  title,
  children,
  center,
  errorMessage,
}) => {
  const classes = useStyles();
  return (
    <TextField
      variant="outlined"
      label={title}
      multiline
      fullWidth
      InputLabelProps={{ shrink: true }}
      InputProps={{
        //@ts-ignore
        inputComponent: InputComponent,
      }}
      inputProps={{
        children: children,
        className: center ? classes.center : "",
      }}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
    />
  );
};

export default Label;
