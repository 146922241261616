import React from "react";
import { ApolloError } from "@apollo/client";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { GraphQLError } from "graphql";

interface ErrorToast {
  error?: ApolloError;
  processCustomError?: (error: string) => string;
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ErrorToast: React.FC<ErrorToast> = ({ error, processCustomError }) => {
  const [mutationError, setMutationError] = React.useState<
    ApolloError | undefined
  >(error);

  React.useEffect(() => {
    setMutationError(error);
  }, [error]);

  return (
    <Snackbar
      open={mutationError !== undefined}
      autoHideDuration={6000}
      onClose={() => setMutationError(undefined)}
    >
      <Alert severity="error">
        {mutationError?.graphQLErrors.map(({ message }, i) => (
          <span key={i}>
            {processCustomError ? processCustomError(message) : message}
          </span>
        ))}
      </Alert>
    </Snackbar>
  );
};

export default ErrorToast;
