import React from "react";
import { red, blueGrey } from "@material-ui/core/colors";
import { createMuiTheme, Theme } from "@material-ui/core/styles";

function themeGen(primaryColor: string, secondaryColor: string) {
  return createMuiTheme({
    typography: {
      fontFamily: "'Source Sans Pro', serif",
      fontSize: 15,
    },
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
      error: {
        main: red.A400,
      },
      background: {
        default: "#fff",
      },
    },
  });
}

export type ThemeKey =
  | "mft"
  | "yellow"
  | "darkGreen"
  | "darkBlue"
  | "brown"
  | "purple"
  | "green"
  | "lightGreen"
  | "lightBrown"
  | "lightPurple"
  | "grey"
  | "dark";

type MftTheme = {
  [K in ThemeKey]: Theme;
};

export const themeMap: MftTheme = {
  mft: themeGen("#3dccc7", "#8ee9ce"),
  yellow: themeGen("#ced86d", "#e5ef82"),
  darkGreen: themeGen("#65bfa6", "#8ee9ce"),
  darkBlue: themeGen("#7cafbf", "#a5d7e8"),
  brown: themeGen("#bf8f65", "#e9b68a"),
  purple: themeGen("#7986cb", "#a0aef6"),
  green: themeGen("#88d498", "#a4f1b4"),
  lightGreen: themeGen("#aed581", "#caf29c"),
  lightBrown: themeGen("#d6ce93", "#f1e9ae"),
  lightPurple: themeGen("#95b8d1", "#b0d4ee"),
  grey: themeGen("#b8bdb5", "#d2d4c8"),
  dark: themeGen(blueGrey[900], blueGrey[400]),
};

export default React.createContext({
  currentTheme: "mft",
  updateTheme: (themeKey: ThemeKey) => {},
});
