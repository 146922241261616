import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import LogoutIcon from "@material-ui/icons/PowerSettingsNew";
import Link from "@material-ui/core/Link";
import { useLocation } from "react-router-dom";
import PermanentDrawerLeft, { drawerWidth } from "components/sidebar/SideBar";
import Redirect from "components/Redirect";
import { useFindMeQuery } from "../../generated/graphql";

const useStyles = makeStyles((theme: Theme) => ({
  appBar: (props: MenuAppBarProps) => ({
    "@media print": {
      display: "none",
    },
    width: props.fullWidth ? "100%" : `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    boxShadow: "none",
    zIndex: theme.zIndex.drawer + 1,
    background: "url('../images/mft-headerbg.jpg') no-repeat",
    backgroundSize: "cover",
    color: "#FFF",
  }),
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  userName: {
    cursor: "pointer",
  },
  profileMenu: {
    width: theme.spacing(32),
    height: theme.spacing(22),
    zIndex: 1000,
    right: "20px",
    position: "absolute",
    padding: theme.spacing(3, 2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  logout: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  bgColorTransparent: {
    color: "#000 !important",
    background: "none !important",
    borderBottom: "1px solid #d8d8d8",
  },
  logo: {
    backgroundSize: "contain !important",
    height: "50px",
    width: "195px",
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: "center !important",
  },
  centerLogo: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  brandingLogo: {
    verticalAlign: "middle",
  },
}));

interface MenuAppBarProps {
  fullWidth?: boolean;
}

export default function MenuAppBar(props: MenuAppBarProps) {
  const classes = useStyles(props);
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setShowDropdown((prevShow) => !prevShow);
  };

  const { loading, error, data } = useFindMeQuery();

  if (loading) {
    return null;
  }

  if (error || !data) {
    return <Redirect url="/login" />;
  }

  const me = data?.me[0];
  const name = `${me?.first_name} ${me?.last_name}`;

  return (
    <AppBar
      position="fixed"
      className={`${classes.appBar} ${
        scroll ? "" : classes.bgColorTransparent
      }`}
    >
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          {me?.school?.name}
        </Typography>

        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <span className={classes.userName} onClick={handleMenu}>
            {name}
          </span>

          {showDropdown && (
            <Paper className={classes.profileMenu} elevation={3}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography gutterBottom variant="h6">
                    {name}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {me?.email}
                  </Typography>
                  <Chip label={me?.role} color="primary" size="small" />

                  <Divider className={classes.divider} />

                  <Link
                    className={classes.logout}
                    onClick={() => (window.location.href = "/logout")}
                  >
                    <LogoutIcon />
                    <span>Logout</span>
                  </Link>
                </Grid>
              </Grid>
            </Paper>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}
