import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { useMachine } from "@xstate/react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import clsx from "clsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Stepper from "@material-ui/core/Stepper";
import { StepIconProps } from "@material-ui/core/StepIcon";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { FieldTripParams } from "./consts";

import { tripStateMachine, MachineContext } from "./state";

import {
  useFieldTripStatusQuery,
  FieldTripStatusQuery,
  useFindMeQuery,
  FindMeQuery,
  useIsTripCompleteQuery,
} from "../../generated/graphql";

import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    paper: {
      margin: "0 30px",
      padding: "30px",
    },
    controls: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "20px",
      marginRight: "30px",
    },
    step: {
      cursor: "pointer",
    },
  })
);

const useColorlibStepIconStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#ccc",
      zIndex: 1,
      color: "#fff",
      width: 50,
      height: 50,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
    },
    active: {
      background: theme.palette.primary.main,
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    },
    completed: {
      background: theme.palette.secondary.main,
    },
  })
);

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {props.icon}
    </div>
  );
}

type ExtraInfoType = {
  tripData?: FieldTripStatusQuery;
  meData?: FindMeQuery;
};

export function withExtraInfo<P>(
  WrappedComponent: React.ComponentType<P & ExtraInfoType>
) {
  const ComponentWithExtraInfo = (props: P) => {
    const params = useParams<FieldTripParams>();
    const { data, loading } = useFieldTripStatusQuery({
      variables: {
        uid: params.tripId,
      },
    });
    const { data: meData, loading: meLoading } = useFindMeQuery();
    return loading || meLoading ? (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    ) : (
      <WrappedComponent {...props} tripData={data} meData={meData} />
    );
  };
  return ComponentWithExtraInfo;
}

export default function TripWizard(props: ExtraInfoType) {
  const params = useParams<FieldTripParams>();

  const Component = params.tripId
    ? withExtraInfo(TripWrizardComponent)
    : TripWrizardComponent;

  const { data: meData } = useFindMeQuery();

  if (meData?.me[0].role === "school_admin" && params.tripId == undefined) {
    return <Redirect to={`/`} />;
  }

  return <Component {...props} />;
}

function TripWrizardComponent(props: ExtraInfoType) {
  const params = useParams<FieldTripParams>();
  const classes = useStyles();
  const { tripData, meData } = props;

  const { data: isComplete } = useIsTripCompleteQuery({
    variables: {
      tripId: params.tripId,
    },
  });
  const currentStage =
    tripData?.field_trips[0].my_trip_data?.stage ||
    tripData?.field_trips[0].status ||
    "envision";
  const stateMachine = tripStateMachine(currentStage);
  const machine = useMachine(stateMachine);
  const [stage, send] = machine;
  const currentStageData = stage?.meta[`trip.${stage.value}`];
  const CurrentComponent = currentStageData?.component || React.Fragment;

  if (
    meData?.me[0].role === "parent" ||
    isComplete?.field_trips_aggregate.aggregate?.count === 0
  ) {
    return <Redirect to={`/field_trip_detail/${params.tripId}`} />;
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        title={
          params.tripId
            ? `Field Trip - ${tripData?.field_trips[0].title}`
            : "New Field Trip"
        }
      />

      <MachineContext.Provider value={{ stage, send }}>
        <CardContent>
          <Stepper activeStep={currentStageData?.index}>
            {Object.entries(stateMachine.states).map(([key, value]) => {
              const stepProps: { completed?: boolean } = {};

              if (value.type === "final") {
                return <></>;
              }

              return (
                <Step
                  key={key}
                  {...stepProps}
                  onClick={(e) => send(key)}
                  className={classes.step}
                >
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {stateMachine.states[key].meta.label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <div>
            <Typography className={classes.instructions}>
              <Paper className={classes.paper} elevation={2}>
                <CurrentComponent />
              </Paper>
            </Typography>
          </div>
        </CardContent>
      </MachineContext.Provider>
    </Card>
  );
}
