import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import MaterialTable, { Column, Query } from "material-table";
import PublishIcon from "@material-ui/icons/Publish";
import Chip from "@material-ui/core/Chip";
import { CSVReader } from "react-papaparse";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FaceIcon from "@material-ui/icons/Face";
import Badge from "@material-ui/core/Badge";

import UserRelationship from "./UserRelationship";

import {
  UserFieldsFragment,
  useFindMeQuery,
  User_Roles_Enum,
  useFindUsersQuery,
  useUpdateUserMutation,
  useCreateUsersMutation,
  CreateUserInput,
  CreateUserRolesEnum,
  Users_Set_Input,
  FindClassRoomsQuery,
  useFindClassRoomsQuery,
  useCurrentUserNamesLazyQuery,
} from "../../generated/graphql";
import ErrorToast from "components/toast/ErrorToast";
import { TextField, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { name_regx, username_regx } from "utils";

interface TableState {
  schoolId: string;
  isLoading?: boolean;
  userRole: User_Roles_Enum | undefined | string | null;
  hideTitle?: boolean;
  classRoomData?: FindClassRoomsQuery;
}

interface DropItem {
  data: CreateUserInput;
  errors?: Array<string>;
}

const styles = (theme: Theme) =>
  createStyles({
    buttonBar: {
      display: "flex",
      alignItems: "center",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    clickable: {
      cursor: "pointer",
    },
    usernameField: {
      "& input": {
        fontSize: 13,
      },
      "& > p[class*='MuiFormHelperText']": {
        position: "absolute",
        bottom: -20,
        color: "#ff1744",
      },
    },
    downloadCSV: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      gap: "5px",
      marginBottom: 15,
      opacity: 0.7,
      "&:hover": {
        opacity: 1,
      },
    },
  });

const useStyles = makeStyles((theme: Theme) => styles(theme));

const DEFAULT_PAGE_SIZE = 10;

const TableRows: React.FC<TableState> = (props) => {
  const tableRef = React.createRef();
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [relationshipDialogData, setRelationshipDialogData] = React.useState<
    UserFieldsFragment | undefined
  >();
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    DEFAULT_PAGE_SIZE
  );

  const classes = useStyles();
  const { schoolId, hideTitle, classRoomData } = props;

  const { loading, refetch } = useFindUsersQuery({
    variables: {
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
      roles: {},
      school_id: schoolId !== undefined ? { _eq: schoolId } : {},
    },
  });

  const [
    getUserNames,
    { data: getUserNamesData, error: checkUserNamesError },
  ] = useCurrentUserNamesLazyQuery({
    errorPolicy: "all",
  });

  const [
    createUsersMutation,
    { loading: createLloading, error: createUserError },
  ] = useCreateUsersMutation({ errorPolicy: "all" });

  const [
    updateUserMutation,
    { loading: updateLoading, error: updateUserError },
  ] = useUpdateUserMutation({ errorPolicy: "all" });

  const insertUser = async (newData: UserFieldsFragment) => {
    const { school, role, class_room, ...rest } = newData;
    const newRole = Object.entries(CreateUserRolesEnum).find(
      (e) => e[1].valueOf() === role.valueOf()
    );
    await createUsersMutation({
      variables: {
        input: [
          {
            ...rest,
            school_id: schoolId,
            role: newRole && newRole[1],
            class_room_id: newData.class_room?.id,
          } as CreateUserInput,
        ],
      },
      refetchQueries: ["findUsers", "findClassRooms"],
    });

    //@ts-ignore
    tableRef.current && tableRef.current.onQueryChange();
  };

  const updateUser = async (
    newData: UserFieldsFragment,
    oldData: UserFieldsFragment | undefined
  ) => {
    const { id, first_name, last_name, email, role, status } = newData;
    await updateUserMutation({
      variables: {
        id: id,
        input: {
          first_name,
          last_name,
          email,
          role,
          status,
          class_room_id: newData.class_room?.id,
        } as Users_Set_Input,
      },
      refetchQueries: ["findUsers", "findClassRooms"],
    });
  };

  var isUserNameExist = "";

  if (getUserNamesData != undefined) {
    if (getUserNamesData.user_names[0]) {
      //@ts-ignore
      isUserNameExist = getUserNamesData?.user_names[0].username;
    }
  }
  const uploadCSV = async (items: Array<DropItem>) => {
    await createUsersMutation({
      variables: {
        input: items
          .filter((f) => f.data.first_name !== "")
          .map(({ data }: DropItem) => {
            return {
              ...data,
              school_id: schoolId,
            };
          }),
      },
    });
    setDialogOpen(false);
    window.location.reload();
  };

  const count = (
    rowData: UserFieldsFragment,
    key:
      | "user_relationships_backward_aggregate"
      | "user_relationships_forward_aggregate"
  ) => rowData[key].aggregate?.count || 0;

  const rolesAcc: { [key: string]: string } = {};

  const classRoomsAcc: { [key: string]: string } = {};

  let columns: Array<Column<UserFieldsFragment>> = [
    { title: "Id", field: "id", hidden: true },
    {
      title: "First Name",
      field: "first_name",
      validate: (rowData) => ({
        isValid: name_regx.test(rowData?.first_name || ""),
      }),
    },
    {
      title: "Last Name",
      field: "last_name",
      validate: (rowData) => ({
        isValid: name_regx.test(rowData?.last_name || ""),
      }),
    },
    {
      title: "Username",
      field: "username",
      editComponent: (editProps) => {
        return (
          <TextField
            className={classes.usernameField}
            id="user_name"
            helperText={isUserNameExist ? "username already exist" : null}
            placeholder="Username"
            onChange={(e) => editProps.onChange(e.target.value)}
            value={editProps.value}
            error={
              editProps.value == null ||
              editProps.value === isUserNameExist ||
              !username_regx.test(editProps.value)
            }
            onBlur={(e) => {
              if (e.target.value) {
                getUserNames({
                  variables: {
                    userName: e.target.value,
                  },
                });
              }
            }}
          />
        );
      },
      validate: (rowData) => {
        if (!username_regx.test(rowData.username) || !rowData.username) {
          return false;
        }
        return {
          isValid: rowData.username == isUserNameExist ? false : true,
        };
      },
      // validate: (rowData) => ({
      //   isValid: /^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/.test(
      //     `${rowData.username}`
      //   )
      //     ? true
      //     : false || rowData.username !== isUserNameExist,
      // }),
      editable: "onAdd",
    },
    {
      title: "E-Mail",
      field: "email",
      validate: (rowData) => ({
        isValid: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
          rowData?.email || ""
        ),
      }),
    },
    {
      title: "Role",
      field: "role",
      lookup: Object.entries(User_Roles_Enum).reduce((a, c) => {
        if (c[1].valueOf() != "system_admin") {
          a[c[1].valueOf()] = c[0].replace(/([A-Z])/g, " $1");
        }
        return a;
      }, rolesAcc),
    },
    {
      title: "Classroom",
      field: "class_room.id",
      lookup: classRoomData?.class_rooms.reduce((a, c) => {
        a[c.id] = c.name;
        return a;
      }, classRoomsAcc),
    },
  ];

  const remoteData = async (query: Query<UserFieldsFragment>) => {
    const newUsersData = await refetch({
      limit: query.pageSize,
      offset: query.page * query.pageSize,
    });
    return Promise.resolve({
      data: newUsersData.data?.users.map((o) => ({ ...o })) || [],
      page: query.page,
      totalCount: newUsersData.data?.users_aggregate.aggregate?.count || 0,
    });
  };

  return (
    <>
      <ErrorToast
        error={createUserError}
        processCustomError={() =>
          `Unable to create user - ${createUserError?.message}`
        }
      />
      <ErrorToast
        error={updateUserError}
        processCustomError={() =>
          `Unable to update user - ${updateUserError?.message}`
        }
      />

      <ErrorToast
        error={checkUserNamesError}
        processCustomError={() =>
          `Unable to check Username - ${checkUserNamesError?.message}`
        }
      />

      <Dialog
        open={dialogOpen}
        onClose={setDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">
          <Grid
            container
            spacing={2}
            justify="space-between"
            alignItems="center"
          >
            <span> Upload Bulk Add Users CSV File</span>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => setDialogOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div
            className={classes.downloadCSV}
            onClick={() => window.open("/users.csv")}
          >
            <span>Download Sample CSV File </span>{" "}
            <CloudDownloadIcon fontSize="small" />
          </div>
          <DialogContentText id="alert-dialog-description">
            <CSVReader
              onDrop={uploadCSV}
              onError={(y) => console.log(y)}
              addRemoveButton={false}
              onRemoveFile={(y) => console.log(y)}
              style={{ dropArea: { padding: "20px" } }}
              config={{
                header: true,
              }}
            >
              <span>Drag file here or browse</span>
            </CSVReader>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {relationshipDialogData && (
        <Dialog
          open={relationshipDialogData !== undefined}
          onClose={() => setRelationshipDialogData(undefined)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
        >
          <DialogTitle id="alert-dialog-title">
            {`Relationship Details`}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => setRelationshipDialogData(undefined)}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <UserRelationship
              {...relationshipDialogData}
              tableRef={tableRef}
              schoolId={schoolId}
            />
          </DialogContent>
        </Dialog>
      )}

      <MaterialTable
        tableRef={tableRef}
        title={hideTitle ? "" : "Users"}
        isLoading={loading || createLloading || updateLoading}
        options={{
          search: false,
          pageSize: rowsPerPage,
          pageSizeOptions: [10, 25, 50, 100],
          addRowPosition: "first",
          emptyRowsWhenPaging: false,
          actionsCellStyle: { textAlign: "center" },
          actionsColumnIndex: -1,
          loadingType: "overlay",
        }}
        onChangeRowsPerPage={setRowsPerPage}
        editable={{
          onRowAdd: insertUser,
          onRowUpdate: (newData, oldData) => updateUser(newData, oldData),
        }}
        columns={columns}
        data={remoteData}
        // icons={{
        //   Add: React.forwardRef((props, ref) => (
        //     //@ts-ignore
        //     <Chip
        //       {...props}
        //       ref={ref}
        //       className={classes.clickable}
        //       icon={<PersonAddIcon />}
        //       color="primary"
        //       label="Add User"
        //     ></Chip>
        //   )),
        // }}
        actions={[
          (rowData) => ({
            icon: () => {
              const relCount =
                count(rowData, "user_relationships_backward_aggregate") +
                count(rowData, "user_relationships_forward_aggregate");
              return (
                <Badge badgeContent={relCount} color="secondary">
                  <FaceIcon />
                </Badge>
              );
            },
            tooltip: "Add Relationship",
            onClick: (event, rowData) =>
              setRelationshipDialogData(
                Array.isArray(rowData) ? rowData[0] : rowData
              ),
          }),
          {
            icon: (props: any) => (
              <Chip
                {...props}
                icon={<PublishIcon />}
                color="primary"
                label="Bulk Add"
              ></Chip>
            ),
            isFreeAction: true,
            tooltip: "Bulk Add Users",
            onClick: (event, rowData) => setDialogOpen(true),
          },
        ]}
      />
    </>
  );
};

interface ListUsersProps {
  schoolId?: string;
  hideTitle?: boolean;
  classRoomData?: FindClassRoomsQuery;
}

export default function ListUserTable(props: ListUsersProps) {
  const { schoolId, hideTitle, classRoomData } = props;
  const { data: meData } = useFindMeQuery();
  const { data: clsData } = useFindClassRoomsQuery({
    variables: {
      school_id:
        meData?.me[0].school?.id !== undefined
          ? { _eq: meData?.me[0].school?.id }
          : {},
    },
  });

  return (
    <TableRows
      schoolId={schoolId || meData?.me[0].school?.id}
      userRole={meData?.me[0].role}
      hideTitle={hideTitle}
      classRoomData={classRoomData || clsData}
    />
  );
}
