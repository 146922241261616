import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import classes from "*.module.css";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DeleteIcon from "@material-ui/icons/Delete";
import Chip from "@material-ui/core/Chip";
import VideocamIcon from "@material-ui/icons/Videocam";
import {
  useFindMeQuery,
  useUpComingTripsQuery,
  useDeleteVirtualTripMutation,
  User_Roles_Enum,
  Order_By,
} from "../../generated/graphql";

import ErrorToast from "components/toast/ErrorToast";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List/List";
import Pagination from "@material-ui/lab/Pagination";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CreateVirtualTripForm from "./CreateVirtualTripForm";
import VirtualTripPopup from "./VirtualTripPopup";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import GetVirtualTripTitle from "./VirtualTripTitle";
import { DateTime } from "luxon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItem from "@material-ui/core/ListItem";
import VirtualTripDetail from "./VirtualTripDetail";

const useStyles = makeStyles((theme: Theme) =>
  //@ts-ignore
  createStyles({
    root: {
      maxWidth: "100%",
    },
    virtaulTripText: {
      fontSize: 26,
      fontWeight: 700,
      textAlign: "center",
      color: "#18566A",
    },
    tripTopicList: {
      paddingTop: 25,
    },
    borderBox: {
      border: "10px solid #1B8DB1",
      padding: "1.125rem",
      height: "100%",
    },
    tabsHeaderStyle: {
      marginBottom: 10,
      backgroundColor: "#FFF",
    },
    tabItemStyle: {
      width: "50%",
      minWidth: "unset",
      maxWidth: "none",
      fontWeight: 700,
      lineHeight: 1.2,
      fontSize: 14,
      "&.Mui-selected": {
        backgroundColor: "#efefef",
      },
    },
    tripItemRow: {
      paddingBottom: 12,
      borderBottom: "1px solid #EFEFEF",
      marginBottom: 12,
      overflow: "hidden",
      cursor: "pointer",
      transition: "all 0.5s",
      "& :hover h4": {
        color: theme.palette.primary.main,
      },
    },
    tripItemContent: {
      width: "100%",
      overflow: "hidden",
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      "& h4": {
        fontSize: "1.1rem",
        fontWeight: 700,
        lineHeight: "1rem",
        marginTop: 0,
        marginBottom: 6,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        height: "1.2rem",
      },
      "& p": {
        fontSize: "0.9rem",
        lineHeight: "0.8rem",
        marginTop: 0,
        color: "#666666",
        marginBottom: 6,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        height: "0.99rem",
      },
    },
    tripItemAction: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    dateStyle: {
      fontSize: "0.9rem !important",
      color: "#888888 !important",
      marginBottom: "6px !important",
    },
    tripOwner: {
      fontSize: 14,
      marginBottom: 8,
      color: "#1B8DB1 !important",
    },
    topicImage: {
      position: "relative",
      bottom: -6,
    },
    popupActionBtns: {
      paddingTop: 15,
      paddingBottom: 15,
    },
    author: {
      paddingTop: "1rem",
      color: "#999",
    },
    dialogHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& div": {
        lineHeight: 1.2,
      },
    },
    tripThumbnail: {
      marginRight: 12,
      "& .img": {
        position: "relative",
        zIndex: 5,
        width: 95,
        height: 95,
        borderRadius: 3,
        backgroundSize: "cover",
        backgroundPosition: "center",
      },
    },
    chipStyle: {
      fontSize: 12,
      lineHeight: "17px",
      height: "auto",
      backgroundColor: "#FFF",
      marginRight: "3px",
      marginBottom: "1px",
      textTransform: "uppercase",
    },
    btnIconWhite: {
      backgroundColor: "#FFF",
      width: 20,
      height: 20,
      "& svg": {
        fontSize: "18px",
      },
      "& :hover svg": {
        color: theme.palette.warning.dark,
      },
    },
    btnIconMeeting: {
      backgroundColor: "#4588FB",
      width: 20,
      height: 20,
      "& svg": {
        fontSize: "18px",
        color: "#FFFF",
      },
      "& :hover svg": {
        color: theme.palette.info.main,
      },
    },
    pagination: {
      "& ul": {
        justifyContent: "flex-end",
      },
    },
    schoolList: {
      paddingLeft: 20,
      listStyle: "decimal",
      "& li": {
        paddingBottom: 10,
        fontSize: "1.1rem",
      },
    },
    sortStyle: {
      display: "flex",
      justifyContent: "flex-end",
    },
    sortTextStyle: {
      fontWeight: 400,
      fontSize: 14,
      "& p": {
        margin: 0,
      },
      "& span": {
        opacity: 0.75,
      },
    },
  })
);

const options = ["Event Date", "Created Date"];

export default function VirtualTrips() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [schoolModal, setSchoolModal] = useState(false);
  const [schoolModalData, setSchoolModalData] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSchoolModal(false);
  };

  const { data: meData } = useFindMeQuery();
  const me = meData?.me[0];
  const disable = me?.role !== User_Roles_Enum.SystemAdmin ? true : false;

  const [
    deleteVirtualTrip,
    { data, error: virtaulTripDeleteError, loading },
  ] = useDeleteVirtualTripMutation({ errorPolicy: "all" });

  function showSchools(schools: any) {
    setSchoolModalData(schools);
    setSchoolModal(true);
  }

  function DateConvert(props: any) {
    if (props.date) {
      return (
        <>
          {DateTime.fromISO(`${props.date}`).toLocaleString(
            //@ts-ignore
            DateTime.DATETIME_FULL
          )}
        </>
      );
    }
    return <></>;
  }

  function DeleteTripItem(props: any) {
    const { itemData, deleteConfirm, setDeleteConfirm } = props;
    const handleClose = () => {
      setDeleteConfirm(false);
    };

    return (
      <>
        <Dialog open={deleteConfirm} onClose={handleClose}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to delete
              <b>
                {itemData != null ? (
                  <GetVirtualTripTitle topic={itemData.topic} />
                ) : (
                  ""
                )}
              </b>
              ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              color="primary"
              onClick={() => {
                deleteVirtualTrip({
                  variables: {
                    id: itemData.id,
                  },
                  refetchQueries: ["upComingTrips"],
                });
              }}
            >
              Yes
            </Button>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  function SchoolListModal(props: any) {
    const { schoolModalData } = props;
    const handleClose = () => {
      setSchoolModal(false);
    };
    return (
      <>
        <Dialog
          onClose={handleClose}
          aria-labelledby="school-list-modal"
          open={schoolModal}
          fullWidth={true}
          maxWidth="xs"
        >
          <DialogTitle id="school-list-modal">
            <div className={classes.dialogHeader}>
              <div>Schools</div>

              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent dividers>
            <ul className={classes.schoolList}>
              {schoolModalData &&
                schoolModalData.map((s: any) => (
                  <li key={s.school.id}>{s.school.name}</li>
                ))}
            </ul>
          </DialogContent>
        </Dialog>
      </>
    );
  }

  function SchoolList(props: any) {
    const { schools } = props;
    const school = schools.slice(0, 3).map((m: any) => {
      const fullName = m.school.name.split(" ");

      const first = fullName.shift().charAt(0);
      const last = fullName.length > 0 ? fullName.pop().charAt(0) : "";

      return {
        id: first + " " + last,
        name: m.school.name,
      };
    });

    return (
      <>
        {school?.map((s: any) => (
          <Tooltip key={s.id} title={`${s.name}`} placement="top" arrow>
            <Chip className={classes.chipStyle} label={`${s.id}`} />
          </Tooltip>
        ))}

        {schools.length > 3 && (
          <Tooltip title="View all Schools" placement="top" arrow>
            <Chip className={classes.chipStyle} label="..." />
          </Tooltip>
        )}
      </>
    );
  }

  function UpComingVirtualTripList() {
    const [openPopup, setOpenPopup] = useState(false);
    const [deleteConfirm, setDeleteConfirm] = useState(false);

    const [deleteTripItem, setDeleteTripItem] = useState(null);
    const [editTripItem, setEditTripItem] = useState(null);
    const [editPopupTitle, setEditPopupTitle] = useState("vitual trip");

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const itemsPerPage = 4;

    const {
      data: upComingTrips,
      error: upComingTripsError,
      loading: upComingTripsLoading,
      refetch: refetchVirtualTrips,
    } = useUpComingTripsQuery({
      variables: {
        dateRange: {
          _gte: "now()",
        },
        limit: itemsPerPage,
      },
    });

    const aggregateCount =
      upComingTrips?.virtual_trips_aggregate.aggregate?.count || 1;

    const [page, setPage] = React.useState(1);
    //@ts-ignore
    const noOfPages = Math.ceil(aggregateCount / itemsPerPage);

    const handleChange = (e: any, v: any) => {
      setPage(v);
      if (v) {
        refetchVirtualTrips({
          offset: (v - 1) * itemsPerPage,
          limit: itemsPerPage,
        });
      }
    };

    const handleMenuItemClick = (
      event: React.MouseEvent<HTMLElement>,
      index: number
    ) => {
      setSelectedIndex(index);
      setAnchorEl(null);

      if (index === 0) {
        refetchVirtualTrips({
          limit: itemsPerPage,
          sortCreatedDate: null,
          sortEventDate: Order_By.Asc,
        });
      } else {
        refetchVirtualTrips({
          limit: itemsPerPage,
          sortCreatedDate: Order_By.Desc,
          sortEventDate: null,
        });
      }
    };

    const openInPoup = (item: any) => {
      setEditTripItem(item);
      setOpenPopup(true);
      setEditPopupTitle(item.topic);
    };

    const deleteItem = (item: any) => {
      setDeleteTripItem(item);
      setDeleteConfirm(true);
    };

    if (upComingTripsLoading) {
      return (
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      );
    }

    return (
      <>
        <ErrorToast
          error={upComingTripsError}
          processCustomError={() =>
            `Unable to load upcoming virtual trips - ${upComingTripsError?.message}`
          }
        />
        <div className={classes.sortStyle}>
          <div className={classes.root}>
            <List component="nav" aria-label="Device settings">
              <ListItem
                button
                aria-haspopup="true"
                aria-controls="lock-menu"
                aria-label="when device is locked"
                onClick={handleClickListItem}
              >
                <div className={classes.sortTextStyle}>
                  <p>
                    <span>Sort By</span> {options[selectedIndex]}
                  </p>
                </div>
              </ListItem>
            </List>
            <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {options.map((option, index) => (
                <MenuItem
                  key={option}
                  //disabled={index === 0}
                  selected={index === selectedIndex}
                  onClick={(event) => handleMenuItemClick(event, index)}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
        <List>
          {upComingTrips?.virtual_trips?.map((item: any) => (
            <Grid
              container
              spacing={0}
              wrap="nowrap"
              className={classes.tripItemRow}
              key={item.id}
            >
              <div className={classes.tripItemContent}>
                <div className={classes.tripThumbnail}>
                  <div
                    className="img"
                    style={{
                      backgroundImage: `url("${
                        item.virtual_trip_images[0]?.image_link
                          ? item.virtual_trip_images[0]?.image_link
                          : "/images/no-image.jpg"
                      }")`,
                    }}
                  ></div>
                </div>
                <div style={{ overflow: "hidden" }}>
                  <div onClick={() => openInPoup(item)}>
                    <h4>
                      <GetVirtualTripTitle topic={item.topic} />
                    </h4>
                    <p>{item.description}</p>
                    <p className={classes.dateStyle}>
                      <DateConvert date={item.event_date} />
                    </p>
                    <p style={{ fontSize: 13, marginBottom: 2 }}>
                      Created {DateTime.fromISO(item.created_at).toRelative()}
                    </p>
                  </div>

                  {!disable && item.all_schools && (
                    <Tooltip title="Added all Schools" placement="top" arrow>
                      <Chip className={classes.chipStyle} label="All Schools" />
                    </Tooltip>
                  )}

                  {!disable && (
                    <>
                      {item.all_schools == false || item.all_schools == null ? (
                        <div
                          onClick={() => showSchools(item.virtual_trip_schools)}
                        >
                          <SchoolList schools={item.virtual_trip_schools} />
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className={classes.tripItemAction}>
                <Tooltip title="Meeting Link">
                  <IconButton
                    aria-label="delete"
                    className={classes.btnIconMeeting}
                    onClick={() => {
                      window.open(`${item.meeting_link}`, "_blank");
                    }}
                  >
                    <VideocamIcon />
                  </IconButton>
                </Tooltip>

                {!disable && (
                  <Tooltip title="Delete">
                    <IconButton
                      aria-label="delete"
                      className={classes.btnIconWhite}
                      onClick={() => {
                        deleteItem(item);
                      }}
                    >
                      <DeleteIcon fontSize="small" color="disabled" />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </Grid>
          ))}
        </List>

        <Box component="span">
          <Pagination
            //@ts-ignore
            count={noOfPages}
            page={page}
            onChange={handleChange}
            defaultPage={1}
            color="standard"
            size="small"
            showFirstButton
            showLastButton
            className={classes.pagination}
          />
        </Box>
        <DeleteTripItem
          deleteConfirm={deleteConfirm}
          setDeleteConfirm={setDeleteConfirm}
          itemData={deleteTripItem}
        />

        <VirtualTripPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          title={editPopupTitle}
        >
          {meData?.me[0].role !== User_Roles_Enum.SystemAdmin ? (
            <VirtualTripDetail
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
              addOrEdit={editTripItem}
            />
          ) : (
            <CreateVirtualTripForm
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
              addOrEdit={editTripItem}
            />
          )}
        </VirtualTripPopup>
        <SchoolListModal schoolModalData={schoolModalData} />
      </>
    );
  }

  function CompletedVirtualTripList() {
    const [openPopup, setOpenPopup] = useState(false);
    const [editTripItem, setEditTripItem] = useState(null);

    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [deleteTripItem, setDeleteTripItem] = useState(null);
    const [editPopupTitle, setEditPopupTitle] = useState("vitual trip");

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const itemsPerPage = 4;

    const {
      data: completedTrips,
      error: completedTripsError,
      loading: completedTripsLoading,
      refetch: refetchVirtualTrips,
    } = useUpComingTripsQuery({
      variables: {
        dateRange: {
          _lte: "now()",
        },
        limit: itemsPerPage,
      },
    });

    const aggregateCount =
      completedTrips?.virtual_trips_aggregate.aggregate?.count || 1;

    const [page, setPage] = React.useState(1);
    //@ts-ignore
    const noOfPages = Math.ceil(aggregateCount / itemsPerPage);

    const handleChange = (e: any, v: any) => {
      setPage(v);
      if (v) {
        refetchVirtualTrips({
          offset: (v - 1) * itemsPerPage,
          limit: itemsPerPage,
        });
      }
    };

    const handleMenuItemClick = (
      event: React.MouseEvent<HTMLElement>,
      index: number
    ) => {
      setSelectedIndex(index);
      setAnchorEl(null);

      if (index === 0) {
        refetchVirtualTrips({
          limit: itemsPerPage,
          sortCreatedDate: null,
          sortEventDate: Order_By.Asc,
        });
      } else {
        refetchVirtualTrips({
          limit: itemsPerPage,
          sortCreatedDate: Order_By.Asc,
          sortEventDate: null,
        });
      }
    };

    const openInPoup = (item: any) => {
      setEditTripItem(item);
      setOpenPopup(true);
      setEditPopupTitle(item.topic);
    };

    const deleteItem = (item: any) => {
      setDeleteTripItem(item);
      setDeleteConfirm(true);
    };

    if (completedTripsLoading) {
      return (
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      );
    }
    return (
      <>
        <ErrorToast
          error={completedTripsError}
          processCustomError={() =>
            `Unable to load completed virtual trips - ${completedTripsError?.message}`
          }
        />

        <div className={classes.sortStyle}>
          <div className={classes.root}>
            <List component="nav" aria-label="Device settings">
              <ListItem
                button
                aria-haspopup="true"
                aria-controls="lock-menu"
                aria-label="when device is locked"
                onClick={handleClickListItem}
              >
                <div className={classes.sortTextStyle}>
                  <p>
                    <span>Sort By</span> {options[selectedIndex]}
                  </p>
                </div>
              </ListItem>
            </List>
            <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {options.map((option, index) => (
                <MenuItem
                  key={option}
                  //disabled={index === 0}
                  selected={index === selectedIndex}
                  onClick={(event) => handleMenuItemClick(event, index)}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>

        <List>
          {completedTrips?.virtual_trips?.map((item: any) => (
            <Grid
              container
              spacing={0}
              wrap="nowrap"
              className={classes.tripItemRow}
              key={item.id}
            >
              <div className={classes.tripItemContent}>
                <div className={classes.tripThumbnail}>
                  <div
                    className="img"
                    style={{
                      backgroundImage: `url("${
                        item.virtual_trip_images[0]?.image_link
                          ? item.virtual_trip_images[0]?.image_link
                          : "/images/no-image.jpg"
                      }")`,
                    }}
                  ></div>
                </div>
                <div style={{ overflow: "hidden" }}>
                  <div onClick={() => openInPoup(item)}>
                    <h4>
                      <GetVirtualTripTitle topic={item.topic} />
                    </h4>
                    <p>{item.description}</p>
                    <p className={classes.dateStyle}>
                      <DateConvert date={item.event_date} />
                    </p>
                    <p style={{ fontSize: 13, marginBottom: 2 }}>
                      Created {DateTime.fromISO(item.created_at).toRelative()}
                    </p>
                  </div>
                  {!disable && (
                    <div onClick={() => showSchools(item.virtual_trip_schools)}>
                      <SchoolList schools={item.virtual_trip_schools} />
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.tripItemAction}>
                <Tooltip title="Meeting Link">
                  <IconButton
                    aria-label="delete"
                    className={classes.btnIconMeeting}
                    onClick={() => {
                      window.open(`${item.meeting_link}`, "_blank");
                    }}
                  >
                    <VideocamIcon />
                  </IconButton>
                </Tooltip>
                {!disable && (
                  <Tooltip title="Delete">
                    <IconButton
                      aria-label="delete"
                      className={classes.btnIconWhite}
                      onClick={() => {
                        deleteItem(item);
                      }}
                    >
                      <DeleteIcon fontSize="small" color="disabled" />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </Grid>
          ))}
        </List>

        <Box component="span">
          <Pagination
            //@ts-ignore
            count={noOfPages}
            page={page}
            onChange={handleChange}
            defaultPage={1}
            color="standard"
            size="small"
            showFirstButton
            showLastButton
            className={classes.pagination}
          />
        </Box>
        <DeleteTripItem
          deleteConfirm={deleteConfirm}
          setDeleteConfirm={setDeleteConfirm}
          itemData={deleteTripItem}
        />

        <VirtualTripPopup
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          title={editPopupTitle}
        >
          {meData?.me[0].role !== User_Roles_Enum.SystemAdmin ? (
            <VirtualTripDetail
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
              addOrEdit={editTripItem}
            />
          ) : (
            <CreateVirtualTripForm
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
              addOrEdit={editTripItem}
            />
          )}
        </VirtualTripPopup>
        <SchoolListModal schoolModalData={schoolModalData} />
      </>
    );
  }

  function TripTabs() {
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      setValue(newValue);
    };

    function TabPanel(props: any) {
      const { children, value, index } = props;

      return <div>{value === index && <div>{children}</div>}</div>;
    }

    return (
      <>
        <Tabs
          className={classes.tabsHeaderStyle}
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="virtual trips"
        >
          <Tab className={classes.tabItemStyle} label="Upcoming Trips" />
          <Tab className={classes.tabItemStyle} label="Completed Trips" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <UpComingVirtualTripList />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CompletedVirtualTripList />
        </TabPanel>
      </>
    );
  }

  return (
    <>
      <ErrorToast
        error={virtaulTripDeleteError}
        processCustomError={() =>
          `Unable to delete virtual trip - ${virtaulTripDeleteError?.message}`
        }
      />
      <TripTabs />
    </>
  );
}
