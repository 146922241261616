import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import GetVirtualTripTitle from "./VirtualTripTitle";
const useStyles = makeStyles((theme: Theme) =>
  //@ts-ignore
  createStyles({
    dialogHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& div": {
        lineHeight: 1.2,
      },
    },
  })
);

export default function VirtualTripPopup(props: any) {
  const classes = useStyles();
  const { title, children, openPopup, setOpenPopup } = props;

  const handleClose = () => {
    setOpenPopup(false);
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="create-edit-virtual-trip"
        open={openPopup}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="create-edit-virtual-trip">
          <div className={classes.dialogHeader}>
            <div>
              <GetVirtualTripTitle topic={title} />
            </div>

            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent dividers>{children}</DialogContent>
      </Dialog>
    </>
  );
}
