import React, { useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { FormProvider, useForm } from "react-hook-form";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import SelectForm from "components/form/Select";
import InputField from "components/form/Input";
import TextArea from "components/form/TextArea";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { uniq } from "lodash";
import { FieldTripParams } from "./consts";
import ErrorToast from "components/toast/ErrorToast";
import { MachineContext } from "./state";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import ReportProblemRoundedIcon from "@material-ui/icons/ReportProblemRounded";

import {
  useFindUsersQuery,
  User_Roles_Enum,
  useFindMeQuery,
  useCreateFieldTripMutation,
  useFieldTripEnvisionQuery,
  Field_Trips_Insert_Input,
  FieldTripEnvisionQuery,
  Field_Trips_Set_Input,
  useUpdateFieldTripMutation,
  useRemoveFieldTripStudentMutation,
  UserFieldsFragment,
  useAddFieldTripStudentMutation,
  useHasParentLazyQuery,
} from "generated/graphql";

//@ts-ignore
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles((theme: Theme) =>
  //@ts-ignore
  createStyles({
    noParentTitle: {
      fontWeight: 700,
    },
    noParentContent: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: "8px",
      "& p": {
        fontSize: 20,
        marginTop: 10,
        marginBottom: 0,
        color: theme.palette.grey[700],
        "& span": {
          color: theme.palette.grey[900],
        },
      },
    },
    noParentIcon: {
      textAlign: "center",
      "& svg": {
        fontSize: "4.5rem",
      },
    },
  })
);

interface EnvisionProps {
  ownerId?: string;
  tripData?: FieldTripEnvisionQuery;
}

type ExtraInfoType = {
  tripData?: FieldTripEnvisionQuery;
};

export function withExtraInfo<P>(
  WrappedComponent: React.ComponentType<P & ExtraInfoType>
) {
  const ComponentWithExtraInfo = (props: P) => {
    const params = useParams<FieldTripParams>();
    const { data, loading } = useFieldTripEnvisionQuery({
      variables: {
        uid: params.tripId,
      },
    });
    return loading ? (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    ) : (
      <WrappedComponent {...props} tripData={data} />
    );
  };
  return ComponentWithExtraInfo;
}

export default function Envision(props: EnvisionProps) {
  const params = useParams<FieldTripParams>();

  const Component = params.tripId
    ? withExtraInfo(EnvisionComponent)
    : EnvisionComponent;

  return <Component {...props} />;
}

const schema = yup.object().shape({
  title: yup.string().required("Title is required"),
  interest_area: yup.string().required("Interest Area is required"),
  students: yup.array().required("Student selection required"),
  research_questions_goals: yup
    .string()
    .required("Research Questions and Goals required"),
  owner_id: yup.string().required("Trip owner required"),
});

function EnvisionComponent(props: EnvisionProps) {
  const { send, readOnly } = useContext(MachineContext);
  const history = useHistory();
  const { data: meData } = useFindMeQuery();
  const params = useParams<FieldTripParams>();

  const [
    createFieldTripMutation,
    { loading: createLoading, error: createFieldTripError },
  ] = useCreateFieldTripMutation({
    errorPolicy: "all",
  });

  const [
    updateFieldTripMutation,
    { error: updateFieldTripError },
  ] = useUpdateFieldTripMutation({
    errorPolicy: "all",
  });

  const [
    addStudent,
    { error: addStudentError },
  ] = useAddFieldTripStudentMutation({
    errorPolicy: "all",
  });

  const me = meData?.me[0];

  let studentList: any = [];
  if (props.tripData) {
    studentList = props.tripData?.field_trips[0].field_trip_students.map(
      (student) => student.user.id
    );
    studentList.splice(
      studentList.indexOf(props.tripData?.field_trips[0].owner_id),
      1
    );
    studentList.unshift(props.tripData?.field_trips[0].owner_id);
  }
  const [tripStudents, setTripStudents] = React.useState(studentList || []);

  React.useEffect(() => {
    if (tripStudents.length) {
      form.setValue("students", tripStudents);
    }
  }, [tripStudents]);

  let defaultData = undefined;
  if (me?.role === User_Roles_Enum.Student && props.tripData === undefined) {
    defaultData = {
      owner_id: me.id,
      students: [me.id],
    };
  }
  if (props.tripData) {
    const { ...rest } = props.tripData?.field_trips[0];
    defaultData = {
      ...rest,
      //@ts-ignore
      students: tripStudents,
    };
  }

  const form = useForm<Field_Trips_Insert_Input | Field_Trips_Set_Input>({
    resolver: yupResolver(schema),
    defaultValues: defaultData,
  });

  const onSubmit = async (data: Field_Trips_Insert_Input) => {
    // @ts-ignore
    const { students, owner_id, ...rest } = data;

    if (params.tripId !== undefined) {
      //  update trip envision data
      try {
        const { errors } = await updateFieldTripMutation({
          variables: {
            id: params.tripId,
            object: {
              ...rest,
            },
          },
          refetchQueries: ["fieldTripEnvision"],
        });
        if (!errors) {
          send("next");
        }
      } catch (e) {
        console.error(e);
      }
      return;
    }

    const { errors, data: createdData } = await createFieldTripMutation({
      variables: {
        object: {
          ...rest,
          school_id: me?.school?.id,
          owner_id: owner_id,
          field_trip_students: {
            data: uniq([owner_id, ...students]).map((id) => ({ user_id: id })),
          },
        },
      },
    });
    if (!errors) {
      history.push(
        `/edit_field_trip/${createdData?.insert_field_trips_one?.uid}`
      );
    }
  };

  const { data, loading } = useFindUsersQuery({
    variables: {
      offset: 0,
      limit: 100,
      roles: { _in: [User_Roles_Enum.Student] },
      school_id: {},
    },
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ErrorToast
          error={createFieldTripError}
          processCustomError={() =>
            `Unable to create field trip - ${createFieldTripError?.message}`
          }
        />
        <ErrorToast
          error={updateFieldTripError}
          processCustomError={() =>
            `Unable to update field trip - ${updateFieldTripError?.message}`
          }
        />
        <ErrorToast
          error={addStudentError}
          processCustomError={() =>
            `Unable to insert student - ${addStudentError?.message}`
          }
        />
        <Grid container spacing={2}>
          <Grid item lg={4} xs={12}>
            {loading ? (
              <Grid container justify="center">
                <CircularProgress />
              </Grid>
            ) : (
              <SelectForm
                name={"owner_id"}
                label={"Trip Owner"}
                isDisabled={me?.role !== User_Roles_Enum.Teacher}
                options={
                  data?.users.map((u) => ({
                    value: u.id,
                    label: `${u.first_name} ${u.last_name}`,
                  })) || []
                }
                onChange={(value) => {
                  if (value === undefined && !form.watch("owner_id")) {
                    form.setValue("students", []);
                  } else {
                    const studentsList: any = form.watch("students") || [];
                    if (studentsList.includes(value)) {
                      studentsList.splice(studentsList.indexOf(value), 1);
                      studentsList.unshift(value);
                    } else {
                      studentsList.unshift(value);
                      if (params.tripId !== undefined) {
                        addStudent({
                          variables: {
                            fieldTripId: props.tripData?.field_trips[0].id,
                            userId: value,
                          },
                        });
                      }
                    }
                    form.setValue("students", studentsList);
                  }
                }}
              />
            )}
          </Grid>

          <Grid item lg={4} xs={12}>
            <InputField name="title" label="Title" />
          </Grid>

          <Grid item lg={4} xs={12}>
            <InputField name="interest_area" label="Interest Area" />
          </Grid>

          <Grid item lg={12} xs={12}>
            <StudentSelection
              students={data?.users}
              loading={loading}
              fieldTripId={params.tripId}
              fieldTripRowId={props.tripData?.field_trips[0].id}
              ownerId={form.watch("owner_id")}
              watchStudents={form.watch("students")}
              setTripStudents={setTripStudents}
            />
          </Grid>

          <Grid item lg={12} xs={12}>
            <TextArea
              name="research_questions_goals"
              label="Research Questions and Goals"
            />
          </Grid>

          {!readOnly && (
            <Grid item container justify="flex-end">
              <Button variant="contained" color="primary" type="submit">
                Next
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    </FormProvider>
  );
}

interface StudentSelectionProps {
  students?: Array<UserFieldsFragment>;
  loading: boolean;
  fieldTripId?: string;
  fieldTripRowId?: number;
  ownerId?: string;
  watchStudents?: Array<string>;
  setTripStudents?: React.Dispatch<React.SetStateAction<Array<string>>>;
}

function StudentSelection(props: StudentSelectionProps) {
  const [
    removeFieldTripStudent,
    { error: removeStudentError },
  ] = useRemoveFieldTripStudentMutation({
    errorPolicy: "all",
  });
  const classes = useStyles();
  const [openPopup, setOpenPopup] = React.useState(false);

  const [hasParent, { data: hasparentData }] = useHasParentLazyQuery({
    errorPolicy: "all",
    fetchPolicy: "network-only",
  });
  const backwordRelation =
    hasparentData?.users[0].user_relationships_backward_aggregate.aggregate
      ?.count;
  const fowawrdRelation =
    hasparentData?.users[0].user_relationships_forward_aggregate.aggregate
      ?.count;

  React.useEffect(() => {
    if (fowawrdRelation === 0 && backwordRelation === 0) {
      setOpenPopup(true);

      //@ts-ignore
      setTripStudents(
        //@ts-ignore
        watchStudents?.filter(
          (student) => student !== hasparentData?.users[0].id
        )
      );
    } else if (hasparentData?.users[0].id) {
      //insert student
      addStudent({
        variables: {
          fieldTripId: fieldTripRowId,
          userId: hasparentData?.users[0].id,
        },
      });
    }
  }, [hasparentData?.users]);

  const handleClose = () => {
    setOpenPopup(false);
  };

  const checkHasParent = (studentId: any, fieldTripId: any) => {
    if (studentId) {
      hasParent({
        variables: {
          studentId: studentId,
        },
      });
    }
  };

  const [
    addStudent,
    { error: addStudentError },
  ] = useAddFieldTripStudentMutation({
    errorPolicy: "all",
  });

  const {
    loading,
    students,
    fieldTripId,
    fieldTripRowId,
    ownerId,
    watchStudents,
    setTripStudents,
  } = props;

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  //@ts-ignore
  const changeStudent = (value, actionMetadata) => {
    if (fieldTripId !== undefined) {
      switch (actionMetadata.action) {
        case "remove-value":
          // remove student
          removeFieldTripStudent({
            variables: {
              fieldTripId,
              userId: actionMetadata.removedValue.value,
            },
          });
          break;
        case "select-option":
          checkHasParent(actionMetadata.option.value, fieldTripId);
          break;
        case "clear":
          //@ts-ignore
          setTripStudents([ownerId]);
          watchStudents?.map((student) => {
            if (student !== ownerId) {
              removeFieldTripStudent({
                variables: {
                  fieldTripId,
                  userId: student,
                },
              });
            }
          });
          break;
        default:
          console.log("not supported");
      }
    }

    switch (actionMetadata.action) {
      case "clear":
        //@ts-ignore
        setTripStudents([ownerId]);
        break;
      default:
        console.log("not supported");
    }
  };

  return (
    <>
      <ErrorToast
        error={addStudentError}
        processCustomError={() =>
          `Unable to insert student - ${addStudentError?.message}`
        }
      />
      <ErrorToast
        error={removeStudentError}
        processCustomError={() =>
          `Unable to remove student - ${removeStudentError?.message}`
        }
      />

      <SelectForm
        name={"students"}
        label={"Students"}
        mode="multiple"
        fixed={true}
        options={
          students?.map((u) => ({
            value: u.id,
            label: `${u.first_name} ${u.last_name}`,
          })) || []
        }
        onChange={(value, actionMetadata) =>
          changeStudent(value, actionMetadata)
        }
      />

      <Dialog
        onClose={handleClose}
        aria-labelledby="create-edit-virtual-trip"
        open={openPopup}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogContent>
          <div className={classes.noParentIcon}>
            <ReportProblemRoundedIcon style={{ color: "#f2d76b" }} />
          </div>
          <div className={classes.noParentContent}>
            <p>
              <span>
                {`${hasparentData?.users[0].first_name} ${hasparentData?.users[0].last_name}`}{" "}
              </span>
              doesn't have a parent assigned
            </p>
          </div>
        </DialogContent>
        <DialogActions disableSpacing={true}>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
