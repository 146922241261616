import React from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import DashboardIcon from "@material-ui/icons/Dashboard";
import BusinessIcon from "@material-ui/icons/Business";
import EventIcon from "@material-ui/icons/Event";
import ListIcon from "@material-ui/icons/List";
import PeopleIcon from "@material-ui/icons/People";
import EventNoteIcon from "@material-ui/icons/EventNote";
import PublicIcon from "@material-ui/icons/Public";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useLocation } from "react-router-dom";
import {
  useFindMeQuery,
  User_Roles_Enum,
  useSchoolInfoQuery,
} from "../../generated/graphql";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { SvgIconTypeMap } from "@material-ui/core/SvgIcon";
import ListItemLink from "components/link";
import { Grid } from "@material-ui/core";
import { whiteColor } from "assets/jss/material-dashboard-react";

export let drawerWidth = 240;

const styles = (theme: Theme) =>
  createStyles({
    drawer: {
      "@media print": {
        display: "none",
      },
      width: drawerWidth,
      flexShrink: 0,
      boxShadow:
        "0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 17%), 0 8px 10px -5px rgb(0 0 0 / 81%)",
      "& .MuiDrawer-paperAnchorDockedLeft": {
        borderRight: 0,
      },
      "& .defaultLogo": {
        backgroundColor: "transparent",
      },
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
      //backgroundColor: theme.palette.primary.main,
      backgroundColor: whiteColor,
      borderBottom: "1px solid rgba(255, 255, 255, 0.4)",
    },
    brandingLogo: {
      verticalAlign: "middle",
    },
    drawerPaper: {
      width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    spinner: {
      display: "flex",
      justifyContent: "center",
      margin: theme.spacing(5, 0),
    },
    title: {
      fontSize: "33px",
      fontFamily: "Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif",
    },
    logo: {
      backgroundSize: "contain !important",
      height: "50px",
      width: "195px",
      backgroundRepeat: "no-repeat !important",
      backgroundPosition: "center !important",
    },
    centerLogo: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    sideBarBg: {
      height: "100%",
      background: "url(../images/mft-sidebar.jpg) no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center top",
      borderRight: "0 !important",
    },
    sideBarNavs: {
      paddingRight: "12px",
      paddingLeft: "12px",
      paddingTop: "20px",
      "& li": {
        marginBottom: "1px",
      },
      "& *": {
        color: "#FFF",
      },
      "& [class*='MuiListItem-root'].Mui-selected, [class*='MuiListItem-root']:hover": {
        //background: "rgba(255, 255, 255, 0.4)",
        background: theme.palette.primary.main,
        borderRadius: "3px",
      },
    },
  });

const { Parent, Student, Teacher, SchoolAdmin, SystemAdmin } = User_Roles_Enum;

interface SideBardLink {
  text: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  path: string;
  restrictedRoles?: Array<User_Roles_Enum>;
}

const getRoleValue = (roleEnum: User_Roles_Enum) => roleEnum.valueOf();

const Links = (schoolId: string) => [
  { text: "Dashboard", Icon: DashboardIcon, path: "/dashboard" },
  {
    text: "Create School",
    Icon: BusinessIcon,
    path: "/create_school",
    restrictedRoles: [Parent, Teacher, Student, SchoolAdmin],
  },
  {
    text: "Update School",
    Icon: BusinessIcon,
    path: `/edit_school/${schoolId}`,
    restrictedRoles: [Parent, Teacher, Student, SystemAdmin],
  },
  {
    text: "List Schools",
    Icon: ListIcon,
    path: "/list_schools",
    restrictedRoles: [Parent, Teacher, Student, SchoolAdmin],
  },
  {
    text: "List Users",
    Icon: PeopleIcon,
    path: "/list_users",
    restrictedRoles: [Student, Parent, SystemAdmin],
  },
  {
    text: "Create Field Trip",
    Icon: EventIcon,
    path: "/create_field_trip",
    restrictedRoles: [Parent, SystemAdmin, SchoolAdmin],
  },
  { text: "List Field Trips", Icon: EventNoteIcon, path: "/list_field_trips" },
  {
    text: "Virtual Trips",
    Icon: PublicIcon,
    path: "/create_virtual_trip",
    restrictedRoles: [Parent, Teacher, Student, SchoolAdmin],
  },
];

const useStyles = makeStyles((theme: Theme) => styles(theme));

type StyleProps = WithStyles<typeof styles>;

function SideBarLinks(props: StyleProps) {
  const { classes } = props;
  const { loading, error, data } = useFindMeQuery();

  if (loading) {
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return null;
  }

  const userRole = data?.me[0].role;

  const virtualTripEnabled = data?.me[0].school?.school_product_features
    .map((m) => m.product_feature.feature_slug)
    .includes("virtual-field-trip");

  if (!userRole) {
    return null;
  }

  return (
    <List className={classes.sideBarNavs}>
      {Links(data?.me[0].school?.id)
        .filter(
          (a) =>
            !a.restrictedRoles ||
            !a.restrictedRoles.map((e) => getRoleValue(e)).includes(userRole)
        )
        .map(({ text, Icon, path }) => (
          <ListItemLink key={text} text={text} icon={<Icon />} to={path} />
        ))}

      {virtualTripEnabled ? (
        <ListItemLink
          key="Virtual Trips"
          text="Virtual Trips"
          icon={<PublicIcon />}
          to="/share_virtual_trip"
        />
      ) : null}
    </List>
  );
}

interface SidebarProps {
  logoUrl: any;
}

export default function PermanentDrawerLeft(props: SidebarProps) {
  //@ts-ignore
  const classes = useStyles();
  const { loading, error, data } = useFindMeQuery();

  // const { data: schoolData, loading: schoolDataLoading } = useSchoolInfoQuery({
  //   variables: {
  //     schoolId: data?.me[0].school?.id,
  //   },
  // });

  const logoUrl = props.logoUrl;

  const fieldTripProduct = data?.me[0].school?.school_product_features
    ? data?.me[0].school?.school_product_features
    : [];

  const currentPath = useLocation();

  const isPhysicalTrip = Object.values(
    fieldTripProduct.map((m) => m.product_feature.feature_slug)
  ).includes("field-trip");

  if (data?.me[0].role !== User_Roles_Enum.SystemAdmin) {
    if (!isPhysicalTrip || currentPath.pathname === "/select_trip_type") {
      return null;
    }
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: `${classes.drawerPaper} ${classes.sideBarBg}`,
      }}
      anchor="left"
    >
      {/* <div className={classes.toolbar} /> */}
      <div
        className={`${classes.drawerHeader} ${logoUrl ? "" : "defaultLogo"}`}
      >
        {loading ? (
          <Grid container alignItems="center" justify="center">
            <CircularProgress color="primary" />
          </Grid>
        ) : logoUrl ? (
          <b className={classes.centerLogo}>
            <div
              style={{ background: `url(${logoUrl})` }}
              className={classes.logo}
            ></div>
          </b>
        ) : (
          <b>
            <img
              src="/images/logo.png"
              width={"41px"}
              alt="home"
              className={classes.brandingLogo}
            />
            <span className={`hidden-xs ${classes.title}`}>
              {" "}
              <span style={{ color: "#10b7b3" }}>my</span>
              <span style={{ color: "#2086aa" }}>fieldtrip</span>
            </span>
          </b>
        )}
      </div>
      <Divider />

      <SideBarLinks classes={classes} />
    </Drawer>
  );
}
