import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import NavBar from "components/nav/NavBar";
import { Switch, Route } from "react-router-dom";

import PermissionSlip from "components/trip/PermissionSlip";
import ChaperoneRequest from "components/trip/ChaperoneRequest";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      backgroundColor: "#f0f2f5",
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: "100vh",
    },
  })
);

export default function Layout() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <NavBar fullWidth />

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <Route
            path="/preview/permission_slip/:tripId"
            component={PermissionSlip}
          />
          <Route
            path="/preview/chaperon_request/:tripId"
            component={ChaperoneRequest}
          />
        </Switch>
      </main>
    </div>
  );
}
