import React from "react";
import { Auth } from "aws-amplify";

export default function Logout() {
  async function signOut() {
    try {
      await Auth.signOut()
        .then((res) => {
          localStorage.clear();
          window.location.replace("/login");
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }
  signOut();
  ///localStorage.clear();

  // localStorage.removeItem("token");
  // localStorage.removeItem("access_token");

  //return <Redirect to="/login" />;
  return <></>;

  // if (location.hash) {
  //   const params = new URLSearchParams(location.hash.substring(1));
  //   if (params.get("id_token")) {
  //     localStorage.setItem("token", params.get("id_token"));
  //     localStorage.setItem("access_token", params.get("access_token"));
  //     return <Redirect to="/dashboard" />;
  //   }
  // }

  // return (
  //   <form>
  //     <Alert message={"Authentication Error"} type={"danger"} />
  //   </form>
  // );
}
