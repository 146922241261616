import React, { FunctionComponent, useContext, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import AddIcon from "@material-ui/icons/Add";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import CloseIcon from "@material-ui/icons/Close";
import { FormProvider, useForm } from "react-hook-form";
import { CircularProgress, Grid } from "@material-ui/core";
import InputField from "components/form/Input";
import TextArea from "components/form/TextArea";
import "rc-time-picker/assets/index.css";
import { FieldTripParams } from "./consts";
import AutoComplete from "components/form/AutoComplete";
import { DateTime } from "luxon";
import GooglePlaceBox from "components/google/GooglePlaceBox";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ErrorToast from "components/toast/ErrorToast";

//@ts-ignore
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  useFieldTripLocationsQuery,
  useLocationsByNameQuery,
  Field_Trip_Locations_Insert_Input,
  useCreateFieldTripLocationMutation,
  useDeleteFieldTripLocationMutation,
} from "generated/graphql";
import { MachineContext } from "./state";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  hidden: {
    visibility: "hidden",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  clickable: {
    cursor: "pointer",
  },
  oddEvenStyle: {
    "& li:first-child": {
      "& [class*='MuiTimelineSeparator-root']": {
        "& [class*='MuiTimelineConnector-root']:first-child": {
          backgroundColor: "transparent",
        },
      },
    },
    "& li:last-child": {
      "& [class*='MuiTimelineSeparator-root']": {
        "& [class*='MuiTimelineConnector-root']:last-child": {
          backgroundColor: "transparent",
        },
      },
    },
    "& li:nth-child(odd)": {
      "& [class*='MuiTimelineItem-content']": {
        "& [class*='MuiGrid-container']": {
          flexFlow: "row",
          justifyContent: "flex-end",
          "& .actionColumn": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
            textAlign: "right",
          },
        },
      },
    },
    "& li:nth-child(even)": {
      "& [class*='MuiTimelineSeparator-root']": {
        marginLeft: "1px",
      },
      "& [class*='MuiTimelineItem-content']": {
        "& [class*='MuiGrid-container']": {
          flexFlow: "row-reverse",
          justifyContent: "flex-end",
          "& .actionColumn": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            textAlign: "left",
          },
        },
      },
    },
  },
  locationDeleteBtn: {
    marginTop: "12px",
    width: "25px",
    height: "25px",
    paddingTop: "4px",
  },
}));

const StyledDialog = withStyles({
  root: {
    position: "fixed",
    //@ts-ignore
    zIndex: "9999999 !important",
    right: "0px",
    bottom: "0px",
    top: "0px",
    left: "0px",
  },
})(Dialog);

interface TimeLineItemProps {
  locationId: string;
  time: string;
  title: string;
  address: string;
  type: "location" | "food";
  google_maps_url: string;
}

const iconMap = {
  location: <LocationOnIcon />,
  food: <FastfoodIcon />,
};

const Item: FunctionComponent<TimeLineItemProps> = ({
  locationId,
  time,
  title,
  address,
  type,
  google_maps_url,
}) => {
  const classes = useStyles();
  const [
    deleteFieldTripLocation,
    { error: deleteError },
  ] = useDeleteFieldTripLocationMutation({
    errorPolicy: "all",
  });

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const deleteTripLocation = (locationId: string) => {
    deleteFieldTripLocation({
      variables: {
        id: locationId,
      },
      refetchQueries: ["fieldTripLocations"],
    });

    setOpen(false);
  };

  return (
    <TimelineItem>
      <TimelineOppositeContent></TimelineOppositeContent>
      <ErrorToast
        error={deleteError}
        processCustomError={(msg) =>
          msg.includes(
            "field_trip_location_reviews_field_trip_location_id_fkey"
          )
            ? "A user has already reviewed this trip, you cannot delete this location"
            : msg
        }
      />
      <TimelineSeparator>
        <TimelineConnector />
        <IconButton size="small" onClick={() => window.open(google_maps_url)}>
          <TimelineDot color="primary">
            {iconMap[type] || <MoreHorizIcon />}
          </TimelineDot>
        </IconButton>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Paper elevation={3} className={classes.paper}>
          <Grid container spacing={1}>
            <Grid item lg={8} md={12}>
              <Typography variant="h6" component="h1">
                {title}
              </Typography>
              <Typography>{address}</Typography>
            </Grid>
            <Grid item lg={4} md={12} container>
              <div className="actionColumn">
                <div>
                  <Typography variant="body2" color="textSecondary">
                    {DateTime.fromISO(time).toFormat("ff")}
                  </Typography>
                </div>
                <div>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    size="small"
                    className={classes.locationDeleteBtn}
                    onClick={() => {
                      handleClickOpen();
                    }}
                  >
                    <Tooltip placement="top" title="Delete">
                      <DeleteOutline style={{ fontSize: 18 }} />
                    </Tooltip>
                  </IconButton>
                </div>
              </div>
            </Grid>
          </Grid>
        </Paper>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to delete <b>{title}</b>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              color="primary"
              onClick={() => {
                deleteTripLocation(locationId);
              }}
            >
              Yes
            </Button>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </TimelineContent>
    </TimelineItem>
  );
};

const schema = yup.object().shape({
  title: yup.string().required("Title is required"),
  address: yup.string().required("Address is required"),
  location_type: yup.string().required("Type is required"),
  time: yup.string().required("Time is required"),
  notes: yup.string(),
  contact_info: yup.string(),
  location_id: yup.string(),
  location_point: yup.string(),
  google_maps_url: yup.string(),
});

const types = ["location", "food"];

interface AddItemProps extends FieldTripParams {
  tripDbId?: number;
}

interface NewLocationDialogProps {
  setShowNewItem: React.Dispatch<React.SetStateAction<boolean>>;
  tripDbId?: number;
  showNewItem: boolean;
}

function NewLocationDialog(props: NewLocationDialogProps) {
  const classes = useStyles();

  const { setShowNewItem, tripDbId, showNewItem } = props;

  const form = useForm<Field_Trip_Locations_Insert_Input>({
    resolver: yupResolver(schema),
  });

  const { loading, refetch } = useLocationsByNameQuery();

  const loadLocations = async (value: string) => {
    //@ts-ignore
    const { data } = await refetch({
      name: value.endsWith("%") ? value : `${value}%`,
    });

    return data.locations.map((l) => ({ label: l.name, value: l }));
  };

  const filterTypes = (inputValue: string) => {
    return types
      .map((t) => ({ value: t, label: t }))
      .filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const promiseTypes = (inputValue: any) =>
    new Promise((resolve) => {
      resolve(filterTypes(inputValue));
    });

  const [
    createFieldTripLocation,
    { error: createFieldTripLocationError },
  ] = useCreateFieldTripLocationMutation({
    errorPolicy: "all",
  });

  const onSubmit = async (data: Field_Trip_Locations_Insert_Input) => {
    //@ts-ignore
    const {
      //@ts-ignore
      title,
      //@ts-ignore
      address,
      location_id,
      //@ts-ignore
      location_point,
      //@ts-ignore
      google_maps_url,
      //@ts-ignore
      contact_info,
      ...rest
    } = data;
    const newRecord: Field_Trip_Locations_Insert_Input = {
      ...rest,
      contact_info,
      field_trip_id: tripDbId,
    };

    if (location_id) {
      newRecord["location_id"] = location_id;
    } else {
      newRecord.location = {
        data: {
          name: title,
          address: address,
          google_maps_url,
          location_point: location_point,
        },
      };
    }

    const { errors, data: createdData } = await createFieldTripLocation({
      variables: {
        object: newRecord,
      },
      refetchQueries: ["fieldTripLocations"],
    });

    if (!errors) {
      setShowNewItem(false);
    }
  };

  const watchAddress = form.watch("address");
  const watchLocationPoint = form.watch("location_point");

  function resetForm() {
    form.setValue("address", "");
    form.setValue("location_id", undefined);
  }
  return (
    <>
      <ErrorToast
        error={createFieldTripLocationError}
        processCustomError={() =>
          `Unable to create field trip location - ${createFieldTripLocationError?.message}`
        }
      />
      <StyledDialog
        open={showNewItem}
        onClose={() => {
          resetForm();
          setShowNewItem(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          {`Add New Event`}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              resetForm();
              setShowNewItem(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item lg={12} xs={12}>
                  <AutoComplete
                    name="title"
                    label="Location Title"
                    loadOptions={loadLocations}
                    onChange={(v) => {
                      console.log(v);
                      if (typeof v === "object") {
                        form.setValue("location_id", v.id);
                        form.setValue("address", v.address);
                        form.setValue("title", v.name);
                        form.setValue("location_point", v.location_point);
                        form.setValue("google_maps_url", v.google_maps_url);
                      } else {
                        resetForm();
                      }
                    }}
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <GooglePlaceBox
                    onLocationChange={(data: any) => {
                      form.setValue("address", data.address);
                      form.setValue(
                        "location_point",
                        `${data.lng},${data.lat}`
                      );
                      form.setValue("google_maps_url", data.url);
                    }}
                    address={watchAddress}
                    point={watchLocationPoint}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <AutoComplete
                    name="location_type"
                    label="Type"
                    loadOptions={promiseTypes}
                    defaultOptions
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <InputField name="time" label="Time" type="datetime-local" />
                </Grid>

                <Grid item lg={6} xs={12}>
                  <TextArea name="notes" label="Notes" />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextArea name="contact_info" label="Contact" />
                </Grid>

                <Grid item lg={12} xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ marginBottom: 25 }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </DialogContent>
      </StyledDialog>
    </>
  );
}

const AddItem: FunctionComponent<AddItemProps> = ({ tripDbId }) => {
  const classes = useStyles();
  const [showNewItem, setShowNewItem] = useState(false);

  return (
    <>
      {showNewItem && (
        <NewLocationDialog
          tripDbId={tripDbId}
          showNewItem={showNewItem}
          setShowNewItem={setShowNewItem}
        />
      )}

      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="secondary" className={classes.clickable}>
            <AddIcon onClick={() => setShowNewItem(true)} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.hidden}>
            <Typography variant="h6" component="h1"></Typography>
            <Typography></Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    </>
  );
};

interface TimelineProps extends FieldTripParams {
  tripDbId?: number;
}

export default function CustomizedTimeline(props: TimelineProps) {
  const classes = useStyles();
  const { readOnly } = useContext(MachineContext);
  const { loading, data } = useFieldTripLocationsQuery({
    variables: {
      tripId: props.tripId,
    },
  });

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Timeline align="alternate" className={classes.oddEvenStyle}>
      {data?.field_trip_locations.map(
        ({
          id,
          time,
          location_type,
          location: { address, name, google_maps_url },
        }) => (
          <Item
            locationId={id}
            key={`timeline-item-${time}`}
            time={time}
            title={name}
            address={address || ""}
            //@ts-ignore
            type={location_type}
            google_maps_url={google_maps_url || ""}
          />
        )
      )}

      {!readOnly && <AddItem {...props} />}
    </Timeline>
  );
}
