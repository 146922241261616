import React from "react";
import { Link } from "react-router-dom";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Icon from "@material-ui/core/Icon";
import DateRange from "@material-ui/icons/DateRange";
import StatCard from "components/card/Card.js";
import StatCardHeader from "components/card/CardHeader.js";
import StatCardIcon from "components/card/CardIcon.js";
import EventNoteIcon from "@material-ui/icons/EventNote";
import StatCardFooter from "components/card/CardFooter.js";

import { DateTime } from "luxon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";

import {
  useDashboardCountQuery,
  useFindMeQuery,
  useDashboardTripsSummaryQuery,
} from "generated/graphql";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import { CircularProgress, Typography } from "@material-ui/core";
import {
  blackColor,
  hexToRgb,
  whiteColor,
} from "assets/jss/material-dashboard-react";
import CardBody from "components/card/CardBody";
import classNames from "classnames";
import CardFooter from "components/card/CardFooter.js";
import Grid from "@material-ui/core/Grid";
import ErrorToast from "components/toast/ErrorToast";

const useStyles = makeStyles((theme: Theme) =>
  //@ts-ignore
  createStyles({
    ...styles,
    root: {
      maxWidth: "100%",
    },
    grid: {
      flexGrow: 1,
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    wrapperCard: {
      overflow: "unset",
      height: "100%",
      boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
      "& [class*='MuiTypography-h6']": {
        color: whiteColor,
      },
    },
    cardHeaderStyle: {
      border: "0",
      margin: "0 15px 0 15px",
      borderRadius: "3px",
      color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
      width: "auto",
      boxShadow: "0 5px 8px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
      position: "relative",
      display: "flex",
      minWidth: "0",
      gap: "10px",
      wordWrap: "break-word",
      fontSize: ".875rem",
      top: "-25px",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "20px 25px",
      "& h6": {
        lineHeight: 1.2,
      },
    },
    cardBodyStyle: {
      paddingTop: 0,
      "& ul li:last-child": {
        borderBottom: "0 !important",
      },
      "& ul li:first-child": {
        paddingTop: "0 !important",
      },
    },
    cardFooterStyle: {
      borderTop: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.06)",
      padding: "0.9375rem 20px",
      justifyContent: "center",
    },
    badgeCount: {
      background: whiteColor,
      borderRadius: "3px",
      width: "30px",
      textAlign: "center",
      height: "30px",
      lineHeight: "30px",
      fontSize: "16px",
      color: "rgba(" + hexToRgb(blackColor) + ", 0.6)",
    },
    listItemStyle: {
      padding: "8px 0px",
      borderBottom: "1px solid rgba(" + hexToRgb(blackColor) + ", 0.06)",
      "& [class*='MuiTypography-colorTextSecondary']": {
        fontSize: "14px",
        color: "rgba(" + hexToRgb(blackColor) + ", 0.35)",
      },
      "& [class*='MuiListItemText-multiline']": {
        margin: 0,
      },
    },
    listItemLInkStyle: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      textDecoration: "none",
      color: "#666",
      padding: "5px 8px",
      borderRadius: "2px",
      "&:hover": {
        backgroundColor: "#efefef",
      },
      "& [class*='MuiListItemText'] > span": {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        paddingRight: 10,
      },
    },
    viewAllStyle: {
      textDecoration: "none",
      color: "#333",
    },
    cardMainText: {
      fontSize: "22px",
    },
    cardTitle: {
      fontSize: 26,
      color: blackColor,
      fontWeight: 400,
    },
  })
);

interface dashboardTripStageProps {
  section?: string;
  title?: string;
  headerColorClass?: string;
  count?: number | null;
  data?: any;
}

function DashboardTripsStages(props: dashboardTripStageProps) {
  const { section, title, headerColorClass, count, data } = props;
  const classes = useStyles();

  const cardHeaderStyles = classNames({
    [classes.cardHeaderStyle]: true,
    [classes.bgBlue]: headerColorClass === "bgBlue",
    [classes.bgOrange]: headerColorClass === "bgOrange",
    [classes.bgGreen]: headerColorClass === "bgGreen",
  });

  return (
    <Card className={classes.wrapperCard}>
      <div className={cardHeaderStyles}>
        <div>
          <Typography variant="h6">{title}</Typography>
        </div>
        <div>
          <div className={classes.badgeCount}>{count}</div>
        </div>
      </div>
      <CardBody className={classes.cardBodyStyle}>
        <List>
          {count == 0 ? (
            <Typography variant="body1" color="textSecondary">
              No Trips found!
            </Typography>
          ) : (
            data?.map((s: any) => (
              <ListItem className={classes.listItemStyle}>
                <Link
                  className={classes.listItemLInkStyle}
                  to={{ pathname: `/edit_field_trip/${s.uid}` }}
                >
                  <ListItemText
                    primary={`${s.title}`}
                    secondary={`${s.event_date}`}
                    title={`${s.title}`}
                  />
                  <ChevronRightRoundedIcon fontSize="small" />
                </Link>
              </ListItem>
            ))
          )}
        </List>
      </CardBody>
      {
        //@ts-ignore
        count >= 6 ? (
          <CardFooter className={classes.cardFooterStyle}>
            <Link
              className={classes.viewAllStyle}
              to={{ pathname: "/list_field_trips/" }}
            >
              View All
            </Link>
          </CardFooter>
        ) : null
      }
    </Card>
  );
}

interface IFormInput {
  name: string;
}

export default function Dashboard() {
  const classes = useStyles();
  const { data: meData } = useFindMeQuery();
  const logged = meData?.me[0];
  const {
    data: tripSummary,
    loading: tripSummaryLoading,
    error: tripSummaryError,
  } = useDashboardTripsSummaryQuery({
    variables: {
      limit: 5,
      userId: logged?.role === "parent" ? { _eq: logged.id } : {},
    },
  });

  const { loading, data } = useDashboardCountQuery({
    variables: {
      since: DateTime.local().minus({ days: 7 }).toSQLDate(),
      next: DateTime.local().plus({ days: 14 }).toSQLDate(),
    },
  });

  if (loading || tripSummaryLoading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      <Card className={classes.root}>
        <CardHeader title="Dashboard" />

        <CardContent>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <StatCard>
                <StatCardHeader color="warning" stats icon>
                  <StatCardIcon color="warning">
                    <Icon>people</Icon>
                  </StatCardIcon>
                  <p
                    className={`${classes.cardCategory} ${classes.cardMainText}`}
                  >
                    New Users
                  </p>
                  <h3 className={classes.cardTitle}>
                    {data?.users_aggregate.aggregate?.count}
                  </h3>
                </StatCardHeader>
                <StatCardFooter stats>
                  <div className={classes.stats}>
                    <DateRange />
                    Last 7 days
                  </div>
                </StatCardFooter>
              </StatCard>
            </GridItem>

            <GridItem xs={12} sm={6} md={6}>
              <StatCard>
                <StatCardHeader color="success" stats icon>
                  <StatCardIcon color="success">
                    <EventNoteIcon />
                  </StatCardIcon>
                  <p
                    className={`${classes.cardCategory} ${classes.cardMainText}`}
                  >
                    Upcoming Trips
                  </p>
                  <h3 className={classes.cardTitle}>
                    {data?.field_trips_aggregate.aggregate?.count}
                  </h3>
                </StatCardHeader>
                <StatCardFooter stats>
                  <div className={classes.stats}>
                    <DateRange />
                    Next 14 days
                  </div>
                </StatCardFooter>
              </StatCard>
            </GridItem>
          </GridContainer>
          <br />
          <br />
          <br />
          <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <DashboardTripsStages
                section="approve"
                title="Needs Approval"
                headerColorClass="bgBlue"
                count={tripSummary?.needsApprovalCount.aggregate?.count}
                data={tripSummary?.needsApproval}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <DashboardTripsStages
                section="reflect"
                title="Needs Reflection"
                headerColorClass="bgOrange"
                count={tripSummary?.needsReflectionCount.aggregate?.count}
                data={tripSummary?.needsReflection}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <DashboardTripsStages
                section="complete"
                title="Completed"
                headerColorClass="bgGreen"
                count={tripSummary?.completedTripsCount.aggregate?.count}
                data={tripSummary?.completedTrips}
              />
            </GridItem>
          </GridContainer>
        </CardContent>
      </Card>

      <ErrorToast
        error={tripSummaryError}
        processCustomError={() =>
          `Unable to load Trip Summary detail: ${tripSummaryError?.message}`
        }
      />
    </>
  );
}
