import React from "react";
import { useHistory, Link } from "react-router-dom";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import {
  ListSchoolsQuery,
  useListSchoolsQuery,
  School_Types_Enum,
} from "../../generated/graphql";
import Edit from "@material-ui/icons/Edit";
import styles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import MaterialTable, { Column, Query } from "material-table";
import { capitalize } from "utils";

const useStyles = makeStyles((theme: Theme) =>
  //@ts-ignore
  createStyles({
    ...styles,
    root: {
      maxWidth: "100%",
    },
    headerColumn: {
      fontWeight: 600,
    },
  })
);

const DEFAULT_PAGE_SIZE = 10;

export default function ListSchools() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader title="Schools" />

      <CardContent>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TableRows />
          </GridItem>
        </GridContainer>
      </CardContent>
    </Card>
  );
}

const TableRows: React.FC<any> = (props) => {
  const tableRef = React.createRef();

  const [rowsPerPage, setRowsPerPage] = React.useState<number>(
    DEFAULT_PAGE_SIZE
  );

  const { data, loading, refetch } = useListSchoolsQuery({
    variables: {
      limit: 10,
    },
  });

  const classes = useStyles();
  const history = useHistory();
  const remoteData = async (query: Query<ListSchoolsQuery>) => {
    const newUsersData = await refetch({
      limit: query.pageSize,
      offset: query.page * query.pageSize,
    });
    return Promise.resolve({
      data: newUsersData.data?.schools.map((o) => ({ ...o })) || [],
      page: query.page,
      totalCount: newUsersData.data?.schools_aggregate.aggregate?.count || 0,
    });
  };

  let columns: Array<Column<ListSchoolsQuery>> = [
    { title: "Id", field: "id", hidden: true },
    { title: "School Name", field: "name", hidden: false },
    {
      title: "Type",
      field: "school_type",
      render: (data) => {
        return (
          <>
            {
              //@ts-ignore
              capitalize(data.school_type, "_")
            }
          </>
        );
      },
      hidden: false,
    },
    {
      title: "Number of Users",
      field: "users_aggregate.aggregate.count",
      hidden: false,
      align: "center",
      render: (data) => {
        return (
          <Link
            to={{
              //@ts-ignore
              pathname: `/edit_school/${data.id}`,
              hash: "#users",
            }}
          >
            {
              //@ts-ignore
              data?.users_aggregate?.aggregate?.count
            }
          </Link>
        );
      },
    },
    {
      title: "Number of Class Rooms",
      field: "class_rooms_aggregate.aggregate.count",
      hidden: false,
      align: "center",
    },
    {
      title: "Actions",
      field: "actions",
      hidden: false,
      align: "center",
      render: (data) => {
        return (
          <IconButton
            aria-label="Edit"
            className={classes.tableActionButton}
            onClick={() =>
              //@ts-ignore
              history.push(`/edit_school/${data.id}`)
            }
          >
            <Edit
              className={classes.tableActionButtonIcon + " " + classes.edit}
            />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      <MaterialTable
        tableRef={tableRef}
        title={""}
        isLoading={loading}
        options={{
          search: false,
          pageSize: rowsPerPage,
          pageSizeOptions: [10, 25, 50, 100],
          addRowPosition: "first",
          emptyRowsWhenPaging: false,
          actionsCellStyle: { textAlign: "center" },
          actionsColumnIndex: -1,
          loadingType: "overlay",
        }}
        onChangeRowsPerPage={setRowsPerPage}
        columns={columns}
        //@ts-ignore
        data={remoteData}
      />
    </>
  );
};
