import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import {
  createMuiTheme,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { green, orange, blue } from "@material-ui/core/colors";
import Icon from "@material-ui/core/Icon";
import SelectForm from "components/form/Select";
import { AgreementList } from "components/school/AgreementQuestions";
import { QuestionTypeEnum } from "components/school/consts";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import {
  useListAgreementQuestionsQuery,
  FieldTripPlanQuery,
  useFieldTripApproveQuery,
  useFieldTripStudentsQuery,
  useFindMeQuery,
  ListAgreementQuestionsQuery,
  User_Roles_Enum,
  FieldTripStudentsQuery,
  FindMeQuery,
  ApproveFieldsFragment,
  useUpdateFieldTripStudentMutation,
  useFieldTripApprovalsQuery,
  ApprovalUserFieldsFragment,
  FieldTripStudentFieldsFragment,
  useCreateFieldTripApprovalMutation,
  useFindUsersQuery,
  useUpdateFieldTripMutation,
} from "generated/graphql";
import { FieldTripParams } from "./consts";
import { MachineContext } from "./state";

//@ts-ignore
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorToast from "components/toast/ErrorToast";

interface ApproveProps {
  ownerId?: string;
  tripData?: FieldTripPlanQuery;
}

type ExtraInfoType = {
  tripData?: FieldTripPlanQuery;
};

export function withExtraInfo<P>(
  WrappedComponent: React.ComponentType<P & ExtraInfoType>
) {
  const ComponentWithExtraInfo = (props: P) => {
    const params = useParams<FieldTripParams>();
    const { data, loading } = useFieldTripApproveQuery({
      variables: {
        uid: params.tripId,
      },
    });
    return loading ? (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    ) : (
      <WrappedComponent {...props} tripData={data} />
    );
  };
  return ComponentWithExtraInfo;
}

export default function Approve(props: ApproveProps) {
  const params = useParams<FieldTripParams>();

  const Component = params.tripId
    ? withExtraInfo(ApproveComponent)
    : ApproveComponent;

  return <Component {...props} />;
}

const getFilteredQuestions = (
  type: QuestionTypeEnum,
  data?: ListAgreementQuestionsQuery
) =>
  data?.agreement_questions
    .filter((q) => q.type === type.toString())
    .map((q) => ({ label: q.label, type, id: q.id }));

const schema = yup.object().shape({
  grace_courtesy_approval: yup
    .boolean()
    .required("Grace and Courtesy approval is required"),
  transportation_logistics_approval: yup
    .boolean()
    .required("Transportation and Logistics approval is required"),
  student_id: yup.string().required("Student Context required"),
});

export function ApproveComponent(props: ApproveProps) {
  const { data: meData, loading: meLoading } = useFindMeQuery();
  const tripData = props.tripData?.field_trips[0];
  const {
    loading: agreementLoading,
    data: agreementData,
  } = useListAgreementQuestionsQuery({
    variables: {
      schoolId: tripData?.school_id,
      type: {},
    },
  });
  const {
    loading: loadingStudents,
    data: studentData,
  } = useFieldTripStudentsQuery({
    variables: {
      tripId: tripData?.id,
    },
  });

  if (loadingStudents || meLoading || agreementLoading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <AgreementUI
      meData={meData}
      studentData={studentData}
      agreementData={agreementData}
      tripData={tripData}
    />
  );
}

interface CheckProps {
  value?: boolean | null;
}
function Check(props: CheckProps) {
  const { value } = props;
  return value ? (
    <Icon
      className="fa fa-check-circle"
      color="primary"
      style={{ fontSize: 30 }}
    />
  ) : (
    <Icon
      className="fa fa-times-circle"
      color="secondary"
      style={{ fontSize: 30 }}
    />
  );
}
interface ApprovalButtonProps {
  user: FieldTripStudentFieldsFragment;
  approvalMap?: {
    [key: string]: ApprovalUserFieldsFragment;
  };
  tripData?: ApproveFieldsFragment;
}

const genBgColor = (theme: Theme, color: any) => {
  return {
    color: theme.palette.getContrastText(color[500]),
    backgroundColor: color[500],
    "&:hover": {
      backgroundColor: color[700],
    },
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pendingButton: genBgColor(theme, orange),
    requestButton: genBgColor(theme, blue),
    approvedButton: genBgColor(theme, green),
    popupButton: {
      zIndex: 1,
    },
    controls: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "20px",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    items: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      minHeight: 40,
      gap: "10px",
    },
    iconCircle: {
      width: 26,
      height: 26,
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.main,
      "& a": {
        display: "block",
        height: 26,
        lineHeight: "30px",
        textAlign: "center",
      },
      "& svg": {
        color: "#FFF",
        fontSize: 16,
      },
    },
  })
);

function ApprovalButton(props: ApprovalButtonProps) {
  const { readOnly } = useContext(MachineContext);
  const classes = useStyles();
  const { user, approvalMap, tripData } = props;

  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [
    createParentApproval,
    { loading: parentApprovalLoading, error: createParentApprovalError },
  ] = useCreateFieldTripApprovalMutation({
    errorPolicy: "all",
  });

  if (user.user_relationships_forward.length === 0) {
    return (
      <>
        <Tooltip title="No parent assigned" placement="top">
          <InfoIcon color="primary" />
        </Tooltip>
      </>
    );
  }

  function ParentApproval(props: any) {
    const { parentData, approvalMap } = props;
    const [open, setOpen] = React.useState(false);

    const handleClick = (username: any) => {};

    const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
        return;
      }

      setOpen(false);
    };

    const handleMenuItemClick = (
      event: React.MouseEvent<HTMLLIElement, MouseEvent>,
      isChaperone: boolean,
      parentId: string
    ) => {
      setOpen(false);

      createParentApproval({
        variables: {
          object: {
            field_trip_id: tripData?.id,
            user_id: parentId,
            is_chaperone: isChaperone,
          },
        },
        refetchQueries: ["fieldTripApprovals"],
      });
    };

    const approvalUser = approvalMap && approvalMap[parentData.to_user.id];
    if (approvalUser?.status === "pending") {
      return (
        <div className={classes.items}>
          <Button
            variant="contained"
            disabled
            size="small"
            className={classes.pendingButton}
          >
            Pending
          </Button>
        </div>
      );
    } else if (approvalUser?.status === "approved") {
      return (
        <>
          <div className={classes.items}>
            <Button
              variant="contained"
              disabled
              size="small"
              className={classes.approvedButton}
            >
              Approved
            </Button>{" "}
            <Tooltip title="View permission slip" placement="top">
              <div className={classes.iconCircle}>
                <a
                  href={`/preview/permission_slip/${tripData?.uid}`}
                  target="_blank"
                >
                  <DescriptionOutlinedIcon />
                </a>
              </div>
            </Tooltip>
          </div>
        </>
      );
    }

    return (
      <>
        <div className={classes.items}>
          <ButtonGroup
            variant="contained"
            color="primary"
            ref={anchorRef}
            aria-label="split button"
            disabled={readOnly}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                handleClick(parentData?.to_user?.last_name);
              }}
              className={classes.requestButton}
              disabled={parentApprovalLoading}
            >
              Request Permission
            </Button>
            <Button
              color="primary"
              size="small"
              aria-controls={open ? "split-button-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              disabled={parentApprovalLoading}
              className={classes.requestButton}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
        </div>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          className={classes.popupButton}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    <MenuItem
                      selected={true}
                      onClick={(event) =>
                        handleMenuItemClick(
                          event,
                          false,
                          parentData?.to_user.id
                        )
                      }
                    >
                      Request Approval
                    </MenuItem>
                    <MenuItem
                      onClick={(event) =>
                        handleMenuItemClick(event, true, parentData?.to_user.id)
                      }
                    >
                      Request Chaperone
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  }

  const approvals = user.user_relationships_forward.filter(
    (u) => approvalMap && approvalMap[u.to_user.id]
  );

  return (
    <>
      <ErrorToast
        error={createParentApprovalError}
        processCustomError={() =>
          `Unable to process parent approval - ${createParentApprovalError?.message}`
        }
      />
      {user?.user_relationships_forward?.map((m) => (
        <div key={m.to_user.id}>
          <ParentApproval approvalMap={approvalMap} parentData={m} />
        </div>
      ))}
    </>
  );
}

interface AdminApprovalButtonProps {
  approvalMap?: {
    [key: string]: ApprovalUserFieldsFragment;
  };
  tripData?: ApproveFieldsFragment;
}

function AdminApprovalButton(props: AdminApprovalButtonProps) {
  const { readOnly } = useContext(MachineContext);
  const { approvalMap, tripData } = props;
  const { loading, data } = useFindUsersQuery({
    variables: {
      limit: 1,
      offset: 0,
      roles: { _in: [User_Roles_Enum.SchoolAdmin] },
      school_id:
        tripData?.school_id !== undefined ? { _eq: tripData?.school_id } : {},
    },
  });
  const [
    createAdminApproval,
    { error: createAdminApprovalError },
  ] = useCreateFieldTripApprovalMutation({
    errorPolicy: "all",
  });

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }
  const approvalStatus =
    approvalMap && approvalMap[data?.users?.[0]?.id]?.status;

  switch (approvalStatus) {
    case "pending":
      return (
        <Button variant="contained" color="primary" disabled>
          Admin Approval Pending
        </Button>
      );
    case "approved":
      return (
        <Button variant="contained" color="primary" disabled>
          Admin Approved
        </Button>
      );
    default:
      return (
        <>
          <ErrorToast
            error={createAdminApprovalError}
            processCustomError={() =>
              `Unable to process admin approval - ${createAdminApprovalError?.message} `
            }
          />

          <Button
            variant="contained"
            color="primary"
            disabled={readOnly}
            onClick={() => {
              createAdminApproval({
                variables: {
                  object: {
                    field_trip_id: tripData?.id,
                    user_id: data?.users?.[0]?.id,
                  },
                },
                refetchQueries: ["fieldTripApprovals"],
              });
            }}
          >
            Request Admin Approval
          </Button>
        </>
      );
  }
}

export function ApprovalTable(props: AgreementUIProps) {
  const classes = useStyles();
  const { studentData, tripData } = props;
  const { loading, data } = useFieldTripApprovalsQuery({
    variables: {
      tripId: tripData?.id === undefined ? -1 : tripData?.id,
    },
  });

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  const initApprobalMap: { [key: string]: ApprovalUserFieldsFragment } = {};

  const approvalMap = data?.field_trip_approvals.reduce((a, c) => {
    a[c.user.id] = c;
    return a;
  }, initApprobalMap);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <caption>
          <AdminApprovalButton approvalMap={approvalMap} tripData={tripData} />
        </caption>
        <TableHead>
          <TableRow>
            <TableCell>Student</TableCell>
            <TableCell align="center">Transporation Agreement</TableCell>
            <TableCell align="center">Grace and Courtesy Agreement</TableCell>
            <TableCell>Parent</TableCell>
            <TableCell>Chaperone</TableCell>
            <TableCell>Parent Permission Slip</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {studentData?.field_trip_students.map((student) => {
            return (
              <TableRow key={student.id}>
                <TableCell>{`${student.user.first_name} ${student.user.last_name}`}</TableCell>
                <TableCell align="center">
                  <Check value={student.transportation_logistics_approval} />
                </TableCell>
                <TableCell align="center">
                  <Check value={student.grace_courtesy_approval} />
                </TableCell>
                <TableCell>
                  <div>
                    {student.user.user_relationships_forward.map((parent) => (
                      <div
                        className={classes.items}
                      >{`${parent.to_user.first_name} ${parent.to_user.last_name}`}</div>
                    ))}
                  </div>
                </TableCell>
                <TableCell align="center">
                  {student.user.user_relationships_forward.map((parent) => (
                    <div className={classes.items}>
                      <Check
                        value={
                          approvalMap &&
                          approvalMap[parent.to_user.id]?.is_chaperone
                        }
                      />
                      {approvalMap &&
                        approvalMap[parent.to_user.id]?.is_chaperone && (
                          <Tooltip
                            title="View chaperon request"
                            placement="top"
                          >
                            <div className={classes.iconCircle}>
                              <a
                                href={`/preview/chaperon_request/${tripData?.uid}`}
                                target="_blank"
                              >
                                <DescriptionOutlinedIcon />
                              </a>
                            </div>
                          </Tooltip>
                        )}
                    </div>
                  ))}
                </TableCell>
                <TableCell>
                  <ApprovalButton
                    user={student.user}
                    approvalMap={approvalMap}
                    tripData={tripData}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

interface AgreementUIProps {
  meData?: FindMeQuery;
  studentData?: FieldTripStudentsQuery;
  agreementData?: ListAgreementQuestionsQuery;
  tripData?: ApproveFieldsFragment;
}

interface IFormInput {
  student_id: string;
}

function AgreementUI(props: AgreementUIProps) {
  const { send, readOnly } = useContext(MachineContext);
  const classes = useStyles();
  const { meData, studentData, agreementData, tripData } = props;

  const me = meData?.me[0];

  const tripContextUserId =
    me?.role === User_Roles_Enum.Student ? me.id : tripData?.owner.id;

  const form = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      student_id: tripContextUserId,
    },
  });

  const [
    updateFieldTripStudentMutation,
    { error: updateFieldTripStudentError },
  ] = useUpdateFieldTripStudentMutation({
    errorPolicy: "all",
  });

  const [
    updateFieldTripMutation,
    { error: updateFieldTripError },
  ] = useUpdateFieldTripMutation({
    errorPolicy: "all",
  });

  const watchTripContextUserId = form.watch("student_id", tripContextUserId);
  const selectedStudent = studentData?.field_trip_students.find(
    (s) => s.user.id === watchTripContextUserId
  );

  const onSubmit = async (data: IFormInput) => {};

  const onAgree = (
    agreed: boolean,
    key: "transportation_logistics_approval" | "grace_courtesy_approval"
  ) => {
    if (agreed && selectedStudent?.id) {
      updateFieldTripStudentMutation({
        variables: {
          id: selectedStudent.id,
          object: {
            [key]: true,
          },
        },
        refetchQueries: ["fieldTripStudents"],
      });
    }
  };

  const onNext = async () => {
    if (tripData?.uid) {
      try {
        const { errors } = await updateFieldTripMutation({
          variables: {
            id: tripData?.uid,
            object: {
              status: "reflect",
            },
          },
        });
        console.log(errors);
        if (!errors) {
          send("next");
        }
      } catch (e) {
        console.error(e);
      }
      return;
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ErrorToast
          error={updateFieldTripStudentError}
          processCustomError={() =>
            `Unable to update agreement - ${updateFieldTripStudentError?.message}`
          }
        />
        <ErrorToast
          error={updateFieldTripError}
          processCustomError={() =>
            `Unable to update field trip - ${updateFieldTripError?.message}`
          }
        />

        <Grid container spacing={2}>
          {me?.role === User_Roles_Enum.Teacher && (
            <Grid item lg={12} xs={12}>
              <SelectForm
                name={"student_id"}
                label={"Select Student Context"}
                options={
                  studentData?.field_trip_students.map((u) => ({
                    value: u.user.id,
                    label: `${u.user.first_name} ${u.user.last_name}`,
                  })) || []
                }
              />
            </Grid>
          )}

          <Grid item lg={6} xs={12}>
            <AgreementList
              key={`transportation-${watchTripContextUserId}`}
              type={QuestionTypeEnum.transportation}
              title="Transportation and Logistics"
              questions={getFilteredQuestions(
                QuestionTypeEnum.transportation,
                agreementData
              )}
              isAgreed={selectedStudent?.transportation_logistics_approval}
              onAgreementChange={(agreed) =>
                onAgree(agreed, "transportation_logistics_approval")
              }
              disabled={readOnly}
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <AgreementList
              key={`grace-courtsey-${watchTripContextUserId}`}
              type={QuestionTypeEnum.grace_courtsey}
              title="Grace and Courtsey"
              questions={getFilteredQuestions(
                QuestionTypeEnum.grace_courtsey,
                agreementData
              )}
              isAgreed={selectedStudent?.grace_courtesy_approval}
              onAgreementChange={(agreed) =>
                onAgree(agreed, "grace_courtesy_approval")
              }
              disabled={readOnly}
            />
          </Grid>

          <Grid item lg={12} xs={12}>
            <ApprovalTable {...props} />
          </Grid>

          {!readOnly && (
            <Grid item container justify="flex-end" lg={12} xs={12}>
              <div className={classes.controls}>
                <Button className={classes.button} onClick={() => send("back")}>
                  Back
                </Button>
                <Button variant="contained" color="primary" onClick={onNext}>
                  Next
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </form>
    </FormProvider>
  );
}
