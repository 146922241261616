import React from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { TextField } from "@material-ui/core";

const point_regex = /^\((-?\d*\.{0,1}\d+),(-?\d*\.{0,1}\d+)\)$/;

let map;

export default function GooglePlaceBox(props) {
  const { address, readOnly, point, onLocationChange } = props;

  const addressInput = React.createRef();

  const loader = new Loader({
    apiKey: "AIzaSyCsFzb1lyYdhgkhpIvidBlDlWv8GcWEsHw",
    version: "weekly",
    libraries: ["places"],
  });

  React.useEffect(() => {
    loader.load().then(() => {
      initMapListener();
    });
  }, []);

  React.useEffect(() => {
    loader.load().then(() => {
      initMapLocation();
    });
  }, [address, point]);

  const initMapLocation = () => {
    if (!map) {
      return;
    }

    let { lat, lng, point, address } = props;

    if (point) {
      const found = point.match(point_regex);
      if (found && found.length === 3) {
        lng = Number(found[1]);
        lat = Number(found[2]);
      }
    }

    const myLatlng = new window.google.maps.LatLng(lat, lng);

    const marker = new window.google.maps.Marker({
      position: myLatlng,
      map: map,
      title: address,
      animation: window.google.maps.Animation.DROP,
    });

    marker.setPosition(myLatlng);
    map.setCenter(myLatlng);
    map.setZoom(16);
  };

  const initMapListener = () => {
    let { lat, lng, point } = props;

    if (point) {
      const found = point.match(point_regex);
      if (found && found.length === 3) {
        lng = Number(found[1]);
        lat = Number(found[2]);
      }
    }

    const center =
      lat && lng ? { lat: lat, lng: lng } : { lat: 37.1, lng: -95.7 };

    const input = document.getElementById("pac-input");

    if (!input) {
      return;
    }

    map = new window.google.maps.Map(document.getElementById("map"), {
      center,
      zoom: lat && lng ? 16 : 3,
      mapTypeId: "roadmap",
    });

    const searchBox = new window.google.maps.places.SearchBox(input);

    // Bias the SearchBox results towards current map's viewport.
    // map.addListener("bounds_changed", function () {
    //   searchBox.setBounds(map.getBounds());
    // });

    let markers = [];
    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("places_changed", function () {
      const places = searchBox.getPlaces();

      if (places.length === 0) {
        return;
      }

      // Clear out the old markers.
      markers.forEach(function (marker) {
        marker.setMap(null);
      });
      markers = [];

      // For each place, get the icon, name and location.
      const bounds = new window.google.maps.LatLngBounds();

      places.forEach(function (place) {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }
        const icon = {
          url: place.icon,
          size: new window.google.maps.Size(71, 71),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(17, 34),
          scaledSize: new window.google.maps.Size(25, 25),
        };

        // Create a marker for each place.
        markers.push(
          new window.google.maps.Marker({
            map: map,
            icon: icon,
            title: place.name,
            position: place.geometry.location,
          })
        );

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }

        if (place.geometry.location) {
          if (onLocationChange) {
            onLocationChange({
              address: place.formatted_address,
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
              url: place.url,
            });
          }
        }
      });
      map.fitBounds(bounds);
    });
  };

  return (
    <>
      <TextField
        id={"pac-input"}
        name={"address"}
        type={"text"}
        label={"Address"}
        required={true}
        value={address}
        onChange={onLocationChange}
        //@ts-ignore
        inputRef={addressInput}
        // inputRef={register({ ...ref, required })}
        margin="normal"
        fullWidth
        variant="outlined"
        size="small"
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          id: "pac-input",
          name: "address",
        }}
      />
      <div style={{ height: 400, width: "100%" }}>
        <div id="map"></div>
      </div>
    </>
  );
}
