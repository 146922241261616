import React, { useContext } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography,
} from "@material-ui/core";
import { FormProvider, useForm } from "react-hook-form";
import SelectForm from "components/form/Select";
import Checkbox from "@material-ui/core/Checkbox";

import { LearningOptionsList } from "components/school/consts";
import { FieldTripParams } from "./consts";

import styles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import {
  FieldTripReflectQuery,
  FindMeQuery,
  useFieldTripReflectQuery,
  useFindMeQuery,
  User_Roles_Enum,
  Field_Trip_Students_Set_Input,
  useUpdateFieldTripStudentMutation,
  useFieldTripStudentReflectQuery,
  Exact,
  FieldTripStudentReflectQuery,
  useFieldTripCompleteMutation,
} from "generated/graphql";
import { useParams } from "react-router-dom";
//@ts-ignore
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ApolloQueryResult } from "@apollo/client";
import { MachineContext } from "./state";
import ErrorToast from "components/toast/ErrorToast";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import Redirect from "components/Redirect";

const useStyles = makeStyles((theme: Theme) =>
  //@ts-ignore
  createStyles({
    ...styles,
    root: {
      maxWidth: "100%",
      display: "flex",
      alignItems: "center",
    },
    rating: {
      maxWidth: 200,
    },
    headerColumn: {
      fontWeight: 600,
    },
    sliderContainer: {
      width: 350,
    },
    label: {
      width: 40,
      whiteSpace: "break-spaces",
    },
    controls: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "20px",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    customErrorMsg: {
      paddingLeft: 33,
    },
  })
);

interface LabelProps {
  label: string;
}

function LabelComponent(props: LabelProps) {
  const { label } = props;
  const classes = useStyles();

  return <div className={classes.label}>{label}</div>;
}

interface ReflectProps {
  ownerId?: string;
  tripData?: FieldTripReflectQuery;
  meData?: FindMeQuery;
  studentData?: FieldTripStudentReflectQuery;
  refetch?: (
    variables?:
      | Partial<
          Exact<{
            fieldTripId: any;
            studentId: any;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<FieldTripStudentReflectQuery>>;
}

type ExtraInfoType = {
  tripData?: FieldTripReflectQuery;
  meData?: FindMeQuery;
};

export function withExtraInfo<P>(
  WrappedComponent: React.ComponentType<P & ExtraInfoType>
) {
  const ComponentWithExtraInfo = (props: P) => {
    const params = useParams<FieldTripParams>();
    const { data, loading } = useFieldTripReflectQuery({
      variables: {
        uid: params.tripId,
      },
    });
    const { data: meData, loading: meLoading } = useFindMeQuery();
    return loading || meLoading ? (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    ) : (
      <WrappedComponent {...props} tripData={data} meData={meData} />
    );
  };
  return ComponentWithExtraInfo;
}

export default function Reflect(props: ReflectProps) {
  const params = useParams<FieldTripParams>();

  const Component = params.tripId
    ? withExtraInfo(ShareComponent)
    : ShareComponent;

  return <Component {...props} />;
}

const schema = yup.object().shape({
  share_experience: yup
    .array()
    .typeError(
      "Decide what you will create to share your learning experience with your peers is required"
    )
    .min(1, "Share Experience field must have at least 1 item")
    .required(
      "Decide what you will create to share your learning experience with your peers is required"
    ),
  gratitude_mail_agreement: yup
    .boolean()
    .oneOf([true], "Gratitude mail agreement is required"),
  share_experience_agreement: yup
    .boolean()
    .oneOf([true], "Share experience agreement is required"),
  student_id: yup.string(),
});

export function ShareComponent(props: ReflectProps) {
  const params = useParams<FieldTripParams>();
  const { meData } = props;
  const tripData = props.tripData?.field_trips[0];
  const me = meData?.me[0];

  const tripContextUserId =
    me?.role === User_Roles_Enum.Student ? me.id : tripData?.owner.id;

  const {
    loading: loadingStudents,
    data: studentData,
    refetch,
  } = useFieldTripStudentReflectQuery({
    variables: {
      fieldTripId: params.tripId,
      studentId: tripContextUserId,
    },
  });

  if (loadingStudents) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <ShareFormComponent
      {...props}
      studentData={studentData}
      refetch={refetch}
    />
  );
}

type IFormInput = Field_Trip_Students_Set_Input & {
  student_id: string;
};

export function ShareFormComponent(props: ReflectProps) {
  const { send, readOnly } = useContext(MachineContext);
  const classes = useStyles();
  const tripData = props.tripData?.field_trips[0];

  const [openSuccess, setOpenSuccess] = React.useState(false);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const { studentData, meData, refetch } = props;

  const me = meData?.me[0];

  const tripContextUserId =
    me?.role === User_Roles_Enum.Student ? me.id : tripData?.owner.id;

  //@ts-ignore
  const {
    //@ts-ignore
    user,
    //@ts-ignore
    reflect_survey,
    //@ts-ignore
    ___typename,
    ...studentReflectData
  } = studentData?.field_trip_students[0] || {};

  const form = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...studentReflectData,
      student_id: tripContextUserId,
    },
  });

  const [
    updateFieldTripStudent,
    { error: updateFieldTripStudentError },
  ] = useUpdateFieldTripStudentMutation({
    errorPolicy: "all",
  });

  const [
    fieldTripCountMutation,
    { error: fieldTripSetStatusCompleteError },
  ] = useFieldTripCompleteMutation({
    errorPolicy: "all",
  });

  const updateTripStage = async () => {
    const countMutationRes = await fieldTripCountMutation({
      variables: {
        tripId: tripData?.uid,
        status: "complete",
      },
    });

    const count = countMutationRes.data?.update_field_trips?.affected_rows
      ? countMutationRes.data?.update_field_trips?.affected_rows
      : 0;

    if (count > 0) {
      window.location.replace(`/field_trip_detail/${tripData?.uid}`);
    }
  };

  const watchTripContextUserId = form.watch("student_id", tripContextUserId);

  const onSubmit = async (data: IFormInput) => {
    const { student_id, ...rest } = data;
    const selectedStudent = tripData?.field_trip_students.find(
      (u) => u.user.id === watchTripContextUserId
    );

    if (selectedStudent?.id !== undefined) {
      const updatedData = await updateFieldTripStudent({
        variables: {
          id: selectedStudent.id,
          object: {
            ...rest,
            stage: "complete",
          },
        },
      });
      const tripUpdateStatus =
        updatedData.data?.update_field_trip_students_by_pk;
      const stageCount = tripUpdateStatus?.field_trip.field_trip_students.filter(
        (i) => i.stage === "complete"
      ).length;

      if (tripUpdateStatus && meData?.me[0].role === "student") {
        window.location.replace(`/field_trip_detail/${tripData?.uid}`);
      } else {
        if (
          stageCount !== undefined &&
          stageCount == tripUpdateStatus?.field_trip.field_trip_students.length
        ) {
          updateTripStage();
        }
      }

      if (!updatedData.errors) {
        setOpenSuccess(true);
      }
    }
  };

  const changeStudentContext = async (value: any) => {
    setOpenSuccess(false);
    if (refetch) {
      const { data } = await refetch({
        fieldTripId: tripData?.uid,
        studentId: value,
      });

      if (data.field_trip_students && data.field_trip_students.length > 0) {
        const {
          gratitude_mail_agreement,
          share_experience,
          share_experience_agreement,
        } = data.field_trip_students[0];

        form.setValue("gratitude_mail_agreement", gratitude_mail_agreement);
        form.setValue("share_experience", share_experience);
        form.setValue("share_experience_agreement", share_experience_agreement);

        form.clearErrors();
      }
    }
  };

  function SelectStudent() {
    if (
      me?.role === User_Roles_Enum.Teacher ||
      me?.role === User_Roles_Enum.SchoolAdmin
    ) {
      return (
        <Grid item lg={12} xs={12}>
          <SelectForm
            name={"student_id"}
            isClearable={false}
            label={"Select Student Context"}
            options={
              tripData?.field_trip_students.map((u) => ({
                value: u.user.id,
                label: `${u.user.first_name} ${u.user.last_name}`,
              })) || []
            }
            onChange={changeStudentContext}
          />
        </Grid>
      );
    } else {
      return <></>;
    }
  }

  return (
    <FormProvider {...form} key={`share-form-${watchTripContextUserId}`}>
      <Snackbar
        open={openSuccess}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="success"
        >{`Trip Completed for ${studentData?.field_trip_students[0].user.first_name} ${studentData?.field_trip_students[0].user.last_name}`}</Alert>
      </Snackbar>

      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ErrorToast
          error={updateFieldTripStudentError}
          processCustomError={() =>
            `Unable to update field trip - ${updateFieldTripStudentError?.message}`
          }
        />
        <ErrorToast
          error={fieldTripSetStatusCompleteError}
          processCustomError={() =>
            `Unable to update field trip status - ${fieldTripSetStatusCompleteError?.message}`
          }
        />

        <Grid container spacing={2}>
          <SelectStudent />

          <Grid item lg={12} xs={12}>
            <hr />
            <Typography variant="h6">
              Let's complete some important steps before starting our next trip
            </Typography>
          </Grid>

          <Grid item lg={12} xs={12}>
            <SelectForm
              name={"share_experience"}
              mode="multiple"
              label={
                "Decide what you will create to share your learning experience with your peers: (Select all that apply)"
              }
              options={LearningOptionsList}
            />
          </Grid>

          <Grid item lg={12} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(form.watch("gratitude_mail_agreement"))}
                  onChange={(e, checked) =>
                    form.setValue("gratitude_mail_agreement", checked)
                  }
                  name="gratitude_mail_agreement"
                />
              }
              label="Write and mail thank you cards or letters to all chaperones and any hosts, teachers, or docents who worked with you during your tripondary"
            />
            <FormHelperText error className={classes.customErrorMsg}>
              {form.errors.gratitude_mail_agreement?.message
                ? form.errors.gratitude_mail_agreement.message
                : null}
            </FormHelperText>
          </Grid>

          <Grid item lg={12} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(form.watch("share_experience_agreement"))}
                  onChange={(e, checked) =>
                    form.setValue("share_experience_agreement", checked)
                  }
                  name="share_experience_agreement"
                />
              }
              label="Share your learning experience with your peer"
            />
            <FormHelperText error className={classes.customErrorMsg}>
              {form.errors.share_experience_agreement?.message
                ? form.errors.share_experience_agreement.message
                : null}
            </FormHelperText>
          </Grid>

          {!readOnly && (
            <Grid item container justify="flex-end" lg={12} xs={12}>
              <div className={classes.controls}>
                <Button className={classes.button} onClick={() => send("back")}>
                  Back
                </Button>
                <Button variant="contained" color="primary" type="submit">
                  Complete
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </form>
    </FormProvider>
  );
}
