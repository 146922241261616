import React, { useContext } from "react";
import { CircularProgress, Grid } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { FormProvider, useForm } from "react-hook-form";
import Label from "components/form/Label";
import SelectForm from "components/form/Select";
import Button from "@material-ui/core/Button";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Timeline from "./Timeline";
import {
  FieldTripParams,
  supplyList,
  transportationOptionsList,
} from "./consts";
import {
  FieldTripPlanQuery,
  Field_Trips_Set_Input,
  useFieldTripPlanQuery,
  useUpdateFieldTripMutation,
} from "generated/graphql";
import TextArea from "components/form/TextArea";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";

//@ts-ignore
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { MachineContext } from "./state";
import ErrorToast from "components/toast/ErrorToast";
import InputField from "components/form/Input";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: theme.spacing(1),
    },
    controls: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "20px",
      // marginRight: "30px",
    },
    form: {
      width: "100%",
    },
    container: {
      margin: 0,
      paddingRight: "14px",
    },
  })
);

const date = new Date();

const schema = yup.object().shape({
  event_date: yup
    .date()
    .required("Event Date is required")
    .min(date, "Invalid Date")
    .typeError("Event Date is required"),
  agenda: yup.string().required("Agenda is required"),
  cost_per_person: yup
    .string()
    .typeError("Please enter numeric value")
    .matches(
      /^\$?(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$/,
      "Please Enter Valid Amount"
    )
    .required("Cost per person is required"),
  total_cost: yup
    .string()
    .matches(
      /^\$?(\d{1,3}(\,\d{3})*|(\d+))(\.\d{2})?$/,
      "Please Enter Valid Amount"
    )
    .typeError("Please enter numeric value")
    .required("Total Cost is required"),
  expectations_rules: yup.string().required("Expectations / Rules required"),
  supply_list: yup
    .array()
    .ensure()
    .min(1, "Supply list must have at least 1 item")
    .required("Supply list is required"),
  transportation_options: yup
    .array()
    .ensure()
    .min(1, "Transportation Options must have at least 1 item")
    .required("Transporation Options required"),
});

type ExtraInfoType = {
  tripData?: FieldTripPlanQuery;
};

interface PlanProps {
  tripData?: FieldTripPlanQuery;
}

export function withExtraInfo<P>(
  WrappedComponent: React.ComponentType<P & ExtraInfoType>
) {
  const ComponentWithExtraInfo = (props: P) => {
    const params = useParams<FieldTripParams>();
    const { data, loading } = useFieldTripPlanQuery({
      variables: {
        uid: params.tripId,
      },
    });
    return loading ? (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    ) : (
      <WrappedComponent {...props} tripData={data} />
    );
  };
  return ComponentWithExtraInfo;
}

export default function Plan(props: PlanProps) {
  const params = useParams<FieldTripParams>();

  const Component = params.tripId
    ? withExtraInfo(PlanComponent)
    : PlanComponent;

  return <Component {...props} />;
}

export function PlanComponent(props: PlanProps) {
  const { send, readOnly } = useContext(MachineContext);
  const classes = useStyles();
  const params = useParams<FieldTripParams>();

  const tripData = props.tripData?.field_trips[0];
  console.log({ tripData });
  const form = useForm<Field_Trips_Set_Input>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...tripData,
      event_date:
        tripData?.event_date &&
        DateTime.fromSQL(tripData?.event_date).toJSDate(),
    },
  });

  const watchEventDate = form.watch(
    "event_date",
    tripData?.event_date && DateTime.fromSQL(tripData?.event_date).toJSDate()
  );

  const [
    updateFieldTripMutation,
    { error: updateFieldTripError },
  ] = useUpdateFieldTripMutation({
    errorPolicy: "all",
  });

  const onSubmit = async (data: Field_Trips_Set_Input) => {
    if (params.tripId !== undefined) {
      const getDate =
        typeof data.event_date === "object"
          ? data.event_date.toLocaleString()
          : data.event_date;

      const [date] = getDate.split(",");
      const [month, day, year] = date.split("/");
      const eventDate = `${year}-${month}-${day}`;

      try {
        const { errors } = await updateFieldTripMutation({
          variables: {
            id: params.tripId,
            object: {
              ...data,
              event_date: eventDate,
              status: "approve",
            },
          },
          refetchQueries: ["fieldTripPlan"],
        });
        if (!errors) {
          send("next");
        }
      } catch (e) {
        console.error(e);
      }
      return;
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item lg={12} xs={12}>
        <Label title="Location Timeline">
          <Timeline tripId={params.tripId} tripDbId={tripData?.id} />
        </Label>
      </Grid>

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className={classes.form}>
          <ErrorToast
            error={updateFieldTripError}
            processCustomError={() =>
              `Unable to update field trip - ${updateFieldTripError?.message}`
            }
          />

          <Grid container spacing={2} className={classes.container}>
            <Grid item lg={4} xs={12} alignItems="center">
              <div style={{ marginTop: 15, marginBottom: 8 }}>
                <Label
                  title="Event Date"
                  center={true}
                  errorMessage={form.errors?.event_date?.message}
                >
                  <Calendar
                    defaultValue={watchEventDate}
                    onChange={(v) => form.setValue("event_date", v)}
                  />
                  <input
                    type="hidden"
                    defaultValue={watchEventDate}
                    name="event_date"
                    ref={form.register}
                  />
                </Label>
              </div>
            </Grid>

            <Grid item lg={4} xs={12}>
              <TextArea name="agenda" label="Agenda" />

              <InputField name="cost_per_person" label="Cost Per Person" />
            </Grid>

            <Grid item lg={4} xs={12}>
              <TextArea
                name="expectations_rules"
                label="Expectations / Rules"
              />
              <InputField name="total_cost" label="Total Cost" />
            </Grid>

            <Grid item lg={6} xs={12}>
              <SelectForm
                name={"supply_list"}
                label={"Supply List"}
                mode="multiple"
                options={supplyList}
              />
            </Grid>

            <Grid item lg={6} xs={12}>
              <SelectForm
                name={"transportation_options"}
                label={"Transportation Options"}
                mode="multiple"
                options={transportationOptionsList}
              />
            </Grid>

            {!readOnly && (
              <Grid item container justify="flex-end">
                <div className={classes.controls}>
                  <Button
                    className={classes.button}
                    onClick={() => send("back")}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classes.button}
                  >
                    Next
                  </Button>
                </div>
              </Grid>
            )}
          </Grid>
        </form>
      </FormProvider>
    </Grid>
  );
}
