import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import InputField from "components/form/Input";
import SelectForm from "components/form/Select";
import TextArea from "components/form/TextArea";
import { FormProvider, useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import {
  useFindMeQuery,
  User_Roles_Enum,
  useCreateVirtualTripMutation,
  Virtual_Trips_Insert_Input,
  useSchoolsQuery,
  useGetVirtualTripByIdQuery,
  Virtual_Trips_Set_Input,
  Virtual_Trip_Images_Constraint,
  Virtual_Trip_Images_Update_Column,
  Virtual_Trip_Schools_Constraint,
  Virtual_Trip_Schools_Update_Column,
  useDeleteVirtualTripSchoolMutation,
  useDeleteVirtualTripImagesMutation,
} from "../../generated/graphql";

import { DateTime } from "luxon";

//@ts-ignore
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { virtualTripTopics } from "./Const";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorToast from "components/toast/ErrorToast";
import ToastMessage from "./ToastMessage";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme: Theme) =>
  //@ts-ignore
  createStyles({
    root: {
      maxWidth: "100%",
    },
    popupActionBtns: {
      paddingTop: 15,
      paddingBottom: 15,
    },
    imageUploadWrapper: {
      border: "1px solid #CCC",
      width: "100%",
      minHeight: 160,
      borderRadius: 5,
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 10,
      marginBottom: 15,
    },
    uploadBtnStyle: {
      width: "100%",
      height: "100%",
      position: "absolute",
      border: "0 !important",
      color: "#CCC",
      zIndex: 1,
    },
    clearImageBtnStyle: {
      position: "absolute",
      top: 0,
      right: 0,
      border: "0 !important",
      color: "#666",
      zIndex: 99,
      textTransform: "none",
    },
    uploadImageList: {
      display: "flex",
      flexDirection: "row",
      gap: "5px",
      "& .img": {
        position: "relative",
        zIndex: 5,
        width: 100,
        height: 100,
        borderRadius: 3,
        backgroundSize: "cover",
        backgroundPosition: "center",
      },
    },
  })
);

interface FormProps {
  id: number;
}

const schema = yup.object().shape({
  topic: yup.string().required("Topic is required"),
  virtual_trip_schools: yup.array().when("set_all_schools", {
    is: "false" || "undefined",
    then: yup
      .array()
      .ensure()
      .min(1, "School must have at least 1 item")
      .required("School is required"),
  }),
  description: yup.string().required("Description is required"),
  event_date: yup.string().required("Date is required"),
  meeting_link: yup.string().required("Meeting link is required"),
});

export function withExtraInfo<P>(
  WrappedComponent: React.ComponentType<P & FormProps>
) {
  const ComponentWithExtraInfo = (props: any) => {
    const { data, loading } = useGetVirtualTripByIdQuery({
      variables: {
        id: props.addOrEdit.id,
      },
    });
    return loading ? (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    ) : (
      <WrappedComponent {...props} data={data} />
    );
  };
  return ComponentWithExtraInfo;
}

export default function CreateVirtualTripWrapper(props: any) {
  const Component = props.addOrEdit
    ? withExtraInfo(CreateVirtualTripForm)
    : CreateVirtualTripForm;

  return <Component {...props} />;
}

export function CreateVirtualTripForm(props: any) {
  const classes = useStyles();
  const { addOrEdit, openPopup, setOpenPopup, data } = props;

  const [
    createVirtualTrip,
    { error: createVirtualError, loading: createVirtualLoading },
  ] = useCreateVirtualTripMutation({ errorPolicy: "all" });

  const [
    deleteSchools,
    { error: deleteSchoolError, loading: deleteSchoolLoading },
  ] = useDeleteVirtualTripSchoolMutation({
    errorPolicy: "all",
  });

  const [
    deleteImages,
    { error: deleteImagesError, loading: deleteImagesLoading },
  ] = useDeleteVirtualTripImagesMutation({
    errorPolicy: "all",
  });

  const { data: schoolList, loading: schoolLoading } = useSchoolsQuery();

  const [succes, setSuccess] = useState(false);
  const [images, setImages] = useState([0]);

  const { data: me } = useFindMeQuery();

  const readOnly =
    me?.me[0].role === User_Roles_Enum.SystemAdmin ? false : true;

  const handleClose = () => {
    setOpenPopup(false);
  };

  let defaultData = {
    virtual_trip_images: [],
  };

  if (data?.virtual_trips_by_pk) {
    const {
      virtual_trip_schools,
      virtual_trip_images,
      event_date,
      all_schools,
      ...rest
    } = data?.virtual_trips_by_pk;

    //  Created event should always be configured / stroed as EST
    const estTime = DateTime.fromISO(event_date, {
      zone: "America/New_York",
    }).toFormat("yyyy-LL-dd'T'TT");

    const schoolArr = virtual_trip_schools.map((m: any) => m.school.id);
    defaultData = {
      virtual_trip_schools: schoolArr,
      event_date: estTime,
      all_schools: all_schools,
      ...rest,
    };
  }

  const form = useForm<Virtual_Trips_Insert_Input | Virtual_Trips_Set_Input>({
    resolver: yupResolver(schema),
    //@ts-ignore
    defaultValues: defaultData,
  });

  const { handleSubmit } = form;

  useEffect(() => {
    if (data?.virtual_trips_by_pk) {
      const imageArr = data?.virtual_trips_by_pk.virtual_trip_images.map(
        (m: any) => ({
          url: m.image_link,
        })
      );
      setImages(imageArr);
    }
  }, []);

  const tripId = data?.virtual_trips_by_pk.id;

  const onSubmit = async (data: Virtual_Trips_Insert_Input) => {
    const {
      event_date,
      description,
      meeting_link,
      notes,
      topic,
      virtual_trip_schools,
      all_schools,
    } = data;
    //  Created event should always be configured / stroed as EST
    const estTime = DateTime.fromISO(event_date, { zone: "America/New_York" });

    //@ts-ignore
    const school_id = virtual_trip_schools?.map((school) => ({
      school_id: school,
    }));
    const image_link = images.map((image) => ({
      //@ts-ignore
      image_link: image.url,
    }));

    if (data.description) {
      const newData = {
        event_date: estTime.toString(),
        description: description,
        meeting_link: meeting_link,
        notes: notes,
        topic: topic,
        all_schools: form.watch("all_schools"),
        virtual_trip_schools: {
          data: form.watch("all_schools") ? [] : school_id,
          on_conflict: {
            update_columns: [Virtual_Trip_Schools_Update_Column.UpdatedAt],
            constraint: Virtual_Trip_Schools_Constraint.VirtualTripSchoolsPkey,
          },
        },
        virtual_trip_images: {
          data: image_link[0]?.image_link ? image_link : [],
          on_conflict: {
            update_columns: [Virtual_Trip_Images_Update_Column.UpdatedAt],
            constraint:
              Virtual_Trip_Images_Constraint.VirtualTrpImagesVirtualTripIdImageLinkKey,
          },
        },
      };

      const { errors } = await createVirtualTrip({
        variables: {
          object: tripId
            ? {
                ...newData,
                id: tripId,
              }
            : {
                ...newData,
              },
        },
        refetchQueries: ["upComingTrips", "getVirtualTripById"],
      });
      if (!errors) {
        setSuccess(true);
        setTimeout(() => {
          setImages([0]);
          form.reset();
          setOpenPopup(false);
        }, 3000);
      }
    }
  };

  const uploadLogo = () => {
    //@ts-ignore
    cloudinary.openUploadWidget(
      {
        cloud_name: process.env.REACT_APP_CLOUDINARY_NAME,
        upload_preset: process.env.REACT_APP_UPLOAD_PRESET,
        cropping: true,
        sources: ["local"],
      },
      //@ts-ignore
      (err, result) => {
        if (!err) {
          setImages(result);
        }
      }
    );
  };

  return (
    <>
      {succes && !tripId ? (
        <ToastMessage message="Virtual Trip Successfully Created" />
      ) : null}

      {succes && tripId ? (
        <ToastMessage message="Virtual Trip Successfully Updated" />
      ) : null}
      <ErrorToast
        error={createVirtualError}
        processCustomError={() =>
          `Unable to Create Virtual Trip - ${createVirtualError?.message}`
        }
      />
      <ErrorToast
        error={deleteSchoolError}
        processCustomError={() =>
          `Unable to Remove School: ${deleteSchoolError?.message}`
        }
      />
      <ErrorToast
        error={deleteImagesError}
        processCustomError={() =>
          `Unable to Delete Images: ${deleteImagesError?.message}`
        }
      />

      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectForm
            name={"topic"}
            label={"Select a Topic"}
            isDisabled={readOnly}
            //@ts-ignore
            options={virtualTripTopics.map((topic) => ({
              value: topic.value,
              label: topic.label,
            }))}
          />
          <InputField
            name="meeting_link"
            label="Meeting Link"
            disabled={readOnly}
          />

          <FormControlLabel
            disabled={readOnly}
            control={
              <Checkbox
                checked={Boolean(form.watch("all_schools"))}
                defaultChecked={false}
                onChange={(event, value) => {
                  form.setValue("all_schools", value);
                }}
                name="all_schools"
                color="primary"
              />
            }
            label="Add all Schools"
          />
          <span style={{ display: "none" }}>
            <InputField
              name="set_all_schools"
              //label="test"
              type="hidden"
              value={`${form.watch("all_schools") ? "true" : "false"}`}
            />
          </span>
          {!form.watch("all_schools") && (
            <SelectForm
              name={"virtual_trip_schools"}
              label={"School"}
              mode="multiple"
              isDisabled={
                schoolLoading ||
                readOnly ||
                form.watch("all_schools") ||
                deleteSchoolLoading
              }
              onChange={(values, action) => {
                if (action.action === "remove-value" && tripId) {
                  deleteSchools({
                    variables: {
                      tripId: tripId,
                      schoolId: action.removedValue.value,
                    },
                    refetchQueries: ["upComingTrips"],
                  });
                }
              }}
              options={
                schoolList
                  ? schoolList.schools.map((sc) => ({
                      label: sc.name,
                      value: sc.id,
                    }))
                  : []
              }
            />
          )}

          <InputField
            name="event_date"
            label="Date & Time (EST)"
            type="datetime-local"
            disabled={readOnly}
          />
          <TextArea
            name="description"
            label="Description"
            disabled={readOnly}
          />
          <TextArea name="notes" label="Note" disabled={readOnly} />
          <div className={classes.imageUploadWrapper}>
            <Button
              component="span"
              variant="outlined"
              size="small"
              color="primary"
              onClick={uploadLogo}
              disabled={images[0] ? true : false || readOnly}
              className={classes.uploadBtnStyle}
            >
              Choose Images
            </Button>
            {images[0] ? (
              <div className={classes.uploadImageList}>
                {images?.map((m) => (
                  <div
                    className="img"
                    //@ts-ignore
                    style={{ backgroundImage: `url("${m.url}")` }}
                  ></div>
                ))}
              </div>
            ) : null}

            <Button
              component="span"
              variant="outlined"
              size="small"
              color="primary"
              disabled={readOnly || deleteImagesLoading}
              onClick={async () => {
                if (tripId) {
                  const { errors } = await deleteImages({
                    variables: {
                      tripId: tripId,
                    },
                    refetchQueries: ["upComingTrips"],
                  });
                  if (!errors) {
                    setImages([0]);
                  }
                }
              }}
              className={classes.clearImageBtnStyle}
            >
              Clear images
            </Button>
          </div>

          <Grid
            container
            justify="flex-end"
            spacing={1}
            className={classes.popupActionBtns}
          >
            <Button
              style={{ marginRight: 10 }}
              variant="contained"
              onClick={handleClose}
              color="primary"
            >
              Close
            </Button>
            {readOnly ? null : (
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={createVirtualLoading}
              >
                Save
              </Button>
            )}
          </Grid>
        </form>
      </FormProvider>
    </>
  );
}
