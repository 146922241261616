import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  json: any;
  jsonb: any;
  money: any;
  name: any;
  point: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars["Boolean"]>;
  _gt?: Maybe<Scalars["Boolean"]>;
  _gte?: Maybe<Scalars["Boolean"]>;
  _in?: Maybe<Array<Scalars["Boolean"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Boolean"]>;
  _lte?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<Scalars["Boolean"]>;
  _nin?: Maybe<Array<Scalars["Boolean"]>>;
};

export type CreateUserInput = {
  class_room_id?: Maybe<Scalars["uuid"]>;
  email: Scalars["String"];
  first_name?: Maybe<Scalars["String"]>;
  last_name?: Maybe<Scalars["String"]>;
  role?: Maybe<CreateUserRolesEnum>;
  school_id?: Maybe<Scalars["uuid"]>;
  username?: Maybe<Scalars["String"]>;
};

export type CreateUserOutput = {
  __typename?: "CreateUserOutput";
  id: Scalars["uuid"];
};

export enum CreateUserRolesEnum {
  /** Parent of Student */
  Parent = "parent",
  SchoolAdmin = "school_admin",
  Student = "student",
  Teacher = "teacher",
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars["Int"]>;
  _gt?: Maybe<Scalars["Int"]>;
  _gte?: Maybe<Scalars["Int"]>;
  _in?: Maybe<Array<Scalars["Int"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Int"]>;
  _lte?: Maybe<Scalars["Int"]>;
  _neq?: Maybe<Scalars["Int"]>;
  _nin?: Maybe<Array<Scalars["Int"]>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars["String"]>;
  _gt?: Maybe<Scalars["String"]>;
  _gte?: Maybe<Scalars["String"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars["String"]>;
  _in?: Maybe<Array<Scalars["String"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars["String"]>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars["String"]>;
  _lt?: Maybe<Scalars["String"]>;
  _lte?: Maybe<Scalars["String"]>;
  _neq?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars["String"]>;
  _nin?: Maybe<Array<Scalars["String"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars["String"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars["String"]>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars["String"]>;
};

export type UserInput = {
  id: Scalars["String"];
};

export type UserOutput = {
  __typename?: "UserOutput";
  classroom?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  name: Scalars["String"];
  role: Scalars["String"];
  school_id: Scalars["String"];
  school_name: Scalars["String"];
  temp_password: Scalars["String"];
  username: Scalars["String"];
};

/** columns and relationships of "agreement_questions" */
export type Agreement_Questions = {
  __typename?: "agreement_questions";
  id: Scalars["uuid"];
  label: Scalars["String"];
  /** An object relationship */
  school: Schools;
  school_id: Scalars["uuid"];
  type: Scalars["String"];
};

/** aggregated selection of "agreement_questions" */
export type Agreement_Questions_Aggregate = {
  __typename?: "agreement_questions_aggregate";
  aggregate?: Maybe<Agreement_Questions_Aggregate_Fields>;
  nodes: Array<Agreement_Questions>;
};

/** aggregate fields of "agreement_questions" */
export type Agreement_Questions_Aggregate_Fields = {
  __typename?: "agreement_questions_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Agreement_Questions_Max_Fields>;
  min?: Maybe<Agreement_Questions_Min_Fields>;
};

/** aggregate fields of "agreement_questions" */
export type Agreement_Questions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Agreement_Questions_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "agreement_questions" */
export type Agreement_Questions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Agreement_Questions_Max_Order_By>;
  min?: Maybe<Agreement_Questions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "agreement_questions" */
export type Agreement_Questions_Arr_Rel_Insert_Input = {
  data: Array<Agreement_Questions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Agreement_Questions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "agreement_questions". All fields are combined with a logical 'AND'. */
export type Agreement_Questions_Bool_Exp = {
  _and?: Maybe<Array<Agreement_Questions_Bool_Exp>>;
  _not?: Maybe<Agreement_Questions_Bool_Exp>;
  _or?: Maybe<Array<Agreement_Questions_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  school?: Maybe<Schools_Bool_Exp>;
  school_id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "agreement_questions" */
export enum Agreement_Questions_Constraint {
  /** unique or primary key constraint */
  AgreementQuestionLabelSchoolKey = "agreement_question_label_school_key",
  /** unique or primary key constraint */
  AgreementQuestionPkey = "agreement_question_pkey",
}

/** input type for inserting data into table "agreement_questions" */
export type Agreement_Questions_Insert_Input = {
  id?: Maybe<Scalars["uuid"]>;
  label?: Maybe<Scalars["String"]>;
  school?: Maybe<Schools_Obj_Rel_Insert_Input>;
  school_id?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Agreement_Questions_Max_Fields = {
  __typename?: "agreement_questions_max_fields";
  id?: Maybe<Scalars["uuid"]>;
  label?: Maybe<Scalars["String"]>;
  school_id?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "agreement_questions" */
export type Agreement_Questions_Max_Order_By = {
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Agreement_Questions_Min_Fields = {
  __typename?: "agreement_questions_min_fields";
  id?: Maybe<Scalars["uuid"]>;
  label?: Maybe<Scalars["String"]>;
  school_id?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "agreement_questions" */
export type Agreement_Questions_Min_Order_By = {
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** response of any mutation on the table "agreement_questions" */
export type Agreement_Questions_Mutation_Response = {
  __typename?: "agreement_questions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Agreement_Questions>;
};

/** on_conflict condition type for table "agreement_questions" */
export type Agreement_Questions_On_Conflict = {
  constraint: Agreement_Questions_Constraint;
  update_columns: Array<Agreement_Questions_Update_Column>;
  where?: Maybe<Agreement_Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "agreement_questions". */
export type Agreement_Questions_Order_By = {
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  school?: Maybe<Schools_Order_By>;
  school_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: agreement_questions */
export type Agreement_Questions_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "agreement_questions" */
export enum Agreement_Questions_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Label = "label",
  /** column name */
  SchoolId = "school_id",
  /** column name */
  Type = "type",
}

/** input type for updating data in table "agreement_questions" */
export type Agreement_Questions_Set_Input = {
  id?: Maybe<Scalars["uuid"]>;
  label?: Maybe<Scalars["String"]>;
  school_id?: Maybe<Scalars["uuid"]>;
  type?: Maybe<Scalars["String"]>;
};

/** update columns of table "agreement_questions" */
export enum Agreement_Questions_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Label = "label",
  /** column name */
  SchoolId = "school_id",
  /** column name */
  Type = "type",
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars["bigint"]>;
  _gt?: Maybe<Scalars["bigint"]>;
  _gte?: Maybe<Scalars["bigint"]>;
  _in?: Maybe<Array<Scalars["bigint"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["bigint"]>;
  _lte?: Maybe<Scalars["bigint"]>;
  _neq?: Maybe<Scalars["bigint"]>;
  _nin?: Maybe<Array<Scalars["bigint"]>>;
};

/** columns and relationships of "class_rooms" */
export type Class_Rooms = {
  __typename?: "class_rooms";
  id: Scalars["uuid"];
  name: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  /** An object relationship */
  school: Schools;
  school_id: Scalars["uuid"];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
};

/** columns and relationships of "class_rooms" */
export type Class_RoomsUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** columns and relationships of "class_rooms" */
export type Class_RoomsUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** aggregated selection of "class_rooms" */
export type Class_Rooms_Aggregate = {
  __typename?: "class_rooms_aggregate";
  aggregate?: Maybe<Class_Rooms_Aggregate_Fields>;
  nodes: Array<Class_Rooms>;
};

/** aggregate fields of "class_rooms" */
export type Class_Rooms_Aggregate_Fields = {
  __typename?: "class_rooms_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Class_Rooms_Max_Fields>;
  min?: Maybe<Class_Rooms_Min_Fields>;
};

/** aggregate fields of "class_rooms" */
export type Class_Rooms_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Class_Rooms_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "class_rooms" */
export type Class_Rooms_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Class_Rooms_Max_Order_By>;
  min?: Maybe<Class_Rooms_Min_Order_By>;
};

/** input type for inserting array relation for remote table "class_rooms" */
export type Class_Rooms_Arr_Rel_Insert_Input = {
  data: Array<Class_Rooms_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Class_Rooms_On_Conflict>;
};

/** Boolean expression to filter rows from the table "class_rooms". All fields are combined with a logical 'AND'. */
export type Class_Rooms_Bool_Exp = {
  _and?: Maybe<Array<Class_Rooms_Bool_Exp>>;
  _not?: Maybe<Class_Rooms_Bool_Exp>;
  _or?: Maybe<Array<Class_Rooms_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  school?: Maybe<Schools_Bool_Exp>;
  school_id?: Maybe<Uuid_Comparison_Exp>;
  users?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "class_rooms" */
export enum Class_Rooms_Constraint {
  /** unique or primary key constraint */
  ClassRoomNameSchoolKey = "class_room_name_school_key",
  /** unique or primary key constraint */
  ClassRoomPkey = "class_room_pkey",
}

/** input type for inserting data into table "class_rooms" */
export type Class_Rooms_Insert_Input = {
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  school?: Maybe<Schools_Obj_Rel_Insert_Input>;
  school_id?: Maybe<Scalars["uuid"]>;
  users?: Maybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Class_Rooms_Max_Fields = {
  __typename?: "class_rooms_max_fields";
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  school_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "class_rooms" */
export type Class_Rooms_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Class_Rooms_Min_Fields = {
  __typename?: "class_rooms_min_fields";
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  school_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "class_rooms" */
export type Class_Rooms_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "class_rooms" */
export type Class_Rooms_Mutation_Response = {
  __typename?: "class_rooms_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Class_Rooms>;
};

/** input type for inserting object relation for remote table "class_rooms" */
export type Class_Rooms_Obj_Rel_Insert_Input = {
  data: Class_Rooms_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Class_Rooms_On_Conflict>;
};

/** on_conflict condition type for table "class_rooms" */
export type Class_Rooms_On_Conflict = {
  constraint: Class_Rooms_Constraint;
  update_columns: Array<Class_Rooms_Update_Column>;
  where?: Maybe<Class_Rooms_Bool_Exp>;
};

/** Ordering options when selecting data from "class_rooms". */
export type Class_Rooms_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  school?: Maybe<Schools_Order_By>;
  school_id?: Maybe<Order_By>;
  users_aggregate?: Maybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: class_rooms */
export type Class_Rooms_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "class_rooms" */
export enum Class_Rooms_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Notes = "notes",
  /** column name */
  SchoolId = "school_id",
}

/** input type for updating data in table "class_rooms" */
export type Class_Rooms_Set_Input = {
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  school_id?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "class_rooms" */
export enum Class_Rooms_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Notes = "notes",
  /** column name */
  SchoolId = "school_id",
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars["date"]>;
  _gt?: Maybe<Scalars["date"]>;
  _gte?: Maybe<Scalars["date"]>;
  _in?: Maybe<Array<Scalars["date"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["date"]>;
  _lte?: Maybe<Scalars["date"]>;
  _neq?: Maybe<Scalars["date"]>;
  _nin?: Maybe<Array<Scalars["date"]>>;
};

/** columns and relationships of "field_trip_approvals" */
export type Field_Trip_Approvals = {
  __typename?: "field_trip_approvals";
  approved_at?: Maybe<Scalars["timestamptz"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  field_trip: Field_Trips;
  field_trip_id: Scalars["Int"];
  id: Scalars["Int"];
  is_chaperone?: Maybe<Scalars["Boolean"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  user: Users;
  user_id: Scalars["uuid"];
};

/** aggregated selection of "field_trip_approvals" */
export type Field_Trip_Approvals_Aggregate = {
  __typename?: "field_trip_approvals_aggregate";
  aggregate?: Maybe<Field_Trip_Approvals_Aggregate_Fields>;
  nodes: Array<Field_Trip_Approvals>;
};

/** aggregate fields of "field_trip_approvals" */
export type Field_Trip_Approvals_Aggregate_Fields = {
  __typename?: "field_trip_approvals_aggregate_fields";
  avg?: Maybe<Field_Trip_Approvals_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Field_Trip_Approvals_Max_Fields>;
  min?: Maybe<Field_Trip_Approvals_Min_Fields>;
  stddev?: Maybe<Field_Trip_Approvals_Stddev_Fields>;
  stddev_pop?: Maybe<Field_Trip_Approvals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Field_Trip_Approvals_Stddev_Samp_Fields>;
  sum?: Maybe<Field_Trip_Approvals_Sum_Fields>;
  var_pop?: Maybe<Field_Trip_Approvals_Var_Pop_Fields>;
  var_samp?: Maybe<Field_Trip_Approvals_Var_Samp_Fields>;
  variance?: Maybe<Field_Trip_Approvals_Variance_Fields>;
};

/** aggregate fields of "field_trip_approvals" */
export type Field_Trip_Approvals_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Field_Trip_Approvals_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "field_trip_approvals" */
export type Field_Trip_Approvals_Aggregate_Order_By = {
  avg?: Maybe<Field_Trip_Approvals_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Field_Trip_Approvals_Max_Order_By>;
  min?: Maybe<Field_Trip_Approvals_Min_Order_By>;
  stddev?: Maybe<Field_Trip_Approvals_Stddev_Order_By>;
  stddev_pop?: Maybe<Field_Trip_Approvals_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Field_Trip_Approvals_Stddev_Samp_Order_By>;
  sum?: Maybe<Field_Trip_Approvals_Sum_Order_By>;
  var_pop?: Maybe<Field_Trip_Approvals_Var_Pop_Order_By>;
  var_samp?: Maybe<Field_Trip_Approvals_Var_Samp_Order_By>;
  variance?: Maybe<Field_Trip_Approvals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "field_trip_approvals" */
export type Field_Trip_Approvals_Arr_Rel_Insert_Input = {
  data: Array<Field_Trip_Approvals_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Field_Trip_Approvals_On_Conflict>;
};

/** aggregate avg on columns */
export type Field_Trip_Approvals_Avg_Fields = {
  __typename?: "field_trip_approvals_avg_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "field_trip_approvals" */
export type Field_Trip_Approvals_Avg_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "field_trip_approvals". All fields are combined with a logical 'AND'. */
export type Field_Trip_Approvals_Bool_Exp = {
  _and?: Maybe<Array<Field_Trip_Approvals_Bool_Exp>>;
  _not?: Maybe<Field_Trip_Approvals_Bool_Exp>;
  _or?: Maybe<Array<Field_Trip_Approvals_Bool_Exp>>;
  approved_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  field_trip?: Maybe<Field_Trips_Bool_Exp>;
  field_trip_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_chaperone?: Maybe<Boolean_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "field_trip_approvals" */
export enum Field_Trip_Approvals_Constraint {
  /** unique or primary key constraint */
  FieldTripApprovalsFieldTripIdUserIdKey = "field_trip_approvals_field_trip_id_user_id_key",
  /** unique or primary key constraint */
  FieldTripApprovalsPkey = "field_trip_approvals_pkey",
}

/** input type for incrementing numeric columns in table "field_trip_approvals" */
export type Field_Trip_Approvals_Inc_Input = {
  field_trip_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "field_trip_approvals" */
export type Field_Trip_Approvals_Insert_Input = {
  approved_at?: Maybe<Scalars["timestamptz"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  field_trip?: Maybe<Field_Trips_Obj_Rel_Insert_Input>;
  field_trip_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_chaperone?: Maybe<Scalars["Boolean"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Field_Trip_Approvals_Max_Fields = {
  __typename?: "field_trip_approvals_max_fields";
  approved_at?: Maybe<Scalars["timestamptz"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  field_trip_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "field_trip_approvals" */
export type Field_Trip_Approvals_Max_Order_By = {
  approved_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Field_Trip_Approvals_Min_Fields = {
  __typename?: "field_trip_approvals_min_fields";
  approved_at?: Maybe<Scalars["timestamptz"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  field_trip_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "field_trip_approvals" */
export type Field_Trip_Approvals_Min_Order_By = {
  approved_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "field_trip_approvals" */
export type Field_Trip_Approvals_Mutation_Response = {
  __typename?: "field_trip_approvals_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Field_Trip_Approvals>;
};

/** on_conflict condition type for table "field_trip_approvals" */
export type Field_Trip_Approvals_On_Conflict = {
  constraint: Field_Trip_Approvals_Constraint;
  update_columns: Array<Field_Trip_Approvals_Update_Column>;
  where?: Maybe<Field_Trip_Approvals_Bool_Exp>;
};

/** Ordering options when selecting data from "field_trip_approvals". */
export type Field_Trip_Approvals_Order_By = {
  approved_at?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  field_trip?: Maybe<Field_Trips_Order_By>;
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_chaperone?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: field_trip_approvals */
export type Field_Trip_Approvals_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "field_trip_approvals" */
export enum Field_Trip_Approvals_Select_Column {
  /** column name */
  ApprovedAt = "approved_at",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FieldTripId = "field_trip_id",
  /** column name */
  Id = "id",
  /** column name */
  IsChaperone = "is_chaperone",
  /** column name */
  Status = "status",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "field_trip_approvals" */
export type Field_Trip_Approvals_Set_Input = {
  approved_at?: Maybe<Scalars["timestamptz"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  field_trip_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_chaperone?: Maybe<Scalars["Boolean"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Field_Trip_Approvals_Stddev_Fields = {
  __typename?: "field_trip_approvals_stddev_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "field_trip_approvals" */
export type Field_Trip_Approvals_Stddev_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Field_Trip_Approvals_Stddev_Pop_Fields = {
  __typename?: "field_trip_approvals_stddev_pop_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "field_trip_approvals" */
export type Field_Trip_Approvals_Stddev_Pop_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Field_Trip_Approvals_Stddev_Samp_Fields = {
  __typename?: "field_trip_approvals_stddev_samp_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "field_trip_approvals" */
export type Field_Trip_Approvals_Stddev_Samp_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Field_Trip_Approvals_Sum_Fields = {
  __typename?: "field_trip_approvals_sum_fields";
  field_trip_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "field_trip_approvals" */
export type Field_Trip_Approvals_Sum_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "field_trip_approvals" */
export enum Field_Trip_Approvals_Update_Column {
  /** column name */
  ApprovedAt = "approved_at",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FieldTripId = "field_trip_id",
  /** column name */
  Id = "id",
  /** column name */
  IsChaperone = "is_chaperone",
  /** column name */
  Status = "status",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Field_Trip_Approvals_Var_Pop_Fields = {
  __typename?: "field_trip_approvals_var_pop_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "field_trip_approvals" */
export type Field_Trip_Approvals_Var_Pop_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Field_Trip_Approvals_Var_Samp_Fields = {
  __typename?: "field_trip_approvals_var_samp_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "field_trip_approvals" */
export type Field_Trip_Approvals_Var_Samp_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Field_Trip_Approvals_Variance_Fields = {
  __typename?: "field_trip_approvals_variance_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "field_trip_approvals" */
export type Field_Trip_Approvals_Variance_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "field_trip_location_reviews" */
export type Field_Trip_Location_Reviews = {
  __typename?: "field_trip_location_reviews";
  comments?: Maybe<Scalars["String"]>;
  /** An object relationship */
  field_trip: Field_Trips;
  field_trip_id: Scalars["Int"];
  /** An object relationship */
  field_trip_location: Field_Trip_Locations;
  field_trip_location_id: Scalars["uuid"];
  id: Scalars["bigint"];
  rating?: Maybe<Scalars["Int"]>;
  /** An object relationship */
  user: Users;
  user_id: Scalars["uuid"];
};

/** aggregated selection of "field_trip_location_reviews" */
export type Field_Trip_Location_Reviews_Aggregate = {
  __typename?: "field_trip_location_reviews_aggregate";
  aggregate?: Maybe<Field_Trip_Location_Reviews_Aggregate_Fields>;
  nodes: Array<Field_Trip_Location_Reviews>;
};

/** aggregate fields of "field_trip_location_reviews" */
export type Field_Trip_Location_Reviews_Aggregate_Fields = {
  __typename?: "field_trip_location_reviews_aggregate_fields";
  avg?: Maybe<Field_Trip_Location_Reviews_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Field_Trip_Location_Reviews_Max_Fields>;
  min?: Maybe<Field_Trip_Location_Reviews_Min_Fields>;
  stddev?: Maybe<Field_Trip_Location_Reviews_Stddev_Fields>;
  stddev_pop?: Maybe<Field_Trip_Location_Reviews_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Field_Trip_Location_Reviews_Stddev_Samp_Fields>;
  sum?: Maybe<Field_Trip_Location_Reviews_Sum_Fields>;
  var_pop?: Maybe<Field_Trip_Location_Reviews_Var_Pop_Fields>;
  var_samp?: Maybe<Field_Trip_Location_Reviews_Var_Samp_Fields>;
  variance?: Maybe<Field_Trip_Location_Reviews_Variance_Fields>;
};

/** aggregate fields of "field_trip_location_reviews" */
export type Field_Trip_Location_Reviews_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Field_Trip_Location_Reviews_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "field_trip_location_reviews" */
export type Field_Trip_Location_Reviews_Aggregate_Order_By = {
  avg?: Maybe<Field_Trip_Location_Reviews_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Field_Trip_Location_Reviews_Max_Order_By>;
  min?: Maybe<Field_Trip_Location_Reviews_Min_Order_By>;
  stddev?: Maybe<Field_Trip_Location_Reviews_Stddev_Order_By>;
  stddev_pop?: Maybe<Field_Trip_Location_Reviews_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Field_Trip_Location_Reviews_Stddev_Samp_Order_By>;
  sum?: Maybe<Field_Trip_Location_Reviews_Sum_Order_By>;
  var_pop?: Maybe<Field_Trip_Location_Reviews_Var_Pop_Order_By>;
  var_samp?: Maybe<Field_Trip_Location_Reviews_Var_Samp_Order_By>;
  variance?: Maybe<Field_Trip_Location_Reviews_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "field_trip_location_reviews" */
export type Field_Trip_Location_Reviews_Arr_Rel_Insert_Input = {
  data: Array<Field_Trip_Location_Reviews_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Field_Trip_Location_Reviews_On_Conflict>;
};

/** aggregate avg on columns */
export type Field_Trip_Location_Reviews_Avg_Fields = {
  __typename?: "field_trip_location_reviews_avg_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  rating?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "field_trip_location_reviews" */
export type Field_Trip_Location_Reviews_Avg_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "field_trip_location_reviews". All fields are combined with a logical 'AND'. */
export type Field_Trip_Location_Reviews_Bool_Exp = {
  _and?: Maybe<Array<Field_Trip_Location_Reviews_Bool_Exp>>;
  _not?: Maybe<Field_Trip_Location_Reviews_Bool_Exp>;
  _or?: Maybe<Array<Field_Trip_Location_Reviews_Bool_Exp>>;
  comments?: Maybe<String_Comparison_Exp>;
  field_trip?: Maybe<Field_Trips_Bool_Exp>;
  field_trip_id?: Maybe<Int_Comparison_Exp>;
  field_trip_location?: Maybe<Field_Trip_Locations_Bool_Exp>;
  field_trip_location_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  rating?: Maybe<Int_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "field_trip_location_reviews" */
export enum Field_Trip_Location_Reviews_Constraint {
  /** unique or primary key constraint */
  FieldTripLocationReviewsFieldTripIdUserIdFieldTripLo = "field_trip_location_reviews_field_trip_id_user_id_field_trip_lo",
  /** unique or primary key constraint */
  FieldTripLocationReviewsPkey = "field_trip_location_reviews_pkey",
}

/** input type for incrementing numeric columns in table "field_trip_location_reviews" */
export type Field_Trip_Location_Reviews_Inc_Input = {
  field_trip_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["bigint"]>;
  rating?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "field_trip_location_reviews" */
export type Field_Trip_Location_Reviews_Insert_Input = {
  comments?: Maybe<Scalars["String"]>;
  field_trip?: Maybe<Field_Trips_Obj_Rel_Insert_Input>;
  field_trip_id?: Maybe<Scalars["Int"]>;
  field_trip_location?: Maybe<Field_Trip_Locations_Obj_Rel_Insert_Input>;
  field_trip_location_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["bigint"]>;
  rating?: Maybe<Scalars["Int"]>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Field_Trip_Location_Reviews_Max_Fields = {
  __typename?: "field_trip_location_reviews_max_fields";
  comments?: Maybe<Scalars["String"]>;
  field_trip_id?: Maybe<Scalars["Int"]>;
  field_trip_location_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["bigint"]>;
  rating?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "field_trip_location_reviews" */
export type Field_Trip_Location_Reviews_Max_Order_By = {
  comments?: Maybe<Order_By>;
  field_trip_id?: Maybe<Order_By>;
  field_trip_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Field_Trip_Location_Reviews_Min_Fields = {
  __typename?: "field_trip_location_reviews_min_fields";
  comments?: Maybe<Scalars["String"]>;
  field_trip_id?: Maybe<Scalars["Int"]>;
  field_trip_location_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["bigint"]>;
  rating?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "field_trip_location_reviews" */
export type Field_Trip_Location_Reviews_Min_Order_By = {
  comments?: Maybe<Order_By>;
  field_trip_id?: Maybe<Order_By>;
  field_trip_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "field_trip_location_reviews" */
export type Field_Trip_Location_Reviews_Mutation_Response = {
  __typename?: "field_trip_location_reviews_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Field_Trip_Location_Reviews>;
};

/** on_conflict condition type for table "field_trip_location_reviews" */
export type Field_Trip_Location_Reviews_On_Conflict = {
  constraint: Field_Trip_Location_Reviews_Constraint;
  update_columns: Array<Field_Trip_Location_Reviews_Update_Column>;
  where?: Maybe<Field_Trip_Location_Reviews_Bool_Exp>;
};

/** Ordering options when selecting data from "field_trip_location_reviews". */
export type Field_Trip_Location_Reviews_Order_By = {
  comments?: Maybe<Order_By>;
  field_trip?: Maybe<Field_Trips_Order_By>;
  field_trip_id?: Maybe<Order_By>;
  field_trip_location?: Maybe<Field_Trip_Locations_Order_By>;
  field_trip_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: field_trip_location_reviews */
export type Field_Trip_Location_Reviews_Pk_Columns_Input = {
  id: Scalars["bigint"];
};

/** select columns of table "field_trip_location_reviews" */
export enum Field_Trip_Location_Reviews_Select_Column {
  /** column name */
  Comments = "comments",
  /** column name */
  FieldTripId = "field_trip_id",
  /** column name */
  FieldTripLocationId = "field_trip_location_id",
  /** column name */
  Id = "id",
  /** column name */
  Rating = "rating",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "field_trip_location_reviews" */
export type Field_Trip_Location_Reviews_Set_Input = {
  comments?: Maybe<Scalars["String"]>;
  field_trip_id?: Maybe<Scalars["Int"]>;
  field_trip_location_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["bigint"]>;
  rating?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Field_Trip_Location_Reviews_Stddev_Fields = {
  __typename?: "field_trip_location_reviews_stddev_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  rating?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "field_trip_location_reviews" */
export type Field_Trip_Location_Reviews_Stddev_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Field_Trip_Location_Reviews_Stddev_Pop_Fields = {
  __typename?: "field_trip_location_reviews_stddev_pop_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  rating?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "field_trip_location_reviews" */
export type Field_Trip_Location_Reviews_Stddev_Pop_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Field_Trip_Location_Reviews_Stddev_Samp_Fields = {
  __typename?: "field_trip_location_reviews_stddev_samp_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  rating?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "field_trip_location_reviews" */
export type Field_Trip_Location_Reviews_Stddev_Samp_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Field_Trip_Location_Reviews_Sum_Fields = {
  __typename?: "field_trip_location_reviews_sum_fields";
  field_trip_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["bigint"]>;
  rating?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "field_trip_location_reviews" */
export type Field_Trip_Location_Reviews_Sum_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** update columns of table "field_trip_location_reviews" */
export enum Field_Trip_Location_Reviews_Update_Column {
  /** column name */
  Comments = "comments",
  /** column name */
  FieldTripId = "field_trip_id",
  /** column name */
  FieldTripLocationId = "field_trip_location_id",
  /** column name */
  Id = "id",
  /** column name */
  Rating = "rating",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Field_Trip_Location_Reviews_Var_Pop_Fields = {
  __typename?: "field_trip_location_reviews_var_pop_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  rating?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "field_trip_location_reviews" */
export type Field_Trip_Location_Reviews_Var_Pop_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Field_Trip_Location_Reviews_Var_Samp_Fields = {
  __typename?: "field_trip_location_reviews_var_samp_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  rating?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "field_trip_location_reviews" */
export type Field_Trip_Location_Reviews_Var_Samp_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Field_Trip_Location_Reviews_Variance_Fields = {
  __typename?: "field_trip_location_reviews_variance_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  rating?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "field_trip_location_reviews" */
export type Field_Trip_Location_Reviews_Variance_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
};

/** columns and relationships of "field_trip_locations" */
export type Field_Trip_Locations = {
  __typename?: "field_trip_locations";
  contact_info?: Maybe<Scalars["String"]>;
  /** An object relationship */
  field_trip: Field_Trips;
  field_trip_id: Scalars["Int"];
  /** An array relationship */
  field_trip_location_reviews: Array<Field_Trip_Location_Reviews>;
  /** An aggregate relationship */
  field_trip_location_reviews_aggregate: Field_Trip_Location_Reviews_Aggregate;
  id: Scalars["uuid"];
  /** An object relationship */
  location: Locations;
  location_id: Scalars["uuid"];
  location_type: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  time: Scalars["timestamp"];
};

/** columns and relationships of "field_trip_locations" */
export type Field_Trip_LocationsField_Trip_Location_ReviewsArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Location_Reviews_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Location_Reviews_Order_By>>;
  where?: Maybe<Field_Trip_Location_Reviews_Bool_Exp>;
};

/** columns and relationships of "field_trip_locations" */
export type Field_Trip_LocationsField_Trip_Location_Reviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Location_Reviews_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Location_Reviews_Order_By>>;
  where?: Maybe<Field_Trip_Location_Reviews_Bool_Exp>;
};

/** aggregated selection of "field_trip_locations" */
export type Field_Trip_Locations_Aggregate = {
  __typename?: "field_trip_locations_aggregate";
  aggregate?: Maybe<Field_Trip_Locations_Aggregate_Fields>;
  nodes: Array<Field_Trip_Locations>;
};

/** aggregate fields of "field_trip_locations" */
export type Field_Trip_Locations_Aggregate_Fields = {
  __typename?: "field_trip_locations_aggregate_fields";
  avg?: Maybe<Field_Trip_Locations_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Field_Trip_Locations_Max_Fields>;
  min?: Maybe<Field_Trip_Locations_Min_Fields>;
  stddev?: Maybe<Field_Trip_Locations_Stddev_Fields>;
  stddev_pop?: Maybe<Field_Trip_Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Field_Trip_Locations_Stddev_Samp_Fields>;
  sum?: Maybe<Field_Trip_Locations_Sum_Fields>;
  var_pop?: Maybe<Field_Trip_Locations_Var_Pop_Fields>;
  var_samp?: Maybe<Field_Trip_Locations_Var_Samp_Fields>;
  variance?: Maybe<Field_Trip_Locations_Variance_Fields>;
};

/** aggregate fields of "field_trip_locations" */
export type Field_Trip_Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Field_Trip_Locations_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "field_trip_locations" */
export type Field_Trip_Locations_Aggregate_Order_By = {
  avg?: Maybe<Field_Trip_Locations_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Field_Trip_Locations_Max_Order_By>;
  min?: Maybe<Field_Trip_Locations_Min_Order_By>;
  stddev?: Maybe<Field_Trip_Locations_Stddev_Order_By>;
  stddev_pop?: Maybe<Field_Trip_Locations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Field_Trip_Locations_Stddev_Samp_Order_By>;
  sum?: Maybe<Field_Trip_Locations_Sum_Order_By>;
  var_pop?: Maybe<Field_Trip_Locations_Var_Pop_Order_By>;
  var_samp?: Maybe<Field_Trip_Locations_Var_Samp_Order_By>;
  variance?: Maybe<Field_Trip_Locations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "field_trip_locations" */
export type Field_Trip_Locations_Arr_Rel_Insert_Input = {
  data: Array<Field_Trip_Locations_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Field_Trip_Locations_On_Conflict>;
};

/** aggregate avg on columns */
export type Field_Trip_Locations_Avg_Fields = {
  __typename?: "field_trip_locations_avg_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "field_trip_locations" */
export type Field_Trip_Locations_Avg_Order_By = {
  field_trip_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "field_trip_locations". All fields are combined with a logical 'AND'. */
export type Field_Trip_Locations_Bool_Exp = {
  _and?: Maybe<Array<Field_Trip_Locations_Bool_Exp>>;
  _not?: Maybe<Field_Trip_Locations_Bool_Exp>;
  _or?: Maybe<Array<Field_Trip_Locations_Bool_Exp>>;
  contact_info?: Maybe<String_Comparison_Exp>;
  field_trip?: Maybe<Field_Trips_Bool_Exp>;
  field_trip_id?: Maybe<Int_Comparison_Exp>;
  field_trip_location_reviews?: Maybe<Field_Trip_Location_Reviews_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Locations_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  location_type?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  time?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "field_trip_locations" */
export enum Field_Trip_Locations_Constraint {
  /** unique or primary key constraint */
  FieldTripLocationsPkey = "field_trip_locations_pkey",
}

/** input type for incrementing numeric columns in table "field_trip_locations" */
export type Field_Trip_Locations_Inc_Input = {
  field_trip_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "field_trip_locations" */
export type Field_Trip_Locations_Insert_Input = {
  contact_info?: Maybe<Scalars["String"]>;
  field_trip?: Maybe<Field_Trips_Obj_Rel_Insert_Input>;
  field_trip_id?: Maybe<Scalars["Int"]>;
  field_trip_location_reviews?: Maybe<
    Field_Trip_Location_Reviews_Arr_Rel_Insert_Input
  >;
  id?: Maybe<Scalars["uuid"]>;
  location?: Maybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars["uuid"]>;
  location_type?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamp"]>;
};

/** aggregate max on columns */
export type Field_Trip_Locations_Max_Fields = {
  __typename?: "field_trip_locations_max_fields";
  contact_info?: Maybe<Scalars["String"]>;
  field_trip_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  location_type?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamp"]>;
};

/** order by max() on columns of table "field_trip_locations" */
export type Field_Trip_Locations_Max_Order_By = {
  contact_info?: Maybe<Order_By>;
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  location_type?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Field_Trip_Locations_Min_Fields = {
  __typename?: "field_trip_locations_min_fields";
  contact_info?: Maybe<Scalars["String"]>;
  field_trip_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  location_type?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamp"]>;
};

/** order by min() on columns of table "field_trip_locations" */
export type Field_Trip_Locations_Min_Order_By = {
  contact_info?: Maybe<Order_By>;
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  location_type?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

/** response of any mutation on the table "field_trip_locations" */
export type Field_Trip_Locations_Mutation_Response = {
  __typename?: "field_trip_locations_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Field_Trip_Locations>;
};

/** input type for inserting object relation for remote table "field_trip_locations" */
export type Field_Trip_Locations_Obj_Rel_Insert_Input = {
  data: Field_Trip_Locations_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Field_Trip_Locations_On_Conflict>;
};

/** on_conflict condition type for table "field_trip_locations" */
export type Field_Trip_Locations_On_Conflict = {
  constraint: Field_Trip_Locations_Constraint;
  update_columns: Array<Field_Trip_Locations_Update_Column>;
  where?: Maybe<Field_Trip_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "field_trip_locations". */
export type Field_Trip_Locations_Order_By = {
  contact_info?: Maybe<Order_By>;
  field_trip?: Maybe<Field_Trips_Order_By>;
  field_trip_id?: Maybe<Order_By>;
  field_trip_location_reviews_aggregate?: Maybe<
    Field_Trip_Location_Reviews_Aggregate_Order_By
  >;
  id?: Maybe<Order_By>;
  location?: Maybe<Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  location_type?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  time?: Maybe<Order_By>;
};

/** primary key columns input for table: field_trip_locations */
export type Field_Trip_Locations_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "field_trip_locations" */
export enum Field_Trip_Locations_Select_Column {
  /** column name */
  ContactInfo = "contact_info",
  /** column name */
  FieldTripId = "field_trip_id",
  /** column name */
  Id = "id",
  /** column name */
  LocationId = "location_id",
  /** column name */
  LocationType = "location_type",
  /** column name */
  Notes = "notes",
  /** column name */
  Time = "time",
}

/** input type for updating data in table "field_trip_locations" */
export type Field_Trip_Locations_Set_Input = {
  contact_info?: Maybe<Scalars["String"]>;
  field_trip_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  location_type?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  time?: Maybe<Scalars["timestamp"]>;
};

/** aggregate stddev on columns */
export type Field_Trip_Locations_Stddev_Fields = {
  __typename?: "field_trip_locations_stddev_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "field_trip_locations" */
export type Field_Trip_Locations_Stddev_Order_By = {
  field_trip_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Field_Trip_Locations_Stddev_Pop_Fields = {
  __typename?: "field_trip_locations_stddev_pop_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "field_trip_locations" */
export type Field_Trip_Locations_Stddev_Pop_Order_By = {
  field_trip_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Field_Trip_Locations_Stddev_Samp_Fields = {
  __typename?: "field_trip_locations_stddev_samp_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "field_trip_locations" */
export type Field_Trip_Locations_Stddev_Samp_Order_By = {
  field_trip_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Field_Trip_Locations_Sum_Fields = {
  __typename?: "field_trip_locations_sum_fields";
  field_trip_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "field_trip_locations" */
export type Field_Trip_Locations_Sum_Order_By = {
  field_trip_id?: Maybe<Order_By>;
};

/** update columns of table "field_trip_locations" */
export enum Field_Trip_Locations_Update_Column {
  /** column name */
  ContactInfo = "contact_info",
  /** column name */
  FieldTripId = "field_trip_id",
  /** column name */
  Id = "id",
  /** column name */
  LocationId = "location_id",
  /** column name */
  LocationType = "location_type",
  /** column name */
  Notes = "notes",
  /** column name */
  Time = "time",
}

/** aggregate var_pop on columns */
export type Field_Trip_Locations_Var_Pop_Fields = {
  __typename?: "field_trip_locations_var_pop_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "field_trip_locations" */
export type Field_Trip_Locations_Var_Pop_Order_By = {
  field_trip_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Field_Trip_Locations_Var_Samp_Fields = {
  __typename?: "field_trip_locations_var_samp_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "field_trip_locations" */
export type Field_Trip_Locations_Var_Samp_Order_By = {
  field_trip_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Field_Trip_Locations_Variance_Fields = {
  __typename?: "field_trip_locations_variance_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "field_trip_locations" */
export type Field_Trip_Locations_Variance_Order_By = {
  field_trip_id?: Maybe<Order_By>;
};

/** columns and relationships of "field_trip_students" */
export type Field_Trip_Students = {
  __typename?: "field_trip_students";
  created_at?: Maybe<Scalars["timestamptz"]>;
  enjoyed_most?: Maybe<Scalars["String"]>;
  /** An object relationship */
  field_trip: Field_Trips;
  field_trip_id: Scalars["Int"];
  grace_courtesy_approval: Scalars["Boolean"];
  gratitude_mail_agreement: Scalars["Boolean"];
  id: Scalars["Int"];
  improvements?: Maybe<Scalars["String"]>;
  reflect_survey?: Maybe<Scalars["jsonb"]>;
  share_experience?: Maybe<Scalars["jsonb"]>;
  share_experience_agreement: Scalars["Boolean"];
  stage?: Maybe<Scalars["String"]>;
  transportation_logistics_approval: Scalars["Boolean"];
  updated_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  user: Users;
  user_id: Scalars["uuid"];
};

/** columns and relationships of "field_trip_students" */
export type Field_Trip_StudentsReflect_SurveyArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "field_trip_students" */
export type Field_Trip_StudentsShare_ExperienceArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "field_trip_students" */
export type Field_Trip_Students_Aggregate = {
  __typename?: "field_trip_students_aggregate";
  aggregate?: Maybe<Field_Trip_Students_Aggregate_Fields>;
  nodes: Array<Field_Trip_Students>;
};

/** aggregate fields of "field_trip_students" */
export type Field_Trip_Students_Aggregate_Fields = {
  __typename?: "field_trip_students_aggregate_fields";
  avg?: Maybe<Field_Trip_Students_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Field_Trip_Students_Max_Fields>;
  min?: Maybe<Field_Trip_Students_Min_Fields>;
  stddev?: Maybe<Field_Trip_Students_Stddev_Fields>;
  stddev_pop?: Maybe<Field_Trip_Students_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Field_Trip_Students_Stddev_Samp_Fields>;
  sum?: Maybe<Field_Trip_Students_Sum_Fields>;
  var_pop?: Maybe<Field_Trip_Students_Var_Pop_Fields>;
  var_samp?: Maybe<Field_Trip_Students_Var_Samp_Fields>;
  variance?: Maybe<Field_Trip_Students_Variance_Fields>;
};

/** aggregate fields of "field_trip_students" */
export type Field_Trip_Students_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Field_Trip_Students_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "field_trip_students" */
export type Field_Trip_Students_Aggregate_Order_By = {
  avg?: Maybe<Field_Trip_Students_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Field_Trip_Students_Max_Order_By>;
  min?: Maybe<Field_Trip_Students_Min_Order_By>;
  stddev?: Maybe<Field_Trip_Students_Stddev_Order_By>;
  stddev_pop?: Maybe<Field_Trip_Students_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Field_Trip_Students_Stddev_Samp_Order_By>;
  sum?: Maybe<Field_Trip_Students_Sum_Order_By>;
  var_pop?: Maybe<Field_Trip_Students_Var_Pop_Order_By>;
  var_samp?: Maybe<Field_Trip_Students_Var_Samp_Order_By>;
  variance?: Maybe<Field_Trip_Students_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Field_Trip_Students_Append_Input = {
  reflect_survey?: Maybe<Scalars["jsonb"]>;
  share_experience?: Maybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "field_trip_students" */
export type Field_Trip_Students_Arr_Rel_Insert_Input = {
  data: Array<Field_Trip_Students_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Field_Trip_Students_On_Conflict>;
};

/** aggregate avg on columns */
export type Field_Trip_Students_Avg_Fields = {
  __typename?: "field_trip_students_avg_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "field_trip_students" */
export type Field_Trip_Students_Avg_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "field_trip_students". All fields are combined with a logical 'AND'. */
export type Field_Trip_Students_Bool_Exp = {
  _and?: Maybe<Array<Field_Trip_Students_Bool_Exp>>;
  _not?: Maybe<Field_Trip_Students_Bool_Exp>;
  _or?: Maybe<Array<Field_Trip_Students_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  enjoyed_most?: Maybe<String_Comparison_Exp>;
  field_trip?: Maybe<Field_Trips_Bool_Exp>;
  field_trip_id?: Maybe<Int_Comparison_Exp>;
  grace_courtesy_approval?: Maybe<Boolean_Comparison_Exp>;
  gratitude_mail_agreement?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  improvements?: Maybe<String_Comparison_Exp>;
  reflect_survey?: Maybe<Jsonb_Comparison_Exp>;
  share_experience?: Maybe<Jsonb_Comparison_Exp>;
  share_experience_agreement?: Maybe<Boolean_Comparison_Exp>;
  stage?: Maybe<String_Comparison_Exp>;
  transportation_logistics_approval?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "field_trip_students" */
export enum Field_Trip_Students_Constraint {
  /** unique or primary key constraint */
  FieldTripStudentsFieldTripIdUserIdKey = "field_trip_students_field_trip_id_user_id_key",
  /** unique or primary key constraint */
  FieldTripStudentsPkey = "field_trip_students_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Field_Trip_Students_Delete_At_Path_Input = {
  reflect_survey?: Maybe<Array<Scalars["String"]>>;
  share_experience?: Maybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Field_Trip_Students_Delete_Elem_Input = {
  reflect_survey?: Maybe<Scalars["Int"]>;
  share_experience?: Maybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Field_Trip_Students_Delete_Key_Input = {
  reflect_survey?: Maybe<Scalars["String"]>;
  share_experience?: Maybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "field_trip_students" */
export type Field_Trip_Students_Inc_Input = {
  field_trip_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "field_trip_students" */
export type Field_Trip_Students_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  enjoyed_most?: Maybe<Scalars["String"]>;
  field_trip?: Maybe<Field_Trips_Obj_Rel_Insert_Input>;
  field_trip_id?: Maybe<Scalars["Int"]>;
  grace_courtesy_approval?: Maybe<Scalars["Boolean"]>;
  gratitude_mail_agreement?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["Int"]>;
  improvements?: Maybe<Scalars["String"]>;
  reflect_survey?: Maybe<Scalars["jsonb"]>;
  share_experience?: Maybe<Scalars["jsonb"]>;
  share_experience_agreement?: Maybe<Scalars["Boolean"]>;
  stage?: Maybe<Scalars["String"]>;
  transportation_logistics_approval?: Maybe<Scalars["Boolean"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Field_Trip_Students_Max_Fields = {
  __typename?: "field_trip_students_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  enjoyed_most?: Maybe<Scalars["String"]>;
  field_trip_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  improvements?: Maybe<Scalars["String"]>;
  stage?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "field_trip_students" */
export type Field_Trip_Students_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  enjoyed_most?: Maybe<Order_By>;
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  improvements?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Field_Trip_Students_Min_Fields = {
  __typename?: "field_trip_students_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  enjoyed_most?: Maybe<Scalars["String"]>;
  field_trip_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  improvements?: Maybe<Scalars["String"]>;
  stage?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "field_trip_students" */
export type Field_Trip_Students_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  enjoyed_most?: Maybe<Order_By>;
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  improvements?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "field_trip_students" */
export type Field_Trip_Students_Mutation_Response = {
  __typename?: "field_trip_students_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Field_Trip_Students>;
};

/** on_conflict condition type for table "field_trip_students" */
export type Field_Trip_Students_On_Conflict = {
  constraint: Field_Trip_Students_Constraint;
  update_columns: Array<Field_Trip_Students_Update_Column>;
  where?: Maybe<Field_Trip_Students_Bool_Exp>;
};

/** Ordering options when selecting data from "field_trip_students". */
export type Field_Trip_Students_Order_By = {
  created_at?: Maybe<Order_By>;
  enjoyed_most?: Maybe<Order_By>;
  field_trip?: Maybe<Field_Trips_Order_By>;
  field_trip_id?: Maybe<Order_By>;
  grace_courtesy_approval?: Maybe<Order_By>;
  gratitude_mail_agreement?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  improvements?: Maybe<Order_By>;
  reflect_survey?: Maybe<Order_By>;
  share_experience?: Maybe<Order_By>;
  share_experience_agreement?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  transportation_logistics_approval?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: field_trip_students */
export type Field_Trip_Students_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Field_Trip_Students_Prepend_Input = {
  reflect_survey?: Maybe<Scalars["jsonb"]>;
  share_experience?: Maybe<Scalars["jsonb"]>;
};

/** select columns of table "field_trip_students" */
export enum Field_Trip_Students_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  EnjoyedMost = "enjoyed_most",
  /** column name */
  FieldTripId = "field_trip_id",
  /** column name */
  GraceCourtesyApproval = "grace_courtesy_approval",
  /** column name */
  GratitudeMailAgreement = "gratitude_mail_agreement",
  /** column name */
  Id = "id",
  /** column name */
  Improvements = "improvements",
  /** column name */
  ReflectSurvey = "reflect_survey",
  /** column name */
  ShareExperience = "share_experience",
  /** column name */
  ShareExperienceAgreement = "share_experience_agreement",
  /** column name */
  Stage = "stage",
  /** column name */
  TransportationLogisticsApproval = "transportation_logistics_approval",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "field_trip_students" */
export type Field_Trip_Students_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  enjoyed_most?: Maybe<Scalars["String"]>;
  field_trip_id?: Maybe<Scalars["Int"]>;
  grace_courtesy_approval?: Maybe<Scalars["Boolean"]>;
  gratitude_mail_agreement?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["Int"]>;
  improvements?: Maybe<Scalars["String"]>;
  reflect_survey?: Maybe<Scalars["jsonb"]>;
  share_experience?: Maybe<Scalars["jsonb"]>;
  share_experience_agreement?: Maybe<Scalars["Boolean"]>;
  stage?: Maybe<Scalars["String"]>;
  transportation_logistics_approval?: Maybe<Scalars["Boolean"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Field_Trip_Students_Stddev_Fields = {
  __typename?: "field_trip_students_stddev_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "field_trip_students" */
export type Field_Trip_Students_Stddev_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Field_Trip_Students_Stddev_Pop_Fields = {
  __typename?: "field_trip_students_stddev_pop_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "field_trip_students" */
export type Field_Trip_Students_Stddev_Pop_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Field_Trip_Students_Stddev_Samp_Fields = {
  __typename?: "field_trip_students_stddev_samp_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "field_trip_students" */
export type Field_Trip_Students_Stddev_Samp_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Field_Trip_Students_Sum_Fields = {
  __typename?: "field_trip_students_sum_fields";
  field_trip_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "field_trip_students" */
export type Field_Trip_Students_Sum_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "field_trip_students" */
export enum Field_Trip_Students_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  EnjoyedMost = "enjoyed_most",
  /** column name */
  FieldTripId = "field_trip_id",
  /** column name */
  GraceCourtesyApproval = "grace_courtesy_approval",
  /** column name */
  GratitudeMailAgreement = "gratitude_mail_agreement",
  /** column name */
  Id = "id",
  /** column name */
  Improvements = "improvements",
  /** column name */
  ReflectSurvey = "reflect_survey",
  /** column name */
  ShareExperience = "share_experience",
  /** column name */
  ShareExperienceAgreement = "share_experience_agreement",
  /** column name */
  Stage = "stage",
  /** column name */
  TransportationLogisticsApproval = "transportation_logistics_approval",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type Field_Trip_Students_Var_Pop_Fields = {
  __typename?: "field_trip_students_var_pop_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "field_trip_students" */
export type Field_Trip_Students_Var_Pop_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Field_Trip_Students_Var_Samp_Fields = {
  __typename?: "field_trip_students_var_samp_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "field_trip_students" */
export type Field_Trip_Students_Var_Samp_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Field_Trip_Students_Variance_Fields = {
  __typename?: "field_trip_students_variance_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "field_trip_students" */
export type Field_Trip_Students_Variance_Order_By = {
  field_trip_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "field_trips" */
export type Field_Trips = {
  __typename?: "field_trips";
  agenda?: Maybe<Scalars["String"]>;
  cost_per_person?: Maybe<Scalars["money"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  event_date?: Maybe<Scalars["date"]>;
  expectations_rules?: Maybe<Scalars["String"]>;
  /** An array relationship */
  field_trip_approvals: Array<Field_Trip_Approvals>;
  /** An aggregate relationship */
  field_trip_approvals_aggregate: Field_Trip_Approvals_Aggregate;
  /** An array relationship */
  field_trip_location_reviews: Array<Field_Trip_Location_Reviews>;
  /** An aggregate relationship */
  field_trip_location_reviews_aggregate: Field_Trip_Location_Reviews_Aggregate;
  /** An array relationship */
  field_trip_locations: Array<Field_Trip_Locations>;
  /** An aggregate relationship */
  field_trip_locations_aggregate: Field_Trip_Locations_Aggregate;
  /** An array relationship */
  field_trip_students: Array<Field_Trip_Students>;
  /** An aggregate relationship */
  field_trip_students_aggregate: Field_Trip_Students_Aggregate;
  id: Scalars["Int"];
  interest_area: Scalars["String"];
  /** An object relationship */
  my_trip_data?: Maybe<My_Field_Trip_Data>;
  /** An object relationship */
  owner: Users;
  owner_id: Scalars["uuid"];
  research_questions_goals?: Maybe<Scalars["String"]>;
  /** An object relationship */
  school: Schools;
  school_id: Scalars["uuid"];
  status: Scalars["String"];
  supply_list?: Maybe<Scalars["jsonb"]>;
  title: Scalars["String"];
  total_cost?: Maybe<Scalars["money"]>;
  transportation_options?: Maybe<Scalars["jsonb"]>;
  uid: Scalars["uuid"];
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** columns and relationships of "field_trips" */
export type Field_TripsField_Trip_ApprovalsArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Approvals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Approvals_Order_By>>;
  where?: Maybe<Field_Trip_Approvals_Bool_Exp>;
};

/** columns and relationships of "field_trips" */
export type Field_TripsField_Trip_Approvals_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Approvals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Approvals_Order_By>>;
  where?: Maybe<Field_Trip_Approvals_Bool_Exp>;
};

/** columns and relationships of "field_trips" */
export type Field_TripsField_Trip_Location_ReviewsArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Location_Reviews_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Location_Reviews_Order_By>>;
  where?: Maybe<Field_Trip_Location_Reviews_Bool_Exp>;
};

/** columns and relationships of "field_trips" */
export type Field_TripsField_Trip_Location_Reviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Location_Reviews_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Location_Reviews_Order_By>>;
  where?: Maybe<Field_Trip_Location_Reviews_Bool_Exp>;
};

/** columns and relationships of "field_trips" */
export type Field_TripsField_Trip_LocationsArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Locations_Order_By>>;
  where?: Maybe<Field_Trip_Locations_Bool_Exp>;
};

/** columns and relationships of "field_trips" */
export type Field_TripsField_Trip_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Locations_Order_By>>;
  where?: Maybe<Field_Trip_Locations_Bool_Exp>;
};

/** columns and relationships of "field_trips" */
export type Field_TripsField_Trip_StudentsArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Students_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Students_Order_By>>;
  where?: Maybe<Field_Trip_Students_Bool_Exp>;
};

/** columns and relationships of "field_trips" */
export type Field_TripsField_Trip_Students_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Students_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Students_Order_By>>;
  where?: Maybe<Field_Trip_Students_Bool_Exp>;
};

/** columns and relationships of "field_trips" */
export type Field_TripsSupply_ListArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "field_trips" */
export type Field_TripsTransportation_OptionsArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "field_trips" */
export type Field_Trips_Aggregate = {
  __typename?: "field_trips_aggregate";
  aggregate?: Maybe<Field_Trips_Aggregate_Fields>;
  nodes: Array<Field_Trips>;
};

/** aggregate fields of "field_trips" */
export type Field_Trips_Aggregate_Fields = {
  __typename?: "field_trips_aggregate_fields";
  avg?: Maybe<Field_Trips_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Field_Trips_Max_Fields>;
  min?: Maybe<Field_Trips_Min_Fields>;
  stddev?: Maybe<Field_Trips_Stddev_Fields>;
  stddev_pop?: Maybe<Field_Trips_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Field_Trips_Stddev_Samp_Fields>;
  sum?: Maybe<Field_Trips_Sum_Fields>;
  var_pop?: Maybe<Field_Trips_Var_Pop_Fields>;
  var_samp?: Maybe<Field_Trips_Var_Samp_Fields>;
  variance?: Maybe<Field_Trips_Variance_Fields>;
};

/** aggregate fields of "field_trips" */
export type Field_Trips_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Field_Trips_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "field_trips" */
export type Field_Trips_Aggregate_Order_By = {
  avg?: Maybe<Field_Trips_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Field_Trips_Max_Order_By>;
  min?: Maybe<Field_Trips_Min_Order_By>;
  stddev?: Maybe<Field_Trips_Stddev_Order_By>;
  stddev_pop?: Maybe<Field_Trips_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Field_Trips_Stddev_Samp_Order_By>;
  sum?: Maybe<Field_Trips_Sum_Order_By>;
  var_pop?: Maybe<Field_Trips_Var_Pop_Order_By>;
  var_samp?: Maybe<Field_Trips_Var_Samp_Order_By>;
  variance?: Maybe<Field_Trips_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Field_Trips_Append_Input = {
  supply_list?: Maybe<Scalars["jsonb"]>;
  transportation_options?: Maybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "field_trips" */
export type Field_Trips_Arr_Rel_Insert_Input = {
  data: Array<Field_Trips_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Field_Trips_On_Conflict>;
};

/** aggregate avg on columns */
export type Field_Trips_Avg_Fields = {
  __typename?: "field_trips_avg_fields";
  cost_per_person?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  total_cost?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "field_trips" */
export type Field_Trips_Avg_Order_By = {
  cost_per_person?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  total_cost?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "field_trips". All fields are combined with a logical 'AND'. */
export type Field_Trips_Bool_Exp = {
  _and?: Maybe<Array<Field_Trips_Bool_Exp>>;
  _not?: Maybe<Field_Trips_Bool_Exp>;
  _or?: Maybe<Array<Field_Trips_Bool_Exp>>;
  agenda?: Maybe<String_Comparison_Exp>;
  cost_per_person?: Maybe<Money_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  event_date?: Maybe<Date_Comparison_Exp>;
  expectations_rules?: Maybe<String_Comparison_Exp>;
  field_trip_approvals?: Maybe<Field_Trip_Approvals_Bool_Exp>;
  field_trip_location_reviews?: Maybe<Field_Trip_Location_Reviews_Bool_Exp>;
  field_trip_locations?: Maybe<Field_Trip_Locations_Bool_Exp>;
  field_trip_students?: Maybe<Field_Trip_Students_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  interest_area?: Maybe<String_Comparison_Exp>;
  my_trip_data?: Maybe<My_Field_Trip_Data_Bool_Exp>;
  owner?: Maybe<Users_Bool_Exp>;
  owner_id?: Maybe<Uuid_Comparison_Exp>;
  research_questions_goals?: Maybe<String_Comparison_Exp>;
  school?: Maybe<Schools_Bool_Exp>;
  school_id?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  supply_list?: Maybe<Jsonb_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  total_cost?: Maybe<Money_Comparison_Exp>;
  transportation_options?: Maybe<Jsonb_Comparison_Exp>;
  uid?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "field_trips" */
export enum Field_Trips_Constraint {
  /** unique or primary key constraint */
  FieldTripsPkey = "field_trips_pkey",
  /** unique or primary key constraint */
  FieldTripsUidKey = "field_trips_uid_key",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Field_Trips_Delete_At_Path_Input = {
  supply_list?: Maybe<Array<Scalars["String"]>>;
  transportation_options?: Maybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Field_Trips_Delete_Elem_Input = {
  supply_list?: Maybe<Scalars["Int"]>;
  transportation_options?: Maybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Field_Trips_Delete_Key_Input = {
  supply_list?: Maybe<Scalars["String"]>;
  transportation_options?: Maybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "field_trips" */
export type Field_Trips_Inc_Input = {
  cost_per_person?: Maybe<Scalars["money"]>;
  id?: Maybe<Scalars["Int"]>;
  total_cost?: Maybe<Scalars["money"]>;
};

/** input type for inserting data into table "field_trips" */
export type Field_Trips_Insert_Input = {
  agenda?: Maybe<Scalars["String"]>;
  cost_per_person?: Maybe<Scalars["money"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  event_date?: Maybe<Scalars["date"]>;
  expectations_rules?: Maybe<Scalars["String"]>;
  field_trip_approvals?: Maybe<Field_Trip_Approvals_Arr_Rel_Insert_Input>;
  field_trip_location_reviews?: Maybe<
    Field_Trip_Location_Reviews_Arr_Rel_Insert_Input
  >;
  field_trip_locations?: Maybe<Field_Trip_Locations_Arr_Rel_Insert_Input>;
  field_trip_students?: Maybe<Field_Trip_Students_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars["Int"]>;
  interest_area?: Maybe<Scalars["String"]>;
  my_trip_data?: Maybe<My_Field_Trip_Data_Obj_Rel_Insert_Input>;
  owner?: Maybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: Maybe<Scalars["uuid"]>;
  research_questions_goals?: Maybe<Scalars["String"]>;
  school?: Maybe<Schools_Obj_Rel_Insert_Input>;
  school_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  supply_list?: Maybe<Scalars["jsonb"]>;
  title?: Maybe<Scalars["String"]>;
  total_cost?: Maybe<Scalars["money"]>;
  transportation_options?: Maybe<Scalars["jsonb"]>;
  uid?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Field_Trips_Max_Fields = {
  __typename?: "field_trips_max_fields";
  agenda?: Maybe<Scalars["String"]>;
  cost_per_person?: Maybe<Scalars["money"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  event_date?: Maybe<Scalars["date"]>;
  expectations_rules?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  interest_area?: Maybe<Scalars["String"]>;
  owner_id?: Maybe<Scalars["uuid"]>;
  research_questions_goals?: Maybe<Scalars["String"]>;
  school_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  total_cost?: Maybe<Scalars["money"]>;
  uid?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "field_trips" */
export type Field_Trips_Max_Order_By = {
  agenda?: Maybe<Order_By>;
  cost_per_person?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  event_date?: Maybe<Order_By>;
  expectations_rules?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interest_area?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  research_questions_goals?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  total_cost?: Maybe<Order_By>;
  uid?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Field_Trips_Min_Fields = {
  __typename?: "field_trips_min_fields";
  agenda?: Maybe<Scalars["String"]>;
  cost_per_person?: Maybe<Scalars["money"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  event_date?: Maybe<Scalars["date"]>;
  expectations_rules?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  interest_area?: Maybe<Scalars["String"]>;
  owner_id?: Maybe<Scalars["uuid"]>;
  research_questions_goals?: Maybe<Scalars["String"]>;
  school_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  total_cost?: Maybe<Scalars["money"]>;
  uid?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "field_trips" */
export type Field_Trips_Min_Order_By = {
  agenda?: Maybe<Order_By>;
  cost_per_person?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  event_date?: Maybe<Order_By>;
  expectations_rules?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  interest_area?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  research_questions_goals?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  total_cost?: Maybe<Order_By>;
  uid?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "field_trips" */
export type Field_Trips_Mutation_Response = {
  __typename?: "field_trips_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Field_Trips>;
};

/** input type for inserting object relation for remote table "field_trips" */
export type Field_Trips_Obj_Rel_Insert_Input = {
  data: Field_Trips_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Field_Trips_On_Conflict>;
};

/** on_conflict condition type for table "field_trips" */
export type Field_Trips_On_Conflict = {
  constraint: Field_Trips_Constraint;
  update_columns: Array<Field_Trips_Update_Column>;
  where?: Maybe<Field_Trips_Bool_Exp>;
};

/** Ordering options when selecting data from "field_trips". */
export type Field_Trips_Order_By = {
  agenda?: Maybe<Order_By>;
  cost_per_person?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  event_date?: Maybe<Order_By>;
  expectations_rules?: Maybe<Order_By>;
  field_trip_approvals_aggregate?: Maybe<
    Field_Trip_Approvals_Aggregate_Order_By
  >;
  field_trip_location_reviews_aggregate?: Maybe<
    Field_Trip_Location_Reviews_Aggregate_Order_By
  >;
  field_trip_locations_aggregate?: Maybe<
    Field_Trip_Locations_Aggregate_Order_By
  >;
  field_trip_students_aggregate?: Maybe<Field_Trip_Students_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  interest_area?: Maybe<Order_By>;
  my_trip_data?: Maybe<My_Field_Trip_Data_Order_By>;
  owner?: Maybe<Users_Order_By>;
  owner_id?: Maybe<Order_By>;
  research_questions_goals?: Maybe<Order_By>;
  school?: Maybe<Schools_Order_By>;
  school_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  supply_list?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  total_cost?: Maybe<Order_By>;
  transportation_options?: Maybe<Order_By>;
  uid?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: field_trips */
export type Field_Trips_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Field_Trips_Prepend_Input = {
  supply_list?: Maybe<Scalars["jsonb"]>;
  transportation_options?: Maybe<Scalars["jsonb"]>;
};

/** select columns of table "field_trips" */
export enum Field_Trips_Select_Column {
  /** column name */
  Agenda = "agenda",
  /** column name */
  CostPerPerson = "cost_per_person",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  EventDate = "event_date",
  /** column name */
  ExpectationsRules = "expectations_rules",
  /** column name */
  Id = "id",
  /** column name */
  InterestArea = "interest_area",
  /** column name */
  OwnerId = "owner_id",
  /** column name */
  ResearchQuestionsGoals = "research_questions_goals",
  /** column name */
  SchoolId = "school_id",
  /** column name */
  Status = "status",
  /** column name */
  SupplyList = "supply_list",
  /** column name */
  Title = "title",
  /** column name */
  TotalCost = "total_cost",
  /** column name */
  TransportationOptions = "transportation_options",
  /** column name */
  Uid = "uid",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "field_trips" */
export type Field_Trips_Set_Input = {
  agenda?: Maybe<Scalars["String"]>;
  cost_per_person?: Maybe<Scalars["money"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  event_date?: Maybe<Scalars["date"]>;
  expectations_rules?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  interest_area?: Maybe<Scalars["String"]>;
  owner_id?: Maybe<Scalars["uuid"]>;
  research_questions_goals?: Maybe<Scalars["String"]>;
  school_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  supply_list?: Maybe<Scalars["jsonb"]>;
  title?: Maybe<Scalars["String"]>;
  total_cost?: Maybe<Scalars["money"]>;
  transportation_options?: Maybe<Scalars["jsonb"]>;
  uid?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Field_Trips_Stddev_Fields = {
  __typename?: "field_trips_stddev_fields";
  cost_per_person?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  total_cost?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "field_trips" */
export type Field_Trips_Stddev_Order_By = {
  cost_per_person?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  total_cost?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Field_Trips_Stddev_Pop_Fields = {
  __typename?: "field_trips_stddev_pop_fields";
  cost_per_person?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  total_cost?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "field_trips" */
export type Field_Trips_Stddev_Pop_Order_By = {
  cost_per_person?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  total_cost?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Field_Trips_Stddev_Samp_Fields = {
  __typename?: "field_trips_stddev_samp_fields";
  cost_per_person?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  total_cost?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "field_trips" */
export type Field_Trips_Stddev_Samp_Order_By = {
  cost_per_person?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  total_cost?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Field_Trips_Sum_Fields = {
  __typename?: "field_trips_sum_fields";
  cost_per_person?: Maybe<Scalars["money"]>;
  id?: Maybe<Scalars["Int"]>;
  total_cost?: Maybe<Scalars["money"]>;
};

/** order by sum() on columns of table "field_trips" */
export type Field_Trips_Sum_Order_By = {
  cost_per_person?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  total_cost?: Maybe<Order_By>;
};

/** update columns of table "field_trips" */
export enum Field_Trips_Update_Column {
  /** column name */
  Agenda = "agenda",
  /** column name */
  CostPerPerson = "cost_per_person",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  EventDate = "event_date",
  /** column name */
  ExpectationsRules = "expectations_rules",
  /** column name */
  Id = "id",
  /** column name */
  InterestArea = "interest_area",
  /** column name */
  OwnerId = "owner_id",
  /** column name */
  ResearchQuestionsGoals = "research_questions_goals",
  /** column name */
  SchoolId = "school_id",
  /** column name */
  Status = "status",
  /** column name */
  SupplyList = "supply_list",
  /** column name */
  Title = "title",
  /** column name */
  TotalCost = "total_cost",
  /** column name */
  TransportationOptions = "transportation_options",
  /** column name */
  Uid = "uid",
  /** column name */
  UpdatedAt = "updated_at",
}

/** aggregate var_pop on columns */
export type Field_Trips_Var_Pop_Fields = {
  __typename?: "field_trips_var_pop_fields";
  cost_per_person?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  total_cost?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "field_trips" */
export type Field_Trips_Var_Pop_Order_By = {
  cost_per_person?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  total_cost?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Field_Trips_Var_Samp_Fields = {
  __typename?: "field_trips_var_samp_fields";
  cost_per_person?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  total_cost?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "field_trips" */
export type Field_Trips_Var_Samp_Order_By = {
  cost_per_person?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  total_cost?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Field_Trips_Variance_Fields = {
  __typename?: "field_trips_variance_fields";
  cost_per_person?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  total_cost?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "field_trips" */
export type Field_Trips_Variance_Order_By = {
  cost_per_person?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  total_cost?: Maybe<Order_By>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars["json"]>;
  _gt?: Maybe<Scalars["json"]>;
  _gte?: Maybe<Scalars["json"]>;
  _in?: Maybe<Array<Scalars["json"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["json"]>;
  _lte?: Maybe<Scalars["json"]>;
  _neq?: Maybe<Scalars["json"]>;
  _nin?: Maybe<Array<Scalars["json"]>>;
};

export type Jsonb_Cast_Exp = {
  String?: Maybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: Maybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars["jsonb"]>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars["jsonb"]>;
  _eq?: Maybe<Scalars["jsonb"]>;
  _gt?: Maybe<Scalars["jsonb"]>;
  _gte?: Maybe<Scalars["jsonb"]>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars["String"]>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars["String"]>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars["String"]>>;
  _in?: Maybe<Array<Scalars["jsonb"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["jsonb"]>;
  _lte?: Maybe<Scalars["jsonb"]>;
  _neq?: Maybe<Scalars["jsonb"]>;
  _nin?: Maybe<Array<Scalars["jsonb"]>>;
};

/** columns and relationships of "locations" */
export type Locations = {
  __typename?: "locations";
  address?: Maybe<Scalars["String"]>;
  google_maps_url?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  location_point?: Maybe<Scalars["point"]>;
  name: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  /** An array relationship */
  user_location_ratings: Array<User_Location_Ratings>;
  /** An aggregate relationship */
  user_location_ratings_aggregate: User_Location_Ratings_Aggregate;
};

/** columns and relationships of "locations" */
export type LocationsUser_Location_RatingsArgs = {
  distinct_on?: Maybe<Array<User_Location_Ratings_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Location_Ratings_Order_By>>;
  where?: Maybe<User_Location_Ratings_Bool_Exp>;
};

/** columns and relationships of "locations" */
export type LocationsUser_Location_Ratings_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Location_Ratings_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Location_Ratings_Order_By>>;
  where?: Maybe<User_Location_Ratings_Bool_Exp>;
};

/** aggregated selection of "locations" */
export type Locations_Aggregate = {
  __typename?: "locations_aggregate";
  aggregate?: Maybe<Locations_Aggregate_Fields>;
  nodes: Array<Locations>;
};

/** aggregate fields of "locations" */
export type Locations_Aggregate_Fields = {
  __typename?: "locations_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Locations_Max_Fields>;
  min?: Maybe<Locations_Min_Fields>;
};

/** aggregate fields of "locations" */
export type Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Locations_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "locations". All fields are combined with a logical 'AND'. */
export type Locations_Bool_Exp = {
  _and?: Maybe<Array<Locations_Bool_Exp>>;
  _not?: Maybe<Locations_Bool_Exp>;
  _or?: Maybe<Array<Locations_Bool_Exp>>;
  address?: Maybe<String_Comparison_Exp>;
  google_maps_url?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location_point?: Maybe<Point_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  user_location_ratings?: Maybe<User_Location_Ratings_Bool_Exp>;
};

/** unique or primary key constraints on table "locations" */
export enum Locations_Constraint {
  /** unique or primary key constraint */
  LocationPkey = "location_pkey",
  /** unique or primary key constraint */
  LocationsNameKey = "locations_name_key",
}

/** input type for inserting data into table "locations" */
export type Locations_Insert_Input = {
  address?: Maybe<Scalars["String"]>;
  google_maps_url?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  location_point?: Maybe<Scalars["point"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  user_location_ratings?: Maybe<User_Location_Ratings_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Locations_Max_Fields = {
  __typename?: "locations_max_fields";
  address?: Maybe<Scalars["String"]>;
  google_maps_url?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Locations_Min_Fields = {
  __typename?: "locations_min_fields";
  address?: Maybe<Scalars["String"]>;
  google_maps_url?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "locations" */
export type Locations_Mutation_Response = {
  __typename?: "locations_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Locations>;
};

/** input type for inserting object relation for remote table "locations" */
export type Locations_Obj_Rel_Insert_Input = {
  data: Locations_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Locations_On_Conflict>;
};

/** on_conflict condition type for table "locations" */
export type Locations_On_Conflict = {
  constraint: Locations_Constraint;
  update_columns: Array<Locations_Update_Column>;
  where?: Maybe<Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "locations". */
export type Locations_Order_By = {
  address?: Maybe<Order_By>;
  google_maps_url?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_point?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  user_location_ratings_aggregate?: Maybe<
    User_Location_Ratings_Aggregate_Order_By
  >;
};

/** primary key columns input for table: locations */
export type Locations_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "locations" */
export enum Locations_Select_Column {
  /** column name */
  Address = "address",
  /** column name */
  GoogleMapsUrl = "google_maps_url",
  /** column name */
  Id = "id",
  /** column name */
  LocationPoint = "location_point",
  /** column name */
  Name = "name",
  /** column name */
  Notes = "notes",
}

/** input type for updating data in table "locations" */
export type Locations_Set_Input = {
  address?: Maybe<Scalars["String"]>;
  google_maps_url?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  location_point?: Maybe<Scalars["point"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
};

/** update columns of table "locations" */
export enum Locations_Update_Column {
  /** column name */
  Address = "address",
  /** column name */
  GoogleMapsUrl = "google_maps_url",
  /** column name */
  Id = "id",
  /** column name */
  LocationPoint = "location_point",
  /** column name */
  Name = "name",
  /** column name */
  Notes = "notes",
}

/** columns and relationships of "me" */
export type Me = {
  __typename?: "me";
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_id?: Maybe<Scalars["uuid"]>;
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_name?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
  /** An object relationship */
  school?: Maybe<Schools>;
  school_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  username?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "me" */
export type Me_Aggregate = {
  __typename?: "me_aggregate";
  aggregate?: Maybe<Me_Aggregate_Fields>;
  nodes: Array<Me>;
};

/** aggregate fields of "me" */
export type Me_Aggregate_Fields = {
  __typename?: "me_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Me_Max_Fields>;
  min?: Maybe<Me_Min_Fields>;
};

/** aggregate fields of "me" */
export type Me_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Me_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "me". All fields are combined with a logical 'AND'. */
export type Me_Bool_Exp = {
  _and?: Maybe<Array<Me_Bool_Exp>>;
  _not?: Maybe<Me_Bool_Exp>;
  _or?: Maybe<Array<Me_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by_id?: Maybe<Uuid_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
  school?: Maybe<Schools_Bool_Exp>;
  school_id?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  username?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "me" */
export type Me_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_id?: Maybe<Scalars["uuid"]>;
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_name?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
  school?: Maybe<Schools_Obj_Rel_Insert_Input>;
  school_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  username?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Me_Max_Fields = {
  __typename?: "me_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_id?: Maybe<Scalars["uuid"]>;
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_name?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
  school_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  username?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Me_Min_Fields = {
  __typename?: "me_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_id?: Maybe<Scalars["uuid"]>;
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_name?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
  school_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  username?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "me" */
export type Me_Mutation_Response = {
  __typename?: "me_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Me>;
};

/** Ordering options when selecting data from "me". */
export type Me_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  school?: Maybe<Schools_Order_By>;
  school_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** select columns of table "me" */
export enum Me_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedById = "created_by_id",
  /** column name */
  Email = "email",
  /** column name */
  FirstName = "first_name",
  /** column name */
  Id = "id",
  /** column name */
  LastName = "last_name",
  /** column name */
  Role = "role",
  /** column name */
  SchoolId = "school_id",
  /** column name */
  Status = "status",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Username = "username",
}

/** input type for updating data in table "me" */
export type Me_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_id?: Maybe<Scalars["uuid"]>;
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_name?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
  school_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  username?: Maybe<Scalars["String"]>;
};

/** Boolean expression to compare columns of type "money". All fields are combined with logical 'AND'. */
export type Money_Comparison_Exp = {
  _eq?: Maybe<Scalars["money"]>;
  _gt?: Maybe<Scalars["money"]>;
  _gte?: Maybe<Scalars["money"]>;
  _in?: Maybe<Array<Scalars["money"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["money"]>;
  _lte?: Maybe<Scalars["money"]>;
  _neq?: Maybe<Scalars["money"]>;
  _nin?: Maybe<Array<Scalars["money"]>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: "mutation_root";
  createCongnitoUser?: Maybe<UserOutput>;
  createUsers?: Maybe<Array<Maybe<CreateUserOutput>>>;
  /** delete data from the table: "agreement_questions" */
  delete_agreement_questions?: Maybe<Agreement_Questions_Mutation_Response>;
  /** delete single row from the table: "agreement_questions" */
  delete_agreement_questions_by_pk?: Maybe<Agreement_Questions>;
  /** delete data from the table: "class_rooms" */
  delete_class_rooms?: Maybe<Class_Rooms_Mutation_Response>;
  /** delete single row from the table: "class_rooms" */
  delete_class_rooms_by_pk?: Maybe<Class_Rooms>;
  /** delete data from the table: "field_trip_approvals" */
  delete_field_trip_approvals?: Maybe<Field_Trip_Approvals_Mutation_Response>;
  /** delete single row from the table: "field_trip_approvals" */
  delete_field_trip_approvals_by_pk?: Maybe<Field_Trip_Approvals>;
  /** delete data from the table: "field_trip_location_reviews" */
  delete_field_trip_location_reviews?: Maybe<
    Field_Trip_Location_Reviews_Mutation_Response
  >;
  /** delete single row from the table: "field_trip_location_reviews" */
  delete_field_trip_location_reviews_by_pk?: Maybe<Field_Trip_Location_Reviews>;
  /** delete data from the table: "field_trip_locations" */
  delete_field_trip_locations?: Maybe<Field_Trip_Locations_Mutation_Response>;
  /** delete single row from the table: "field_trip_locations" */
  delete_field_trip_locations_by_pk?: Maybe<Field_Trip_Locations>;
  /** delete data from the table: "field_trip_students" */
  delete_field_trip_students?: Maybe<Field_Trip_Students_Mutation_Response>;
  /** delete single row from the table: "field_trip_students" */
  delete_field_trip_students_by_pk?: Maybe<Field_Trip_Students>;
  /** delete data from the table: "field_trips" */
  delete_field_trips?: Maybe<Field_Trips_Mutation_Response>;
  /** delete single row from the table: "field_trips" */
  delete_field_trips_by_pk?: Maybe<Field_Trips>;
  /** delete data from the table: "locations" */
  delete_locations?: Maybe<Locations_Mutation_Response>;
  /** delete single row from the table: "locations" */
  delete_locations_by_pk?: Maybe<Locations>;
  /** delete data from the table: "me" */
  delete_me?: Maybe<Me_Mutation_Response>;
  /** delete data from the table: "my_field_trip_data" */
  delete_my_field_trip_data?: Maybe<My_Field_Trip_Data_Mutation_Response>;
  /** delete data from the table: "product_features" */
  delete_product_features?: Maybe<Product_Features_Mutation_Response>;
  /** delete single row from the table: "product_features" */
  delete_product_features_by_pk?: Maybe<Product_Features>;
  /** delete data from the table: "reflect_questions" */
  delete_reflect_questions?: Maybe<Reflect_Questions_Mutation_Response>;
  /** delete single row from the table: "reflect_questions" */
  delete_reflect_questions_by_pk?: Maybe<Reflect_Questions>;
  /** delete data from the table: "school_product_features" */
  delete_school_product_features?: Maybe<
    School_Product_Features_Mutation_Response
  >;
  /** delete single row from the table: "school_product_features" */
  delete_school_product_features_by_pk?: Maybe<School_Product_Features>;
  /** delete data from the table: "school_types" */
  delete_school_types?: Maybe<School_Types_Mutation_Response>;
  /** delete single row from the table: "school_types" */
  delete_school_types_by_pk?: Maybe<School_Types>;
  /** delete data from the table: "schools" */
  delete_schools?: Maybe<Schools_Mutation_Response>;
  /** delete single row from the table: "schools" */
  delete_schools_by_pk?: Maybe<Schools>;
  /** delete data from the table: "secondary_school_types" */
  delete_secondary_school_types?: Maybe<
    Secondary_School_Types_Mutation_Response
  >;
  /** delete single row from the table: "secondary_school_types" */
  delete_secondary_school_types_by_pk?: Maybe<Secondary_School_Types>;
  /** delete data from the table: "user_location_ratings" */
  delete_user_location_ratings?: Maybe<User_Location_Ratings_Mutation_Response>;
  /** delete single row from the table: "user_location_ratings" */
  delete_user_location_ratings_by_pk?: Maybe<User_Location_Ratings>;
  /** delete data from the table: "user_names" */
  delete_user_names?: Maybe<User_Names_Mutation_Response>;
  /** delete data from the table: "user_relationship" */
  delete_user_relationship?: Maybe<User_Relationship_Mutation_Response>;
  /** delete single row from the table: "user_relationship" */
  delete_user_relationship_by_pk?: Maybe<User_Relationship>;
  /** delete data from the table: "user_relationship_types" */
  delete_user_relationship_types?: Maybe<
    User_Relationship_Types_Mutation_Response
  >;
  /** delete single row from the table: "user_relationship_types" */
  delete_user_relationship_types_by_pk?: Maybe<User_Relationship_Types>;
  /** delete data from the table: "user_roles" */
  delete_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** delete single row from the table: "user_roles" */
  delete_user_roles_by_pk?: Maybe<User_Roles>;
  /** delete data from the table: "user_status" */
  delete_user_status?: Maybe<User_Status_Mutation_Response>;
  /** delete single row from the table: "user_status" */
  delete_user_status_by_pk?: Maybe<User_Status>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "virtual_trip_images" */
  delete_virtual_trip_images?: Maybe<Virtual_Trip_Images_Mutation_Response>;
  /** delete single row from the table: "virtual_trip_images" */
  delete_virtual_trip_images_by_pk?: Maybe<Virtual_Trip_Images>;
  /** delete data from the table: "virtual_trip_plans" */
  delete_virtual_trip_plans?: Maybe<Virtual_Trip_Plans_Mutation_Response>;
  /** delete single row from the table: "virtual_trip_plans" */
  delete_virtual_trip_plans_by_pk?: Maybe<Virtual_Trip_Plans>;
  /** delete data from the table: "virtual_trip_proposals" */
  delete_virtual_trip_proposals?: Maybe<
    Virtual_Trip_Proposals_Mutation_Response
  >;
  /** delete single row from the table: "virtual_trip_proposals" */
  delete_virtual_trip_proposals_by_pk?: Maybe<Virtual_Trip_Proposals>;
  /** delete data from the table: "virtual_trip_schools" */
  delete_virtual_trip_schools?: Maybe<Virtual_Trip_Schools_Mutation_Response>;
  /** delete single row from the table: "virtual_trip_schools" */
  delete_virtual_trip_schools_by_pk?: Maybe<Virtual_Trip_Schools>;
  /** delete data from the table: "virtual_trips" */
  delete_virtual_trips?: Maybe<Virtual_Trips_Mutation_Response>;
  /** delete single row from the table: "virtual_trips" */
  delete_virtual_trips_by_pk?: Maybe<Virtual_Trips>;
  /** insert data into the table: "agreement_questions" */
  insert_agreement_questions?: Maybe<Agreement_Questions_Mutation_Response>;
  /** insert a single row into the table: "agreement_questions" */
  insert_agreement_questions_one?: Maybe<Agreement_Questions>;
  /** insert data into the table: "class_rooms" */
  insert_class_rooms?: Maybe<Class_Rooms_Mutation_Response>;
  /** insert a single row into the table: "class_rooms" */
  insert_class_rooms_one?: Maybe<Class_Rooms>;
  /** insert data into the table: "field_trip_approvals" */
  insert_field_trip_approvals?: Maybe<Field_Trip_Approvals_Mutation_Response>;
  /** insert a single row into the table: "field_trip_approvals" */
  insert_field_trip_approvals_one?: Maybe<Field_Trip_Approvals>;
  /** insert data into the table: "field_trip_location_reviews" */
  insert_field_trip_location_reviews?: Maybe<
    Field_Trip_Location_Reviews_Mutation_Response
  >;
  /** insert a single row into the table: "field_trip_location_reviews" */
  insert_field_trip_location_reviews_one?: Maybe<Field_Trip_Location_Reviews>;
  /** insert data into the table: "field_trip_locations" */
  insert_field_trip_locations?: Maybe<Field_Trip_Locations_Mutation_Response>;
  /** insert a single row into the table: "field_trip_locations" */
  insert_field_trip_locations_one?: Maybe<Field_Trip_Locations>;
  /** insert data into the table: "field_trip_students" */
  insert_field_trip_students?: Maybe<Field_Trip_Students_Mutation_Response>;
  /** insert a single row into the table: "field_trip_students" */
  insert_field_trip_students_one?: Maybe<Field_Trip_Students>;
  /** insert data into the table: "field_trips" */
  insert_field_trips?: Maybe<Field_Trips_Mutation_Response>;
  /** insert a single row into the table: "field_trips" */
  insert_field_trips_one?: Maybe<Field_Trips>;
  /** insert data into the table: "locations" */
  insert_locations?: Maybe<Locations_Mutation_Response>;
  /** insert a single row into the table: "locations" */
  insert_locations_one?: Maybe<Locations>;
  /** insert data into the table: "me" */
  insert_me?: Maybe<Me_Mutation_Response>;
  /** insert a single row into the table: "me" */
  insert_me_one?: Maybe<Me>;
  /** insert data into the table: "my_field_trip_data" */
  insert_my_field_trip_data?: Maybe<My_Field_Trip_Data_Mutation_Response>;
  /** insert a single row into the table: "my_field_trip_data" */
  insert_my_field_trip_data_one?: Maybe<My_Field_Trip_Data>;
  /** insert data into the table: "product_features" */
  insert_product_features?: Maybe<Product_Features_Mutation_Response>;
  /** insert a single row into the table: "product_features" */
  insert_product_features_one?: Maybe<Product_Features>;
  /** insert data into the table: "reflect_questions" */
  insert_reflect_questions?: Maybe<Reflect_Questions_Mutation_Response>;
  /** insert a single row into the table: "reflect_questions" */
  insert_reflect_questions_one?: Maybe<Reflect_Questions>;
  /** insert data into the table: "school_product_features" */
  insert_school_product_features?: Maybe<
    School_Product_Features_Mutation_Response
  >;
  /** insert a single row into the table: "school_product_features" */
  insert_school_product_features_one?: Maybe<School_Product_Features>;
  /** insert data into the table: "school_types" */
  insert_school_types?: Maybe<School_Types_Mutation_Response>;
  /** insert a single row into the table: "school_types" */
  insert_school_types_one?: Maybe<School_Types>;
  /** insert data into the table: "schools" */
  insert_schools?: Maybe<Schools_Mutation_Response>;
  /** insert a single row into the table: "schools" */
  insert_schools_one?: Maybe<Schools>;
  /** insert data into the table: "secondary_school_types" */
  insert_secondary_school_types?: Maybe<
    Secondary_School_Types_Mutation_Response
  >;
  /** insert a single row into the table: "secondary_school_types" */
  insert_secondary_school_types_one?: Maybe<Secondary_School_Types>;
  /** insert data into the table: "user_location_ratings" */
  insert_user_location_ratings?: Maybe<User_Location_Ratings_Mutation_Response>;
  /** insert a single row into the table: "user_location_ratings" */
  insert_user_location_ratings_one?: Maybe<User_Location_Ratings>;
  /** insert data into the table: "user_names" */
  insert_user_names?: Maybe<User_Names_Mutation_Response>;
  /** insert a single row into the table: "user_names" */
  insert_user_names_one?: Maybe<User_Names>;
  /** insert data into the table: "user_relationship" */
  insert_user_relationship?: Maybe<User_Relationship_Mutation_Response>;
  /** insert a single row into the table: "user_relationship" */
  insert_user_relationship_one?: Maybe<User_Relationship>;
  /** insert data into the table: "user_relationship_types" */
  insert_user_relationship_types?: Maybe<
    User_Relationship_Types_Mutation_Response
  >;
  /** insert a single row into the table: "user_relationship_types" */
  insert_user_relationship_types_one?: Maybe<User_Relationship_Types>;
  /** insert data into the table: "user_roles" */
  insert_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** insert a single row into the table: "user_roles" */
  insert_user_roles_one?: Maybe<User_Roles>;
  /** insert data into the table: "user_status" */
  insert_user_status?: Maybe<User_Status_Mutation_Response>;
  /** insert a single row into the table: "user_status" */
  insert_user_status_one?: Maybe<User_Status>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "virtual_trip_images" */
  insert_virtual_trip_images?: Maybe<Virtual_Trip_Images_Mutation_Response>;
  /** insert a single row into the table: "virtual_trip_images" */
  insert_virtual_trip_images_one?: Maybe<Virtual_Trip_Images>;
  /** insert data into the table: "virtual_trip_plans" */
  insert_virtual_trip_plans?: Maybe<Virtual_Trip_Plans_Mutation_Response>;
  /** insert a single row into the table: "virtual_trip_plans" */
  insert_virtual_trip_plans_one?: Maybe<Virtual_Trip_Plans>;
  /** insert data into the table: "virtual_trip_proposals" */
  insert_virtual_trip_proposals?: Maybe<
    Virtual_Trip_Proposals_Mutation_Response
  >;
  /** insert a single row into the table: "virtual_trip_proposals" */
  insert_virtual_trip_proposals_one?: Maybe<Virtual_Trip_Proposals>;
  /** insert data into the table: "virtual_trip_schools" */
  insert_virtual_trip_schools?: Maybe<Virtual_Trip_Schools_Mutation_Response>;
  /** insert a single row into the table: "virtual_trip_schools" */
  insert_virtual_trip_schools_one?: Maybe<Virtual_Trip_Schools>;
  /** insert data into the table: "virtual_trips" */
  insert_virtual_trips?: Maybe<Virtual_Trips_Mutation_Response>;
  /** insert a single row into the table: "virtual_trips" */
  insert_virtual_trips_one?: Maybe<Virtual_Trips>;
  /** update data of the table: "agreement_questions" */
  update_agreement_questions?: Maybe<Agreement_Questions_Mutation_Response>;
  /** update single row of the table: "agreement_questions" */
  update_agreement_questions_by_pk?: Maybe<Agreement_Questions>;
  /** update data of the table: "class_rooms" */
  update_class_rooms?: Maybe<Class_Rooms_Mutation_Response>;
  /** update single row of the table: "class_rooms" */
  update_class_rooms_by_pk?: Maybe<Class_Rooms>;
  /** update data of the table: "field_trip_approvals" */
  update_field_trip_approvals?: Maybe<Field_Trip_Approvals_Mutation_Response>;
  /** update single row of the table: "field_trip_approvals" */
  update_field_trip_approvals_by_pk?: Maybe<Field_Trip_Approvals>;
  /** update data of the table: "field_trip_location_reviews" */
  update_field_trip_location_reviews?: Maybe<
    Field_Trip_Location_Reviews_Mutation_Response
  >;
  /** update single row of the table: "field_trip_location_reviews" */
  update_field_trip_location_reviews_by_pk?: Maybe<Field_Trip_Location_Reviews>;
  /** update data of the table: "field_trip_locations" */
  update_field_trip_locations?: Maybe<Field_Trip_Locations_Mutation_Response>;
  /** update single row of the table: "field_trip_locations" */
  update_field_trip_locations_by_pk?: Maybe<Field_Trip_Locations>;
  /** update data of the table: "field_trip_students" */
  update_field_trip_students?: Maybe<Field_Trip_Students_Mutation_Response>;
  /** update single row of the table: "field_trip_students" */
  update_field_trip_students_by_pk?: Maybe<Field_Trip_Students>;
  /** update data of the table: "field_trips" */
  update_field_trips?: Maybe<Field_Trips_Mutation_Response>;
  /** update single row of the table: "field_trips" */
  update_field_trips_by_pk?: Maybe<Field_Trips>;
  /** update data of the table: "locations" */
  update_locations?: Maybe<Locations_Mutation_Response>;
  /** update single row of the table: "locations" */
  update_locations_by_pk?: Maybe<Locations>;
  /** update data of the table: "me" */
  update_me?: Maybe<Me_Mutation_Response>;
  /** update data of the table: "my_field_trip_data" */
  update_my_field_trip_data?: Maybe<My_Field_Trip_Data_Mutation_Response>;
  /** update data of the table: "product_features" */
  update_product_features?: Maybe<Product_Features_Mutation_Response>;
  /** update single row of the table: "product_features" */
  update_product_features_by_pk?: Maybe<Product_Features>;
  /** update data of the table: "reflect_questions" */
  update_reflect_questions?: Maybe<Reflect_Questions_Mutation_Response>;
  /** update single row of the table: "reflect_questions" */
  update_reflect_questions_by_pk?: Maybe<Reflect_Questions>;
  /** update data of the table: "school_product_features" */
  update_school_product_features?: Maybe<
    School_Product_Features_Mutation_Response
  >;
  /** update single row of the table: "school_product_features" */
  update_school_product_features_by_pk?: Maybe<School_Product_Features>;
  /** update data of the table: "school_types" */
  update_school_types?: Maybe<School_Types_Mutation_Response>;
  /** update single row of the table: "school_types" */
  update_school_types_by_pk?: Maybe<School_Types>;
  /** update data of the table: "schools" */
  update_schools?: Maybe<Schools_Mutation_Response>;
  /** update single row of the table: "schools" */
  update_schools_by_pk?: Maybe<Schools>;
  /** update data of the table: "secondary_school_types" */
  update_secondary_school_types?: Maybe<
    Secondary_School_Types_Mutation_Response
  >;
  /** update single row of the table: "secondary_school_types" */
  update_secondary_school_types_by_pk?: Maybe<Secondary_School_Types>;
  /** update data of the table: "user_location_ratings" */
  update_user_location_ratings?: Maybe<User_Location_Ratings_Mutation_Response>;
  /** update single row of the table: "user_location_ratings" */
  update_user_location_ratings_by_pk?: Maybe<User_Location_Ratings>;
  /** update data of the table: "user_names" */
  update_user_names?: Maybe<User_Names_Mutation_Response>;
  /** update data of the table: "user_relationship" */
  update_user_relationship?: Maybe<User_Relationship_Mutation_Response>;
  /** update single row of the table: "user_relationship" */
  update_user_relationship_by_pk?: Maybe<User_Relationship>;
  /** update data of the table: "user_relationship_types" */
  update_user_relationship_types?: Maybe<
    User_Relationship_Types_Mutation_Response
  >;
  /** update single row of the table: "user_relationship_types" */
  update_user_relationship_types_by_pk?: Maybe<User_Relationship_Types>;
  /** update data of the table: "user_roles" */
  update_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** update single row of the table: "user_roles" */
  update_user_roles_by_pk?: Maybe<User_Roles>;
  /** update data of the table: "user_status" */
  update_user_status?: Maybe<User_Status_Mutation_Response>;
  /** update single row of the table: "user_status" */
  update_user_status_by_pk?: Maybe<User_Status>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "virtual_trip_images" */
  update_virtual_trip_images?: Maybe<Virtual_Trip_Images_Mutation_Response>;
  /** update single row of the table: "virtual_trip_images" */
  update_virtual_trip_images_by_pk?: Maybe<Virtual_Trip_Images>;
  /** update data of the table: "virtual_trip_plans" */
  update_virtual_trip_plans?: Maybe<Virtual_Trip_Plans_Mutation_Response>;
  /** update single row of the table: "virtual_trip_plans" */
  update_virtual_trip_plans_by_pk?: Maybe<Virtual_Trip_Plans>;
  /** update data of the table: "virtual_trip_proposals" */
  update_virtual_trip_proposals?: Maybe<
    Virtual_Trip_Proposals_Mutation_Response
  >;
  /** update single row of the table: "virtual_trip_proposals" */
  update_virtual_trip_proposals_by_pk?: Maybe<Virtual_Trip_Proposals>;
  /** update data of the table: "virtual_trip_schools" */
  update_virtual_trip_schools?: Maybe<Virtual_Trip_Schools_Mutation_Response>;
  /** update single row of the table: "virtual_trip_schools" */
  update_virtual_trip_schools_by_pk?: Maybe<Virtual_Trip_Schools>;
  /** update data of the table: "virtual_trips" */
  update_virtual_trips?: Maybe<Virtual_Trips_Mutation_Response>;
  /** update single row of the table: "virtual_trips" */
  update_virtual_trips_by_pk?: Maybe<Virtual_Trips>;
};

/** mutation root */
export type Mutation_RootCreateCongnitoUserArgs = {
  userInput: UserInput;
};

/** mutation root */
export type Mutation_RootCreateUsersArgs = {
  input: Array<CreateUserInput>;
};

/** mutation root */
export type Mutation_RootDelete_Agreement_QuestionsArgs = {
  where: Agreement_Questions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Agreement_Questions_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Class_RoomsArgs = {
  where: Class_Rooms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Class_Rooms_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Field_Trip_ApprovalsArgs = {
  where: Field_Trip_Approvals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Field_Trip_Approvals_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Field_Trip_Location_ReviewsArgs = {
  where: Field_Trip_Location_Reviews_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Field_Trip_Location_Reviews_By_PkArgs = {
  id: Scalars["bigint"];
};

/** mutation root */
export type Mutation_RootDelete_Field_Trip_LocationsArgs = {
  where: Field_Trip_Locations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Field_Trip_Locations_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Field_Trip_StudentsArgs = {
  where: Field_Trip_Students_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Field_Trip_Students_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Field_TripsArgs = {
  where: Field_Trips_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Field_Trips_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_LocationsArgs = {
  where: Locations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Locations_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_MeArgs = {
  where: Me_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_My_Field_Trip_DataArgs = {
  where: My_Field_Trip_Data_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Product_FeaturesArgs = {
  where: Product_Features_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Product_Features_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Reflect_QuestionsArgs = {
  where: Reflect_Questions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Reflect_Questions_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_School_Product_FeaturesArgs = {
  where: School_Product_Features_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_School_Product_Features_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_School_TypesArgs = {
  where: School_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_School_Types_By_PkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_SchoolsArgs = {
  where: Schools_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Schools_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Secondary_School_TypesArgs = {
  where: Secondary_School_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Secondary_School_Types_By_PkArgs = {
  id: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_User_Location_RatingsArgs = {
  where: User_Location_Ratings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Location_Ratings_By_PkArgs = {
  location_id: Scalars["uuid"];
  user_id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_User_NamesArgs = {
  where: User_Names_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_RelationshipArgs = {
  where: User_Relationship_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Relationship_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_User_Relationship_TypesArgs = {
  where: User_Relationship_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Relationship_Types_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_User_RolesArgs = {
  where: User_Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Roles_By_PkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_User_StatusArgs = {
  where: User_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Status_By_PkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Virtual_Trip_ImagesArgs = {
  where: Virtual_Trip_Images_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Virtual_Trip_Images_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Virtual_Trip_PlansArgs = {
  where: Virtual_Trip_Plans_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Virtual_Trip_Plans_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Virtual_Trip_ProposalsArgs = {
  where: Virtual_Trip_Proposals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Virtual_Trip_Proposals_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Virtual_Trip_SchoolsArgs = {
  where: Virtual_Trip_Schools_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Virtual_Trip_Schools_By_PkArgs = {
  school_id: Scalars["uuid"];
  virtual_trip_id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Virtual_TripsArgs = {
  where: Virtual_Trips_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Virtual_Trips_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootInsert_Agreement_QuestionsArgs = {
  objects: Array<Agreement_Questions_Insert_Input>;
  on_conflict?: Maybe<Agreement_Questions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Agreement_Questions_OneArgs = {
  object: Agreement_Questions_Insert_Input;
  on_conflict?: Maybe<Agreement_Questions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Class_RoomsArgs = {
  objects: Array<Class_Rooms_Insert_Input>;
  on_conflict?: Maybe<Class_Rooms_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Class_Rooms_OneArgs = {
  object: Class_Rooms_Insert_Input;
  on_conflict?: Maybe<Class_Rooms_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_Trip_ApprovalsArgs = {
  objects: Array<Field_Trip_Approvals_Insert_Input>;
  on_conflict?: Maybe<Field_Trip_Approvals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_Trip_Approvals_OneArgs = {
  object: Field_Trip_Approvals_Insert_Input;
  on_conflict?: Maybe<Field_Trip_Approvals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_Trip_Location_ReviewsArgs = {
  objects: Array<Field_Trip_Location_Reviews_Insert_Input>;
  on_conflict?: Maybe<Field_Trip_Location_Reviews_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_Trip_Location_Reviews_OneArgs = {
  object: Field_Trip_Location_Reviews_Insert_Input;
  on_conflict?: Maybe<Field_Trip_Location_Reviews_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_Trip_LocationsArgs = {
  objects: Array<Field_Trip_Locations_Insert_Input>;
  on_conflict?: Maybe<Field_Trip_Locations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_Trip_Locations_OneArgs = {
  object: Field_Trip_Locations_Insert_Input;
  on_conflict?: Maybe<Field_Trip_Locations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_Trip_StudentsArgs = {
  objects: Array<Field_Trip_Students_Insert_Input>;
  on_conflict?: Maybe<Field_Trip_Students_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_Trip_Students_OneArgs = {
  object: Field_Trip_Students_Insert_Input;
  on_conflict?: Maybe<Field_Trip_Students_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_TripsArgs = {
  objects: Array<Field_Trips_Insert_Input>;
  on_conflict?: Maybe<Field_Trips_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_Trips_OneArgs = {
  object: Field_Trips_Insert_Input;
  on_conflict?: Maybe<Field_Trips_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LocationsArgs = {
  objects: Array<Locations_Insert_Input>;
  on_conflict?: Maybe<Locations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Locations_OneArgs = {
  object: Locations_Insert_Input;
  on_conflict?: Maybe<Locations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_MeArgs = {
  objects: Array<Me_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Me_OneArgs = {
  object: Me_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_My_Field_Trip_DataArgs = {
  objects: Array<My_Field_Trip_Data_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_My_Field_Trip_Data_OneArgs = {
  object: My_Field_Trip_Data_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Product_FeaturesArgs = {
  objects: Array<Product_Features_Insert_Input>;
  on_conflict?: Maybe<Product_Features_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Features_OneArgs = {
  object: Product_Features_Insert_Input;
  on_conflict?: Maybe<Product_Features_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Reflect_QuestionsArgs = {
  objects: Array<Reflect_Questions_Insert_Input>;
  on_conflict?: Maybe<Reflect_Questions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Reflect_Questions_OneArgs = {
  object: Reflect_Questions_Insert_Input;
  on_conflict?: Maybe<Reflect_Questions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_School_Product_FeaturesArgs = {
  objects: Array<School_Product_Features_Insert_Input>;
  on_conflict?: Maybe<School_Product_Features_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_School_Product_Features_OneArgs = {
  object: School_Product_Features_Insert_Input;
  on_conflict?: Maybe<School_Product_Features_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_School_TypesArgs = {
  objects: Array<School_Types_Insert_Input>;
  on_conflict?: Maybe<School_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_School_Types_OneArgs = {
  object: School_Types_Insert_Input;
  on_conflict?: Maybe<School_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SchoolsArgs = {
  objects: Array<Schools_Insert_Input>;
  on_conflict?: Maybe<Schools_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Schools_OneArgs = {
  object: Schools_Insert_Input;
  on_conflict?: Maybe<Schools_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Secondary_School_TypesArgs = {
  objects: Array<Secondary_School_Types_Insert_Input>;
  on_conflict?: Maybe<Secondary_School_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Secondary_School_Types_OneArgs = {
  object: Secondary_School_Types_Insert_Input;
  on_conflict?: Maybe<Secondary_School_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Location_RatingsArgs = {
  objects: Array<User_Location_Ratings_Insert_Input>;
  on_conflict?: Maybe<User_Location_Ratings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Location_Ratings_OneArgs = {
  object: User_Location_Ratings_Insert_Input;
  on_conflict?: Maybe<User_Location_Ratings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_NamesArgs = {
  objects: Array<User_Names_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_User_Names_OneArgs = {
  object: User_Names_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_User_RelationshipArgs = {
  objects: Array<User_Relationship_Insert_Input>;
  on_conflict?: Maybe<User_Relationship_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Relationship_OneArgs = {
  object: User_Relationship_Insert_Input;
  on_conflict?: Maybe<User_Relationship_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Relationship_TypesArgs = {
  objects: Array<User_Relationship_Types_Insert_Input>;
  on_conflict?: Maybe<User_Relationship_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Relationship_Types_OneArgs = {
  object: User_Relationship_Types_Insert_Input;
  on_conflict?: Maybe<User_Relationship_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_RolesArgs = {
  objects: Array<User_Roles_Insert_Input>;
  on_conflict?: Maybe<User_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Roles_OneArgs = {
  object: User_Roles_Insert_Input;
  on_conflict?: Maybe<User_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_StatusArgs = {
  objects: Array<User_Status_Insert_Input>;
  on_conflict?: Maybe<User_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Status_OneArgs = {
  object: User_Status_Insert_Input;
  on_conflict?: Maybe<User_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Virtual_Trip_ImagesArgs = {
  objects: Array<Virtual_Trip_Images_Insert_Input>;
  on_conflict?: Maybe<Virtual_Trip_Images_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Virtual_Trip_Images_OneArgs = {
  object: Virtual_Trip_Images_Insert_Input;
  on_conflict?: Maybe<Virtual_Trip_Images_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Virtual_Trip_PlansArgs = {
  objects: Array<Virtual_Trip_Plans_Insert_Input>;
  on_conflict?: Maybe<Virtual_Trip_Plans_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Virtual_Trip_Plans_OneArgs = {
  object: Virtual_Trip_Plans_Insert_Input;
  on_conflict?: Maybe<Virtual_Trip_Plans_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Virtual_Trip_ProposalsArgs = {
  objects: Array<Virtual_Trip_Proposals_Insert_Input>;
  on_conflict?: Maybe<Virtual_Trip_Proposals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Virtual_Trip_Proposals_OneArgs = {
  object: Virtual_Trip_Proposals_Insert_Input;
  on_conflict?: Maybe<Virtual_Trip_Proposals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Virtual_Trip_SchoolsArgs = {
  objects: Array<Virtual_Trip_Schools_Insert_Input>;
  on_conflict?: Maybe<Virtual_Trip_Schools_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Virtual_Trip_Schools_OneArgs = {
  object: Virtual_Trip_Schools_Insert_Input;
  on_conflict?: Maybe<Virtual_Trip_Schools_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Virtual_TripsArgs = {
  objects: Array<Virtual_Trips_Insert_Input>;
  on_conflict?: Maybe<Virtual_Trips_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Virtual_Trips_OneArgs = {
  object: Virtual_Trips_Insert_Input;
  on_conflict?: Maybe<Virtual_Trips_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_Agreement_QuestionsArgs = {
  _set?: Maybe<Agreement_Questions_Set_Input>;
  where: Agreement_Questions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Agreement_Questions_By_PkArgs = {
  _set?: Maybe<Agreement_Questions_Set_Input>;
  pk_columns: Agreement_Questions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Class_RoomsArgs = {
  _set?: Maybe<Class_Rooms_Set_Input>;
  where: Class_Rooms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Class_Rooms_By_PkArgs = {
  _set?: Maybe<Class_Rooms_Set_Input>;
  pk_columns: Class_Rooms_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Field_Trip_ApprovalsArgs = {
  _inc?: Maybe<Field_Trip_Approvals_Inc_Input>;
  _set?: Maybe<Field_Trip_Approvals_Set_Input>;
  where: Field_Trip_Approvals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Field_Trip_Approvals_By_PkArgs = {
  _inc?: Maybe<Field_Trip_Approvals_Inc_Input>;
  _set?: Maybe<Field_Trip_Approvals_Set_Input>;
  pk_columns: Field_Trip_Approvals_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Field_Trip_Location_ReviewsArgs = {
  _inc?: Maybe<Field_Trip_Location_Reviews_Inc_Input>;
  _set?: Maybe<Field_Trip_Location_Reviews_Set_Input>;
  where: Field_Trip_Location_Reviews_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Field_Trip_Location_Reviews_By_PkArgs = {
  _inc?: Maybe<Field_Trip_Location_Reviews_Inc_Input>;
  _set?: Maybe<Field_Trip_Location_Reviews_Set_Input>;
  pk_columns: Field_Trip_Location_Reviews_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Field_Trip_LocationsArgs = {
  _inc?: Maybe<Field_Trip_Locations_Inc_Input>;
  _set?: Maybe<Field_Trip_Locations_Set_Input>;
  where: Field_Trip_Locations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Field_Trip_Locations_By_PkArgs = {
  _inc?: Maybe<Field_Trip_Locations_Inc_Input>;
  _set?: Maybe<Field_Trip_Locations_Set_Input>;
  pk_columns: Field_Trip_Locations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Field_Trip_StudentsArgs = {
  _append?: Maybe<Field_Trip_Students_Append_Input>;
  _delete_at_path?: Maybe<Field_Trip_Students_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Field_Trip_Students_Delete_Elem_Input>;
  _delete_key?: Maybe<Field_Trip_Students_Delete_Key_Input>;
  _inc?: Maybe<Field_Trip_Students_Inc_Input>;
  _prepend?: Maybe<Field_Trip_Students_Prepend_Input>;
  _set?: Maybe<Field_Trip_Students_Set_Input>;
  where: Field_Trip_Students_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Field_Trip_Students_By_PkArgs = {
  _append?: Maybe<Field_Trip_Students_Append_Input>;
  _delete_at_path?: Maybe<Field_Trip_Students_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Field_Trip_Students_Delete_Elem_Input>;
  _delete_key?: Maybe<Field_Trip_Students_Delete_Key_Input>;
  _inc?: Maybe<Field_Trip_Students_Inc_Input>;
  _prepend?: Maybe<Field_Trip_Students_Prepend_Input>;
  _set?: Maybe<Field_Trip_Students_Set_Input>;
  pk_columns: Field_Trip_Students_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Field_TripsArgs = {
  _append?: Maybe<Field_Trips_Append_Input>;
  _delete_at_path?: Maybe<Field_Trips_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Field_Trips_Delete_Elem_Input>;
  _delete_key?: Maybe<Field_Trips_Delete_Key_Input>;
  _inc?: Maybe<Field_Trips_Inc_Input>;
  _prepend?: Maybe<Field_Trips_Prepend_Input>;
  _set?: Maybe<Field_Trips_Set_Input>;
  where: Field_Trips_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Field_Trips_By_PkArgs = {
  _append?: Maybe<Field_Trips_Append_Input>;
  _delete_at_path?: Maybe<Field_Trips_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Field_Trips_Delete_Elem_Input>;
  _delete_key?: Maybe<Field_Trips_Delete_Key_Input>;
  _inc?: Maybe<Field_Trips_Inc_Input>;
  _prepend?: Maybe<Field_Trips_Prepend_Input>;
  _set?: Maybe<Field_Trips_Set_Input>;
  pk_columns: Field_Trips_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_LocationsArgs = {
  _set?: Maybe<Locations_Set_Input>;
  where: Locations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Locations_By_PkArgs = {
  _set?: Maybe<Locations_Set_Input>;
  pk_columns: Locations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_MeArgs = {
  _set?: Maybe<Me_Set_Input>;
  where: Me_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_My_Field_Trip_DataArgs = {
  _append?: Maybe<My_Field_Trip_Data_Append_Input>;
  _delete_at_path?: Maybe<My_Field_Trip_Data_Delete_At_Path_Input>;
  _delete_elem?: Maybe<My_Field_Trip_Data_Delete_Elem_Input>;
  _delete_key?: Maybe<My_Field_Trip_Data_Delete_Key_Input>;
  _inc?: Maybe<My_Field_Trip_Data_Inc_Input>;
  _prepend?: Maybe<My_Field_Trip_Data_Prepend_Input>;
  _set?: Maybe<My_Field_Trip_Data_Set_Input>;
  where: My_Field_Trip_Data_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Product_FeaturesArgs = {
  _set?: Maybe<Product_Features_Set_Input>;
  where: Product_Features_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Features_By_PkArgs = {
  _set?: Maybe<Product_Features_Set_Input>;
  pk_columns: Product_Features_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Reflect_QuestionsArgs = {
  _set?: Maybe<Reflect_Questions_Set_Input>;
  where: Reflect_Questions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Reflect_Questions_By_PkArgs = {
  _set?: Maybe<Reflect_Questions_Set_Input>;
  pk_columns: Reflect_Questions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_School_Product_FeaturesArgs = {
  _inc?: Maybe<School_Product_Features_Inc_Input>;
  _set?: Maybe<School_Product_Features_Set_Input>;
  where: School_Product_Features_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_School_Product_Features_By_PkArgs = {
  _inc?: Maybe<School_Product_Features_Inc_Input>;
  _set?: Maybe<School_Product_Features_Set_Input>;
  pk_columns: School_Product_Features_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_School_TypesArgs = {
  _set?: Maybe<School_Types_Set_Input>;
  where: School_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_School_Types_By_PkArgs = {
  _set?: Maybe<School_Types_Set_Input>;
  pk_columns: School_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_SchoolsArgs = {
  _set?: Maybe<Schools_Set_Input>;
  where: Schools_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Schools_By_PkArgs = {
  _set?: Maybe<Schools_Set_Input>;
  pk_columns: Schools_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Secondary_School_TypesArgs = {
  _set?: Maybe<Secondary_School_Types_Set_Input>;
  where: Secondary_School_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Secondary_School_Types_By_PkArgs = {
  _set?: Maybe<Secondary_School_Types_Set_Input>;
  pk_columns: Secondary_School_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Location_RatingsArgs = {
  _inc?: Maybe<User_Location_Ratings_Inc_Input>;
  _set?: Maybe<User_Location_Ratings_Set_Input>;
  where: User_Location_Ratings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Location_Ratings_By_PkArgs = {
  _inc?: Maybe<User_Location_Ratings_Inc_Input>;
  _set?: Maybe<User_Location_Ratings_Set_Input>;
  pk_columns: User_Location_Ratings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_NamesArgs = {
  _set?: Maybe<User_Names_Set_Input>;
  where: User_Names_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_RelationshipArgs = {
  _inc?: Maybe<User_Relationship_Inc_Input>;
  _set?: Maybe<User_Relationship_Set_Input>;
  where: User_Relationship_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Relationship_By_PkArgs = {
  _inc?: Maybe<User_Relationship_Inc_Input>;
  _set?: Maybe<User_Relationship_Set_Input>;
  pk_columns: User_Relationship_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Relationship_TypesArgs = {
  _inc?: Maybe<User_Relationship_Types_Inc_Input>;
  _set?: Maybe<User_Relationship_Types_Set_Input>;
  where: User_Relationship_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Relationship_Types_By_PkArgs = {
  _inc?: Maybe<User_Relationship_Types_Inc_Input>;
  _set?: Maybe<User_Relationship_Types_Set_Input>;
  pk_columns: User_Relationship_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_RolesArgs = {
  _set?: Maybe<User_Roles_Set_Input>;
  where: User_Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Roles_By_PkArgs = {
  _set?: Maybe<User_Roles_Set_Input>;
  pk_columns: User_Roles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_StatusArgs = {
  _set?: Maybe<User_Status_Set_Input>;
  where: User_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Status_By_PkArgs = {
  _set?: Maybe<User_Status_Set_Input>;
  pk_columns: User_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Virtual_Trip_ImagesArgs = {
  _inc?: Maybe<Virtual_Trip_Images_Inc_Input>;
  _set?: Maybe<Virtual_Trip_Images_Set_Input>;
  where: Virtual_Trip_Images_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Virtual_Trip_Images_By_PkArgs = {
  _inc?: Maybe<Virtual_Trip_Images_Inc_Input>;
  _set?: Maybe<Virtual_Trip_Images_Set_Input>;
  pk_columns: Virtual_Trip_Images_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Virtual_Trip_PlansArgs = {
  _inc?: Maybe<Virtual_Trip_Plans_Inc_Input>;
  _set?: Maybe<Virtual_Trip_Plans_Set_Input>;
  where: Virtual_Trip_Plans_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Virtual_Trip_Plans_By_PkArgs = {
  _inc?: Maybe<Virtual_Trip_Plans_Inc_Input>;
  _set?: Maybe<Virtual_Trip_Plans_Set_Input>;
  pk_columns: Virtual_Trip_Plans_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Virtual_Trip_ProposalsArgs = {
  _inc?: Maybe<Virtual_Trip_Proposals_Inc_Input>;
  _set?: Maybe<Virtual_Trip_Proposals_Set_Input>;
  where: Virtual_Trip_Proposals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Virtual_Trip_Proposals_By_PkArgs = {
  _inc?: Maybe<Virtual_Trip_Proposals_Inc_Input>;
  _set?: Maybe<Virtual_Trip_Proposals_Set_Input>;
  pk_columns: Virtual_Trip_Proposals_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Virtual_Trip_SchoolsArgs = {
  _inc?: Maybe<Virtual_Trip_Schools_Inc_Input>;
  _set?: Maybe<Virtual_Trip_Schools_Set_Input>;
  where: Virtual_Trip_Schools_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Virtual_Trip_Schools_By_PkArgs = {
  _inc?: Maybe<Virtual_Trip_Schools_Inc_Input>;
  _set?: Maybe<Virtual_Trip_Schools_Set_Input>;
  pk_columns: Virtual_Trip_Schools_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Virtual_TripsArgs = {
  _inc?: Maybe<Virtual_Trips_Inc_Input>;
  _set?: Maybe<Virtual_Trips_Set_Input>;
  where: Virtual_Trips_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Virtual_Trips_By_PkArgs = {
  _inc?: Maybe<Virtual_Trips_Inc_Input>;
  _set?: Maybe<Virtual_Trips_Set_Input>;
  pk_columns: Virtual_Trips_Pk_Columns_Input;
};

/** columns and relationships of "my_field_trip_data" */
export type My_Field_Trip_Data = {
  __typename?: "my_field_trip_data";
  created_at?: Maybe<Scalars["timestamptz"]>;
  enjoyed_most?: Maybe<Scalars["String"]>;
  field_trip_id?: Maybe<Scalars["Int"]>;
  grace_courtesy_approval?: Maybe<Scalars["Boolean"]>;
  gratitude_mail_agreement?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["Int"]>;
  improvements?: Maybe<Scalars["String"]>;
  reflect_survey?: Maybe<Scalars["jsonb"]>;
  share_experience?: Maybe<Scalars["jsonb"]>;
  share_experience_agreement?: Maybe<Scalars["Boolean"]>;
  stage?: Maybe<Scalars["String"]>;
  transportation_logistics_approval?: Maybe<Scalars["Boolean"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** columns and relationships of "my_field_trip_data" */
export type My_Field_Trip_DataReflect_SurveyArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "my_field_trip_data" */
export type My_Field_Trip_DataShare_ExperienceArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "my_field_trip_data" */
export type My_Field_Trip_Data_Aggregate = {
  __typename?: "my_field_trip_data_aggregate";
  aggregate?: Maybe<My_Field_Trip_Data_Aggregate_Fields>;
  nodes: Array<My_Field_Trip_Data>;
};

/** aggregate fields of "my_field_trip_data" */
export type My_Field_Trip_Data_Aggregate_Fields = {
  __typename?: "my_field_trip_data_aggregate_fields";
  avg?: Maybe<My_Field_Trip_Data_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<My_Field_Trip_Data_Max_Fields>;
  min?: Maybe<My_Field_Trip_Data_Min_Fields>;
  stddev?: Maybe<My_Field_Trip_Data_Stddev_Fields>;
  stddev_pop?: Maybe<My_Field_Trip_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<My_Field_Trip_Data_Stddev_Samp_Fields>;
  sum?: Maybe<My_Field_Trip_Data_Sum_Fields>;
  var_pop?: Maybe<My_Field_Trip_Data_Var_Pop_Fields>;
  var_samp?: Maybe<My_Field_Trip_Data_Var_Samp_Fields>;
  variance?: Maybe<My_Field_Trip_Data_Variance_Fields>;
};

/** aggregate fields of "my_field_trip_data" */
export type My_Field_Trip_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<My_Field_Trip_Data_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type My_Field_Trip_Data_Append_Input = {
  reflect_survey?: Maybe<Scalars["jsonb"]>;
  share_experience?: Maybe<Scalars["jsonb"]>;
};

/** aggregate avg on columns */
export type My_Field_Trip_Data_Avg_Fields = {
  __typename?: "my_field_trip_data_avg_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "my_field_trip_data". All fields are combined with a logical 'AND'. */
export type My_Field_Trip_Data_Bool_Exp = {
  _and?: Maybe<Array<My_Field_Trip_Data_Bool_Exp>>;
  _not?: Maybe<My_Field_Trip_Data_Bool_Exp>;
  _or?: Maybe<Array<My_Field_Trip_Data_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  enjoyed_most?: Maybe<String_Comparison_Exp>;
  field_trip_id?: Maybe<Int_Comparison_Exp>;
  grace_courtesy_approval?: Maybe<Boolean_Comparison_Exp>;
  gratitude_mail_agreement?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  improvements?: Maybe<String_Comparison_Exp>;
  reflect_survey?: Maybe<Jsonb_Comparison_Exp>;
  share_experience?: Maybe<Jsonb_Comparison_Exp>;
  share_experience_agreement?: Maybe<Boolean_Comparison_Exp>;
  stage?: Maybe<String_Comparison_Exp>;
  transportation_logistics_approval?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type My_Field_Trip_Data_Delete_At_Path_Input = {
  reflect_survey?: Maybe<Array<Scalars["String"]>>;
  share_experience?: Maybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type My_Field_Trip_Data_Delete_Elem_Input = {
  reflect_survey?: Maybe<Scalars["Int"]>;
  share_experience?: Maybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type My_Field_Trip_Data_Delete_Key_Input = {
  reflect_survey?: Maybe<Scalars["String"]>;
  share_experience?: Maybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "my_field_trip_data" */
export type My_Field_Trip_Data_Inc_Input = {
  field_trip_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "my_field_trip_data" */
export type My_Field_Trip_Data_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  enjoyed_most?: Maybe<Scalars["String"]>;
  field_trip_id?: Maybe<Scalars["Int"]>;
  grace_courtesy_approval?: Maybe<Scalars["Boolean"]>;
  gratitude_mail_agreement?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["Int"]>;
  improvements?: Maybe<Scalars["String"]>;
  reflect_survey?: Maybe<Scalars["jsonb"]>;
  share_experience?: Maybe<Scalars["jsonb"]>;
  share_experience_agreement?: Maybe<Scalars["Boolean"]>;
  stage?: Maybe<Scalars["String"]>;
  transportation_logistics_approval?: Maybe<Scalars["Boolean"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type My_Field_Trip_Data_Max_Fields = {
  __typename?: "my_field_trip_data_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  enjoyed_most?: Maybe<Scalars["String"]>;
  field_trip_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  improvements?: Maybe<Scalars["String"]>;
  stage?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type My_Field_Trip_Data_Min_Fields = {
  __typename?: "my_field_trip_data_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  enjoyed_most?: Maybe<Scalars["String"]>;
  field_trip_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  improvements?: Maybe<Scalars["String"]>;
  stage?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "my_field_trip_data" */
export type My_Field_Trip_Data_Mutation_Response = {
  __typename?: "my_field_trip_data_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<My_Field_Trip_Data>;
};

/** input type for inserting object relation for remote table "my_field_trip_data" */
export type My_Field_Trip_Data_Obj_Rel_Insert_Input = {
  data: My_Field_Trip_Data_Insert_Input;
};

/** Ordering options when selecting data from "my_field_trip_data". */
export type My_Field_Trip_Data_Order_By = {
  created_at?: Maybe<Order_By>;
  enjoyed_most?: Maybe<Order_By>;
  field_trip_id?: Maybe<Order_By>;
  grace_courtesy_approval?: Maybe<Order_By>;
  gratitude_mail_agreement?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  improvements?: Maybe<Order_By>;
  reflect_survey?: Maybe<Order_By>;
  share_experience?: Maybe<Order_By>;
  share_experience_agreement?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  transportation_logistics_approval?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type My_Field_Trip_Data_Prepend_Input = {
  reflect_survey?: Maybe<Scalars["jsonb"]>;
  share_experience?: Maybe<Scalars["jsonb"]>;
};

/** select columns of table "my_field_trip_data" */
export enum My_Field_Trip_Data_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  EnjoyedMost = "enjoyed_most",
  /** column name */
  FieldTripId = "field_trip_id",
  /** column name */
  GraceCourtesyApproval = "grace_courtesy_approval",
  /** column name */
  GratitudeMailAgreement = "gratitude_mail_agreement",
  /** column name */
  Id = "id",
  /** column name */
  Improvements = "improvements",
  /** column name */
  ReflectSurvey = "reflect_survey",
  /** column name */
  ShareExperience = "share_experience",
  /** column name */
  ShareExperienceAgreement = "share_experience_agreement",
  /** column name */
  Stage = "stage",
  /** column name */
  TransportationLogisticsApproval = "transportation_logistics_approval",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "my_field_trip_data" */
export type My_Field_Trip_Data_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  enjoyed_most?: Maybe<Scalars["String"]>;
  field_trip_id?: Maybe<Scalars["Int"]>;
  grace_courtesy_approval?: Maybe<Scalars["Boolean"]>;
  gratitude_mail_agreement?: Maybe<Scalars["Boolean"]>;
  id?: Maybe<Scalars["Int"]>;
  improvements?: Maybe<Scalars["String"]>;
  reflect_survey?: Maybe<Scalars["jsonb"]>;
  share_experience?: Maybe<Scalars["jsonb"]>;
  share_experience_agreement?: Maybe<Scalars["Boolean"]>;
  stage?: Maybe<Scalars["String"]>;
  transportation_logistics_approval?: Maybe<Scalars["Boolean"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type My_Field_Trip_Data_Stddev_Fields = {
  __typename?: "my_field_trip_data_stddev_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type My_Field_Trip_Data_Stddev_Pop_Fields = {
  __typename?: "my_field_trip_data_stddev_pop_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type My_Field_Trip_Data_Stddev_Samp_Fields = {
  __typename?: "my_field_trip_data_stddev_samp_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate sum on columns */
export type My_Field_Trip_Data_Sum_Fields = {
  __typename?: "my_field_trip_data_sum_fields";
  field_trip_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** aggregate var_pop on columns */
export type My_Field_Trip_Data_Var_Pop_Fields = {
  __typename?: "my_field_trip_data_var_pop_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type My_Field_Trip_Data_Var_Samp_Fields = {
  __typename?: "my_field_trip_data_var_samp_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type My_Field_Trip_Data_Variance_Fields = {
  __typename?: "my_field_trip_data_variance_fields";
  field_trip_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to compare columns of type "name". All fields are combined with logical 'AND'. */
export type Name_Comparison_Exp = {
  _eq?: Maybe<Scalars["name"]>;
  _gt?: Maybe<Scalars["name"]>;
  _gte?: Maybe<Scalars["name"]>;
  _in?: Maybe<Array<Scalars["name"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["name"]>;
  _lte?: Maybe<Scalars["name"]>;
  _neq?: Maybe<Scalars["name"]>;
  _nin?: Maybe<Array<Scalars["name"]>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = "asc",
  /** in ascending order, nulls first */
  AscNullsFirst = "asc_nulls_first",
  /** in ascending order, nulls last */
  AscNullsLast = "asc_nulls_last",
  /** in descending order, nulls first */
  Desc = "desc",
  /** in descending order, nulls first */
  DescNullsFirst = "desc_nulls_first",
  /** in descending order, nulls last */
  DescNullsLast = "desc_nulls_last",
}

/** Boolean expression to compare columns of type "point". All fields are combined with logical 'AND'. */
export type Point_Comparison_Exp = {
  _eq?: Maybe<Scalars["point"]>;
  _gt?: Maybe<Scalars["point"]>;
  _gte?: Maybe<Scalars["point"]>;
  _in?: Maybe<Array<Scalars["point"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["point"]>;
  _lte?: Maybe<Scalars["point"]>;
  _neq?: Maybe<Scalars["point"]>;
  _nin?: Maybe<Array<Scalars["point"]>>;
};

/** columns and relationships of "product_features" */
export type Product_Features = {
  __typename?: "product_features";
  feature_name: Scalars["String"];
  feature_slug: Scalars["String"];
  id: Scalars["uuid"];
  /** An array relationship */
  school_product_features: Array<School_Product_Features>;
  /** An aggregate relationship */
  school_product_features_aggregate: School_Product_Features_Aggregate;
};

/** columns and relationships of "product_features" */
export type Product_FeaturesSchool_Product_FeaturesArgs = {
  distinct_on?: Maybe<Array<School_Product_Features_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<School_Product_Features_Order_By>>;
  where?: Maybe<School_Product_Features_Bool_Exp>;
};

/** columns and relationships of "product_features" */
export type Product_FeaturesSchool_Product_Features_AggregateArgs = {
  distinct_on?: Maybe<Array<School_Product_Features_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<School_Product_Features_Order_By>>;
  where?: Maybe<School_Product_Features_Bool_Exp>;
};

/** aggregated selection of "product_features" */
export type Product_Features_Aggregate = {
  __typename?: "product_features_aggregate";
  aggregate?: Maybe<Product_Features_Aggregate_Fields>;
  nodes: Array<Product_Features>;
};

/** aggregate fields of "product_features" */
export type Product_Features_Aggregate_Fields = {
  __typename?: "product_features_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Product_Features_Max_Fields>;
  min?: Maybe<Product_Features_Min_Fields>;
};

/** aggregate fields of "product_features" */
export type Product_Features_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Features_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "product_features". All fields are combined with a logical 'AND'. */
export type Product_Features_Bool_Exp = {
  _and?: Maybe<Array<Product_Features_Bool_Exp>>;
  _not?: Maybe<Product_Features_Bool_Exp>;
  _or?: Maybe<Array<Product_Features_Bool_Exp>>;
  feature_name?: Maybe<String_Comparison_Exp>;
  feature_slug?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  school_product_features?: Maybe<School_Product_Features_Bool_Exp>;
};

/** unique or primary key constraints on table "product_features" */
export enum Product_Features_Constraint {
  /** unique or primary key constraint */
  ProductFeaturesFeatureNameKey = "product_features_feature_name_key",
  /** unique or primary key constraint */
  ProductFeaturesFeatureSlugKey = "product_features_feature_slug_key",
  /** unique or primary key constraint */
  ProductFeaturesPkey = "product_features_pkey",
}

/** input type for inserting data into table "product_features" */
export type Product_Features_Insert_Input = {
  feature_name?: Maybe<Scalars["String"]>;
  feature_slug?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  school_product_features?: Maybe<School_Product_Features_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Product_Features_Max_Fields = {
  __typename?: "product_features_max_fields";
  feature_name?: Maybe<Scalars["String"]>;
  feature_slug?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type Product_Features_Min_Fields = {
  __typename?: "product_features_min_fields";
  feature_name?: Maybe<Scalars["String"]>;
  feature_slug?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "product_features" */
export type Product_Features_Mutation_Response = {
  __typename?: "product_features_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Features>;
};

/** input type for inserting object relation for remote table "product_features" */
export type Product_Features_Obj_Rel_Insert_Input = {
  data: Product_Features_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Product_Features_On_Conflict>;
};

/** on_conflict condition type for table "product_features" */
export type Product_Features_On_Conflict = {
  constraint: Product_Features_Constraint;
  update_columns: Array<Product_Features_Update_Column>;
  where?: Maybe<Product_Features_Bool_Exp>;
};

/** Ordering options when selecting data from "product_features". */
export type Product_Features_Order_By = {
  feature_name?: Maybe<Order_By>;
  feature_slug?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  school_product_features_aggregate?: Maybe<
    School_Product_Features_Aggregate_Order_By
  >;
};

/** primary key columns input for table: product_features */
export type Product_Features_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "product_features" */
export enum Product_Features_Select_Column {
  /** column name */
  FeatureName = "feature_name",
  /** column name */
  FeatureSlug = "feature_slug",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "product_features" */
export type Product_Features_Set_Input = {
  feature_name?: Maybe<Scalars["String"]>;
  feature_slug?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "product_features" */
export enum Product_Features_Update_Column {
  /** column name */
  FeatureName = "feature_name",
  /** column name */
  FeatureSlug = "feature_slug",
  /** column name */
  Id = "id",
}

export type Query_Root = {
  __typename?: "query_root";
  /** An array relationship */
  agreement_questions: Array<Agreement_Questions>;
  /** An aggregate relationship */
  agreement_questions_aggregate: Agreement_Questions_Aggregate;
  /** fetch data from the table: "agreement_questions" using primary key columns */
  agreement_questions_by_pk?: Maybe<Agreement_Questions>;
  /** An array relationship */
  class_rooms: Array<Class_Rooms>;
  /** An aggregate relationship */
  class_rooms_aggregate: Class_Rooms_Aggregate;
  /** fetch data from the table: "class_rooms" using primary key columns */
  class_rooms_by_pk?: Maybe<Class_Rooms>;
  /** An array relationship */
  field_trip_approvals: Array<Field_Trip_Approvals>;
  /** An aggregate relationship */
  field_trip_approvals_aggregate: Field_Trip_Approvals_Aggregate;
  /** fetch data from the table: "field_trip_approvals" using primary key columns */
  field_trip_approvals_by_pk?: Maybe<Field_Trip_Approvals>;
  /** An array relationship */
  field_trip_location_reviews: Array<Field_Trip_Location_Reviews>;
  /** An aggregate relationship */
  field_trip_location_reviews_aggregate: Field_Trip_Location_Reviews_Aggregate;
  /** fetch data from the table: "field_trip_location_reviews" using primary key columns */
  field_trip_location_reviews_by_pk?: Maybe<Field_Trip_Location_Reviews>;
  /** An array relationship */
  field_trip_locations: Array<Field_Trip_Locations>;
  /** An aggregate relationship */
  field_trip_locations_aggregate: Field_Trip_Locations_Aggregate;
  /** fetch data from the table: "field_trip_locations" using primary key columns */
  field_trip_locations_by_pk?: Maybe<Field_Trip_Locations>;
  /** An array relationship */
  field_trip_students: Array<Field_Trip_Students>;
  /** An aggregate relationship */
  field_trip_students_aggregate: Field_Trip_Students_Aggregate;
  /** fetch data from the table: "field_trip_students" using primary key columns */
  field_trip_students_by_pk?: Maybe<Field_Trip_Students>;
  /** An array relationship */
  field_trips: Array<Field_Trips>;
  /** An aggregate relationship */
  field_trips_aggregate: Field_Trips_Aggregate;
  /** fetch data from the table: "field_trips" using primary key columns */
  field_trips_by_pk?: Maybe<Field_Trips>;
  /** fetch data from the table: "locations" */
  locations: Array<Locations>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** fetch data from the table: "me" */
  me: Array<Me>;
  /** fetch aggregated fields from the table: "me" */
  me_aggregate: Me_Aggregate;
  /** fetch data from the table: "my_field_trip_data" */
  my_field_trip_data: Array<My_Field_Trip_Data>;
  /** fetch aggregated fields from the table: "my_field_trip_data" */
  my_field_trip_data_aggregate: My_Field_Trip_Data_Aggregate;
  /** fetch data from the table: "product_features" */
  product_features: Array<Product_Features>;
  /** fetch aggregated fields from the table: "product_features" */
  product_features_aggregate: Product_Features_Aggregate;
  /** fetch data from the table: "product_features" using primary key columns */
  product_features_by_pk?: Maybe<Product_Features>;
  /** An array relationship */
  reflect_questions: Array<Reflect_Questions>;
  /** An aggregate relationship */
  reflect_questions_aggregate: Reflect_Questions_Aggregate;
  /** fetch data from the table: "reflect_questions" using primary key columns */
  reflect_questions_by_pk?: Maybe<Reflect_Questions>;
  /** An array relationship */
  school_product_features: Array<School_Product_Features>;
  /** An aggregate relationship */
  school_product_features_aggregate: School_Product_Features_Aggregate;
  /** fetch data from the table: "school_product_features" using primary key columns */
  school_product_features_by_pk?: Maybe<School_Product_Features>;
  /** fetch data from the table: "school_types" */
  school_types: Array<School_Types>;
  /** fetch aggregated fields from the table: "school_types" */
  school_types_aggregate: School_Types_Aggregate;
  /** fetch data from the table: "school_types" using primary key columns */
  school_types_by_pk?: Maybe<School_Types>;
  /** fetch data from the table: "schools" */
  schools: Array<Schools>;
  /** fetch aggregated fields from the table: "schools" */
  schools_aggregate: Schools_Aggregate;
  /** fetch data from the table: "schools" using primary key columns */
  schools_by_pk?: Maybe<Schools>;
  /** fetch data from the table: "secondary_school_types" */
  secondary_school_types: Array<Secondary_School_Types>;
  /** fetch aggregated fields from the table: "secondary_school_types" */
  secondary_school_types_aggregate: Secondary_School_Types_Aggregate;
  /** fetch data from the table: "secondary_school_types" using primary key columns */
  secondary_school_types_by_pk?: Maybe<Secondary_School_Types>;
  /** An array relationship */
  user_location_ratings: Array<User_Location_Ratings>;
  /** An aggregate relationship */
  user_location_ratings_aggregate: User_Location_Ratings_Aggregate;
  /** fetch data from the table: "user_location_ratings" using primary key columns */
  user_location_ratings_by_pk?: Maybe<User_Location_Ratings>;
  /** fetch data from the table: "user_names" */
  user_names: Array<User_Names>;
  /** fetch aggregated fields from the table: "user_names" */
  user_names_aggregate: User_Names_Aggregate;
  /** fetch data from the table: "user_relationship" */
  user_relationship: Array<User_Relationship>;
  /** fetch aggregated fields from the table: "user_relationship" */
  user_relationship_aggregate: User_Relationship_Aggregate;
  /** fetch data from the table: "user_relationship" using primary key columns */
  user_relationship_by_pk?: Maybe<User_Relationship>;
  /** fetch data from the table: "user_relationship_types" */
  user_relationship_types: Array<User_Relationship_Types>;
  /** fetch aggregated fields from the table: "user_relationship_types" */
  user_relationship_types_aggregate: User_Relationship_Types_Aggregate;
  /** fetch data from the table: "user_relationship_types" using primary key columns */
  user_relationship_types_by_pk?: Maybe<User_Relationship_Types>;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<User_Roles>;
  /** fetch aggregated fields from the table: "user_roles" */
  user_roles_aggregate: User_Roles_Aggregate;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** fetch data from the table: "user_status" */
  user_status: Array<User_Status>;
  /** fetch aggregated fields from the table: "user_status" */
  user_status_aggregate: User_Status_Aggregate;
  /** fetch data from the table: "user_status" using primary key columns */
  user_status_by_pk?: Maybe<User_Status>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** An array relationship */
  virtual_trip_images: Array<Virtual_Trip_Images>;
  /** An aggregate relationship */
  virtual_trip_images_aggregate: Virtual_Trip_Images_Aggregate;
  /** fetch data from the table: "virtual_trip_images" using primary key columns */
  virtual_trip_images_by_pk?: Maybe<Virtual_Trip_Images>;
  /** fetch data from the table: "virtual_trip_plans" */
  virtual_trip_plans: Array<Virtual_Trip_Plans>;
  /** fetch aggregated fields from the table: "virtual_trip_plans" */
  virtual_trip_plans_aggregate: Virtual_Trip_Plans_Aggregate;
  /** fetch data from the table: "virtual_trip_plans" using primary key columns */
  virtual_trip_plans_by_pk?: Maybe<Virtual_Trip_Plans>;
  /** fetch data from the table: "virtual_trip_proposals" */
  virtual_trip_proposals: Array<Virtual_Trip_Proposals>;
  /** fetch aggregated fields from the table: "virtual_trip_proposals" */
  virtual_trip_proposals_aggregate: Virtual_Trip_Proposals_Aggregate;
  /** fetch data from the table: "virtual_trip_proposals" using primary key columns */
  virtual_trip_proposals_by_pk?: Maybe<Virtual_Trip_Proposals>;
  /** fetch data from the table: "virtual_trip_proposals_topic" */
  virtual_trip_proposals_topic: Array<Virtual_Trip_Proposals_Topic>;
  /** fetch aggregated fields from the table: "virtual_trip_proposals_topic" */
  virtual_trip_proposals_topic_aggregate: Virtual_Trip_Proposals_Topic_Aggregate;
  /** An array relationship */
  virtual_trip_schools: Array<Virtual_Trip_Schools>;
  /** An aggregate relationship */
  virtual_trip_schools_aggregate: Virtual_Trip_Schools_Aggregate;
  /** fetch data from the table: "virtual_trip_schools" using primary key columns */
  virtual_trip_schools_by_pk?: Maybe<Virtual_Trip_Schools>;
  /** fetch data from the table: "virtual_trips" */
  virtual_trips: Array<Virtual_Trips>;
  /** fetch aggregated fields from the table: "virtual_trips" */
  virtual_trips_aggregate: Virtual_Trips_Aggregate;
  /** fetch data from the table: "virtual_trips" using primary key columns */
  virtual_trips_by_pk?: Maybe<Virtual_Trips>;
};

export type Query_RootAgreement_QuestionsArgs = {
  distinct_on?: Maybe<Array<Agreement_Questions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Agreement_Questions_Order_By>>;
  where?: Maybe<Agreement_Questions_Bool_Exp>;
};

export type Query_RootAgreement_Questions_AggregateArgs = {
  distinct_on?: Maybe<Array<Agreement_Questions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Agreement_Questions_Order_By>>;
  where?: Maybe<Agreement_Questions_Bool_Exp>;
};

export type Query_RootAgreement_Questions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootClass_RoomsArgs = {
  distinct_on?: Maybe<Array<Class_Rooms_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Class_Rooms_Order_By>>;
  where?: Maybe<Class_Rooms_Bool_Exp>;
};

export type Query_RootClass_Rooms_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Rooms_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Class_Rooms_Order_By>>;
  where?: Maybe<Class_Rooms_Bool_Exp>;
};

export type Query_RootClass_Rooms_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootField_Trip_ApprovalsArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Approvals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Approvals_Order_By>>;
  where?: Maybe<Field_Trip_Approvals_Bool_Exp>;
};

export type Query_RootField_Trip_Approvals_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Approvals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Approvals_Order_By>>;
  where?: Maybe<Field_Trip_Approvals_Bool_Exp>;
};

export type Query_RootField_Trip_Approvals_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootField_Trip_Location_ReviewsArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Location_Reviews_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Location_Reviews_Order_By>>;
  where?: Maybe<Field_Trip_Location_Reviews_Bool_Exp>;
};

export type Query_RootField_Trip_Location_Reviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Location_Reviews_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Location_Reviews_Order_By>>;
  where?: Maybe<Field_Trip_Location_Reviews_Bool_Exp>;
};

export type Query_RootField_Trip_Location_Reviews_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Query_RootField_Trip_LocationsArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Locations_Order_By>>;
  where?: Maybe<Field_Trip_Locations_Bool_Exp>;
};

export type Query_RootField_Trip_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Locations_Order_By>>;
  where?: Maybe<Field_Trip_Locations_Bool_Exp>;
};

export type Query_RootField_Trip_Locations_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootField_Trip_StudentsArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Students_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Students_Order_By>>;
  where?: Maybe<Field_Trip_Students_Bool_Exp>;
};

export type Query_RootField_Trip_Students_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Students_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Students_Order_By>>;
  where?: Maybe<Field_Trip_Students_Bool_Exp>;
};

export type Query_RootField_Trip_Students_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootField_TripsArgs = {
  distinct_on?: Maybe<Array<Field_Trips_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trips_Order_By>>;
  where?: Maybe<Field_Trips_Bool_Exp>;
};

export type Query_RootField_Trips_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Trips_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trips_Order_By>>;
  where?: Maybe<Field_Trips_Bool_Exp>;
};

export type Query_RootField_Trips_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};

export type Query_RootLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};

export type Query_RootLocations_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootMeArgs = {
  distinct_on?: Maybe<Array<Me_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Me_Order_By>>;
  where?: Maybe<Me_Bool_Exp>;
};

export type Query_RootMe_AggregateArgs = {
  distinct_on?: Maybe<Array<Me_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Me_Order_By>>;
  where?: Maybe<Me_Bool_Exp>;
};

export type Query_RootMy_Field_Trip_DataArgs = {
  distinct_on?: Maybe<Array<My_Field_Trip_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<My_Field_Trip_Data_Order_By>>;
  where?: Maybe<My_Field_Trip_Data_Bool_Exp>;
};

export type Query_RootMy_Field_Trip_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<My_Field_Trip_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<My_Field_Trip_Data_Order_By>>;
  where?: Maybe<My_Field_Trip_Data_Bool_Exp>;
};

export type Query_RootProduct_FeaturesArgs = {
  distinct_on?: Maybe<Array<Product_Features_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Features_Order_By>>;
  where?: Maybe<Product_Features_Bool_Exp>;
};

export type Query_RootProduct_Features_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Features_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Features_Order_By>>;
  where?: Maybe<Product_Features_Bool_Exp>;
};

export type Query_RootProduct_Features_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootReflect_QuestionsArgs = {
  distinct_on?: Maybe<Array<Reflect_Questions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Reflect_Questions_Order_By>>;
  where?: Maybe<Reflect_Questions_Bool_Exp>;
};

export type Query_RootReflect_Questions_AggregateArgs = {
  distinct_on?: Maybe<Array<Reflect_Questions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Reflect_Questions_Order_By>>;
  where?: Maybe<Reflect_Questions_Bool_Exp>;
};

export type Query_RootReflect_Questions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSchool_Product_FeaturesArgs = {
  distinct_on?: Maybe<Array<School_Product_Features_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<School_Product_Features_Order_By>>;
  where?: Maybe<School_Product_Features_Bool_Exp>;
};

export type Query_RootSchool_Product_Features_AggregateArgs = {
  distinct_on?: Maybe<Array<School_Product_Features_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<School_Product_Features_Order_By>>;
  where?: Maybe<School_Product_Features_Bool_Exp>;
};

export type Query_RootSchool_Product_Features_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootSchool_TypesArgs = {
  distinct_on?: Maybe<Array<School_Types_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<School_Types_Order_By>>;
  where?: Maybe<School_Types_Bool_Exp>;
};

export type Query_RootSchool_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<School_Types_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<School_Types_Order_By>>;
  where?: Maybe<School_Types_Bool_Exp>;
};

export type Query_RootSchool_Types_By_PkArgs = {
  id: Scalars["String"];
};

export type Query_RootSchoolsArgs = {
  distinct_on?: Maybe<Array<Schools_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Schools_Order_By>>;
  where?: Maybe<Schools_Bool_Exp>;
};

export type Query_RootSchools_AggregateArgs = {
  distinct_on?: Maybe<Array<Schools_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Schools_Order_By>>;
  where?: Maybe<Schools_Bool_Exp>;
};

export type Query_RootSchools_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSecondary_School_TypesArgs = {
  distinct_on?: Maybe<Array<Secondary_School_Types_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Secondary_School_Types_Order_By>>;
  where?: Maybe<Secondary_School_Types_Bool_Exp>;
};

export type Query_RootSecondary_School_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Secondary_School_Types_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Secondary_School_Types_Order_By>>;
  where?: Maybe<Secondary_School_Types_Bool_Exp>;
};

export type Query_RootSecondary_School_Types_By_PkArgs = {
  id: Scalars["String"];
};

export type Query_RootUser_Location_RatingsArgs = {
  distinct_on?: Maybe<Array<User_Location_Ratings_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Location_Ratings_Order_By>>;
  where?: Maybe<User_Location_Ratings_Bool_Exp>;
};

export type Query_RootUser_Location_Ratings_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Location_Ratings_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Location_Ratings_Order_By>>;
  where?: Maybe<User_Location_Ratings_Bool_Exp>;
};

export type Query_RootUser_Location_Ratings_By_PkArgs = {
  location_id: Scalars["uuid"];
  user_id: Scalars["uuid"];
};

export type Query_RootUser_NamesArgs = {
  distinct_on?: Maybe<Array<User_Names_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Names_Order_By>>;
  where?: Maybe<User_Names_Bool_Exp>;
};

export type Query_RootUser_Names_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Names_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Names_Order_By>>;
  where?: Maybe<User_Names_Bool_Exp>;
};

export type Query_RootUser_RelationshipArgs = {
  distinct_on?: Maybe<Array<User_Relationship_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Relationship_Order_By>>;
  where?: Maybe<User_Relationship_Bool_Exp>;
};

export type Query_RootUser_Relationship_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Relationship_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Relationship_Order_By>>;
  where?: Maybe<User_Relationship_Bool_Exp>;
};

export type Query_RootUser_Relationship_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootUser_Relationship_TypesArgs = {
  distinct_on?: Maybe<Array<User_Relationship_Types_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Relationship_Types_Order_By>>;
  where?: Maybe<User_Relationship_Types_Bool_Exp>;
};

export type Query_RootUser_Relationship_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Relationship_Types_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Relationship_Types_Order_By>>;
  where?: Maybe<User_Relationship_Types_Bool_Exp>;
};

export type Query_RootUser_Relationship_Types_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootUser_RolesArgs = {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
};

export type Query_RootUser_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
};

export type Query_RootUser_Roles_By_PkArgs = {
  value: Scalars["String"];
};

export type Query_RootUser_StatusArgs = {
  distinct_on?: Maybe<Array<User_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Status_Order_By>>;
  where?: Maybe<User_Status_Bool_Exp>;
};

export type Query_RootUser_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Status_Order_By>>;
  where?: Maybe<User_Status_Bool_Exp>;
};

export type Query_RootUser_Status_By_PkArgs = {
  value: Scalars["String"];
};

export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

export type Query_RootUsers_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootVirtual_Trip_ImagesArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Images_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Images_Order_By>>;
  where?: Maybe<Virtual_Trip_Images_Bool_Exp>;
};

export type Query_RootVirtual_Trip_Images_AggregateArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Images_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Images_Order_By>>;
  where?: Maybe<Virtual_Trip_Images_Bool_Exp>;
};

export type Query_RootVirtual_Trip_Images_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootVirtual_Trip_PlansArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Plans_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Plans_Order_By>>;
  where?: Maybe<Virtual_Trip_Plans_Bool_Exp>;
};

export type Query_RootVirtual_Trip_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Plans_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Plans_Order_By>>;
  where?: Maybe<Virtual_Trip_Plans_Bool_Exp>;
};

export type Query_RootVirtual_Trip_Plans_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootVirtual_Trip_ProposalsArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Proposals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Proposals_Order_By>>;
  where?: Maybe<Virtual_Trip_Proposals_Bool_Exp>;
};

export type Query_RootVirtual_Trip_Proposals_AggregateArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Proposals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Proposals_Order_By>>;
  where?: Maybe<Virtual_Trip_Proposals_Bool_Exp>;
};

export type Query_RootVirtual_Trip_Proposals_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootVirtual_Trip_Proposals_TopicArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Proposals_Topic_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Proposals_Topic_Order_By>>;
  where?: Maybe<Virtual_Trip_Proposals_Topic_Bool_Exp>;
};

export type Query_RootVirtual_Trip_Proposals_Topic_AggregateArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Proposals_Topic_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Proposals_Topic_Order_By>>;
  where?: Maybe<Virtual_Trip_Proposals_Topic_Bool_Exp>;
};

export type Query_RootVirtual_Trip_SchoolsArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Schools_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Schools_Order_By>>;
  where?: Maybe<Virtual_Trip_Schools_Bool_Exp>;
};

export type Query_RootVirtual_Trip_Schools_AggregateArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Schools_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Schools_Order_By>>;
  where?: Maybe<Virtual_Trip_Schools_Bool_Exp>;
};

export type Query_RootVirtual_Trip_Schools_By_PkArgs = {
  school_id: Scalars["uuid"];
  virtual_trip_id: Scalars["Int"];
};

export type Query_RootVirtual_TripsArgs = {
  distinct_on?: Maybe<Array<Virtual_Trips_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trips_Order_By>>;
  where?: Maybe<Virtual_Trips_Bool_Exp>;
};

export type Query_RootVirtual_Trips_AggregateArgs = {
  distinct_on?: Maybe<Array<Virtual_Trips_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trips_Order_By>>;
  where?: Maybe<Virtual_Trips_Bool_Exp>;
};

export type Query_RootVirtual_Trips_By_PkArgs = {
  id: Scalars["Int"];
};

/** columns and relationships of "reflect_questions" */
export type Reflect_Questions = {
  __typename?: "reflect_questions";
  id: Scalars["uuid"];
  label: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  /** An object relationship */
  school: Schools;
  school_id: Scalars["uuid"];
};

/** aggregated selection of "reflect_questions" */
export type Reflect_Questions_Aggregate = {
  __typename?: "reflect_questions_aggregate";
  aggregate?: Maybe<Reflect_Questions_Aggregate_Fields>;
  nodes: Array<Reflect_Questions>;
};

/** aggregate fields of "reflect_questions" */
export type Reflect_Questions_Aggregate_Fields = {
  __typename?: "reflect_questions_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Reflect_Questions_Max_Fields>;
  min?: Maybe<Reflect_Questions_Min_Fields>;
};

/** aggregate fields of "reflect_questions" */
export type Reflect_Questions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Reflect_Questions_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "reflect_questions" */
export type Reflect_Questions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Reflect_Questions_Max_Order_By>;
  min?: Maybe<Reflect_Questions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "reflect_questions" */
export type Reflect_Questions_Arr_Rel_Insert_Input = {
  data: Array<Reflect_Questions_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Reflect_Questions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "reflect_questions". All fields are combined with a logical 'AND'. */
export type Reflect_Questions_Bool_Exp = {
  _and?: Maybe<Array<Reflect_Questions_Bool_Exp>>;
  _not?: Maybe<Reflect_Questions_Bool_Exp>;
  _or?: Maybe<Array<Reflect_Questions_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  school?: Maybe<Schools_Bool_Exp>;
  school_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "reflect_questions" */
export enum Reflect_Questions_Constraint {
  /** unique or primary key constraint */
  ReflectQuestionPkey = "reflect_question_pkey",
}

/** input type for inserting data into table "reflect_questions" */
export type Reflect_Questions_Insert_Input = {
  id?: Maybe<Scalars["uuid"]>;
  label?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  school?: Maybe<Schools_Obj_Rel_Insert_Input>;
  school_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Reflect_Questions_Max_Fields = {
  __typename?: "reflect_questions_max_fields";
  id?: Maybe<Scalars["uuid"]>;
  label?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  school_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "reflect_questions" */
export type Reflect_Questions_Max_Order_By = {
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Reflect_Questions_Min_Fields = {
  __typename?: "reflect_questions_min_fields";
  id?: Maybe<Scalars["uuid"]>;
  label?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  school_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "reflect_questions" */
export type Reflect_Questions_Min_Order_By = {
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "reflect_questions" */
export type Reflect_Questions_Mutation_Response = {
  __typename?: "reflect_questions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Reflect_Questions>;
};

/** on_conflict condition type for table "reflect_questions" */
export type Reflect_Questions_On_Conflict = {
  constraint: Reflect_Questions_Constraint;
  update_columns: Array<Reflect_Questions_Update_Column>;
  where?: Maybe<Reflect_Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "reflect_questions". */
export type Reflect_Questions_Order_By = {
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  school?: Maybe<Schools_Order_By>;
  school_id?: Maybe<Order_By>;
};

/** primary key columns input for table: reflect_questions */
export type Reflect_Questions_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "reflect_questions" */
export enum Reflect_Questions_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Label = "label",
  /** column name */
  Notes = "notes",
  /** column name */
  SchoolId = "school_id",
}

/** input type for updating data in table "reflect_questions" */
export type Reflect_Questions_Set_Input = {
  id?: Maybe<Scalars["uuid"]>;
  label?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  school_id?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "reflect_questions" */
export enum Reflect_Questions_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Label = "label",
  /** column name */
  Notes = "notes",
  /** column name */
  SchoolId = "school_id",
}

/** columns and relationships of "school_product_features" */
export type School_Product_Features = {
  __typename?: "school_product_features";
  created_at: Scalars["timestamptz"];
  created_by: Scalars["uuid"];
  id: Scalars["Int"];
  /** An object relationship */
  product_feature: Product_Features;
  product_feature_id: Scalars["uuid"];
  /** An object relationship */
  school: Schools;
  school_id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "school_product_features" */
export type School_Product_Features_Aggregate = {
  __typename?: "school_product_features_aggregate";
  aggregate?: Maybe<School_Product_Features_Aggregate_Fields>;
  nodes: Array<School_Product_Features>;
};

/** aggregate fields of "school_product_features" */
export type School_Product_Features_Aggregate_Fields = {
  __typename?: "school_product_features_aggregate_fields";
  avg?: Maybe<School_Product_Features_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<School_Product_Features_Max_Fields>;
  min?: Maybe<School_Product_Features_Min_Fields>;
  stddev?: Maybe<School_Product_Features_Stddev_Fields>;
  stddev_pop?: Maybe<School_Product_Features_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<School_Product_Features_Stddev_Samp_Fields>;
  sum?: Maybe<School_Product_Features_Sum_Fields>;
  var_pop?: Maybe<School_Product_Features_Var_Pop_Fields>;
  var_samp?: Maybe<School_Product_Features_Var_Samp_Fields>;
  variance?: Maybe<School_Product_Features_Variance_Fields>;
};

/** aggregate fields of "school_product_features" */
export type School_Product_Features_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<School_Product_Features_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "school_product_features" */
export type School_Product_Features_Aggregate_Order_By = {
  avg?: Maybe<School_Product_Features_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<School_Product_Features_Max_Order_By>;
  min?: Maybe<School_Product_Features_Min_Order_By>;
  stddev?: Maybe<School_Product_Features_Stddev_Order_By>;
  stddev_pop?: Maybe<School_Product_Features_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<School_Product_Features_Stddev_Samp_Order_By>;
  sum?: Maybe<School_Product_Features_Sum_Order_By>;
  var_pop?: Maybe<School_Product_Features_Var_Pop_Order_By>;
  var_samp?: Maybe<School_Product_Features_Var_Samp_Order_By>;
  variance?: Maybe<School_Product_Features_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "school_product_features" */
export type School_Product_Features_Arr_Rel_Insert_Input = {
  data: Array<School_Product_Features_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<School_Product_Features_On_Conflict>;
};

/** aggregate avg on columns */
export type School_Product_Features_Avg_Fields = {
  __typename?: "school_product_features_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "school_product_features" */
export type School_Product_Features_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "school_product_features". All fields are combined with a logical 'AND'. */
export type School_Product_Features_Bool_Exp = {
  _and?: Maybe<Array<School_Product_Features_Bool_Exp>>;
  _not?: Maybe<School_Product_Features_Bool_Exp>;
  _or?: Maybe<Array<School_Product_Features_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  product_feature?: Maybe<Product_Features_Bool_Exp>;
  product_feature_id?: Maybe<Uuid_Comparison_Exp>;
  school?: Maybe<Schools_Bool_Exp>;
  school_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "school_product_features" */
export enum School_Product_Features_Constraint {
  /** unique or primary key constraint */
  SchoolProductFeaturesPkey = "school_product_features_pkey",
  /** unique or primary key constraint */
  SchoolProductFeaturesSchoolIdProductFeatureIdKey = "school_product_features_school_id_product_feature_id_key",
}

/** input type for incrementing numeric columns in table "school_product_features" */
export type School_Product_Features_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "school_product_features" */
export type School_Product_Features_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
  product_feature?: Maybe<Product_Features_Obj_Rel_Insert_Input>;
  product_feature_id?: Maybe<Scalars["uuid"]>;
  school?: Maybe<Schools_Obj_Rel_Insert_Input>;
  school_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type School_Product_Features_Max_Fields = {
  __typename?: "school_product_features_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
  product_feature_id?: Maybe<Scalars["uuid"]>;
  school_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "school_product_features" */
export type School_Product_Features_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_feature_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type School_Product_Features_Min_Fields = {
  __typename?: "school_product_features_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
  product_feature_id?: Maybe<Scalars["uuid"]>;
  school_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "school_product_features" */
export type School_Product_Features_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_feature_id?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "school_product_features" */
export type School_Product_Features_Mutation_Response = {
  __typename?: "school_product_features_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<School_Product_Features>;
};

/** on_conflict condition type for table "school_product_features" */
export type School_Product_Features_On_Conflict = {
  constraint: School_Product_Features_Constraint;
  update_columns: Array<School_Product_Features_Update_Column>;
  where?: Maybe<School_Product_Features_Bool_Exp>;
};

/** Ordering options when selecting data from "school_product_features". */
export type School_Product_Features_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_feature?: Maybe<Product_Features_Order_By>;
  product_feature_id?: Maybe<Order_By>;
  school?: Maybe<Schools_Order_By>;
  school_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: school_product_features */
export type School_Product_Features_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "school_product_features" */
export enum School_Product_Features_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  Id = "id",
  /** column name */
  ProductFeatureId = "product_feature_id",
  /** column name */
  SchoolId = "school_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "school_product_features" */
export type School_Product_Features_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
  product_feature_id?: Maybe<Scalars["uuid"]>;
  school_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type School_Product_Features_Stddev_Fields = {
  __typename?: "school_product_features_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "school_product_features" */
export type School_Product_Features_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type School_Product_Features_Stddev_Pop_Fields = {
  __typename?: "school_product_features_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "school_product_features" */
export type School_Product_Features_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type School_Product_Features_Stddev_Samp_Fields = {
  __typename?: "school_product_features_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "school_product_features" */
export type School_Product_Features_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type School_Product_Features_Sum_Fields = {
  __typename?: "school_product_features_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "school_product_features" */
export type School_Product_Features_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "school_product_features" */
export enum School_Product_Features_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  Id = "id",
  /** column name */
  ProductFeatureId = "product_feature_id",
  /** column name */
  SchoolId = "school_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** aggregate var_pop on columns */
export type School_Product_Features_Var_Pop_Fields = {
  __typename?: "school_product_features_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "school_product_features" */
export type School_Product_Features_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type School_Product_Features_Var_Samp_Fields = {
  __typename?: "school_product_features_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "school_product_features" */
export type School_Product_Features_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type School_Product_Features_Variance_Fields = {
  __typename?: "school_product_features_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "school_product_features" */
export type School_Product_Features_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "school_types" */
export type School_Types = {
  __typename?: "school_types";
  id: Scalars["String"];
  value: Scalars["String"];
};

/** aggregated selection of "school_types" */
export type School_Types_Aggregate = {
  __typename?: "school_types_aggregate";
  aggregate?: Maybe<School_Types_Aggregate_Fields>;
  nodes: Array<School_Types>;
};

/** aggregate fields of "school_types" */
export type School_Types_Aggregate_Fields = {
  __typename?: "school_types_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<School_Types_Max_Fields>;
  min?: Maybe<School_Types_Min_Fields>;
};

/** aggregate fields of "school_types" */
export type School_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<School_Types_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "school_types". All fields are combined with a logical 'AND'. */
export type School_Types_Bool_Exp = {
  _and?: Maybe<Array<School_Types_Bool_Exp>>;
  _not?: Maybe<School_Types_Bool_Exp>;
  _or?: Maybe<Array<School_Types_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "school_types" */
export enum School_Types_Constraint {
  /** unique or primary key constraint */
  SchoolTypesPkey = "school_types_pkey",
}

export enum School_Types_Enum {
  /** Charter School */
  CharterSchool = "charter_school",
  /** Independent School */
  IndependentSchool = "independent_school",
  /** Other */
  Other = "other",
}

/** Boolean expression to compare columns of type "school_types_enum". All fields are combined with logical 'AND'. */
export type School_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<School_Types_Enum>;
  _in?: Maybe<Array<School_Types_Enum>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<School_Types_Enum>;
  _nin?: Maybe<Array<School_Types_Enum>>;
};

/** input type for inserting data into table "school_types" */
export type School_Types_Insert_Input = {
  id?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type School_Types_Max_Fields = {
  __typename?: "school_types_max_fields";
  id?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type School_Types_Min_Fields = {
  __typename?: "school_types_min_fields";
  id?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "school_types" */
export type School_Types_Mutation_Response = {
  __typename?: "school_types_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<School_Types>;
};

/** input type for inserting object relation for remote table "school_types" */
export type School_Types_Obj_Rel_Insert_Input = {
  data: School_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<School_Types_On_Conflict>;
};

/** on_conflict condition type for table "school_types" */
export type School_Types_On_Conflict = {
  constraint: School_Types_Constraint;
  update_columns: Array<School_Types_Update_Column>;
  where?: Maybe<School_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "school_types". */
export type School_Types_Order_By = {
  id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: school_types */
export type School_Types_Pk_Columns_Input = {
  id: Scalars["String"];
};

/** select columns of table "school_types" */
export enum School_Types_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "school_types" */
export type School_Types_Set_Input = {
  id?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** update columns of table "school_types" */
export enum School_Types_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Value = "value",
}

/** columns and relationships of "schools" */
export type Schools = {
  __typename?: "schools";
  /** An array relationship */
  agreement_questions: Array<Agreement_Questions>;
  /** An aggregate relationship */
  agreement_questions_aggregate: Agreement_Questions_Aggregate;
  chaperone_request_template?: Maybe<Scalars["String"]>;
  city: Scalars["String"];
  /** An array relationship */
  class_rooms: Array<Class_Rooms>;
  /** An aggregate relationship */
  class_rooms_aggregate: Class_Rooms_Aggregate;
  color_theme?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An array relationship */
  field_trips: Array<Field_Trips>;
  /** An aggregate relationship */
  field_trips_aggregate: Field_Trips_Aggregate;
  id: Scalars["uuid"];
  logo_url?: Maybe<Scalars["String"]>;
  montessori_affiliation?: Maybe<Scalars["name"]>;
  name: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  parent_permission_template?: Maybe<Scalars["String"]>;
  /** An array relationship */
  reflect_questions: Array<Reflect_Questions>;
  /** An aggregate relationship */
  reflect_questions_aggregate: Reflect_Questions_Aggregate;
  /** An object relationship */
  schoolType: School_Types;
  /** An array relationship */
  school_product_features: Array<School_Product_Features>;
  /** An aggregate relationship */
  school_product_features_aggregate: School_Product_Features_Aggregate;
  school_type: School_Types_Enum;
  /** An object relationship */
  secondarySchoolType?: Maybe<Secondary_School_Types>;
  secondary_school_type?: Maybe<Secondary_School_Types_Enum>;
  state: Scalars["String"];
  street_address: Scalars["String"];
  street_address_2?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** An array relationship */
  virtual_trip_schools: Array<Virtual_Trip_Schools>;
  /** An aggregate relationship */
  virtual_trip_schools_aggregate: Virtual_Trip_Schools_Aggregate;
  zip_code: Scalars["String"];
};

/** columns and relationships of "schools" */
export type SchoolsAgreement_QuestionsArgs = {
  distinct_on?: Maybe<Array<Agreement_Questions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Agreement_Questions_Order_By>>;
  where?: Maybe<Agreement_Questions_Bool_Exp>;
};

/** columns and relationships of "schools" */
export type SchoolsAgreement_Questions_AggregateArgs = {
  distinct_on?: Maybe<Array<Agreement_Questions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Agreement_Questions_Order_By>>;
  where?: Maybe<Agreement_Questions_Bool_Exp>;
};

/** columns and relationships of "schools" */
export type SchoolsClass_RoomsArgs = {
  distinct_on?: Maybe<Array<Class_Rooms_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Class_Rooms_Order_By>>;
  where?: Maybe<Class_Rooms_Bool_Exp>;
};

/** columns and relationships of "schools" */
export type SchoolsClass_Rooms_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Rooms_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Class_Rooms_Order_By>>;
  where?: Maybe<Class_Rooms_Bool_Exp>;
};

/** columns and relationships of "schools" */
export type SchoolsField_TripsArgs = {
  distinct_on?: Maybe<Array<Field_Trips_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trips_Order_By>>;
  where?: Maybe<Field_Trips_Bool_Exp>;
};

/** columns and relationships of "schools" */
export type SchoolsField_Trips_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Trips_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trips_Order_By>>;
  where?: Maybe<Field_Trips_Bool_Exp>;
};

/** columns and relationships of "schools" */
export type SchoolsReflect_QuestionsArgs = {
  distinct_on?: Maybe<Array<Reflect_Questions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Reflect_Questions_Order_By>>;
  where?: Maybe<Reflect_Questions_Bool_Exp>;
};

/** columns and relationships of "schools" */
export type SchoolsReflect_Questions_AggregateArgs = {
  distinct_on?: Maybe<Array<Reflect_Questions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Reflect_Questions_Order_By>>;
  where?: Maybe<Reflect_Questions_Bool_Exp>;
};

/** columns and relationships of "schools" */
export type SchoolsSchool_Product_FeaturesArgs = {
  distinct_on?: Maybe<Array<School_Product_Features_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<School_Product_Features_Order_By>>;
  where?: Maybe<School_Product_Features_Bool_Exp>;
};

/** columns and relationships of "schools" */
export type SchoolsSchool_Product_Features_AggregateArgs = {
  distinct_on?: Maybe<Array<School_Product_Features_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<School_Product_Features_Order_By>>;
  where?: Maybe<School_Product_Features_Bool_Exp>;
};

/** columns and relationships of "schools" */
export type SchoolsUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** columns and relationships of "schools" */
export type SchoolsUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** columns and relationships of "schools" */
export type SchoolsVirtual_Trip_SchoolsArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Schools_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Schools_Order_By>>;
  where?: Maybe<Virtual_Trip_Schools_Bool_Exp>;
};

/** columns and relationships of "schools" */
export type SchoolsVirtual_Trip_Schools_AggregateArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Schools_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Schools_Order_By>>;
  where?: Maybe<Virtual_Trip_Schools_Bool_Exp>;
};

/** aggregated selection of "schools" */
export type Schools_Aggregate = {
  __typename?: "schools_aggregate";
  aggregate?: Maybe<Schools_Aggregate_Fields>;
  nodes: Array<Schools>;
};

/** aggregate fields of "schools" */
export type Schools_Aggregate_Fields = {
  __typename?: "schools_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Schools_Max_Fields>;
  min?: Maybe<Schools_Min_Fields>;
};

/** aggregate fields of "schools" */
export type Schools_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Schools_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "schools". All fields are combined with a logical 'AND'. */
export type Schools_Bool_Exp = {
  _and?: Maybe<Array<Schools_Bool_Exp>>;
  _not?: Maybe<Schools_Bool_Exp>;
  _or?: Maybe<Array<Schools_Bool_Exp>>;
  agreement_questions?: Maybe<Agreement_Questions_Bool_Exp>;
  chaperone_request_template?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  class_rooms?: Maybe<Class_Rooms_Bool_Exp>;
  color_theme?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  field_trips?: Maybe<Field_Trips_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  logo_url?: Maybe<String_Comparison_Exp>;
  montessori_affiliation?: Maybe<Name_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  parent_permission_template?: Maybe<String_Comparison_Exp>;
  reflect_questions?: Maybe<Reflect_Questions_Bool_Exp>;
  schoolType?: Maybe<School_Types_Bool_Exp>;
  school_product_features?: Maybe<School_Product_Features_Bool_Exp>;
  school_type?: Maybe<School_Types_Enum_Comparison_Exp>;
  secondarySchoolType?: Maybe<Secondary_School_Types_Bool_Exp>;
  secondary_school_type?: Maybe<Secondary_School_Types_Enum_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  street_address?: Maybe<String_Comparison_Exp>;
  street_address_2?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  users?: Maybe<Users_Bool_Exp>;
  virtual_trip_schools?: Maybe<Virtual_Trip_Schools_Bool_Exp>;
  zip_code?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "schools" */
export enum Schools_Constraint {
  /** unique or primary key constraint */
  SchoolsNameKey = "schools_name_key",
  /** unique or primary key constraint */
  SchoolsPkey = "schools_pkey",
}

/** input type for inserting data into table "schools" */
export type Schools_Insert_Input = {
  agreement_questions?: Maybe<Agreement_Questions_Arr_Rel_Insert_Input>;
  chaperone_request_template?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  class_rooms?: Maybe<Class_Rooms_Arr_Rel_Insert_Input>;
  color_theme?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  field_trips?: Maybe<Field_Trips_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars["uuid"]>;
  logo_url?: Maybe<Scalars["String"]>;
  montessori_affiliation?: Maybe<Scalars["name"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  parent_permission_template?: Maybe<Scalars["String"]>;
  reflect_questions?: Maybe<Reflect_Questions_Arr_Rel_Insert_Input>;
  schoolType?: Maybe<School_Types_Obj_Rel_Insert_Input>;
  school_product_features?: Maybe<School_Product_Features_Arr_Rel_Insert_Input>;
  school_type?: Maybe<School_Types_Enum>;
  secondarySchoolType?: Maybe<Secondary_School_Types_Obj_Rel_Insert_Input>;
  secondary_school_type?: Maybe<Secondary_School_Types_Enum>;
  state?: Maybe<Scalars["String"]>;
  street_address?: Maybe<Scalars["String"]>;
  street_address_2?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  users?: Maybe<Users_Arr_Rel_Insert_Input>;
  virtual_trip_schools?: Maybe<Virtual_Trip_Schools_Arr_Rel_Insert_Input>;
  zip_code?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Schools_Max_Fields = {
  __typename?: "schools_max_fields";
  chaperone_request_template?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  color_theme?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  logo_url?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  parent_permission_template?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  street_address?: Maybe<Scalars["String"]>;
  street_address_2?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  zip_code?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Schools_Min_Fields = {
  __typename?: "schools_min_fields";
  chaperone_request_template?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  color_theme?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  logo_url?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  parent_permission_template?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  street_address?: Maybe<Scalars["String"]>;
  street_address_2?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  zip_code?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "schools" */
export type Schools_Mutation_Response = {
  __typename?: "schools_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Schools>;
};

/** input type for inserting object relation for remote table "schools" */
export type Schools_Obj_Rel_Insert_Input = {
  data: Schools_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Schools_On_Conflict>;
};

/** on_conflict condition type for table "schools" */
export type Schools_On_Conflict = {
  constraint: Schools_Constraint;
  update_columns: Array<Schools_Update_Column>;
  where?: Maybe<Schools_Bool_Exp>;
};

/** Ordering options when selecting data from "schools". */
export type Schools_Order_By = {
  agreement_questions_aggregate?: Maybe<Agreement_Questions_Aggregate_Order_By>;
  chaperone_request_template?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  class_rooms_aggregate?: Maybe<Class_Rooms_Aggregate_Order_By>;
  color_theme?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  field_trips_aggregate?: Maybe<Field_Trips_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  logo_url?: Maybe<Order_By>;
  montessori_affiliation?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  parent_permission_template?: Maybe<Order_By>;
  reflect_questions_aggregate?: Maybe<Reflect_Questions_Aggregate_Order_By>;
  schoolType?: Maybe<School_Types_Order_By>;
  school_product_features_aggregate?: Maybe<
    School_Product_Features_Aggregate_Order_By
  >;
  school_type?: Maybe<Order_By>;
  secondarySchoolType?: Maybe<Secondary_School_Types_Order_By>;
  secondary_school_type?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  street_address?: Maybe<Order_By>;
  street_address_2?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  users_aggregate?: Maybe<Users_Aggregate_Order_By>;
  virtual_trip_schools_aggregate?: Maybe<
    Virtual_Trip_Schools_Aggregate_Order_By
  >;
  zip_code?: Maybe<Order_By>;
};

/** primary key columns input for table: schools */
export type Schools_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "schools" */
export enum Schools_Select_Column {
  /** column name */
  ChaperoneRequestTemplate = "chaperone_request_template",
  /** column name */
  City = "city",
  /** column name */
  ColorTheme = "color_theme",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LogoUrl = "logo_url",
  /** column name */
  MontessoriAffiliation = "montessori_affiliation",
  /** column name */
  Name = "name",
  /** column name */
  Notes = "notes",
  /** column name */
  ParentPermissionTemplate = "parent_permission_template",
  /** column name */
  SchoolType = "school_type",
  /** column name */
  SecondarySchoolType = "secondary_school_type",
  /** column name */
  State = "state",
  /** column name */
  StreetAddress = "street_address",
  /** column name */
  StreetAddress_2 = "street_address_2",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  ZipCode = "zip_code",
}

/** input type for updating data in table "schools" */
export type Schools_Set_Input = {
  chaperone_request_template?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  color_theme?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  logo_url?: Maybe<Scalars["String"]>;
  montessori_affiliation?: Maybe<Scalars["name"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  parent_permission_template?: Maybe<Scalars["String"]>;
  school_type?: Maybe<School_Types_Enum>;
  secondary_school_type?: Maybe<Secondary_School_Types_Enum>;
  state?: Maybe<Scalars["String"]>;
  street_address?: Maybe<Scalars["String"]>;
  street_address_2?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  zip_code?: Maybe<Scalars["String"]>;
};

/** update columns of table "schools" */
export enum Schools_Update_Column {
  /** column name */
  ChaperoneRequestTemplate = "chaperone_request_template",
  /** column name */
  City = "city",
  /** column name */
  ColorTheme = "color_theme",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LogoUrl = "logo_url",
  /** column name */
  MontessoriAffiliation = "montessori_affiliation",
  /** column name */
  Name = "name",
  /** column name */
  Notes = "notes",
  /** column name */
  ParentPermissionTemplate = "parent_permission_template",
  /** column name */
  SchoolType = "school_type",
  /** column name */
  SecondarySchoolType = "secondary_school_type",
  /** column name */
  State = "state",
  /** column name */
  StreetAddress = "street_address",
  /** column name */
  StreetAddress_2 = "street_address_2",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  ZipCode = "zip_code",
}

/** columns and relationships of "secondary_school_types" */
export type Secondary_School_Types = {
  __typename?: "secondary_school_types";
  id: Scalars["String"];
  label: Scalars["String"];
};

/** aggregated selection of "secondary_school_types" */
export type Secondary_School_Types_Aggregate = {
  __typename?: "secondary_school_types_aggregate";
  aggregate?: Maybe<Secondary_School_Types_Aggregate_Fields>;
  nodes: Array<Secondary_School_Types>;
};

/** aggregate fields of "secondary_school_types" */
export type Secondary_School_Types_Aggregate_Fields = {
  __typename?: "secondary_school_types_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Secondary_School_Types_Max_Fields>;
  min?: Maybe<Secondary_School_Types_Min_Fields>;
};

/** aggregate fields of "secondary_school_types" */
export type Secondary_School_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Secondary_School_Types_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "secondary_school_types". All fields are combined with a logical 'AND'. */
export type Secondary_School_Types_Bool_Exp = {
  _and?: Maybe<Array<Secondary_School_Types_Bool_Exp>>;
  _not?: Maybe<Secondary_School_Types_Bool_Exp>;
  _or?: Maybe<Array<Secondary_School_Types_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
  label?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "secondary_school_types" */
export enum Secondary_School_Types_Constraint {
  /** unique or primary key constraint */
  SecondarySchoolTypesPkey = "secondary_school_types_pkey",
}

export enum Secondary_School_Types_Enum {
  /** Boarding School */
  BoardingSchool = "boarding_school",
  /** Language Immersion */
  LanguageImmersion = "language_immersion",
  /** Magnet Program */
  MagnetProgram = "magnet_program",
  /** Montessori */
  Montessori = "montessori",
  /** Parochial */
  Parochial = "parochial",
  /** Religious Education */
  ReligiousEducation = "religious_education",
  /** Special Education */
  SpecialEducation = "special_education",
  /** Virtual/Online */
  VirtualOnline = "virtual_online",
  /** Waldorf */
  Waldorf = "waldorf",
}

/** Boolean expression to compare columns of type "secondary_school_types_enum". All fields are combined with logical 'AND'. */
export type Secondary_School_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Secondary_School_Types_Enum>;
  _in?: Maybe<Array<Secondary_School_Types_Enum>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<Secondary_School_Types_Enum>;
  _nin?: Maybe<Array<Secondary_School_Types_Enum>>;
};

/** input type for inserting data into table "secondary_school_types" */
export type Secondary_School_Types_Insert_Input = {
  id?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Secondary_School_Types_Max_Fields = {
  __typename?: "secondary_school_types_max_fields";
  id?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Secondary_School_Types_Min_Fields = {
  __typename?: "secondary_school_types_min_fields";
  id?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "secondary_school_types" */
export type Secondary_School_Types_Mutation_Response = {
  __typename?: "secondary_school_types_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Secondary_School_Types>;
};

/** input type for inserting object relation for remote table "secondary_school_types" */
export type Secondary_School_Types_Obj_Rel_Insert_Input = {
  data: Secondary_School_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Secondary_School_Types_On_Conflict>;
};

/** on_conflict condition type for table "secondary_school_types" */
export type Secondary_School_Types_On_Conflict = {
  constraint: Secondary_School_Types_Constraint;
  update_columns: Array<Secondary_School_Types_Update_Column>;
  where?: Maybe<Secondary_School_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "secondary_school_types". */
export type Secondary_School_Types_Order_By = {
  id?: Maybe<Order_By>;
  label?: Maybe<Order_By>;
};

/** primary key columns input for table: secondary_school_types */
export type Secondary_School_Types_Pk_Columns_Input = {
  id: Scalars["String"];
};

/** select columns of table "secondary_school_types" */
export enum Secondary_School_Types_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Label = "label",
}

/** input type for updating data in table "secondary_school_types" */
export type Secondary_School_Types_Set_Input = {
  id?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
};

/** update columns of table "secondary_school_types" */
export enum Secondary_School_Types_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Label = "label",
}

export type Subscription_Root = {
  __typename?: "subscription_root";
  /** An array relationship */
  agreement_questions: Array<Agreement_Questions>;
  /** An aggregate relationship */
  agreement_questions_aggregate: Agreement_Questions_Aggregate;
  /** fetch data from the table: "agreement_questions" using primary key columns */
  agreement_questions_by_pk?: Maybe<Agreement_Questions>;
  /** An array relationship */
  class_rooms: Array<Class_Rooms>;
  /** An aggregate relationship */
  class_rooms_aggregate: Class_Rooms_Aggregate;
  /** fetch data from the table: "class_rooms" using primary key columns */
  class_rooms_by_pk?: Maybe<Class_Rooms>;
  /** An array relationship */
  field_trip_approvals: Array<Field_Trip_Approvals>;
  /** An aggregate relationship */
  field_trip_approvals_aggregate: Field_Trip_Approvals_Aggregate;
  /** fetch data from the table: "field_trip_approvals" using primary key columns */
  field_trip_approvals_by_pk?: Maybe<Field_Trip_Approvals>;
  /** An array relationship */
  field_trip_location_reviews: Array<Field_Trip_Location_Reviews>;
  /** An aggregate relationship */
  field_trip_location_reviews_aggregate: Field_Trip_Location_Reviews_Aggregate;
  /** fetch data from the table: "field_trip_location_reviews" using primary key columns */
  field_trip_location_reviews_by_pk?: Maybe<Field_Trip_Location_Reviews>;
  /** An array relationship */
  field_trip_locations: Array<Field_Trip_Locations>;
  /** An aggregate relationship */
  field_trip_locations_aggregate: Field_Trip_Locations_Aggregate;
  /** fetch data from the table: "field_trip_locations" using primary key columns */
  field_trip_locations_by_pk?: Maybe<Field_Trip_Locations>;
  /** An array relationship */
  field_trip_students: Array<Field_Trip_Students>;
  /** An aggregate relationship */
  field_trip_students_aggregate: Field_Trip_Students_Aggregate;
  /** fetch data from the table: "field_trip_students" using primary key columns */
  field_trip_students_by_pk?: Maybe<Field_Trip_Students>;
  /** An array relationship */
  field_trips: Array<Field_Trips>;
  /** An aggregate relationship */
  field_trips_aggregate: Field_Trips_Aggregate;
  /** fetch data from the table: "field_trips" using primary key columns */
  field_trips_by_pk?: Maybe<Field_Trips>;
  /** fetch data from the table: "locations" */
  locations: Array<Locations>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk?: Maybe<Locations>;
  /** fetch data from the table: "me" */
  me: Array<Me>;
  /** fetch aggregated fields from the table: "me" */
  me_aggregate: Me_Aggregate;
  /** fetch data from the table: "my_field_trip_data" */
  my_field_trip_data: Array<My_Field_Trip_Data>;
  /** fetch aggregated fields from the table: "my_field_trip_data" */
  my_field_trip_data_aggregate: My_Field_Trip_Data_Aggregate;
  /** fetch data from the table: "product_features" */
  product_features: Array<Product_Features>;
  /** fetch aggregated fields from the table: "product_features" */
  product_features_aggregate: Product_Features_Aggregate;
  /** fetch data from the table: "product_features" using primary key columns */
  product_features_by_pk?: Maybe<Product_Features>;
  /** An array relationship */
  reflect_questions: Array<Reflect_Questions>;
  /** An aggregate relationship */
  reflect_questions_aggregate: Reflect_Questions_Aggregate;
  /** fetch data from the table: "reflect_questions" using primary key columns */
  reflect_questions_by_pk?: Maybe<Reflect_Questions>;
  /** An array relationship */
  school_product_features: Array<School_Product_Features>;
  /** An aggregate relationship */
  school_product_features_aggregate: School_Product_Features_Aggregate;
  /** fetch data from the table: "school_product_features" using primary key columns */
  school_product_features_by_pk?: Maybe<School_Product_Features>;
  /** fetch data from the table: "school_types" */
  school_types: Array<School_Types>;
  /** fetch aggregated fields from the table: "school_types" */
  school_types_aggregate: School_Types_Aggregate;
  /** fetch data from the table: "school_types" using primary key columns */
  school_types_by_pk?: Maybe<School_Types>;
  /** fetch data from the table: "schools" */
  schools: Array<Schools>;
  /** fetch aggregated fields from the table: "schools" */
  schools_aggregate: Schools_Aggregate;
  /** fetch data from the table: "schools" using primary key columns */
  schools_by_pk?: Maybe<Schools>;
  /** fetch data from the table: "secondary_school_types" */
  secondary_school_types: Array<Secondary_School_Types>;
  /** fetch aggregated fields from the table: "secondary_school_types" */
  secondary_school_types_aggregate: Secondary_School_Types_Aggregate;
  /** fetch data from the table: "secondary_school_types" using primary key columns */
  secondary_school_types_by_pk?: Maybe<Secondary_School_Types>;
  /** An array relationship */
  user_location_ratings: Array<User_Location_Ratings>;
  /** An aggregate relationship */
  user_location_ratings_aggregate: User_Location_Ratings_Aggregate;
  /** fetch data from the table: "user_location_ratings" using primary key columns */
  user_location_ratings_by_pk?: Maybe<User_Location_Ratings>;
  /** fetch data from the table: "user_names" */
  user_names: Array<User_Names>;
  /** fetch aggregated fields from the table: "user_names" */
  user_names_aggregate: User_Names_Aggregate;
  /** fetch data from the table: "user_relationship" */
  user_relationship: Array<User_Relationship>;
  /** fetch aggregated fields from the table: "user_relationship" */
  user_relationship_aggregate: User_Relationship_Aggregate;
  /** fetch data from the table: "user_relationship" using primary key columns */
  user_relationship_by_pk?: Maybe<User_Relationship>;
  /** fetch data from the table: "user_relationship_types" */
  user_relationship_types: Array<User_Relationship_Types>;
  /** fetch aggregated fields from the table: "user_relationship_types" */
  user_relationship_types_aggregate: User_Relationship_Types_Aggregate;
  /** fetch data from the table: "user_relationship_types" using primary key columns */
  user_relationship_types_by_pk?: Maybe<User_Relationship_Types>;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<User_Roles>;
  /** fetch aggregated fields from the table: "user_roles" */
  user_roles_aggregate: User_Roles_Aggregate;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** fetch data from the table: "user_status" */
  user_status: Array<User_Status>;
  /** fetch aggregated fields from the table: "user_status" */
  user_status_aggregate: User_Status_Aggregate;
  /** fetch data from the table: "user_status" using primary key columns */
  user_status_by_pk?: Maybe<User_Status>;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** An array relationship */
  virtual_trip_images: Array<Virtual_Trip_Images>;
  /** An aggregate relationship */
  virtual_trip_images_aggregate: Virtual_Trip_Images_Aggregate;
  /** fetch data from the table: "virtual_trip_images" using primary key columns */
  virtual_trip_images_by_pk?: Maybe<Virtual_Trip_Images>;
  /** fetch data from the table: "virtual_trip_plans" */
  virtual_trip_plans: Array<Virtual_Trip_Plans>;
  /** fetch aggregated fields from the table: "virtual_trip_plans" */
  virtual_trip_plans_aggregate: Virtual_Trip_Plans_Aggregate;
  /** fetch data from the table: "virtual_trip_plans" using primary key columns */
  virtual_trip_plans_by_pk?: Maybe<Virtual_Trip_Plans>;
  /** fetch data from the table: "virtual_trip_proposals" */
  virtual_trip_proposals: Array<Virtual_Trip_Proposals>;
  /** fetch aggregated fields from the table: "virtual_trip_proposals" */
  virtual_trip_proposals_aggregate: Virtual_Trip_Proposals_Aggregate;
  /** fetch data from the table: "virtual_trip_proposals" using primary key columns */
  virtual_trip_proposals_by_pk?: Maybe<Virtual_Trip_Proposals>;
  /** fetch data from the table: "virtual_trip_proposals_topic" */
  virtual_trip_proposals_topic: Array<Virtual_Trip_Proposals_Topic>;
  /** fetch aggregated fields from the table: "virtual_trip_proposals_topic" */
  virtual_trip_proposals_topic_aggregate: Virtual_Trip_Proposals_Topic_Aggregate;
  /** An array relationship */
  virtual_trip_schools: Array<Virtual_Trip_Schools>;
  /** An aggregate relationship */
  virtual_trip_schools_aggregate: Virtual_Trip_Schools_Aggregate;
  /** fetch data from the table: "virtual_trip_schools" using primary key columns */
  virtual_trip_schools_by_pk?: Maybe<Virtual_Trip_Schools>;
  /** fetch data from the table: "virtual_trips" */
  virtual_trips: Array<Virtual_Trips>;
  /** fetch aggregated fields from the table: "virtual_trips" */
  virtual_trips_aggregate: Virtual_Trips_Aggregate;
  /** fetch data from the table: "virtual_trips" using primary key columns */
  virtual_trips_by_pk?: Maybe<Virtual_Trips>;
};

export type Subscription_RootAgreement_QuestionsArgs = {
  distinct_on?: Maybe<Array<Agreement_Questions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Agreement_Questions_Order_By>>;
  where?: Maybe<Agreement_Questions_Bool_Exp>;
};

export type Subscription_RootAgreement_Questions_AggregateArgs = {
  distinct_on?: Maybe<Array<Agreement_Questions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Agreement_Questions_Order_By>>;
  where?: Maybe<Agreement_Questions_Bool_Exp>;
};

export type Subscription_RootAgreement_Questions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootClass_RoomsArgs = {
  distinct_on?: Maybe<Array<Class_Rooms_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Class_Rooms_Order_By>>;
  where?: Maybe<Class_Rooms_Bool_Exp>;
};

export type Subscription_RootClass_Rooms_AggregateArgs = {
  distinct_on?: Maybe<Array<Class_Rooms_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Class_Rooms_Order_By>>;
  where?: Maybe<Class_Rooms_Bool_Exp>;
};

export type Subscription_RootClass_Rooms_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootField_Trip_ApprovalsArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Approvals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Approvals_Order_By>>;
  where?: Maybe<Field_Trip_Approvals_Bool_Exp>;
};

export type Subscription_RootField_Trip_Approvals_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Approvals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Approvals_Order_By>>;
  where?: Maybe<Field_Trip_Approvals_Bool_Exp>;
};

export type Subscription_RootField_Trip_Approvals_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootField_Trip_Location_ReviewsArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Location_Reviews_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Location_Reviews_Order_By>>;
  where?: Maybe<Field_Trip_Location_Reviews_Bool_Exp>;
};

export type Subscription_RootField_Trip_Location_Reviews_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Location_Reviews_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Location_Reviews_Order_By>>;
  where?: Maybe<Field_Trip_Location_Reviews_Bool_Exp>;
};

export type Subscription_RootField_Trip_Location_Reviews_By_PkArgs = {
  id: Scalars["bigint"];
};

export type Subscription_RootField_Trip_LocationsArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Locations_Order_By>>;
  where?: Maybe<Field_Trip_Locations_Bool_Exp>;
};

export type Subscription_RootField_Trip_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Locations_Order_By>>;
  where?: Maybe<Field_Trip_Locations_Bool_Exp>;
};

export type Subscription_RootField_Trip_Locations_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootField_Trip_StudentsArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Students_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Students_Order_By>>;
  where?: Maybe<Field_Trip_Students_Bool_Exp>;
};

export type Subscription_RootField_Trip_Students_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Trip_Students_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trip_Students_Order_By>>;
  where?: Maybe<Field_Trip_Students_Bool_Exp>;
};

export type Subscription_RootField_Trip_Students_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootField_TripsArgs = {
  distinct_on?: Maybe<Array<Field_Trips_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trips_Order_By>>;
  where?: Maybe<Field_Trips_Bool_Exp>;
};

export type Subscription_RootField_Trips_AggregateArgs = {
  distinct_on?: Maybe<Array<Field_Trips_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Field_Trips_Order_By>>;
  where?: Maybe<Field_Trips_Bool_Exp>;
};

export type Subscription_RootField_Trips_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootLocationsArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};

export type Subscription_RootLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Locations_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Locations_Order_By>>;
  where?: Maybe<Locations_Bool_Exp>;
};

export type Subscription_RootLocations_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootMeArgs = {
  distinct_on?: Maybe<Array<Me_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Me_Order_By>>;
  where?: Maybe<Me_Bool_Exp>;
};

export type Subscription_RootMe_AggregateArgs = {
  distinct_on?: Maybe<Array<Me_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Me_Order_By>>;
  where?: Maybe<Me_Bool_Exp>;
};

export type Subscription_RootMy_Field_Trip_DataArgs = {
  distinct_on?: Maybe<Array<My_Field_Trip_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<My_Field_Trip_Data_Order_By>>;
  where?: Maybe<My_Field_Trip_Data_Bool_Exp>;
};

export type Subscription_RootMy_Field_Trip_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<My_Field_Trip_Data_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<My_Field_Trip_Data_Order_By>>;
  where?: Maybe<My_Field_Trip_Data_Bool_Exp>;
};

export type Subscription_RootProduct_FeaturesArgs = {
  distinct_on?: Maybe<Array<Product_Features_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Features_Order_By>>;
  where?: Maybe<Product_Features_Bool_Exp>;
};

export type Subscription_RootProduct_Features_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Features_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Features_Order_By>>;
  where?: Maybe<Product_Features_Bool_Exp>;
};

export type Subscription_RootProduct_Features_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootReflect_QuestionsArgs = {
  distinct_on?: Maybe<Array<Reflect_Questions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Reflect_Questions_Order_By>>;
  where?: Maybe<Reflect_Questions_Bool_Exp>;
};

export type Subscription_RootReflect_Questions_AggregateArgs = {
  distinct_on?: Maybe<Array<Reflect_Questions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Reflect_Questions_Order_By>>;
  where?: Maybe<Reflect_Questions_Bool_Exp>;
};

export type Subscription_RootReflect_Questions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSchool_Product_FeaturesArgs = {
  distinct_on?: Maybe<Array<School_Product_Features_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<School_Product_Features_Order_By>>;
  where?: Maybe<School_Product_Features_Bool_Exp>;
};

export type Subscription_RootSchool_Product_Features_AggregateArgs = {
  distinct_on?: Maybe<Array<School_Product_Features_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<School_Product_Features_Order_By>>;
  where?: Maybe<School_Product_Features_Bool_Exp>;
};

export type Subscription_RootSchool_Product_Features_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootSchool_TypesArgs = {
  distinct_on?: Maybe<Array<School_Types_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<School_Types_Order_By>>;
  where?: Maybe<School_Types_Bool_Exp>;
};

export type Subscription_RootSchool_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<School_Types_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<School_Types_Order_By>>;
  where?: Maybe<School_Types_Bool_Exp>;
};

export type Subscription_RootSchool_Types_By_PkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootSchoolsArgs = {
  distinct_on?: Maybe<Array<Schools_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Schools_Order_By>>;
  where?: Maybe<Schools_Bool_Exp>;
};

export type Subscription_RootSchools_AggregateArgs = {
  distinct_on?: Maybe<Array<Schools_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Schools_Order_By>>;
  where?: Maybe<Schools_Bool_Exp>;
};

export type Subscription_RootSchools_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSecondary_School_TypesArgs = {
  distinct_on?: Maybe<Array<Secondary_School_Types_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Secondary_School_Types_Order_By>>;
  where?: Maybe<Secondary_School_Types_Bool_Exp>;
};

export type Subscription_RootSecondary_School_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Secondary_School_Types_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Secondary_School_Types_Order_By>>;
  where?: Maybe<Secondary_School_Types_Bool_Exp>;
};

export type Subscription_RootSecondary_School_Types_By_PkArgs = {
  id: Scalars["String"];
};

export type Subscription_RootUser_Location_RatingsArgs = {
  distinct_on?: Maybe<Array<User_Location_Ratings_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Location_Ratings_Order_By>>;
  where?: Maybe<User_Location_Ratings_Bool_Exp>;
};

export type Subscription_RootUser_Location_Ratings_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Location_Ratings_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Location_Ratings_Order_By>>;
  where?: Maybe<User_Location_Ratings_Bool_Exp>;
};

export type Subscription_RootUser_Location_Ratings_By_PkArgs = {
  location_id: Scalars["uuid"];
  user_id: Scalars["uuid"];
};

export type Subscription_RootUser_NamesArgs = {
  distinct_on?: Maybe<Array<User_Names_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Names_Order_By>>;
  where?: Maybe<User_Names_Bool_Exp>;
};

export type Subscription_RootUser_Names_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Names_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Names_Order_By>>;
  where?: Maybe<User_Names_Bool_Exp>;
};

export type Subscription_RootUser_RelationshipArgs = {
  distinct_on?: Maybe<Array<User_Relationship_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Relationship_Order_By>>;
  where?: Maybe<User_Relationship_Bool_Exp>;
};

export type Subscription_RootUser_Relationship_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Relationship_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Relationship_Order_By>>;
  where?: Maybe<User_Relationship_Bool_Exp>;
};

export type Subscription_RootUser_Relationship_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootUser_Relationship_TypesArgs = {
  distinct_on?: Maybe<Array<User_Relationship_Types_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Relationship_Types_Order_By>>;
  where?: Maybe<User_Relationship_Types_Bool_Exp>;
};

export type Subscription_RootUser_Relationship_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Relationship_Types_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Relationship_Types_Order_By>>;
  where?: Maybe<User_Relationship_Types_Bool_Exp>;
};

export type Subscription_RootUser_Relationship_Types_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootUser_RolesArgs = {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
};

export type Subscription_RootUser_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Roles_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Roles_Order_By>>;
  where?: Maybe<User_Roles_Bool_Exp>;
};

export type Subscription_RootUser_Roles_By_PkArgs = {
  value: Scalars["String"];
};

export type Subscription_RootUser_StatusArgs = {
  distinct_on?: Maybe<Array<User_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Status_Order_By>>;
  where?: Maybe<User_Status_Bool_Exp>;
};

export type Subscription_RootUser_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Status_Order_By>>;
  where?: Maybe<User_Status_Bool_Exp>;
};

export type Subscription_RootUser_Status_By_PkArgs = {
  value: Scalars["String"];
};

export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootVirtual_Trip_ImagesArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Images_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Images_Order_By>>;
  where?: Maybe<Virtual_Trip_Images_Bool_Exp>;
};

export type Subscription_RootVirtual_Trip_Images_AggregateArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Images_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Images_Order_By>>;
  where?: Maybe<Virtual_Trip_Images_Bool_Exp>;
};

export type Subscription_RootVirtual_Trip_Images_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootVirtual_Trip_PlansArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Plans_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Plans_Order_By>>;
  where?: Maybe<Virtual_Trip_Plans_Bool_Exp>;
};

export type Subscription_RootVirtual_Trip_Plans_AggregateArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Plans_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Plans_Order_By>>;
  where?: Maybe<Virtual_Trip_Plans_Bool_Exp>;
};

export type Subscription_RootVirtual_Trip_Plans_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootVirtual_Trip_ProposalsArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Proposals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Proposals_Order_By>>;
  where?: Maybe<Virtual_Trip_Proposals_Bool_Exp>;
};

export type Subscription_RootVirtual_Trip_Proposals_AggregateArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Proposals_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Proposals_Order_By>>;
  where?: Maybe<Virtual_Trip_Proposals_Bool_Exp>;
};

export type Subscription_RootVirtual_Trip_Proposals_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootVirtual_Trip_Proposals_TopicArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Proposals_Topic_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Proposals_Topic_Order_By>>;
  where?: Maybe<Virtual_Trip_Proposals_Topic_Bool_Exp>;
};

export type Subscription_RootVirtual_Trip_Proposals_Topic_AggregateArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Proposals_Topic_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Proposals_Topic_Order_By>>;
  where?: Maybe<Virtual_Trip_Proposals_Topic_Bool_Exp>;
};

export type Subscription_RootVirtual_Trip_SchoolsArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Schools_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Schools_Order_By>>;
  where?: Maybe<Virtual_Trip_Schools_Bool_Exp>;
};

export type Subscription_RootVirtual_Trip_Schools_AggregateArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Schools_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Schools_Order_By>>;
  where?: Maybe<Virtual_Trip_Schools_Bool_Exp>;
};

export type Subscription_RootVirtual_Trip_Schools_By_PkArgs = {
  school_id: Scalars["uuid"];
  virtual_trip_id: Scalars["Int"];
};

export type Subscription_RootVirtual_TripsArgs = {
  distinct_on?: Maybe<Array<Virtual_Trips_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trips_Order_By>>;
  where?: Maybe<Virtual_Trips_Bool_Exp>;
};

export type Subscription_RootVirtual_Trips_AggregateArgs = {
  distinct_on?: Maybe<Array<Virtual_Trips_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trips_Order_By>>;
  where?: Maybe<Virtual_Trips_Bool_Exp>;
};

export type Subscription_RootVirtual_Trips_By_PkArgs = {
  id: Scalars["Int"];
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars["timestamp"]>;
  _gt?: Maybe<Scalars["timestamp"]>;
  _gte?: Maybe<Scalars["timestamp"]>;
  _in?: Maybe<Array<Scalars["timestamp"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timestamp"]>;
  _lte?: Maybe<Scalars["timestamp"]>;
  _neq?: Maybe<Scalars["timestamp"]>;
  _nin?: Maybe<Array<Scalars["timestamp"]>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars["timestamptz"]>;
  _gt?: Maybe<Scalars["timestamptz"]>;
  _gte?: Maybe<Scalars["timestamptz"]>;
  _in?: Maybe<Array<Scalars["timestamptz"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timestamptz"]>;
  _lte?: Maybe<Scalars["timestamptz"]>;
  _neq?: Maybe<Scalars["timestamptz"]>;
  _nin?: Maybe<Array<Scalars["timestamptz"]>>;
};

/** columns and relationships of "user_location_ratings" */
export type User_Location_Ratings = {
  __typename?: "user_location_ratings";
  location_id: Scalars["uuid"];
  rating: Scalars["Int"];
  user_id: Scalars["uuid"];
};

/** aggregated selection of "user_location_ratings" */
export type User_Location_Ratings_Aggregate = {
  __typename?: "user_location_ratings_aggregate";
  aggregate?: Maybe<User_Location_Ratings_Aggregate_Fields>;
  nodes: Array<User_Location_Ratings>;
};

/** aggregate fields of "user_location_ratings" */
export type User_Location_Ratings_Aggregate_Fields = {
  __typename?: "user_location_ratings_aggregate_fields";
  avg?: Maybe<User_Location_Ratings_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<User_Location_Ratings_Max_Fields>;
  min?: Maybe<User_Location_Ratings_Min_Fields>;
  stddev?: Maybe<User_Location_Ratings_Stddev_Fields>;
  stddev_pop?: Maybe<User_Location_Ratings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Location_Ratings_Stddev_Samp_Fields>;
  sum?: Maybe<User_Location_Ratings_Sum_Fields>;
  var_pop?: Maybe<User_Location_Ratings_Var_Pop_Fields>;
  var_samp?: Maybe<User_Location_Ratings_Var_Samp_Fields>;
  variance?: Maybe<User_Location_Ratings_Variance_Fields>;
};

/** aggregate fields of "user_location_ratings" */
export type User_Location_Ratings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Location_Ratings_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "user_location_ratings" */
export type User_Location_Ratings_Aggregate_Order_By = {
  avg?: Maybe<User_Location_Ratings_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<User_Location_Ratings_Max_Order_By>;
  min?: Maybe<User_Location_Ratings_Min_Order_By>;
  stddev?: Maybe<User_Location_Ratings_Stddev_Order_By>;
  stddev_pop?: Maybe<User_Location_Ratings_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<User_Location_Ratings_Stddev_Samp_Order_By>;
  sum?: Maybe<User_Location_Ratings_Sum_Order_By>;
  var_pop?: Maybe<User_Location_Ratings_Var_Pop_Order_By>;
  var_samp?: Maybe<User_Location_Ratings_Var_Samp_Order_By>;
  variance?: Maybe<User_Location_Ratings_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_location_ratings" */
export type User_Location_Ratings_Arr_Rel_Insert_Input = {
  data: Array<User_Location_Ratings_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<User_Location_Ratings_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Location_Ratings_Avg_Fields = {
  __typename?: "user_location_ratings_avg_fields";
  rating?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "user_location_ratings" */
export type User_Location_Ratings_Avg_Order_By = {
  rating?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_location_ratings". All fields are combined with a logical 'AND'. */
export type User_Location_Ratings_Bool_Exp = {
  _and?: Maybe<Array<User_Location_Ratings_Bool_Exp>>;
  _not?: Maybe<User_Location_Ratings_Bool_Exp>;
  _or?: Maybe<Array<User_Location_Ratings_Bool_Exp>>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  rating?: Maybe<Int_Comparison_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_location_ratings" */
export enum User_Location_Ratings_Constraint {
  /** unique or primary key constraint */
  UserLocationRatingPkey = "user_location_rating_pkey",
}

/** input type for incrementing numeric columns in table "user_location_ratings" */
export type User_Location_Ratings_Inc_Input = {
  rating?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "user_location_ratings" */
export type User_Location_Ratings_Insert_Input = {
  location_id?: Maybe<Scalars["uuid"]>;
  rating?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type User_Location_Ratings_Max_Fields = {
  __typename?: "user_location_ratings_max_fields";
  location_id?: Maybe<Scalars["uuid"]>;
  rating?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "user_location_ratings" */
export type User_Location_Ratings_Max_Order_By = {
  location_id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Location_Ratings_Min_Fields = {
  __typename?: "user_location_ratings_min_fields";
  location_id?: Maybe<Scalars["uuid"]>;
  rating?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "user_location_ratings" */
export type User_Location_Ratings_Min_Order_By = {
  location_id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_location_ratings" */
export type User_Location_Ratings_Mutation_Response = {
  __typename?: "user_location_ratings_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<User_Location_Ratings>;
};

/** on_conflict condition type for table "user_location_ratings" */
export type User_Location_Ratings_On_Conflict = {
  constraint: User_Location_Ratings_Constraint;
  update_columns: Array<User_Location_Ratings_Update_Column>;
  where?: Maybe<User_Location_Ratings_Bool_Exp>;
};

/** Ordering options when selecting data from "user_location_ratings". */
export type User_Location_Ratings_Order_By = {
  location_id?: Maybe<Order_By>;
  rating?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: user_location_ratings */
export type User_Location_Ratings_Pk_Columns_Input = {
  location_id: Scalars["uuid"];
  user_id: Scalars["uuid"];
};

/** select columns of table "user_location_ratings" */
export enum User_Location_Ratings_Select_Column {
  /** column name */
  LocationId = "location_id",
  /** column name */
  Rating = "rating",
  /** column name */
  UserId = "user_id",
}

/** input type for updating data in table "user_location_ratings" */
export type User_Location_Ratings_Set_Input = {
  location_id?: Maybe<Scalars["uuid"]>;
  rating?: Maybe<Scalars["Int"]>;
  user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type User_Location_Ratings_Stddev_Fields = {
  __typename?: "user_location_ratings_stddev_fields";
  rating?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "user_location_ratings" */
export type User_Location_Ratings_Stddev_Order_By = {
  rating?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Location_Ratings_Stddev_Pop_Fields = {
  __typename?: "user_location_ratings_stddev_pop_fields";
  rating?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "user_location_ratings" */
export type User_Location_Ratings_Stddev_Pop_Order_By = {
  rating?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Location_Ratings_Stddev_Samp_Fields = {
  __typename?: "user_location_ratings_stddev_samp_fields";
  rating?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "user_location_ratings" */
export type User_Location_Ratings_Stddev_Samp_Order_By = {
  rating?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type User_Location_Ratings_Sum_Fields = {
  __typename?: "user_location_ratings_sum_fields";
  rating?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "user_location_ratings" */
export type User_Location_Ratings_Sum_Order_By = {
  rating?: Maybe<Order_By>;
};

/** update columns of table "user_location_ratings" */
export enum User_Location_Ratings_Update_Column {
  /** column name */
  LocationId = "location_id",
  /** column name */
  Rating = "rating",
  /** column name */
  UserId = "user_id",
}

/** aggregate var_pop on columns */
export type User_Location_Ratings_Var_Pop_Fields = {
  __typename?: "user_location_ratings_var_pop_fields";
  rating?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "user_location_ratings" */
export type User_Location_Ratings_Var_Pop_Order_By = {
  rating?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Location_Ratings_Var_Samp_Fields = {
  __typename?: "user_location_ratings_var_samp_fields";
  rating?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "user_location_ratings" */
export type User_Location_Ratings_Var_Samp_Order_By = {
  rating?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Location_Ratings_Variance_Fields = {
  __typename?: "user_location_ratings_variance_fields";
  rating?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "user_location_ratings" */
export type User_Location_Ratings_Variance_Order_By = {
  rating?: Maybe<Order_By>;
};

/** columns and relationships of "user_names" */
export type User_Names = {
  __typename?: "user_names";
  username?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "user_names" */
export type User_Names_Aggregate = {
  __typename?: "user_names_aggregate";
  aggregate?: Maybe<User_Names_Aggregate_Fields>;
  nodes: Array<User_Names>;
};

/** aggregate fields of "user_names" */
export type User_Names_Aggregate_Fields = {
  __typename?: "user_names_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<User_Names_Max_Fields>;
  min?: Maybe<User_Names_Min_Fields>;
};

/** aggregate fields of "user_names" */
export type User_Names_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Names_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "user_names". All fields are combined with a logical 'AND'. */
export type User_Names_Bool_Exp = {
  _and?: Maybe<Array<User_Names_Bool_Exp>>;
  _not?: Maybe<User_Names_Bool_Exp>;
  _or?: Maybe<Array<User_Names_Bool_Exp>>;
  username?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "user_names" */
export type User_Names_Insert_Input = {
  username?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type User_Names_Max_Fields = {
  __typename?: "user_names_max_fields";
  username?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type User_Names_Min_Fields = {
  __typename?: "user_names_min_fields";
  username?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "user_names" */
export type User_Names_Mutation_Response = {
  __typename?: "user_names_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<User_Names>;
};

/** Ordering options when selecting data from "user_names". */
export type User_Names_Order_By = {
  username?: Maybe<Order_By>;
};

/** select columns of table "user_names" */
export enum User_Names_Select_Column {
  /** column name */
  Username = "username",
}

/** input type for updating data in table "user_names" */
export type User_Names_Set_Input = {
  username?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "user_relationship" */
export type User_Relationship = {
  __typename?: "user_relationship";
  /** An object relationship */
  from_user: Users;
  from_user_id: Scalars["uuid"];
  id: Scalars["uuid"];
  relationship_type: Scalars["Int"];
  /** An object relationship */
  to_user: Users;
  to_user_id: Scalars["uuid"];
  /** An object relationship */
  user_relationship_type: User_Relationship_Types;
};

/** aggregated selection of "user_relationship" */
export type User_Relationship_Aggregate = {
  __typename?: "user_relationship_aggregate";
  aggregate?: Maybe<User_Relationship_Aggregate_Fields>;
  nodes: Array<User_Relationship>;
};

/** aggregate fields of "user_relationship" */
export type User_Relationship_Aggregate_Fields = {
  __typename?: "user_relationship_aggregate_fields";
  avg?: Maybe<User_Relationship_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<User_Relationship_Max_Fields>;
  min?: Maybe<User_Relationship_Min_Fields>;
  stddev?: Maybe<User_Relationship_Stddev_Fields>;
  stddev_pop?: Maybe<User_Relationship_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Relationship_Stddev_Samp_Fields>;
  sum?: Maybe<User_Relationship_Sum_Fields>;
  var_pop?: Maybe<User_Relationship_Var_Pop_Fields>;
  var_samp?: Maybe<User_Relationship_Var_Samp_Fields>;
  variance?: Maybe<User_Relationship_Variance_Fields>;
};

/** aggregate fields of "user_relationship" */
export type User_Relationship_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Relationship_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "user_relationship" */
export type User_Relationship_Aggregate_Order_By = {
  avg?: Maybe<User_Relationship_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<User_Relationship_Max_Order_By>;
  min?: Maybe<User_Relationship_Min_Order_By>;
  stddev?: Maybe<User_Relationship_Stddev_Order_By>;
  stddev_pop?: Maybe<User_Relationship_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<User_Relationship_Stddev_Samp_Order_By>;
  sum?: Maybe<User_Relationship_Sum_Order_By>;
  var_pop?: Maybe<User_Relationship_Var_Pop_Order_By>;
  var_samp?: Maybe<User_Relationship_Var_Samp_Order_By>;
  variance?: Maybe<User_Relationship_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_relationship" */
export type User_Relationship_Arr_Rel_Insert_Input = {
  data: Array<User_Relationship_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<User_Relationship_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Relationship_Avg_Fields = {
  __typename?: "user_relationship_avg_fields";
  relationship_type?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "user_relationship" */
export type User_Relationship_Avg_Order_By = {
  relationship_type?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_relationship". All fields are combined with a logical 'AND'. */
export type User_Relationship_Bool_Exp = {
  _and?: Maybe<Array<User_Relationship_Bool_Exp>>;
  _not?: Maybe<User_Relationship_Bool_Exp>;
  _or?: Maybe<Array<User_Relationship_Bool_Exp>>;
  from_user?: Maybe<Users_Bool_Exp>;
  from_user_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  relationship_type?: Maybe<Int_Comparison_Exp>;
  to_user?: Maybe<Users_Bool_Exp>;
  to_user_id?: Maybe<Uuid_Comparison_Exp>;
  user_relationship_type?: Maybe<User_Relationship_Types_Bool_Exp>;
};

/** unique or primary key constraints on table "user_relationship" */
export enum User_Relationship_Constraint {
  /** unique or primary key constraint */
  UserRelationshipFromUserIdToUserIdKey = "user_relationship_from_user_id_to_user_id_key",
  /** unique or primary key constraint */
  UserRelationshipPkey = "user_relationship_pkey",
}

/** input type for incrementing numeric columns in table "user_relationship" */
export type User_Relationship_Inc_Input = {
  relationship_type?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "user_relationship" */
export type User_Relationship_Insert_Input = {
  from_user?: Maybe<Users_Obj_Rel_Insert_Input>;
  from_user_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  relationship_type?: Maybe<Scalars["Int"]>;
  to_user?: Maybe<Users_Obj_Rel_Insert_Input>;
  to_user_id?: Maybe<Scalars["uuid"]>;
  user_relationship_type?: Maybe<User_Relationship_Types_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Relationship_Max_Fields = {
  __typename?: "user_relationship_max_fields";
  from_user_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  relationship_type?: Maybe<Scalars["Int"]>;
  to_user_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "user_relationship" */
export type User_Relationship_Max_Order_By = {
  from_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  relationship_type?: Maybe<Order_By>;
  to_user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Relationship_Min_Fields = {
  __typename?: "user_relationship_min_fields";
  from_user_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  relationship_type?: Maybe<Scalars["Int"]>;
  to_user_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "user_relationship" */
export type User_Relationship_Min_Order_By = {
  from_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  relationship_type?: Maybe<Order_By>;
  to_user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "user_relationship" */
export type User_Relationship_Mutation_Response = {
  __typename?: "user_relationship_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<User_Relationship>;
};

/** on_conflict condition type for table "user_relationship" */
export type User_Relationship_On_Conflict = {
  constraint: User_Relationship_Constraint;
  update_columns: Array<User_Relationship_Update_Column>;
  where?: Maybe<User_Relationship_Bool_Exp>;
};

/** Ordering options when selecting data from "user_relationship". */
export type User_Relationship_Order_By = {
  from_user?: Maybe<Users_Order_By>;
  from_user_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  relationship_type?: Maybe<Order_By>;
  to_user?: Maybe<Users_Order_By>;
  to_user_id?: Maybe<Order_By>;
  user_relationship_type?: Maybe<User_Relationship_Types_Order_By>;
};

/** primary key columns input for table: user_relationship */
export type User_Relationship_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "user_relationship" */
export enum User_Relationship_Select_Column {
  /** column name */
  FromUserId = "from_user_id",
  /** column name */
  Id = "id",
  /** column name */
  RelationshipType = "relationship_type",
  /** column name */
  ToUserId = "to_user_id",
}

/** input type for updating data in table "user_relationship" */
export type User_Relationship_Set_Input = {
  from_user_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  relationship_type?: Maybe<Scalars["Int"]>;
  to_user_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type User_Relationship_Stddev_Fields = {
  __typename?: "user_relationship_stddev_fields";
  relationship_type?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "user_relationship" */
export type User_Relationship_Stddev_Order_By = {
  relationship_type?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Relationship_Stddev_Pop_Fields = {
  __typename?: "user_relationship_stddev_pop_fields";
  relationship_type?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "user_relationship" */
export type User_Relationship_Stddev_Pop_Order_By = {
  relationship_type?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Relationship_Stddev_Samp_Fields = {
  __typename?: "user_relationship_stddev_samp_fields";
  relationship_type?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "user_relationship" */
export type User_Relationship_Stddev_Samp_Order_By = {
  relationship_type?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type User_Relationship_Sum_Fields = {
  __typename?: "user_relationship_sum_fields";
  relationship_type?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "user_relationship" */
export type User_Relationship_Sum_Order_By = {
  relationship_type?: Maybe<Order_By>;
};

/** columns and relationships of "user_relationship_types" */
export type User_Relationship_Types = {
  __typename?: "user_relationship_types";
  backward: Scalars["String"];
  details?: Maybe<Scalars["json"]>;
  forward: Scalars["String"];
  id: Scalars["Int"];
};

/** columns and relationships of "user_relationship_types" */
export type User_Relationship_TypesDetailsArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "user_relationship_types" */
export type User_Relationship_Types_Aggregate = {
  __typename?: "user_relationship_types_aggregate";
  aggregate?: Maybe<User_Relationship_Types_Aggregate_Fields>;
  nodes: Array<User_Relationship_Types>;
};

/** aggregate fields of "user_relationship_types" */
export type User_Relationship_Types_Aggregate_Fields = {
  __typename?: "user_relationship_types_aggregate_fields";
  avg?: Maybe<User_Relationship_Types_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<User_Relationship_Types_Max_Fields>;
  min?: Maybe<User_Relationship_Types_Min_Fields>;
  stddev?: Maybe<User_Relationship_Types_Stddev_Fields>;
  stddev_pop?: Maybe<User_Relationship_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Relationship_Types_Stddev_Samp_Fields>;
  sum?: Maybe<User_Relationship_Types_Sum_Fields>;
  var_pop?: Maybe<User_Relationship_Types_Var_Pop_Fields>;
  var_samp?: Maybe<User_Relationship_Types_Var_Samp_Fields>;
  variance?: Maybe<User_Relationship_Types_Variance_Fields>;
};

/** aggregate fields of "user_relationship_types" */
export type User_Relationship_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Relationship_Types_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type User_Relationship_Types_Avg_Fields = {
  __typename?: "user_relationship_types_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "user_relationship_types". All fields are combined with a logical 'AND'. */
export type User_Relationship_Types_Bool_Exp = {
  _and?: Maybe<Array<User_Relationship_Types_Bool_Exp>>;
  _not?: Maybe<User_Relationship_Types_Bool_Exp>;
  _or?: Maybe<Array<User_Relationship_Types_Bool_Exp>>;
  backward?: Maybe<String_Comparison_Exp>;
  details?: Maybe<Json_Comparison_Exp>;
  forward?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_relationship_types" */
export enum User_Relationship_Types_Constraint {
  /** unique or primary key constraint */
  UserRelationshipTypesPkey = "user_relationship_types_pkey",
}

/** input type for incrementing numeric columns in table "user_relationship_types" */
export type User_Relationship_Types_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "user_relationship_types" */
export type User_Relationship_Types_Insert_Input = {
  backward?: Maybe<Scalars["String"]>;
  details?: Maybe<Scalars["json"]>;
  forward?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type User_Relationship_Types_Max_Fields = {
  __typename?: "user_relationship_types_max_fields";
  backward?: Maybe<Scalars["String"]>;
  forward?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** aggregate min on columns */
export type User_Relationship_Types_Min_Fields = {
  __typename?: "user_relationship_types_min_fields";
  backward?: Maybe<Scalars["String"]>;
  forward?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** response of any mutation on the table "user_relationship_types" */
export type User_Relationship_Types_Mutation_Response = {
  __typename?: "user_relationship_types_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<User_Relationship_Types>;
};

/** input type for inserting object relation for remote table "user_relationship_types" */
export type User_Relationship_Types_Obj_Rel_Insert_Input = {
  data: User_Relationship_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<User_Relationship_Types_On_Conflict>;
};

/** on_conflict condition type for table "user_relationship_types" */
export type User_Relationship_Types_On_Conflict = {
  constraint: User_Relationship_Types_Constraint;
  update_columns: Array<User_Relationship_Types_Update_Column>;
  where?: Maybe<User_Relationship_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "user_relationship_types". */
export type User_Relationship_Types_Order_By = {
  backward?: Maybe<Order_By>;
  details?: Maybe<Order_By>;
  forward?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: user_relationship_types */
export type User_Relationship_Types_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "user_relationship_types" */
export enum User_Relationship_Types_Select_Column {
  /** column name */
  Backward = "backward",
  /** column name */
  Details = "details",
  /** column name */
  Forward = "forward",
  /** column name */
  Id = "id",
}

/** input type for updating data in table "user_relationship_types" */
export type User_Relationship_Types_Set_Input = {
  backward?: Maybe<Scalars["String"]>;
  details?: Maybe<Scalars["json"]>;
  forward?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type User_Relationship_Types_Stddev_Fields = {
  __typename?: "user_relationship_types_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type User_Relationship_Types_Stddev_Pop_Fields = {
  __typename?: "user_relationship_types_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type User_Relationship_Types_Stddev_Samp_Fields = {
  __typename?: "user_relationship_types_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate sum on columns */
export type User_Relationship_Types_Sum_Fields = {
  __typename?: "user_relationship_types_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "user_relationship_types" */
export enum User_Relationship_Types_Update_Column {
  /** column name */
  Backward = "backward",
  /** column name */
  Details = "details",
  /** column name */
  Forward = "forward",
  /** column name */
  Id = "id",
}

/** aggregate var_pop on columns */
export type User_Relationship_Types_Var_Pop_Fields = {
  __typename?: "user_relationship_types_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type User_Relationship_Types_Var_Samp_Fields = {
  __typename?: "user_relationship_types_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type User_Relationship_Types_Variance_Fields = {
  __typename?: "user_relationship_types_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** update columns of table "user_relationship" */
export enum User_Relationship_Update_Column {
  /** column name */
  FromUserId = "from_user_id",
  /** column name */
  Id = "id",
  /** column name */
  RelationshipType = "relationship_type",
  /** column name */
  ToUserId = "to_user_id",
}

/** aggregate var_pop on columns */
export type User_Relationship_Var_Pop_Fields = {
  __typename?: "user_relationship_var_pop_fields";
  relationship_type?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "user_relationship" */
export type User_Relationship_Var_Pop_Order_By = {
  relationship_type?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Relationship_Var_Samp_Fields = {
  __typename?: "user_relationship_var_samp_fields";
  relationship_type?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "user_relationship" */
export type User_Relationship_Var_Samp_Order_By = {
  relationship_type?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Relationship_Variance_Fields = {
  __typename?: "user_relationship_variance_fields";
  relationship_type?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "user_relationship" */
export type User_Relationship_Variance_Order_By = {
  relationship_type?: Maybe<Order_By>;
};

/** columns and relationships of "user_roles" */
export type User_Roles = {
  __typename?: "user_roles";
  notes?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

/** aggregated selection of "user_roles" */
export type User_Roles_Aggregate = {
  __typename?: "user_roles_aggregate";
  aggregate?: Maybe<User_Roles_Aggregate_Fields>;
  nodes: Array<User_Roles>;
};

/** aggregate fields of "user_roles" */
export type User_Roles_Aggregate_Fields = {
  __typename?: "user_roles_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<User_Roles_Max_Fields>;
  min?: Maybe<User_Roles_Min_Fields>;
};

/** aggregate fields of "user_roles" */
export type User_Roles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Roles_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "user_roles". All fields are combined with a logical 'AND'. */
export type User_Roles_Bool_Exp = {
  _and?: Maybe<Array<User_Roles_Bool_Exp>>;
  _not?: Maybe<User_Roles_Bool_Exp>;
  _or?: Maybe<Array<User_Roles_Bool_Exp>>;
  notes?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_roles" */
export enum User_Roles_Constraint {
  /** unique or primary key constraint */
  UserRolePkey = "user_role_pkey",
}

export enum User_Roles_Enum {
  /** Parent of Student */
  Parent = "parent",
  SchoolAdmin = "school_admin",
  Student = "student",
  SystemAdmin = "system_admin",
  Teacher = "teacher",
}

/** Boolean expression to compare columns of type "user_roles_enum". All fields are combined with logical 'AND'. */
export type User_Roles_Enum_Comparison_Exp = {
  _eq?: Maybe<User_Roles_Enum>;
  _in?: Maybe<Array<User_Roles_Enum>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<User_Roles_Enum>;
  _nin?: Maybe<Array<User_Roles_Enum>>;
};

/** input type for inserting data into table "user_roles" */
export type User_Roles_Insert_Input = {
  notes?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type User_Roles_Max_Fields = {
  __typename?: "user_roles_max_fields";
  notes?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type User_Roles_Min_Fields = {
  __typename?: "user_roles_min_fields";
  notes?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "user_roles" */
export type User_Roles_Mutation_Response = {
  __typename?: "user_roles_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<User_Roles>;
};

/** input type for inserting object relation for remote table "user_roles" */
export type User_Roles_Obj_Rel_Insert_Input = {
  data: User_Roles_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<User_Roles_On_Conflict>;
};

/** on_conflict condition type for table "user_roles" */
export type User_Roles_On_Conflict = {
  constraint: User_Roles_Constraint;
  update_columns: Array<User_Roles_Update_Column>;
  where?: Maybe<User_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "user_roles". */
export type User_Roles_Order_By = {
  notes?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: user_roles */
export type User_Roles_Pk_Columns_Input = {
  value: Scalars["String"];
};

/** select columns of table "user_roles" */
export enum User_Roles_Select_Column {
  /** column name */
  Notes = "notes",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "user_roles" */
export type User_Roles_Set_Input = {
  notes?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** update columns of table "user_roles" */
export enum User_Roles_Update_Column {
  /** column name */
  Notes = "notes",
  /** column name */
  Value = "value",
}

/** columns and relationships of "user_status" */
export type User_Status = {
  __typename?: "user_status";
  notes?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

/** aggregated selection of "user_status" */
export type User_Status_Aggregate = {
  __typename?: "user_status_aggregate";
  aggregate?: Maybe<User_Status_Aggregate_Fields>;
  nodes: Array<User_Status>;
};

/** aggregate fields of "user_status" */
export type User_Status_Aggregate_Fields = {
  __typename?: "user_status_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<User_Status_Max_Fields>;
  min?: Maybe<User_Status_Min_Fields>;
};

/** aggregate fields of "user_status" */
export type User_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "user_status". All fields are combined with a logical 'AND'. */
export type User_Status_Bool_Exp = {
  _and?: Maybe<Array<User_Status_Bool_Exp>>;
  _not?: Maybe<User_Status_Bool_Exp>;
  _or?: Maybe<Array<User_Status_Bool_Exp>>;
  notes?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_status" */
export enum User_Status_Constraint {
  /** unique or primary key constraint */
  UserStatusPkey = "user_status_pkey",
}

export enum User_Status_Enum {
  Active = "active",
  Inactive = "inactive",
}

/** Boolean expression to compare columns of type "user_status_enum". All fields are combined with logical 'AND'. */
export type User_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<User_Status_Enum>;
  _in?: Maybe<Array<User_Status_Enum>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<User_Status_Enum>;
  _nin?: Maybe<Array<User_Status_Enum>>;
};

/** input type for inserting data into table "user_status" */
export type User_Status_Insert_Input = {
  notes?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type User_Status_Max_Fields = {
  __typename?: "user_status_max_fields";
  notes?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type User_Status_Min_Fields = {
  __typename?: "user_status_min_fields";
  notes?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "user_status" */
export type User_Status_Mutation_Response = {
  __typename?: "user_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<User_Status>;
};

/** input type for inserting object relation for remote table "user_status" */
export type User_Status_Obj_Rel_Insert_Input = {
  data: User_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<User_Status_On_Conflict>;
};

/** on_conflict condition type for table "user_status" */
export type User_Status_On_Conflict = {
  constraint: User_Status_Constraint;
  update_columns: Array<User_Status_Update_Column>;
  where?: Maybe<User_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "user_status". */
export type User_Status_Order_By = {
  notes?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: user_status */
export type User_Status_Pk_Columns_Input = {
  value: Scalars["String"];
};

/** select columns of table "user_status" */
export enum User_Status_Select_Column {
  /** column name */
  Notes = "notes",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "user_status" */
export type User_Status_Set_Input = {
  notes?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** update columns of table "user_status" */
export enum User_Status_Update_Column {
  /** column name */
  Notes = "notes",
  /** column name */
  Value = "value",
}

/** columns and relationships of "users" */
export type Users = {
  __typename?: "users";
  /** An object relationship */
  class_room?: Maybe<Class_Rooms>;
  class_room_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_id?: Maybe<Scalars["uuid"]>;
  email?: Maybe<Scalars["String"]>;
  first_name: Scalars["String"];
  id: Scalars["uuid"];
  last_name: Scalars["String"];
  role: User_Roles_Enum;
  /** An object relationship */
  school?: Maybe<Schools>;
  school_id?: Maybe<Scalars["uuid"]>;
  status: User_Status_Enum;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  /** An array relationship */
  user_location_ratings: Array<User_Location_Ratings>;
  /** An aggregate relationship */
  user_location_ratings_aggregate: User_Location_Ratings_Aggregate;
  /** An array relationship */
  user_relationships_backward: Array<User_Relationship>;
  /** An aggregate relationship */
  user_relationships_backward_aggregate: User_Relationship_Aggregate;
  /** An array relationship */
  user_relationships_forward: Array<User_Relationship>;
  /** An aggregate relationship */
  user_relationships_forward_aggregate: User_Relationship_Aggregate;
  /** An object relationship */
  user_role: User_Roles;
  /** An object relationship */
  user_status: User_Status;
  username: Scalars["String"];
};

/** columns and relationships of "users" */
export type UsersUser_Location_RatingsArgs = {
  distinct_on?: Maybe<Array<User_Location_Ratings_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Location_Ratings_Order_By>>;
  where?: Maybe<User_Location_Ratings_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Location_Ratings_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Location_Ratings_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Location_Ratings_Order_By>>;
  where?: Maybe<User_Location_Ratings_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Relationships_BackwardArgs = {
  distinct_on?: Maybe<Array<User_Relationship_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Relationship_Order_By>>;
  where?: Maybe<User_Relationship_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Relationships_Backward_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Relationship_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Relationship_Order_By>>;
  where?: Maybe<User_Relationship_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Relationships_ForwardArgs = {
  distinct_on?: Maybe<Array<User_Relationship_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Relationship_Order_By>>;
  where?: Maybe<User_Relationship_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUser_Relationships_Forward_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Relationship_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<User_Relationship_Order_By>>;
  where?: Maybe<User_Relationship_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: "users_aggregate";
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: "users_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Users_Max_Order_By>;
  min?: Maybe<Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Users_Bool_Exp>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Users_Bool_Exp>>;
  class_room?: Maybe<Class_Rooms_Bool_Exp>;
  class_room_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by_id?: Maybe<Uuid_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  role?: Maybe<User_Roles_Enum_Comparison_Exp>;
  school?: Maybe<Schools_Bool_Exp>;
  school_id?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<User_Status_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_location_ratings?: Maybe<User_Location_Ratings_Bool_Exp>;
  user_relationships_backward?: Maybe<User_Relationship_Bool_Exp>;
  user_relationships_forward?: Maybe<User_Relationship_Bool_Exp>;
  user_role?: Maybe<User_Roles_Bool_Exp>;
  user_status?: Maybe<User_Status_Bool_Exp>;
  username?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersPkey = "users_pkey",
  /** unique or primary key constraint */
  UsersUsernameKey = "users_username_key",
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  class_room?: Maybe<Class_Rooms_Obj_Rel_Insert_Input>;
  class_room_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_id?: Maybe<Scalars["uuid"]>;
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_name?: Maybe<Scalars["String"]>;
  role?: Maybe<User_Roles_Enum>;
  school?: Maybe<Schools_Obj_Rel_Insert_Input>;
  school_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<User_Status_Enum>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user_location_ratings?: Maybe<User_Location_Ratings_Arr_Rel_Insert_Input>;
  user_relationships_backward?: Maybe<User_Relationship_Arr_Rel_Insert_Input>;
  user_relationships_forward?: Maybe<User_Relationship_Arr_Rel_Insert_Input>;
  user_role?: Maybe<User_Roles_Obj_Rel_Insert_Input>;
  user_status?: Maybe<User_Status_Obj_Rel_Insert_Input>;
  username?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: "users_max_fields";
  class_room_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_id?: Maybe<Scalars["uuid"]>;
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_name?: Maybe<Scalars["String"]>;
  school_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  username?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  class_room_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: "users_min_fields";
  class_room_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_id?: Maybe<Scalars["uuid"]>;
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_name?: Maybe<Scalars["String"]>;
  school_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  username?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  class_room_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  username?: Maybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: "users_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  class_room?: Maybe<Class_Rooms_Order_By>;
  class_room_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  school?: Maybe<Schools_Order_By>;
  school_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_location_ratings_aggregate?: Maybe<
    User_Location_Ratings_Aggregate_Order_By
  >;
  user_relationships_backward_aggregate?: Maybe<
    User_Relationship_Aggregate_Order_By
  >;
  user_relationships_forward_aggregate?: Maybe<
    User_Relationship_Aggregate_Order_By
  >;
  user_role?: Maybe<User_Roles_Order_By>;
  user_status?: Maybe<User_Status_Order_By>;
  username?: Maybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  ClassRoomId = "class_room_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedById = "created_by_id",
  /** column name */
  Email = "email",
  /** column name */
  FirstName = "first_name",
  /** column name */
  Id = "id",
  /** column name */
  LastName = "last_name",
  /** column name */
  Role = "role",
  /** column name */
  SchoolId = "school_id",
  /** column name */
  Status = "status",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Username = "username",
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  class_room_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_id?: Maybe<Scalars["uuid"]>;
  email?: Maybe<Scalars["String"]>;
  first_name?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  last_name?: Maybe<Scalars["String"]>;
  role?: Maybe<User_Roles_Enum>;
  school_id?: Maybe<Scalars["uuid"]>;
  status?: Maybe<User_Status_Enum>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  username?: Maybe<Scalars["String"]>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  ClassRoomId = "class_room_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedById = "created_by_id",
  /** column name */
  Email = "email",
  /** column name */
  FirstName = "first_name",
  /** column name */
  Id = "id",
  /** column name */
  LastName = "last_name",
  /** column name */
  Role = "role",
  /** column name */
  SchoolId = "school_id",
  /** column name */
  Status = "status",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Username = "username",
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars["uuid"]>;
  _gt?: Maybe<Scalars["uuid"]>;
  _gte?: Maybe<Scalars["uuid"]>;
  _in?: Maybe<Array<Scalars["uuid"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["uuid"]>;
  _lte?: Maybe<Scalars["uuid"]>;
  _neq?: Maybe<Scalars["uuid"]>;
  _nin?: Maybe<Array<Scalars["uuid"]>>;
};

/** columns and relationships of "virtual_trip_images" */
export type Virtual_Trip_Images = {
  __typename?: "virtual_trip_images";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  image_link: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  virtual_trip: Virtual_Trips;
  virtual_trip_id: Scalars["Int"];
};

/** aggregated selection of "virtual_trip_images" */
export type Virtual_Trip_Images_Aggregate = {
  __typename?: "virtual_trip_images_aggregate";
  aggregate?: Maybe<Virtual_Trip_Images_Aggregate_Fields>;
  nodes: Array<Virtual_Trip_Images>;
};

/** aggregate fields of "virtual_trip_images" */
export type Virtual_Trip_Images_Aggregate_Fields = {
  __typename?: "virtual_trip_images_aggregate_fields";
  avg?: Maybe<Virtual_Trip_Images_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Virtual_Trip_Images_Max_Fields>;
  min?: Maybe<Virtual_Trip_Images_Min_Fields>;
  stddev?: Maybe<Virtual_Trip_Images_Stddev_Fields>;
  stddev_pop?: Maybe<Virtual_Trip_Images_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Virtual_Trip_Images_Stddev_Samp_Fields>;
  sum?: Maybe<Virtual_Trip_Images_Sum_Fields>;
  var_pop?: Maybe<Virtual_Trip_Images_Var_Pop_Fields>;
  var_samp?: Maybe<Virtual_Trip_Images_Var_Samp_Fields>;
  variance?: Maybe<Virtual_Trip_Images_Variance_Fields>;
};

/** aggregate fields of "virtual_trip_images" */
export type Virtual_Trip_Images_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Virtual_Trip_Images_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "virtual_trip_images" */
export type Virtual_Trip_Images_Aggregate_Order_By = {
  avg?: Maybe<Virtual_Trip_Images_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Virtual_Trip_Images_Max_Order_By>;
  min?: Maybe<Virtual_Trip_Images_Min_Order_By>;
  stddev?: Maybe<Virtual_Trip_Images_Stddev_Order_By>;
  stddev_pop?: Maybe<Virtual_Trip_Images_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Virtual_Trip_Images_Stddev_Samp_Order_By>;
  sum?: Maybe<Virtual_Trip_Images_Sum_Order_By>;
  var_pop?: Maybe<Virtual_Trip_Images_Var_Pop_Order_By>;
  var_samp?: Maybe<Virtual_Trip_Images_Var_Samp_Order_By>;
  variance?: Maybe<Virtual_Trip_Images_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "virtual_trip_images" */
export type Virtual_Trip_Images_Arr_Rel_Insert_Input = {
  data: Array<Virtual_Trip_Images_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Virtual_Trip_Images_On_Conflict>;
};

/** aggregate avg on columns */
export type Virtual_Trip_Images_Avg_Fields = {
  __typename?: "virtual_trip_images_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  virtual_trip_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "virtual_trip_images" */
export type Virtual_Trip_Images_Avg_Order_By = {
  id?: Maybe<Order_By>;
  virtual_trip_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "virtual_trip_images". All fields are combined with a logical 'AND'. */
export type Virtual_Trip_Images_Bool_Exp = {
  _and?: Maybe<Array<Virtual_Trip_Images_Bool_Exp>>;
  _not?: Maybe<Virtual_Trip_Images_Bool_Exp>;
  _or?: Maybe<Array<Virtual_Trip_Images_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  image_link?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  virtual_trip?: Maybe<Virtual_Trips_Bool_Exp>;
  virtual_trip_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "virtual_trip_images" */
export enum Virtual_Trip_Images_Constraint {
  /** unique or primary key constraint */
  VirtualTrpImagesPkey = "virtual_trp_images_pkey",
  /** unique or primary key constraint */
  VirtualTrpImagesVirtualTripIdImageLinkKey = "virtual_trp_images_virtual_trip_id_image_link_key",
}

/** input type for incrementing numeric columns in table "virtual_trip_images" */
export type Virtual_Trip_Images_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
  virtual_trip_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "virtual_trip_images" */
export type Virtual_Trip_Images_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  image_link?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  virtual_trip?: Maybe<Virtual_Trips_Obj_Rel_Insert_Input>;
  virtual_trip_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Virtual_Trip_Images_Max_Fields = {
  __typename?: "virtual_trip_images_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  image_link?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  virtual_trip_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "virtual_trip_images" */
export type Virtual_Trip_Images_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_link?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  virtual_trip_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Virtual_Trip_Images_Min_Fields = {
  __typename?: "virtual_trip_images_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  image_link?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  virtual_trip_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "virtual_trip_images" */
export type Virtual_Trip_Images_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_link?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  virtual_trip_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "virtual_trip_images" */
export type Virtual_Trip_Images_Mutation_Response = {
  __typename?: "virtual_trip_images_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Virtual_Trip_Images>;
};

/** on_conflict condition type for table "virtual_trip_images" */
export type Virtual_Trip_Images_On_Conflict = {
  constraint: Virtual_Trip_Images_Constraint;
  update_columns: Array<Virtual_Trip_Images_Update_Column>;
  where?: Maybe<Virtual_Trip_Images_Bool_Exp>;
};

/** Ordering options when selecting data from "virtual_trip_images". */
export type Virtual_Trip_Images_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_link?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  virtual_trip?: Maybe<Virtual_Trips_Order_By>;
  virtual_trip_id?: Maybe<Order_By>;
};

/** primary key columns input for table: virtual_trip_images */
export type Virtual_Trip_Images_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "virtual_trip_images" */
export enum Virtual_Trip_Images_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ImageLink = "image_link",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  VirtualTripId = "virtual_trip_id",
}

/** input type for updating data in table "virtual_trip_images" */
export type Virtual_Trip_Images_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  image_link?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  virtual_trip_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Virtual_Trip_Images_Stddev_Fields = {
  __typename?: "virtual_trip_images_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  virtual_trip_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "virtual_trip_images" */
export type Virtual_Trip_Images_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  virtual_trip_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Virtual_Trip_Images_Stddev_Pop_Fields = {
  __typename?: "virtual_trip_images_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  virtual_trip_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "virtual_trip_images" */
export type Virtual_Trip_Images_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  virtual_trip_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Virtual_Trip_Images_Stddev_Samp_Fields = {
  __typename?: "virtual_trip_images_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  virtual_trip_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "virtual_trip_images" */
export type Virtual_Trip_Images_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  virtual_trip_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Virtual_Trip_Images_Sum_Fields = {
  __typename?: "virtual_trip_images_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  virtual_trip_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "virtual_trip_images" */
export type Virtual_Trip_Images_Sum_Order_By = {
  id?: Maybe<Order_By>;
  virtual_trip_id?: Maybe<Order_By>;
};

/** update columns of table "virtual_trip_images" */
export enum Virtual_Trip_Images_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ImageLink = "image_link",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  VirtualTripId = "virtual_trip_id",
}

/** aggregate var_pop on columns */
export type Virtual_Trip_Images_Var_Pop_Fields = {
  __typename?: "virtual_trip_images_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  virtual_trip_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "virtual_trip_images" */
export type Virtual_Trip_Images_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  virtual_trip_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Virtual_Trip_Images_Var_Samp_Fields = {
  __typename?: "virtual_trip_images_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  virtual_trip_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "virtual_trip_images" */
export type Virtual_Trip_Images_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  virtual_trip_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Virtual_Trip_Images_Variance_Fields = {
  __typename?: "virtual_trip_images_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  virtual_trip_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "virtual_trip_images" */
export type Virtual_Trip_Images_Variance_Order_By = {
  id?: Maybe<Order_By>;
  virtual_trip_id?: Maybe<Order_By>;
};

/** columns and relationships of "virtual_trip_plans" */
export type Virtual_Trip_Plans = {
  __typename?: "virtual_trip_plans";
  created_at: Scalars["timestamptz"];
  created_by: Scalars["uuid"];
  event_date?: Maybe<Scalars["timestamptz"]>;
  event_details: Scalars["String"];
  id: Scalars["Int"];
  title?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "virtual_trip_plans" */
export type Virtual_Trip_Plans_Aggregate = {
  __typename?: "virtual_trip_plans_aggregate";
  aggregate?: Maybe<Virtual_Trip_Plans_Aggregate_Fields>;
  nodes: Array<Virtual_Trip_Plans>;
};

/** aggregate fields of "virtual_trip_plans" */
export type Virtual_Trip_Plans_Aggregate_Fields = {
  __typename?: "virtual_trip_plans_aggregate_fields";
  avg?: Maybe<Virtual_Trip_Plans_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Virtual_Trip_Plans_Max_Fields>;
  min?: Maybe<Virtual_Trip_Plans_Min_Fields>;
  stddev?: Maybe<Virtual_Trip_Plans_Stddev_Fields>;
  stddev_pop?: Maybe<Virtual_Trip_Plans_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Virtual_Trip_Plans_Stddev_Samp_Fields>;
  sum?: Maybe<Virtual_Trip_Plans_Sum_Fields>;
  var_pop?: Maybe<Virtual_Trip_Plans_Var_Pop_Fields>;
  var_samp?: Maybe<Virtual_Trip_Plans_Var_Samp_Fields>;
  variance?: Maybe<Virtual_Trip_Plans_Variance_Fields>;
};

/** aggregate fields of "virtual_trip_plans" */
export type Virtual_Trip_Plans_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Virtual_Trip_Plans_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Virtual_Trip_Plans_Avg_Fields = {
  __typename?: "virtual_trip_plans_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "virtual_trip_plans". All fields are combined with a logical 'AND'. */
export type Virtual_Trip_Plans_Bool_Exp = {
  _and?: Maybe<Array<Virtual_Trip_Plans_Bool_Exp>>;
  _not?: Maybe<Virtual_Trip_Plans_Bool_Exp>;
  _or?: Maybe<Array<Virtual_Trip_Plans_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Uuid_Comparison_Exp>;
  event_date?: Maybe<Timestamptz_Comparison_Exp>;
  event_details?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "virtual_trip_plans" */
export enum Virtual_Trip_Plans_Constraint {
  /** unique or primary key constraint */
  VirtualTripPlansPkey = "virtual_trip_plans_pkey",
}

/** input type for incrementing numeric columns in table "virtual_trip_plans" */
export type Virtual_Trip_Plans_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "virtual_trip_plans" */
export type Virtual_Trip_Plans_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  event_date?: Maybe<Scalars["timestamptz"]>;
  event_details?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Virtual_Trip_Plans_Max_Fields = {
  __typename?: "virtual_trip_plans_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  event_date?: Maybe<Scalars["timestamptz"]>;
  event_details?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Virtual_Trip_Plans_Min_Fields = {
  __typename?: "virtual_trip_plans_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  event_date?: Maybe<Scalars["timestamptz"]>;
  event_details?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "virtual_trip_plans" */
export type Virtual_Trip_Plans_Mutation_Response = {
  __typename?: "virtual_trip_plans_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Virtual_Trip_Plans>;
};

/** on_conflict condition type for table "virtual_trip_plans" */
export type Virtual_Trip_Plans_On_Conflict = {
  constraint: Virtual_Trip_Plans_Constraint;
  update_columns: Array<Virtual_Trip_Plans_Update_Column>;
  where?: Maybe<Virtual_Trip_Plans_Bool_Exp>;
};

/** Ordering options when selecting data from "virtual_trip_plans". */
export type Virtual_Trip_Plans_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  event_date?: Maybe<Order_By>;
  event_details?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: virtual_trip_plans */
export type Virtual_Trip_Plans_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "virtual_trip_plans" */
export enum Virtual_Trip_Plans_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  EventDate = "event_date",
  /** column name */
  EventDetails = "event_details",
  /** column name */
  Id = "id",
  /** column name */
  Title = "title",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "virtual_trip_plans" */
export type Virtual_Trip_Plans_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  event_date?: Maybe<Scalars["timestamptz"]>;
  event_details?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Virtual_Trip_Plans_Stddev_Fields = {
  __typename?: "virtual_trip_plans_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Virtual_Trip_Plans_Stddev_Pop_Fields = {
  __typename?: "virtual_trip_plans_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Virtual_Trip_Plans_Stddev_Samp_Fields = {
  __typename?: "virtual_trip_plans_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate sum on columns */
export type Virtual_Trip_Plans_Sum_Fields = {
  __typename?: "virtual_trip_plans_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "virtual_trip_plans" */
export enum Virtual_Trip_Plans_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  EventDate = "event_date",
  /** column name */
  EventDetails = "event_details",
  /** column name */
  Id = "id",
  /** column name */
  Title = "title",
  /** column name */
  UpdatedAt = "updated_at",
}

/** aggregate var_pop on columns */
export type Virtual_Trip_Plans_Var_Pop_Fields = {
  __typename?: "virtual_trip_plans_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Virtual_Trip_Plans_Var_Samp_Fields = {
  __typename?: "virtual_trip_plans_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Virtual_Trip_Plans_Variance_Fields = {
  __typename?: "virtual_trip_plans_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "virtual_trip_proposals" */
export type Virtual_Trip_Proposals = {
  __typename?: "virtual_trip_proposals";
  created_at: Scalars["timestamptz"];
  created_by: Scalars["uuid"];
  id: Scalars["Int"];
  question: Scalars["String"];
  topic: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "virtual_trip_proposals" */
export type Virtual_Trip_Proposals_Aggregate = {
  __typename?: "virtual_trip_proposals_aggregate";
  aggregate?: Maybe<Virtual_Trip_Proposals_Aggregate_Fields>;
  nodes: Array<Virtual_Trip_Proposals>;
};

/** aggregate fields of "virtual_trip_proposals" */
export type Virtual_Trip_Proposals_Aggregate_Fields = {
  __typename?: "virtual_trip_proposals_aggregate_fields";
  avg?: Maybe<Virtual_Trip_Proposals_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Virtual_Trip_Proposals_Max_Fields>;
  min?: Maybe<Virtual_Trip_Proposals_Min_Fields>;
  stddev?: Maybe<Virtual_Trip_Proposals_Stddev_Fields>;
  stddev_pop?: Maybe<Virtual_Trip_Proposals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Virtual_Trip_Proposals_Stddev_Samp_Fields>;
  sum?: Maybe<Virtual_Trip_Proposals_Sum_Fields>;
  var_pop?: Maybe<Virtual_Trip_Proposals_Var_Pop_Fields>;
  var_samp?: Maybe<Virtual_Trip_Proposals_Var_Samp_Fields>;
  variance?: Maybe<Virtual_Trip_Proposals_Variance_Fields>;
};

/** aggregate fields of "virtual_trip_proposals" */
export type Virtual_Trip_Proposals_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Virtual_Trip_Proposals_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Virtual_Trip_Proposals_Avg_Fields = {
  __typename?: "virtual_trip_proposals_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "virtual_trip_proposals". All fields are combined with a logical 'AND'. */
export type Virtual_Trip_Proposals_Bool_Exp = {
  _and?: Maybe<Array<Virtual_Trip_Proposals_Bool_Exp>>;
  _not?: Maybe<Virtual_Trip_Proposals_Bool_Exp>;
  _or?: Maybe<Array<Virtual_Trip_Proposals_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  question?: Maybe<String_Comparison_Exp>;
  topic?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "virtual_trip_proposals" */
export enum Virtual_Trip_Proposals_Constraint {
  /** unique or primary key constraint */
  VirtualTripProposalsPkey = "virtual_trip_proposals_pkey",
}

/** input type for incrementing numeric columns in table "virtual_trip_proposals" */
export type Virtual_Trip_Proposals_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "virtual_trip_proposals" */
export type Virtual_Trip_Proposals_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
  question?: Maybe<Scalars["String"]>;
  topic?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Virtual_Trip_Proposals_Max_Fields = {
  __typename?: "virtual_trip_proposals_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
  question?: Maybe<Scalars["String"]>;
  topic?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Virtual_Trip_Proposals_Min_Fields = {
  __typename?: "virtual_trip_proposals_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
  question?: Maybe<Scalars["String"]>;
  topic?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "virtual_trip_proposals" */
export type Virtual_Trip_Proposals_Mutation_Response = {
  __typename?: "virtual_trip_proposals_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Virtual_Trip_Proposals>;
};

/** on_conflict condition type for table "virtual_trip_proposals" */
export type Virtual_Trip_Proposals_On_Conflict = {
  constraint: Virtual_Trip_Proposals_Constraint;
  update_columns: Array<Virtual_Trip_Proposals_Update_Column>;
  where?: Maybe<Virtual_Trip_Proposals_Bool_Exp>;
};

/** Ordering options when selecting data from "virtual_trip_proposals". */
export type Virtual_Trip_Proposals_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  question?: Maybe<Order_By>;
  topic?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
};

/** primary key columns input for table: virtual_trip_proposals */
export type Virtual_Trip_Proposals_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "virtual_trip_proposals" */
export enum Virtual_Trip_Proposals_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  Id = "id",
  /** column name */
  Question = "question",
  /** column name */
  Topic = "topic",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "virtual_trip_proposals" */
export type Virtual_Trip_Proposals_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["Int"]>;
  question?: Maybe<Scalars["String"]>;
  topic?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Virtual_Trip_Proposals_Stddev_Fields = {
  __typename?: "virtual_trip_proposals_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Virtual_Trip_Proposals_Stddev_Pop_Fields = {
  __typename?: "virtual_trip_proposals_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Virtual_Trip_Proposals_Stddev_Samp_Fields = {
  __typename?: "virtual_trip_proposals_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate sum on columns */
export type Virtual_Trip_Proposals_Sum_Fields = {
  __typename?: "virtual_trip_proposals_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "virtual_trip_proposals_topic" */
export type Virtual_Trip_Proposals_Topic = {
  __typename?: "virtual_trip_proposals_topic";
  count?: Maybe<Scalars["bigint"]>;
  topic?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "virtual_trip_proposals_topic" */
export type Virtual_Trip_Proposals_Topic_Aggregate = {
  __typename?: "virtual_trip_proposals_topic_aggregate";
  aggregate?: Maybe<Virtual_Trip_Proposals_Topic_Aggregate_Fields>;
  nodes: Array<Virtual_Trip_Proposals_Topic>;
};

/** aggregate fields of "virtual_trip_proposals_topic" */
export type Virtual_Trip_Proposals_Topic_Aggregate_Fields = {
  __typename?: "virtual_trip_proposals_topic_aggregate_fields";
  avg?: Maybe<Virtual_Trip_Proposals_Topic_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Virtual_Trip_Proposals_Topic_Max_Fields>;
  min?: Maybe<Virtual_Trip_Proposals_Topic_Min_Fields>;
  stddev?: Maybe<Virtual_Trip_Proposals_Topic_Stddev_Fields>;
  stddev_pop?: Maybe<Virtual_Trip_Proposals_Topic_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Virtual_Trip_Proposals_Topic_Stddev_Samp_Fields>;
  sum?: Maybe<Virtual_Trip_Proposals_Topic_Sum_Fields>;
  var_pop?: Maybe<Virtual_Trip_Proposals_Topic_Var_Pop_Fields>;
  var_samp?: Maybe<Virtual_Trip_Proposals_Topic_Var_Samp_Fields>;
  variance?: Maybe<Virtual_Trip_Proposals_Topic_Variance_Fields>;
};

/** aggregate fields of "virtual_trip_proposals_topic" */
export type Virtual_Trip_Proposals_Topic_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Virtual_Trip_Proposals_Topic_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Virtual_Trip_Proposals_Topic_Avg_Fields = {
  __typename?: "virtual_trip_proposals_topic_avg_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "virtual_trip_proposals_topic". All fields are combined with a logical 'AND'. */
export type Virtual_Trip_Proposals_Topic_Bool_Exp = {
  _and?: Maybe<Array<Virtual_Trip_Proposals_Topic_Bool_Exp>>;
  _not?: Maybe<Virtual_Trip_Proposals_Topic_Bool_Exp>;
  _or?: Maybe<Array<Virtual_Trip_Proposals_Topic_Bool_Exp>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  topic?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Virtual_Trip_Proposals_Topic_Max_Fields = {
  __typename?: "virtual_trip_proposals_topic_max_fields";
  count?: Maybe<Scalars["bigint"]>;
  topic?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Virtual_Trip_Proposals_Topic_Min_Fields = {
  __typename?: "virtual_trip_proposals_topic_min_fields";
  count?: Maybe<Scalars["bigint"]>;
  topic?: Maybe<Scalars["String"]>;
};

/** Ordering options when selecting data from "virtual_trip_proposals_topic". */
export type Virtual_Trip_Proposals_Topic_Order_By = {
  count?: Maybe<Order_By>;
  topic?: Maybe<Order_By>;
};

/** select columns of table "virtual_trip_proposals_topic" */
export enum Virtual_Trip_Proposals_Topic_Select_Column {
  /** column name */
  Count = "count",
  /** column name */
  Topic = "topic",
}

/** aggregate stddev on columns */
export type Virtual_Trip_Proposals_Topic_Stddev_Fields = {
  __typename?: "virtual_trip_proposals_topic_stddev_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Virtual_Trip_Proposals_Topic_Stddev_Pop_Fields = {
  __typename?: "virtual_trip_proposals_topic_stddev_pop_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Virtual_Trip_Proposals_Topic_Stddev_Samp_Fields = {
  __typename?: "virtual_trip_proposals_topic_stddev_samp_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** aggregate sum on columns */
export type Virtual_Trip_Proposals_Topic_Sum_Fields = {
  __typename?: "virtual_trip_proposals_topic_sum_fields";
  count?: Maybe<Scalars["bigint"]>;
};

/** aggregate var_pop on columns */
export type Virtual_Trip_Proposals_Topic_Var_Pop_Fields = {
  __typename?: "virtual_trip_proposals_topic_var_pop_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Virtual_Trip_Proposals_Topic_Var_Samp_Fields = {
  __typename?: "virtual_trip_proposals_topic_var_samp_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Virtual_Trip_Proposals_Topic_Variance_Fields = {
  __typename?: "virtual_trip_proposals_topic_variance_fields";
  count?: Maybe<Scalars["Float"]>;
};

/** update columns of table "virtual_trip_proposals" */
export enum Virtual_Trip_Proposals_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  Id = "id",
  /** column name */
  Question = "question",
  /** column name */
  Topic = "topic",
  /** column name */
  UpdatedAt = "updated_at",
}

/** aggregate var_pop on columns */
export type Virtual_Trip_Proposals_Var_Pop_Fields = {
  __typename?: "virtual_trip_proposals_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Virtual_Trip_Proposals_Var_Samp_Fields = {
  __typename?: "virtual_trip_proposals_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Virtual_Trip_Proposals_Variance_Fields = {
  __typename?: "virtual_trip_proposals_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "virtual_trip_schools" */
export type Virtual_Trip_Schools = {
  __typename?: "virtual_trip_schools";
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  school: Schools;
  school_id: Scalars["uuid"];
  updated_at?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  virtual_trip: Virtual_Trips;
  virtual_trip_id: Scalars["Int"];
};

/** aggregated selection of "virtual_trip_schools" */
export type Virtual_Trip_Schools_Aggregate = {
  __typename?: "virtual_trip_schools_aggregate";
  aggregate?: Maybe<Virtual_Trip_Schools_Aggregate_Fields>;
  nodes: Array<Virtual_Trip_Schools>;
};

/** aggregate fields of "virtual_trip_schools" */
export type Virtual_Trip_Schools_Aggregate_Fields = {
  __typename?: "virtual_trip_schools_aggregate_fields";
  avg?: Maybe<Virtual_Trip_Schools_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Virtual_Trip_Schools_Max_Fields>;
  min?: Maybe<Virtual_Trip_Schools_Min_Fields>;
  stddev?: Maybe<Virtual_Trip_Schools_Stddev_Fields>;
  stddev_pop?: Maybe<Virtual_Trip_Schools_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Virtual_Trip_Schools_Stddev_Samp_Fields>;
  sum?: Maybe<Virtual_Trip_Schools_Sum_Fields>;
  var_pop?: Maybe<Virtual_Trip_Schools_Var_Pop_Fields>;
  var_samp?: Maybe<Virtual_Trip_Schools_Var_Samp_Fields>;
  variance?: Maybe<Virtual_Trip_Schools_Variance_Fields>;
};

/** aggregate fields of "virtual_trip_schools" */
export type Virtual_Trip_Schools_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Virtual_Trip_Schools_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "virtual_trip_schools" */
export type Virtual_Trip_Schools_Aggregate_Order_By = {
  avg?: Maybe<Virtual_Trip_Schools_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Virtual_Trip_Schools_Max_Order_By>;
  min?: Maybe<Virtual_Trip_Schools_Min_Order_By>;
  stddev?: Maybe<Virtual_Trip_Schools_Stddev_Order_By>;
  stddev_pop?: Maybe<Virtual_Trip_Schools_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Virtual_Trip_Schools_Stddev_Samp_Order_By>;
  sum?: Maybe<Virtual_Trip_Schools_Sum_Order_By>;
  var_pop?: Maybe<Virtual_Trip_Schools_Var_Pop_Order_By>;
  var_samp?: Maybe<Virtual_Trip_Schools_Var_Samp_Order_By>;
  variance?: Maybe<Virtual_Trip_Schools_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "virtual_trip_schools" */
export type Virtual_Trip_Schools_Arr_Rel_Insert_Input = {
  data: Array<Virtual_Trip_Schools_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Virtual_Trip_Schools_On_Conflict>;
};

/** aggregate avg on columns */
export type Virtual_Trip_Schools_Avg_Fields = {
  __typename?: "virtual_trip_schools_avg_fields";
  virtual_trip_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "virtual_trip_schools" */
export type Virtual_Trip_Schools_Avg_Order_By = {
  virtual_trip_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "virtual_trip_schools". All fields are combined with a logical 'AND'. */
export type Virtual_Trip_Schools_Bool_Exp = {
  _and?: Maybe<Array<Virtual_Trip_Schools_Bool_Exp>>;
  _not?: Maybe<Virtual_Trip_Schools_Bool_Exp>;
  _or?: Maybe<Array<Virtual_Trip_Schools_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  school?: Maybe<Schools_Bool_Exp>;
  school_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  virtual_trip?: Maybe<Virtual_Trips_Bool_Exp>;
  virtual_trip_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "virtual_trip_schools" */
export enum Virtual_Trip_Schools_Constraint {
  /** unique or primary key constraint */
  VirtualTripSchoolsPkey = "virtual_trip_schools_pkey",
}

/** input type for incrementing numeric columns in table "virtual_trip_schools" */
export type Virtual_Trip_Schools_Inc_Input = {
  virtual_trip_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "virtual_trip_schools" */
export type Virtual_Trip_Schools_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  school?: Maybe<Schools_Obj_Rel_Insert_Input>;
  school_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  virtual_trip?: Maybe<Virtual_Trips_Obj_Rel_Insert_Input>;
  virtual_trip_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Virtual_Trip_Schools_Max_Fields = {
  __typename?: "virtual_trip_schools_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  school_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  virtual_trip_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "virtual_trip_schools" */
export type Virtual_Trip_Schools_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  virtual_trip_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Virtual_Trip_Schools_Min_Fields = {
  __typename?: "virtual_trip_schools_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  school_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  virtual_trip_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "virtual_trip_schools" */
export type Virtual_Trip_Schools_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  school_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  virtual_trip_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "virtual_trip_schools" */
export type Virtual_Trip_Schools_Mutation_Response = {
  __typename?: "virtual_trip_schools_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Virtual_Trip_Schools>;
};

/** on_conflict condition type for table "virtual_trip_schools" */
export type Virtual_Trip_Schools_On_Conflict = {
  constraint: Virtual_Trip_Schools_Constraint;
  update_columns: Array<Virtual_Trip_Schools_Update_Column>;
  where?: Maybe<Virtual_Trip_Schools_Bool_Exp>;
};

/** Ordering options when selecting data from "virtual_trip_schools". */
export type Virtual_Trip_Schools_Order_By = {
  created_at?: Maybe<Order_By>;
  school?: Maybe<Schools_Order_By>;
  school_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  virtual_trip?: Maybe<Virtual_Trips_Order_By>;
  virtual_trip_id?: Maybe<Order_By>;
};

/** primary key columns input for table: virtual_trip_schools */
export type Virtual_Trip_Schools_Pk_Columns_Input = {
  school_id: Scalars["uuid"];
  virtual_trip_id: Scalars["Int"];
};

/** select columns of table "virtual_trip_schools" */
export enum Virtual_Trip_Schools_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  SchoolId = "school_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  VirtualTripId = "virtual_trip_id",
}

/** input type for updating data in table "virtual_trip_schools" */
export type Virtual_Trip_Schools_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  school_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  virtual_trip_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Virtual_Trip_Schools_Stddev_Fields = {
  __typename?: "virtual_trip_schools_stddev_fields";
  virtual_trip_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "virtual_trip_schools" */
export type Virtual_Trip_Schools_Stddev_Order_By = {
  virtual_trip_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Virtual_Trip_Schools_Stddev_Pop_Fields = {
  __typename?: "virtual_trip_schools_stddev_pop_fields";
  virtual_trip_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "virtual_trip_schools" */
export type Virtual_Trip_Schools_Stddev_Pop_Order_By = {
  virtual_trip_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Virtual_Trip_Schools_Stddev_Samp_Fields = {
  __typename?: "virtual_trip_schools_stddev_samp_fields";
  virtual_trip_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "virtual_trip_schools" */
export type Virtual_Trip_Schools_Stddev_Samp_Order_By = {
  virtual_trip_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Virtual_Trip_Schools_Sum_Fields = {
  __typename?: "virtual_trip_schools_sum_fields";
  virtual_trip_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "virtual_trip_schools" */
export type Virtual_Trip_Schools_Sum_Order_By = {
  virtual_trip_id?: Maybe<Order_By>;
};

/** update columns of table "virtual_trip_schools" */
export enum Virtual_Trip_Schools_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  SchoolId = "school_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  VirtualTripId = "virtual_trip_id",
}

/** aggregate var_pop on columns */
export type Virtual_Trip_Schools_Var_Pop_Fields = {
  __typename?: "virtual_trip_schools_var_pop_fields";
  virtual_trip_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "virtual_trip_schools" */
export type Virtual_Trip_Schools_Var_Pop_Order_By = {
  virtual_trip_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Virtual_Trip_Schools_Var_Samp_Fields = {
  __typename?: "virtual_trip_schools_var_samp_fields";
  virtual_trip_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "virtual_trip_schools" */
export type Virtual_Trip_Schools_Var_Samp_Order_By = {
  virtual_trip_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Virtual_Trip_Schools_Variance_Fields = {
  __typename?: "virtual_trip_schools_variance_fields";
  virtual_trip_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "virtual_trip_schools" */
export type Virtual_Trip_Schools_Variance_Order_By = {
  virtual_trip_id?: Maybe<Order_By>;
};

/** columns and relationships of "virtual_trips" */
export type Virtual_Trips = {
  __typename?: "virtual_trips";
  all_schools?: Maybe<Scalars["Boolean"]>;
  created_at: Scalars["timestamptz"];
  created_by: Scalars["uuid"];
  description: Scalars["String"];
  event_date?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["Int"];
  meeting_link: Scalars["String"];
  notes?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  topic: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  user: Users;
  /** An array relationship */
  virtual_trip_images: Array<Virtual_Trip_Images>;
  /** An aggregate relationship */
  virtual_trip_images_aggregate: Virtual_Trip_Images_Aggregate;
  /** An array relationship */
  virtual_trip_schools: Array<Virtual_Trip_Schools>;
  /** An aggregate relationship */
  virtual_trip_schools_aggregate: Virtual_Trip_Schools_Aggregate;
};

/** columns and relationships of "virtual_trips" */
export type Virtual_TripsVirtual_Trip_ImagesArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Images_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Images_Order_By>>;
  where?: Maybe<Virtual_Trip_Images_Bool_Exp>;
};

/** columns and relationships of "virtual_trips" */
export type Virtual_TripsVirtual_Trip_Images_AggregateArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Images_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Images_Order_By>>;
  where?: Maybe<Virtual_Trip_Images_Bool_Exp>;
};

/** columns and relationships of "virtual_trips" */
export type Virtual_TripsVirtual_Trip_SchoolsArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Schools_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Schools_Order_By>>;
  where?: Maybe<Virtual_Trip_Schools_Bool_Exp>;
};

/** columns and relationships of "virtual_trips" */
export type Virtual_TripsVirtual_Trip_Schools_AggregateArgs = {
  distinct_on?: Maybe<Array<Virtual_Trip_Schools_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Virtual_Trip_Schools_Order_By>>;
  where?: Maybe<Virtual_Trip_Schools_Bool_Exp>;
};

/** aggregated selection of "virtual_trips" */
export type Virtual_Trips_Aggregate = {
  __typename?: "virtual_trips_aggregate";
  aggregate?: Maybe<Virtual_Trips_Aggregate_Fields>;
  nodes: Array<Virtual_Trips>;
};

/** aggregate fields of "virtual_trips" */
export type Virtual_Trips_Aggregate_Fields = {
  __typename?: "virtual_trips_aggregate_fields";
  avg?: Maybe<Virtual_Trips_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Virtual_Trips_Max_Fields>;
  min?: Maybe<Virtual_Trips_Min_Fields>;
  stddev?: Maybe<Virtual_Trips_Stddev_Fields>;
  stddev_pop?: Maybe<Virtual_Trips_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Virtual_Trips_Stddev_Samp_Fields>;
  sum?: Maybe<Virtual_Trips_Sum_Fields>;
  var_pop?: Maybe<Virtual_Trips_Var_Pop_Fields>;
  var_samp?: Maybe<Virtual_Trips_Var_Samp_Fields>;
  variance?: Maybe<Virtual_Trips_Variance_Fields>;
};

/** aggregate fields of "virtual_trips" */
export type Virtual_Trips_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Virtual_Trips_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Virtual_Trips_Avg_Fields = {
  __typename?: "virtual_trips_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "virtual_trips". All fields are combined with a logical 'AND'. */
export type Virtual_Trips_Bool_Exp = {
  _and?: Maybe<Array<Virtual_Trips_Bool_Exp>>;
  _not?: Maybe<Virtual_Trips_Bool_Exp>;
  _or?: Maybe<Array<Virtual_Trips_Bool_Exp>>;
  all_schools?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Uuid_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  event_date?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  meeting_link?: Maybe<String_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  topic?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  virtual_trip_images?: Maybe<Virtual_Trip_Images_Bool_Exp>;
  virtual_trip_schools?: Maybe<Virtual_Trip_Schools_Bool_Exp>;
};

/** unique or primary key constraints on table "virtual_trips" */
export enum Virtual_Trips_Constraint {
  /** unique or primary key constraint */
  VirtualTripsPkey = "virtual_trips_pkey",
}

/** input type for incrementing numeric columns in table "virtual_trips" */
export type Virtual_Trips_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "virtual_trips" */
export type Virtual_Trips_Insert_Input = {
  all_schools?: Maybe<Scalars["Boolean"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  description?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  meeting_link?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  topic?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  virtual_trip_images?: Maybe<Virtual_Trip_Images_Arr_Rel_Insert_Input>;
  virtual_trip_schools?: Maybe<Virtual_Trip_Schools_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Virtual_Trips_Max_Fields = {
  __typename?: "virtual_trips_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  description?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  meeting_link?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  topic?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Virtual_Trips_Min_Fields = {
  __typename?: "virtual_trips_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  description?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  meeting_link?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  topic?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "virtual_trips" */
export type Virtual_Trips_Mutation_Response = {
  __typename?: "virtual_trips_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Virtual_Trips>;
};

/** input type for inserting object relation for remote table "virtual_trips" */
export type Virtual_Trips_Obj_Rel_Insert_Input = {
  data: Virtual_Trips_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Virtual_Trips_On_Conflict>;
};

/** on_conflict condition type for table "virtual_trips" */
export type Virtual_Trips_On_Conflict = {
  constraint: Virtual_Trips_Constraint;
  update_columns: Array<Virtual_Trips_Update_Column>;
  where?: Maybe<Virtual_Trips_Bool_Exp>;
};

/** Ordering options when selecting data from "virtual_trips". */
export type Virtual_Trips_Order_By = {
  all_schools?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  event_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  meeting_link?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  topic?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  virtual_trip_images_aggregate?: Maybe<Virtual_Trip_Images_Aggregate_Order_By>;
  virtual_trip_schools_aggregate?: Maybe<
    Virtual_Trip_Schools_Aggregate_Order_By
  >;
};

/** primary key columns input for table: virtual_trips */
export type Virtual_Trips_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "virtual_trips" */
export enum Virtual_Trips_Select_Column {
  /** column name */
  AllSchools = "all_schools",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  Description = "description",
  /** column name */
  EventDate = "event_date",
  /** column name */
  Id = "id",
  /** column name */
  MeetingLink = "meeting_link",
  /** column name */
  Notes = "notes",
  /** column name */
  Title = "title",
  /** column name */
  Topic = "topic",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "virtual_trips" */
export type Virtual_Trips_Set_Input = {
  all_schools?: Maybe<Scalars["Boolean"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by?: Maybe<Scalars["uuid"]>;
  description?: Maybe<Scalars["String"]>;
  event_date?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  meeting_link?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  topic?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Virtual_Trips_Stddev_Fields = {
  __typename?: "virtual_trips_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Virtual_Trips_Stddev_Pop_Fields = {
  __typename?: "virtual_trips_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Virtual_Trips_Stddev_Samp_Fields = {
  __typename?: "virtual_trips_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate sum on columns */
export type Virtual_Trips_Sum_Fields = {
  __typename?: "virtual_trips_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "virtual_trips" */
export enum Virtual_Trips_Update_Column {
  /** column name */
  AllSchools = "all_schools",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedBy = "created_by",
  /** column name */
  Description = "description",
  /** column name */
  EventDate = "event_date",
  /** column name */
  Id = "id",
  /** column name */
  MeetingLink = "meeting_link",
  /** column name */
  Notes = "notes",
  /** column name */
  Title = "title",
  /** column name */
  Topic = "topic",
  /** column name */
  UpdatedAt = "updated_at",
}

/** aggregate var_pop on columns */
export type Virtual_Trips_Var_Pop_Fields = {
  __typename?: "virtual_trips_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Virtual_Trips_Var_Samp_Fields = {
  __typename?: "virtual_trips_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Virtual_Trips_Variance_Fields = {
  __typename?: "virtual_trips_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type FindClassRoomsQueryVariables = Exact<{
  school_id?: Maybe<Uuid_Comparison_Exp>;
}>;

export type FindClassRoomsQuery = { __typename?: "query_root" } & {
  class_rooms: Array<
    { __typename?: "class_rooms" } & Pick<
      Class_Rooms,
      "id" | "name" | "notes"
    > & {
        users_aggregate: { __typename?: "users_aggregate" } & {
          aggregate?: Maybe<
            { __typename?: "users_aggregate_fields" } & Pick<
              Users_Aggregate_Fields,
              "count"
            >
          >;
        };
      }
  >;
};

export type CreateClassRoomMutationVariables = Exact<{
  object: Class_Rooms_Insert_Input;
}>;

export type CreateClassRoomMutation = { __typename?: "mutation_root" } & {
  insert_class_rooms_one?: Maybe<
    { __typename?: "class_rooms" } & Pick<Class_Rooms, "id">
  >;
};

export type UpdateClassRoomMutationVariables = Exact<{
  id: Scalars["uuid"];
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
}>;

export type UpdateClassRoomMutation = { __typename?: "mutation_root" } & {
  update_class_rooms_by_pk?: Maybe<
    { __typename?: "class_rooms" } & Pick<Class_Rooms, "id">
  >;
};

export type DashboardCountQueryVariables = Exact<{
  since: Scalars["timestamptz"];
  next: Scalars["date"];
}>;

export type DashboardCountQuery = { __typename?: "query_root" } & {
  users_aggregate: { __typename?: "users_aggregate" } & {
    aggregate?: Maybe<
      { __typename?: "users_aggregate_fields" } & Pick<
        Users_Aggregate_Fields,
        "count"
      >
    >;
  };
  field_trips_aggregate: { __typename?: "field_trips_aggregate" } & {
    aggregate?: Maybe<
      { __typename?: "field_trips_aggregate_fields" } & Pick<
        Field_Trips_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type DashboardTripsQueryVariables = Exact<{
  from?: Maybe<Scalars["date"]>;
  to: Scalars["date"];
  limit?: Maybe<Scalars["Int"]>;
  status?: Maybe<Scalars["String"]>;
}>;

export type DashboardTripsQuery = { __typename?: "query_root" } & {
  field_trips: Array<
    { __typename?: "field_trips" } & Pick<
      Field_Trips,
      "uid" | "id" | "title" | "event_date" | "created_at" | "status"
    >
  >;
};

export type DashboardTripsSummaryQueryVariables = Exact<{
  limit?: Maybe<Scalars["Int"]>;
  userId?: Maybe<Uuid_Comparison_Exp>;
}>;

export type DashboardTripsSummaryQuery = { __typename?: "query_root" } & {
  needsApproval: Array<
    { __typename?: "field_trips" } & Pick<
      Field_Trips,
      "uid" | "id" | "title" | "event_date" | "created_at" | "status"
    >
  >;
  needsApprovalCount: { __typename?: "field_trips_aggregate" } & {
    aggregate?: Maybe<
      { __typename?: "field_trips_aggregate_fields" } & Pick<
        Field_Trips_Aggregate_Fields,
        "count"
      >
    >;
  };
  needsReflection: Array<
    { __typename?: "field_trips" } & Pick<
      Field_Trips,
      "uid" | "id" | "title" | "event_date" | "created_at" | "status"
    >
  >;
  needsReflectionCount: { __typename?: "field_trips_aggregate" } & {
    aggregate?: Maybe<
      { __typename?: "field_trips_aggregate_fields" } & Pick<
        Field_Trips_Aggregate_Fields,
        "count"
      >
    >;
  };
  completedTrips: Array<
    { __typename?: "field_trips" } & Pick<
      Field_Trips,
      "uid" | "id" | "title" | "event_date" | "created_at" | "status"
    >
  >;
  completedTripsCount: { __typename?: "field_trips_aggregate" } & {
    aggregate?: Maybe<
      { __typename?: "field_trips_aggregate_fields" } & Pick<
        Field_Trips_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type FindMeQueryVariables = Exact<{ [key: string]: never }>;

export type FindMeQuery = { __typename?: "query_root" } & {
  me: Array<
    { __typename?: "me" } & {
      school?: Maybe<
        { __typename?: "schools" } & Pick<
          Schools,
          "logo_url" | "color_theme"
        > & {
            school_product_features: Array<
              { __typename?: "school_product_features" } & {
                product_feature: { __typename?: "product_features" } & Pick<
                  Product_Features,
                  "feature_name" | "feature_slug"
                >;
              }
            >;
          }
      >;
    } & MyFieldsFragment
  >;
};

export type MyFieldsFragment = { __typename?: "me" } & Pick<
  Me,
  "id" | "username" | "role" | "first_name" | "last_name" | "email" | "status"
> & {
    school?: Maybe<{ __typename?: "schools" } & Pick<Schools, "id" | "name">>;
  };

export type InsertSchoolMutationVariables = Exact<{
  object: Schools_Insert_Input;
}>;

export type InsertSchoolMutation = { __typename?: "mutation_root" } & {
  insert_schools_one?: Maybe<{ __typename?: "schools" } & Pick<Schools, "id">>;
};

export type UpdateSchoolMutationVariables = Exact<{
  id: Scalars["uuid"];
  object: Schools_Set_Input;
}>;

export type UpdateSchoolMutation = { __typename?: "mutation_root" } & {
  update_schools_by_pk?: Maybe<
    { __typename?: "schools" } & Pick<Schools, "id">
  >;
};

export type ListSchoolsQueryVariables = Exact<{
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
}>;

export type ListSchoolsQuery = { __typename?: "query_root" } & {
  schools: Array<
    { __typename?: "schools" } & Pick<
      Schools,
      | "id"
      | "name"
      | "city"
      | "state"
      | "street_address"
      | "zip_code"
      | "created_at"
      | "school_type"
      | "secondary_school_type"
    > & {
        users_aggregate: { __typename?: "users_aggregate" } & {
          aggregate?: Maybe<
            { __typename?: "users_aggregate_fields" } & Pick<
              Users_Aggregate_Fields,
              "count"
            >
          >;
        };
        class_rooms_aggregate: { __typename?: "class_rooms_aggregate" } & {
          aggregate?: Maybe<
            { __typename?: "class_rooms_aggregate_fields" } & Pick<
              Class_Rooms_Aggregate_Fields,
              "count"
            >
          >;
        };
      }
  >;
  schools_aggregate: { __typename?: "schools_aggregate" } & {
    aggregate?: Maybe<
      { __typename?: "schools_aggregate_fields" } & Pick<
        Schools_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type ListAgreementQuestionsQueryVariables = Exact<{
  schoolId: Scalars["uuid"];
  type?: Maybe<String_Comparison_Exp>;
}>;

export type ListAgreementQuestionsQuery = { __typename?: "query_root" } & {
  agreement_questions: Array<
    { __typename?: "agreement_questions" } & Pick<
      Agreement_Questions,
      "id" | "label" | "type"
    >
  >;
};

export type SchoolInfoQueryVariables = Exact<{
  schoolId: Scalars["uuid"];
}>;

export type SchoolInfoQuery = { __typename?: "query_root" } & {
  schools_by_pk?: Maybe<
    { __typename?: "schools" } & Pick<
      Schools,
      | "id"
      | "created_at"
      | "color_theme"
      | "logo_url"
      | "montessori_affiliation"
      | "name"
      | "notes"
      | "school_type"
      | "secondary_school_type"
      | "state"
      | "street_address"
      | "street_address_2"
      | "updated_at"
      | "zip_code"
      | "city"
      | "parent_permission_template"
      | "chaperone_request_template"
    >
  >;
};

export type InsertAgreementQuestionsMutationVariables = Exact<{
  objects: Array<Agreement_Questions_Insert_Input>;
}>;

export type InsertAgreementQuestionsMutation = {
  __typename?: "mutation_root";
} & {
  insert_agreement_questions?: Maybe<
    { __typename?: "agreement_questions_mutation_response" } & {
      returning: Array<
        { __typename?: "agreement_questions" } & Pick<
          Agreement_Questions,
          "id" | "label" | "type"
        >
      >;
    }
  >;
};

export type DeleteAgreementQuestionMutationVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type DeleteAgreementQuestionMutation = {
  __typename?: "mutation_root";
} & {
  delete_agreement_questions_by_pk?: Maybe<
    { __typename?: "agreement_questions" } & Pick<Agreement_Questions, "id">
  >;
};

export type ProductFeatureQueryVariables = Exact<{ [key: string]: never }>;

export type ProductFeatureQuery = { __typename?: "query_root" } & {
  product_features: Array<
    { __typename?: "product_features" } & Pick<
      Product_Features,
      "feature_slug" | "feature_name" | "id"
    >
  >;
};

export type GetSchoolProductFeatureQueryVariables = Exact<{
  school_id?: Maybe<Scalars["uuid"]>;
}>;

export type GetSchoolProductFeatureQuery = { __typename?: "query_root" } & {
  school_product_features: Array<
    { __typename?: "school_product_features" } & {
      product_feature: { __typename?: "product_features" } & Pick<
        Product_Features,
        "feature_name" | "feature_slug" | "id"
      >;
    }
  >;
};

export type InsertSchoolProductFeatureMutationVariables = Exact<{
  school_id?: Maybe<Scalars["uuid"]>;
  product_feature_id?: Maybe<Scalars["uuid"]>;
}>;

export type InsertSchoolProductFeatureMutation = {
  __typename?: "mutation_root";
} & {
  insert_school_product_features?: Maybe<
    { __typename?: "school_product_features_mutation_response" } & Pick<
      School_Product_Features_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type DeleteSchoolProductFeatureMutationVariables = Exact<{
  product_feature_id?: Maybe<Scalars["uuid"]>;
  school_id?: Maybe<Scalars["uuid"]>;
}>;

export type DeleteSchoolProductFeatureMutation = {
  __typename?: "mutation_root";
} & {
  delete_school_product_features?: Maybe<
    { __typename?: "school_product_features_mutation_response" } & Pick<
      School_Product_Features_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type ApproveFieldsFragment = {
  __typename?: "field_trips";
} & FieldTripBaseFieldsFragment;

export type FieldTripApproveQueryVariables = Exact<{
  uid?: Maybe<Scalars["uuid"]>;
}>;

export type FieldTripApproveQuery = { __typename?: "query_root" } & {
  field_trips: Array<{ __typename?: "field_trips" } & ApproveFieldsFragment>;
};

export type UserBaseFieldsFragment = { __typename?: "users" } & Pick<
  Users,
  "id" | "first_name" | "last_name" | "role"
>;

export type FieldTripStudentFieldsFragment = { __typename?: "users" } & {
  class_room?: Maybe<
    { __typename?: "class_rooms" } & Pick<Class_Rooms, "name">
  >;
  user_relationships_forward: Array<
    { __typename?: "user_relationship" } & {
      to_user: { __typename?: "users" } & UserBaseFieldsFragment;
    }
  >;
  user_relationships_backward: Array<
    { __typename?: "user_relationship" } & {
      from_user: { __typename?: "users" } & UserBaseFieldsFragment;
    }
  >;
} & UserBaseFieldsFragment;

export type FieldTripStudentsQueryVariables = Exact<{
  tripId?: Maybe<Scalars["Int"]>;
}>;

export type FieldTripStudentsQuery = { __typename?: "query_root" } & {
  field_trip_students: Array<
    { __typename?: "field_trip_students" } & Pick<
      Field_Trip_Students,
      "id" | "grace_courtesy_approval" | "transportation_logistics_approval"
    > & { user: { __typename?: "users" } & FieldTripStudentFieldsFragment }
  >;
};

export type UpdateFieldTripStudentMutationVariables = Exact<{
  id: Scalars["Int"];
  object: Field_Trip_Students_Set_Input;
}>;

export type UpdateFieldTripStudentMutation = {
  __typename?: "mutation_root";
} & {
  update_field_trip_students_by_pk?: Maybe<
    { __typename?: "field_trip_students" } & Pick<Field_Trip_Students, "id"> & {
        field_trip: { __typename?: "field_trips" } & {
          field_trip_students: Array<
            { __typename?: "field_trip_students" } & Pick<
              Field_Trip_Students,
              "stage"
            >
          >;
        };
      }
  >;
};

export type ApprovalUserFieldsFragment = {
  __typename?: "field_trip_approvals";
} & Pick<
  Field_Trip_Approvals,
  "id" | "is_chaperone" | "status" | "approved_at"
> & { user: { __typename?: "users" } & UserBaseFieldsFragment };

export type FieldTripApprovalsQueryVariables = Exact<{
  tripId: Scalars["Int"];
}>;

export type FieldTripApprovalsQuery = { __typename?: "query_root" } & {
  field_trip_approvals: Array<
    { __typename?: "field_trip_approvals" } & ApprovalUserFieldsFragment
  >;
};

export type CreateFieldTripApprovalMutationVariables = Exact<{
  object: Field_Trip_Approvals_Insert_Input;
}>;

export type CreateFieldTripApprovalMutation = {
  __typename?: "mutation_root";
} & {
  insert_field_trip_approvals_one?: Maybe<
    { __typename?: "field_trip_approvals" } & Pick<Field_Trip_Approvals, "id">
  >;
};

export type HasParentQueryVariables = Exact<{
  studentId?: Maybe<Scalars["uuid"]>;
}>;

export type HasParentQuery = { __typename?: "query_root" } & {
  users: Array<
    { __typename?: "users" } & Pick<
      Users,
      "last_name" | "first_name" | "id"
    > & {
        user_relationships_backward_aggregate: {
          __typename?: "user_relationship_aggregate";
        } & {
          aggregate?: Maybe<
            { __typename?: "user_relationship_aggregate_fields" } & Pick<
              User_Relationship_Aggregate_Fields,
              "count"
            >
          >;
        };
        user_relationships_forward_aggregate: {
          __typename?: "user_relationship_aggregate";
        } & {
          aggregate?: Maybe<
            { __typename?: "user_relationship_aggregate_fields" } & Pick<
              User_Relationship_Aggregate_Fields,
              "count"
            >
          >;
        };
      }
  >;
};

export type CreateFieldTripMutationVariables = Exact<{
  object: Field_Trips_Insert_Input;
}>;

export type CreateFieldTripMutation = { __typename?: "mutation_root" } & {
  insert_field_trips_one?: Maybe<
    { __typename?: "field_trips" } & Pick<Field_Trips, "uid" | "id">
  >;
};

export type UpdateFieldTripByIdMutationVariables = Exact<{
  id: Scalars["Int"];
  object: Field_Trips_Set_Input;
}>;

export type UpdateFieldTripByIdMutation = { __typename?: "mutation_root" } & {
  update_field_trips_by_pk?: Maybe<
    { __typename?: "field_trips" } & Pick<
      Field_Trips,
      "id" | "updated_at" | "uid"
    >
  >;
};

export type UpdateFieldTripMutationVariables = Exact<{
  id: Scalars["uuid"];
  object: Field_Trips_Set_Input;
}>;

export type UpdateFieldTripMutation = { __typename?: "mutation_root" } & {
  update_field_trips?: Maybe<
    { __typename?: "field_trips_mutation_response" } & {
      returning: Array<
        { __typename?: "field_trips" } & Pick<
          Field_Trips,
          "id" | "updated_at" | "uid"
        >
      >;
    }
  >;
};

export type FieldTripBaseFieldsFragment = { __typename?: "field_trips" } & Pick<
  Field_Trips,
  | "id"
  | "uid"
  | "title"
  | "status"
  | "created_at"
  | "event_date"
  | "school_id"
  | "owner_id"
> & {
    owner: { __typename?: "users" } & Pick<
      Users,
      "id" | "first_name" | "last_name"
    >;
  };

export type EnvisionFieldsFragment = { __typename?: "field_trips" } & Pick<
  Field_Trips,
  "interest_area" | "research_questions_goals"
> & {
    field_trip_students: Array<
      { __typename?: "field_trip_students" } & {
        user: { __typename?: "users" } & Pick<
          Users,
          "id" | "first_name" | "last_name"
        >;
      }
    >;
  } & FieldTripBaseFieldsFragment;

export type FieldTripStatusQueryVariables = Exact<{
  uid: Scalars["uuid"];
}>;

export type FieldTripStatusQuery = { __typename?: "query_root" } & {
  field_trips: Array<
    { __typename?: "field_trips" } & Pick<
      Field_Trips,
      "id" | "uid" | "status" | "title"
    > & {
        my_trip_data?: Maybe<
          { __typename?: "my_field_trip_data" } & Pick<
            My_Field_Trip_Data,
            "id" | "stage"
          >
        >;
      }
  >;
};

export type FieldTripEnvisionQueryVariables = Exact<{
  uid?: Maybe<Scalars["uuid"]>;
}>;

export type FieldTripEnvisionQuery = { __typename?: "query_root" } & {
  field_trips: Array<{ __typename?: "field_trips" } & EnvisionFieldsFragment>;
};

export type FieldTripListFieldsFragment = { __typename?: "field_trips" } & Pick<
  Field_Trips,
  "interest_area"
> & {
    field_trip_students_aggregate: {
      __typename?: "field_trip_students_aggregate";
    } & {
      aggregate?: Maybe<
        { __typename?: "field_trip_students_aggregate_fields" } & Pick<
          Field_Trip_Students_Aggregate_Fields,
          "count"
        >
      >;
    };
    school: { __typename?: "schools" } & Pick<Schools, "name">;
  } & FieldTripBaseFieldsFragment;

export type ListFieldTripQueryVariables = Exact<{
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  status?: Maybe<String_Comparison_Exp>;
}>;

export type ListFieldTripQuery = { __typename?: "query_root" } & {
  field_trips: Array<
    { __typename: "field_trips" } & FieldTripListFieldsFragment
  >;
  field_trips_aggregate: { __typename: "field_trips_aggregate" } & {
    aggregate?: Maybe<
      { __typename: "field_trips_aggregate_fields" } & Pick<
        Field_Trips_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type RemoveFieldTripStudentMutationVariables = Exact<{
  fieldTripId?: Maybe<Scalars["uuid"]>;
  userId?: Maybe<Scalars["uuid"]>;
}>;

export type RemoveFieldTripStudentMutation = {
  __typename?: "mutation_root";
} & {
  delete_field_trip_students?: Maybe<
    { __typename?: "field_trip_students_mutation_response" } & Pick<
      Field_Trip_Students_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type AddFieldTripStudentMutationVariables = Exact<{
  fieldTripId?: Maybe<Scalars["Int"]>;
  userId?: Maybe<Scalars["uuid"]>;
}>;

export type AddFieldTripStudentMutation = { __typename?: "mutation_root" } & {
  insert_field_trip_students_one?: Maybe<
    { __typename?: "field_trip_students" } & Pick<Field_Trip_Students, "id">
  >;
};

export type PermissionSlipFragment = { __typename?: "field_trips" } & Pick<
  Field_Trips,
  "id" | "uid" | "status" | "title"
> & {
    field_trip_students: Array<
      { __typename?: "field_trip_students" } & Pick<
        Field_Trip_Students,
        "id"
      > & {
          user: { __typename?: "users" } & Pick<
            Users,
            "first_name" | "last_name"
          >;
        }
    >;
  };

export type FieldTripParentPermissionQueryVariables = Exact<{
  uid?: Maybe<Scalars["uuid"]>;
}>;

export type FieldTripParentPermissionQuery = { __typename?: "query_root" } & {
  field_trips: Array<
    { __typename?: "field_trips" } & {
      school: { __typename?: "schools" } & Pick<
        Schools,
        "parent_permission_template"
      >;
    } & PermissionSlipFragment
  >;
};

export type FieldTripChaperoneRequestQueryVariables = Exact<{
  uid?: Maybe<Scalars["uuid"]>;
}>;

export type FieldTripChaperoneRequestQuery = { __typename?: "query_root" } & {
  field_trips: Array<
    { __typename?: "field_trips" } & {
      school: { __typename?: "schools" } & Pick<
        Schools,
        "chaperone_request_template"
      >;
    } & PermissionSlipFragment
  >;
};

export type FieldTripDetailQueryVariables = Exact<{
  tripId?: Maybe<Scalars["uuid"]>;
}>;

export type FieldTripDetailQuery = { __typename?: "query_root" } & {
  field_trips: Array<
    { __typename?: "field_trips" } & Pick<
      Field_Trips,
      | "cost_per_person"
      | "created_at"
      | "event_date"
      | "expectations_rules"
      | "id"
      | "interest_area"
      | "title"
      | "total_cost"
      | "transportation_options"
      | "updated_at"
      | "research_questions_goals"
      | "agenda"
      | "supply_list"
    > & {
        owner: { __typename?: "users" } & Pick<
          Users,
          "first_name" | "last_name"
        > & {
            school?: Maybe<
              { __typename?: "schools" } & Pick<
                Schools,
                | "parent_permission_template"
                | "chaperone_request_template"
                | "logo_url"
                | "id"
              >
            >;
          };
        field_trip_locations: Array<
          { __typename?: "field_trip_locations" } & Pick<
            Field_Trip_Locations,
            | "time"
            | "notes"
            | "contact_info"
            | "location_type"
            | "location_id"
            | "id"
          > & {
              location: { __typename?: "locations" } & Pick<
                Locations,
                | "address"
                | "google_maps_url"
                | "id"
                | "location_point"
                | "name"
                | "notes"
              >;
            }
        >;
        field_trip_students: Array<
          { __typename?: "field_trip_students" } & Pick<
            Field_Trip_Students,
            | "transportation_logistics_approval"
            | "grace_courtesy_approval"
            | "enjoyed_most"
            | "gratitude_mail_agreement"
            | "improvements"
            | "share_experience"
            | "share_experience_agreement"
          > & {
              user: { __typename?: "users" } & Pick<
                Users,
                "first_name" | "last_name" | "id"
              > & {
                  user_relationships_forward: Array<
                    { __typename?: "user_relationship" } & {
                      to_user: { __typename?: "users" } & Pick<
                        Users,
                        "id" | "first_name" | "last_name" | "role"
                      >;
                    }
                  >;
                };
              field_trip: { __typename?: "field_trips" } & Pick<
                Field_Trips,
                "interest_area" | "research_questions_goals"
              > & {
                  field_trip_location_reviews: Array<
                    { __typename?: "field_trip_location_reviews" } & Pick<
                      Field_Trip_Location_Reviews,
                      "id" | "rating" | "field_trip_location_id"
                    >
                  >;
                };
            }
        >;
      }
  >;
};

export type FieldTripReflectsQueryVariables = Exact<{
  tripId?: Maybe<Scalars["uuid"]>;
  studentId?: Maybe<Scalars["uuid"]>;
  schoolId?: Maybe<Scalars["uuid"]>;
}>;

export type FieldTripReflectsQuery = { __typename?: "query_root" } & {
  field_trip_students: Array<
    { __typename?: "field_trip_students" } & Pick<
      Field_Trip_Students,
      "reflect_survey" | "user_id"
    >
  >;
  agreement_questions: Array<
    { __typename?: "agreement_questions" } & Pick<
      Agreement_Questions,
      "label" | "id"
    >
  >;
};

export type StudentReflectQuestionQueryVariables = Exact<{
  tripId?: Maybe<Scalars["uuid"]>;
  studentId?: Maybe<Scalars["uuid"]>;
  schoolId?: Maybe<Scalars["uuid"]>;
}>;

export type StudentReflectQuestionQuery = { __typename?: "query_root" } & {
  field_trip_students: Array<
    { __typename?: "field_trip_students" } & Pick<
      Field_Trip_Students,
      "reflect_survey" | "user_id"
    >
  >;
};

export type IsTripCompleteQueryVariables = Exact<{
  tripId: Scalars["uuid"];
  status?: Maybe<Scalars["String"]>;
}>;

export type IsTripCompleteQuery = { __typename?: "query_root" } & {
  field_trips_aggregate: { __typename?: "field_trips_aggregate" } & {
    aggregate?: Maybe<
      { __typename?: "field_trips_aggregate_fields" } & Pick<
        Field_Trips_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type SetParentApprovalMutationVariables = Exact<{
  tripId: Scalars["Int"];
  userId: Scalars["uuid"];
  approved_at?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
}>;

export type SetParentApprovalMutation = { __typename?: "mutation_root" } & {
  update_field_trip_approvals?: Maybe<
    { __typename?: "field_trip_approvals_mutation_response" } & Pick<
      Field_Trip_Approvals_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type FieldTripLocationsQueryVariables = Exact<{
  tripId?: Maybe<Scalars["uuid"]>;
}>;

export type FieldTripLocationsQuery = { __typename?: "query_root" } & {
  field_trip_locations: Array<
    { __typename?: "field_trip_locations" } & Pick<
      Field_Trip_Locations,
      "id" | "field_trip_id" | "time" | "location_type"
    > & {
        location: { __typename?: "locations" } & Pick<
          Locations,
          "id" | "name" | "address" | "location_point" | "google_maps_url"
        >;
      }
  >;
};

export type LocationsByNameQueryVariables = Exact<{
  name?: Maybe<Scalars["String"]>;
  limit?: Maybe<Scalars["Int"]>;
}>;

export type LocationsByNameQuery = { __typename?: "query_root" } & {
  locations: Array<
    { __typename?: "locations" } & Pick<
      Locations,
      "id" | "google_maps_url" | "address" | "name" | "location_point"
    >
  >;
};

export type CreateFieldTripLocationMutationVariables = Exact<{
  object: Field_Trip_Locations_Insert_Input;
}>;

export type CreateFieldTripLocationMutation = {
  __typename?: "mutation_root";
} & {
  insert_field_trip_locations_one?: Maybe<
    { __typename?: "field_trip_locations" } & Pick<Field_Trip_Locations, "id">
  >;
};

export type DeleteFieldTripLocationMutationVariables = Exact<{
  id?: Maybe<Scalars["uuid"]>;
}>;

export type DeleteFieldTripLocationMutation = {
  __typename?: "mutation_root";
} & {
  delete_field_trip_locations?: Maybe<
    { __typename?: "field_trip_locations_mutation_response" } & Pick<
      Field_Trip_Locations_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type PlanFieldsFragment = { __typename?: "field_trips" } & Pick<
  Field_Trips,
  | "agenda"
  | "expectations_rules"
  | "supply_list"
  | "transportation_options"
  | "total_cost"
  | "cost_per_person"
> &
  FieldTripBaseFieldsFragment;

export type FieldTripPlanQueryVariables = Exact<{
  uid?: Maybe<Scalars["uuid"]>;
}>;

export type FieldTripPlanQuery = { __typename?: "query_root" } & {
  field_trips: Array<{ __typename?: "field_trips" } & PlanFieldsFragment>;
};

export type FieldTripStudentReflectFieldsFragment = {
  __typename?: "field_trip_students";
} & Pick<
  Field_Trip_Students,
  | "id"
  | "reflect_survey"
  | "enjoyed_most"
  | "improvements"
  | "share_experience"
  | "share_experience_agreement"
  | "gratitude_mail_agreement"
> & { user: { __typename?: "users" } & UserBaseFieldsFragment };

export type FieldTripStudentReflectQueryVariables = Exact<{
  fieldTripId: Scalars["uuid"];
  studentId: Scalars["uuid"];
}>;

export type FieldTripStudentReflectQuery = { __typename?: "query_root" } & {
  field_trip_students: Array<
    {
      __typename?: "field_trip_students";
    } & FieldTripStudentReflectFieldsFragment
  >;
};

export type FieldTripReflectFieldsFragment = { __typename?: "field_trips" } & {
  field_trip_students: Array<
    { __typename?: "field_trip_students" } & Pick<Field_Trip_Students, "id"> & {
        user: { __typename?: "users" } & Pick<
          Users,
          "first_name" | "last_name" | "role" | "id"
        >;
      }
  >;
} & FieldTripBaseFieldsFragment;

export type FieldTripReflectQueryVariables = Exact<{
  uid?: Maybe<Scalars["uuid"]>;
}>;

export type FieldTripReflectQuery = { __typename?: "query_root" } & {
  field_trips: Array<
    { __typename?: "field_trips" } & FieldTripReflectFieldsFragment
  >;
};

export type AddLocationReviewMutationVariables = Exact<{
  field_trip_location_id: Scalars["uuid"];
  field_trip_id: Scalars["Int"];
  user_id: Scalars["uuid"];
  rating: Scalars["Int"];
}>;

export type AddLocationReviewMutation = { __typename?: "mutation_root" } & {
  insert_field_trip_location_reviews_one?: Maybe<
    { __typename?: "field_trip_location_reviews" } & Pick<
      Field_Trip_Location_Reviews,
      "id"
    >
  >;
};

export type FieldTripLocationReviewsQueryVariables = Exact<{
  userId: Scalars["uuid"];
  tripId: Scalars["Int"];
}>;

export type FieldTripLocationReviewsQuery = { __typename?: "query_root" } & {
  field_trip_location_reviews: Array<
    { __typename?: "field_trip_location_reviews" } & Pick<
      Field_Trip_Location_Reviews,
      "id" | "rating" | "field_trip_location_id"
    >
  >;
};

export type FieldTripCompleteMutationVariables = Exact<{
  tripId?: Maybe<Scalars["uuid"]>;
  status?: Maybe<Scalars["String"]>;
}>;

export type FieldTripCompleteMutation = { __typename?: "mutation_root" } & {
  update_field_trips?: Maybe<
    { __typename?: "field_trips_mutation_response" } & Pick<
      Field_Trips_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type FieldTripReflectQuestionsQueryVariables = Exact<{
  school_id?: Maybe<Scalars["uuid"]>;
  question_type?: Maybe<Scalars["String"]>;
}>;

export type FieldTripReflectQuestionsQuery = { __typename?: "query_root" } & {
  agreement_questions: Array<
    { __typename?: "agreement_questions" } & Pick<
      Agreement_Questions,
      "id" | "label"
    >
  >;
};

export type UserFieldsFragment = { __typename?: "users" } & Pick<
  Users,
  "id" | "username" | "role" | "first_name" | "last_name" | "email" | "status"
> & {
    school?: Maybe<{ __typename?: "schools" } & Pick<Schools, "name">>;
    class_room?: Maybe<
      { __typename?: "class_rooms" } & Pick<Class_Rooms, "id" | "name">
    >;
    user_relationships_backward_aggregate: {
      __typename?: "user_relationship_aggregate";
    } & {
      aggregate?: Maybe<
        { __typename?: "user_relationship_aggregate_fields" } & Pick<
          User_Relationship_Aggregate_Fields,
          "count"
        >
      >;
    };
    user_relationships_forward_aggregate: {
      __typename?: "user_relationship_aggregate";
    } & {
      aggregate?: Maybe<
        { __typename?: "user_relationship_aggregate_fields" } & Pick<
          User_Relationship_Aggregate_Fields,
          "count"
        >
      >;
    };
  };

export type FindUsersQueryVariables = Exact<{
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  roles?: Maybe<User_Roles_Enum_Comparison_Exp>;
  school_id?: Maybe<Uuid_Comparison_Exp>;
}>;

export type FindUsersQuery = { __typename?: "query_root" } & {
  users: Array<{ __typename?: "users" } & UserFieldsFragment>;
  users_aggregate: { __typename?: "users_aggregate" } & {
    aggregate?: Maybe<
      { __typename?: "users_aggregate_fields" } & Pick<
        Users_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type UsersSubscriptionVariables = Exact<{
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
}>;

export type UsersSubscription = { __typename?: "subscription_root" } & {
  users: Array<{ __typename?: "users" } & UserFieldsFragment>;
};

export type CreateUsersMutationVariables = Exact<{
  input: Array<CreateUserInput>;
}>;

export type CreateUsersMutation = { __typename?: "mutation_root" } & {
  createUsers?: Maybe<
    Array<
      Maybe<{ __typename?: "CreateUserOutput" } & Pick<CreateUserOutput, "id">>
    >
  >;
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars["uuid"];
  input: Users_Set_Input;
}>;

export type UpdateUserMutation = { __typename?: "mutation_root" } & {
  update_users_by_pk?: Maybe<
    { __typename?: "users" } & Pick<Users, "first_name">
  >;
};

export type UserRelationshipTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserRelationshipTypesQuery = { __typename?: "query_root" } & {
  user_relationship_types: Array<
    { __typename?: "user_relationship_types" } & Pick<
      User_Relationship_Types,
      "id" | "forward" | "backward" | "details"
    >
  >;
};

export type FindUserRelationshipsQueryVariables = Exact<{
  userId: Scalars["uuid"];
}>;

export type FindUserRelationshipsQuery = { __typename?: "query_root" } & {
  user_relationship: Array<
    { __typename?: "user_relationship" } & Pick<User_Relationship, "id"> & {
        from_user: { __typename?: "users" } & Pick<
          Users,
          "id" | "first_name" | "last_name" | "role"
        >;
        to_user: { __typename?: "users" } & Pick<
          Users,
          "id" | "first_name" | "last_name" | "role"
        >;
        user_relationship_type: {
          __typename?: "user_relationship_types";
        } & Pick<User_Relationship_Types, "forward" | "backward" | "details">;
      }
  >;
};

export type CreateUserRelationshipMutationVariables = Exact<{
  object: User_Relationship_Insert_Input;
}>;

export type CreateUserRelationshipMutation = {
  __typename?: "mutation_root";
} & {
  insert_user_relationship_one?: Maybe<
    { __typename?: "user_relationship" } & Pick<User_Relationship, "id">
  >;
};

export type DeleteUserRelationshipMutationVariables = Exact<{
  id: Scalars["uuid"];
}>;

export type DeleteUserRelationshipMutation = {
  __typename?: "mutation_root";
} & {
  delete_user_relationship_by_pk?: Maybe<
    { __typename?: "user_relationship" } & Pick<User_Relationship, "id">
  >;
};

export type UsersCountQueryVariables = Exact<{
  roles?: Maybe<Array<User_Roles_Enum>>;
}>;

export type UsersCountQuery = { __typename?: "query_root" } & {
  users_aggregate: { __typename?: "users_aggregate" } & {
    aggregate?: Maybe<
      { __typename?: "users_aggregate_fields" } & Pick<
        Users_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type CurrentUserNamesQueryVariables = Exact<{
  userName: Scalars["String"];
}>;

export type CurrentUserNamesQuery = { __typename?: "query_root" } & {
  user_names: Array<
    { __typename?: "user_names" } & Pick<User_Names, "username">
  >;
};

export type InsertVirtualProposalTripMutationVariables = Exact<{
  topic: Scalars["String"];
  question: Scalars["String"];
}>;

export type InsertVirtualProposalTripMutation = {
  __typename?: "mutation_root";
} & {
  insert_virtual_trip_proposals?: Maybe<
    { __typename?: "virtual_trip_proposals_mutation_response" } & Pick<
      Virtual_Trip_Proposals_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type InsertVirtualTripPlanMutationVariables = Exact<{
  event_date: Scalars["timestamptz"];
  event_details: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
}>;

export type InsertVirtualTripPlanMutation = { __typename?: "mutation_root" } & {
  insert_virtual_trip_plans?: Maybe<
    { __typename?: "virtual_trip_plans_mutation_response" } & Pick<
      Virtual_Trip_Plans_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type VirtualProposalTripsQueryVariables = Exact<{
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
}>;

export type VirtualProposalTripsQuery = { __typename?: "query_root" } & {
  virtual_trip_proposals: Array<
    { __typename?: "virtual_trip_proposals" } & Pick<
      Virtual_Trip_Proposals,
      "question" | "id" | "topic" | "created_at"
    > & {
        user: { __typename?: "users" } & Pick<
          Users,
          "first_name" | "last_name"
        > & {
            school?: Maybe<{ __typename?: "schools" } & Pick<Schools, "name">>;
          };
      }
  >;
  virtual_trip_proposals_aggregate: {
    __typename?: "virtual_trip_proposals_aggregate";
  } & {
    aggregate?: Maybe<
      { __typename?: "virtual_trip_proposals_aggregate_fields" } & Pick<
        Virtual_Trip_Proposals_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type VirtualPlanTripsQueryVariables = Exact<{
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  sortById?: Maybe<Order_By>;
  sortByEventDate?: Maybe<Order_By>;
}>;

export type VirtualPlanTripsQuery = { __typename?: "query_root" } & {
  virtual_trip_plans: Array<
    { __typename?: "virtual_trip_plans" } & Pick<
      Virtual_Trip_Plans,
      "event_date" | "event_details" | "created_at" | "title" | "id"
    > & {
        user: { __typename?: "users" } & Pick<
          Users,
          "first_name" | "last_name"
        > & {
            school?: Maybe<{ __typename?: "schools" } & Pick<Schools, "name">>;
          };
      }
  >;
  virtual_trip_plans_aggregate: {
    __typename?: "virtual_trip_plans_aggregate";
  } & {
    aggregate?: Maybe<
      { __typename?: "virtual_trip_plans_aggregate_fields" } & Pick<
        Virtual_Trip_Plans_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type SchoolsQueryVariables = Exact<{ [key: string]: never }>;

export type SchoolsQuery = { __typename?: "query_root" } & {
  schools: Array<{ __typename?: "schools" } & Pick<Schools, "id" | "name">>;
};

export type CreateVirtualTripMutationVariables = Exact<{
  object: Virtual_Trips_Insert_Input;
}>;

export type CreateVirtualTripMutation = { __typename?: "mutation_root" } & {
  insert_virtual_trips_one?: Maybe<
    { __typename?: "virtual_trips" } & Pick<Virtual_Trips, "topic">
  >;
};

export type DeleteVirtualPlanTripsMutationVariables = Exact<{
  planTripId: Scalars["Int"];
}>;

export type DeleteVirtualPlanTripsMutation = {
  __typename?: "mutation_root";
} & {
  delete_virtual_trip_plans?: Maybe<
    { __typename?: "virtual_trip_plans_mutation_response" } & Pick<
      Virtual_Trip_Plans_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type DeleteVirtualProposalTripsMutationVariables = Exact<{
  proposalTripId: Scalars["Int"];
}>;

export type DeleteVirtualProposalTripsMutation = {
  __typename?: "mutation_root";
} & {
  delete_virtual_trip_proposals?: Maybe<
    { __typename?: "virtual_trip_proposals_mutation_response" } & Pick<
      Virtual_Trip_Proposals_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type UpComingTripsQueryVariables = Exact<{
  limit?: Maybe<Scalars["Int"]>;
  dateRange?: Maybe<Timestamptz_Comparison_Exp>;
  offset?: Maybe<Scalars["Int"]>;
  sortEventDate?: Maybe<Order_By>;
  sortCreatedDate?: Maybe<Order_By>;
}>;

export type UpComingTripsQuery = { __typename?: "query_root" } & {
  virtual_trips: Array<
    { __typename?: "virtual_trips" } & Pick<
      Virtual_Trips,
      | "all_schools"
      | "event_date"
      | "id"
      | "meeting_link"
      | "topic"
      | "created_at"
      | "description"
    > & {
        virtual_trip_images: Array<
          { __typename?: "virtual_trip_images" } & Pick<
            Virtual_Trip_Images,
            "image_link" | "id"
          >
        >;
        virtual_trip_schools: Array<
          { __typename?: "virtual_trip_schools" } & {
            school: { __typename?: "schools" } & Pick<Schools, "name" | "id">;
          }
        >;
      }
  >;
  virtual_trips_aggregate: { __typename?: "virtual_trips_aggregate" } & {
    aggregate?: Maybe<
      { __typename?: "virtual_trips_aggregate_fields" } & Pick<
        Virtual_Trips_Aggregate_Fields,
        "count"
      >
    >;
  };
};

export type GetVirtualTripByIdQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type GetVirtualTripByIdQuery = { __typename?: "query_root" } & {
  virtual_trips_by_pk?: Maybe<
    { __typename?: "virtual_trips" } & Pick<
      Virtual_Trips,
      | "description"
      | "event_date"
      | "id"
      | "meeting_link"
      | "notes"
      | "title"
      | "topic"
      | "all_schools"
    > & {
        virtual_trip_images: Array<
          { __typename?: "virtual_trip_images" } & Pick<
            Virtual_Trip_Images,
            "image_link" | "id"
          >
        >;
        virtual_trip_schools: Array<
          { __typename?: "virtual_trip_schools" } & {
            school: { __typename?: "schools" } & Pick<Schools, "name" | "id">;
          }
        >;
      }
  >;
};

export type DeleteVirtualTripMutationVariables = Exact<{
  id: Scalars["Int"];
}>;

export type DeleteVirtualTripMutation = { __typename?: "mutation_root" } & {
  delete_virtual_trip_images?: Maybe<
    { __typename?: "virtual_trip_images_mutation_response" } & Pick<
      Virtual_Trip_Images_Mutation_Response,
      "affected_rows"
    >
  >;
  delete_virtual_trip_schools?: Maybe<
    { __typename?: "virtual_trip_schools_mutation_response" } & Pick<
      Virtual_Trip_Schools_Mutation_Response,
      "affected_rows"
    >
  >;
  delete_virtual_trips_by_pk?: Maybe<
    { __typename?: "virtual_trips" } & Pick<Virtual_Trips, "id">
  >;
};

export type ProposalTripsTopicsQueryVariables = Exact<{ [key: string]: never }>;

export type ProposalTripsTopicsQuery = { __typename?: "query_root" } & {
  virtual_trip_proposals_topic: Array<
    { __typename?: "virtual_trip_proposals_topic" } & Pick<
      Virtual_Trip_Proposals_Topic,
      "count" | "topic"
    >
  >;
  virtual_trip_proposals_topic_aggregate: {
    __typename?: "virtual_trip_proposals_topic_aggregate";
  } & {
    aggregate?: Maybe<
      { __typename?: "virtual_trip_proposals_topic_aggregate_fields" } & {
        sum?: Maybe<
          { __typename?: "virtual_trip_proposals_topic_sum_fields" } & Pick<
            Virtual_Trip_Proposals_Topic_Sum_Fields,
            "count"
          >
        >;
      }
    >;
  };
};

export type DeleteVirtualTripSchoolMutationVariables = Exact<{
  tripId: Scalars["Int"];
  schoolId: Scalars["uuid"];
}>;

export type DeleteVirtualTripSchoolMutation = {
  __typename?: "mutation_root";
} & {
  delete_virtual_trip_schools?: Maybe<
    { __typename?: "virtual_trip_schools_mutation_response" } & Pick<
      Virtual_Trip_Schools_Mutation_Response,
      "affected_rows"
    >
  >;
};

export type DeleteVirtualTripImagesMutationVariables = Exact<{
  tripId: Scalars["Int"];
}>;

export type DeleteVirtualTripImagesMutation = {
  __typename?: "mutation_root";
} & {
  delete_virtual_trip_images?: Maybe<
    { __typename?: "virtual_trip_images_mutation_response" } & Pick<
      Virtual_Trip_Images_Mutation_Response,
      "affected_rows"
    >
  >;
};

export const MyFieldsFragmentDoc = gql`
  fragment MyFields on me {
    id
    username
    role
    first_name
    last_name
    email
    school {
      id
      name
    }
    status
  }
`;
export const FieldTripBaseFieldsFragmentDoc = gql`
  fragment FieldTripBaseFields on field_trips {
    id
    uid
    title
    status
    created_at
    event_date
    school_id
    owner_id
    owner {
      id
      first_name
      last_name
    }
  }
`;
export const ApproveFieldsFragmentDoc = gql`
  fragment ApproveFields on field_trips {
    ...FieldTripBaseFields
  }
  ${FieldTripBaseFieldsFragmentDoc}
`;
export const UserBaseFieldsFragmentDoc = gql`
  fragment UserBaseFields on users {
    id
    first_name
    last_name
    role
  }
`;
export const FieldTripStudentFieldsFragmentDoc = gql`
  fragment FieldTripStudentFields on users {
    ...UserBaseFields
    class_room {
      name
    }
    user_relationships_forward(where: { to_user: { role: { _eq: parent } } }) {
      to_user {
        ...UserBaseFields
      }
    }
    user_relationships_backward(
      where: { from_user: { role: { _eq: parent } } }
    ) {
      from_user {
        ...UserBaseFields
      }
    }
  }
  ${UserBaseFieldsFragmentDoc}
`;
export const ApprovalUserFieldsFragmentDoc = gql`
  fragment ApprovalUserFields on field_trip_approvals {
    id
    user {
      ...UserBaseFields
    }
    is_chaperone
    status
    approved_at
  }
  ${UserBaseFieldsFragmentDoc}
`;
export const EnvisionFieldsFragmentDoc = gql`
  fragment EnvisionFields on field_trips {
    ...FieldTripBaseFields
    interest_area
    research_questions_goals
    field_trip_students {
      user {
        id
        first_name
        last_name
      }
    }
  }
  ${FieldTripBaseFieldsFragmentDoc}
`;
export const FieldTripListFieldsFragmentDoc = gql`
  fragment FieldTripListFields on field_trips {
    ...FieldTripBaseFields
    interest_area
    field_trip_students_aggregate {
      aggregate {
        count(columns: id)
      }
    }
    school {
      name
    }
  }
  ${FieldTripBaseFieldsFragmentDoc}
`;
export const PermissionSlipFragmentDoc = gql`
  fragment PermissionSlip on field_trips {
    id
    uid
    status
    title
    field_trip_students {
      id
      user {
        first_name
        last_name
      }
    }
  }
`;
export const PlanFieldsFragmentDoc = gql`
  fragment PlanFields on field_trips {
    ...FieldTripBaseFields
    agenda
    expectations_rules
    supply_list
    transportation_options
    total_cost
    cost_per_person
  }
  ${FieldTripBaseFieldsFragmentDoc}
`;
export const FieldTripStudentReflectFieldsFragmentDoc = gql`
  fragment FieldTripStudentReflectFields on field_trip_students {
    id
    user {
      ...UserBaseFields
    }
    reflect_survey
    enjoyed_most
    improvements
    share_experience
    share_experience_agreement
    gratitude_mail_agreement
  }
  ${UserBaseFieldsFragmentDoc}
`;
export const FieldTripReflectFieldsFragmentDoc = gql`
  fragment FieldTripReflectFields on field_trips {
    ...FieldTripBaseFields
    field_trip_students {
      id
      user {
        first_name
        last_name
        role
        id
      }
    }
  }
  ${FieldTripBaseFieldsFragmentDoc}
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on users {
    id
    username
    role
    first_name
    last_name
    email
    school {
      name
    }
    class_room {
      id
      name
    }
    status
    user_relationships_backward_aggregate {
      aggregate {
        count(columns: id)
      }
    }
    user_relationships_forward_aggregate {
      aggregate {
        count(columns: id)
      }
    }
  }
`;
export const FindClassRoomsDocument = gql`
  query findClassRooms($school_id: uuid_comparison_exp = {}) {
    class_rooms(where: { school_id: $school_id }) {
      id
      name
      notes
      users_aggregate {
        aggregate {
          count(columns: id)
        }
      }
    }
  }
`;

/**
 * __useFindClassRoomsQuery__
 *
 * To run a query within a React component, call `useFindClassRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindClassRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindClassRoomsQuery({
 *   variables: {
 *      school_id: // value for 'school_id'
 *   },
 * });
 */
export function useFindClassRoomsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindClassRoomsQuery,
    FindClassRoomsQueryVariables
  >
) {
  return Apollo.useQuery<FindClassRoomsQuery, FindClassRoomsQueryVariables>(
    FindClassRoomsDocument,
    baseOptions
  );
}
export function useFindClassRoomsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindClassRoomsQuery,
    FindClassRoomsQueryVariables
  >
) {
  return Apollo.useLazyQuery<FindClassRoomsQuery, FindClassRoomsQueryVariables>(
    FindClassRoomsDocument,
    baseOptions
  );
}
export type FindClassRoomsQueryHookResult = ReturnType<
  typeof useFindClassRoomsQuery
>;
export type FindClassRoomsLazyQueryHookResult = ReturnType<
  typeof useFindClassRoomsLazyQuery
>;
export type FindClassRoomsQueryResult = Apollo.QueryResult<
  FindClassRoomsQuery,
  FindClassRoomsQueryVariables
>;
export const CreateClassRoomDocument = gql`
  mutation createClassRoom($object: class_rooms_insert_input!) {
    insert_class_rooms_one(object: $object) {
      id
    }
  }
`;
export type CreateClassRoomMutationFn = Apollo.MutationFunction<
  CreateClassRoomMutation,
  CreateClassRoomMutationVariables
>;

/**
 * __useCreateClassRoomMutation__
 *
 * To run a mutation, you first call `useCreateClassRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassRoomMutation, { data, loading, error }] = useCreateClassRoomMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateClassRoomMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClassRoomMutation,
    CreateClassRoomMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateClassRoomMutation,
    CreateClassRoomMutationVariables
  >(CreateClassRoomDocument, baseOptions);
}
export type CreateClassRoomMutationHookResult = ReturnType<
  typeof useCreateClassRoomMutation
>;
export type CreateClassRoomMutationResult = Apollo.MutationResult<
  CreateClassRoomMutation
>;
export type CreateClassRoomMutationOptions = Apollo.BaseMutationOptions<
  CreateClassRoomMutation,
  CreateClassRoomMutationVariables
>;
export const UpdateClassRoomDocument = gql`
  mutation updateClassRoom($id: uuid!, $name: String, $notes: String) {
    update_class_rooms_by_pk(
      pk_columns: { id: $id }
      _set: { name: $name, notes: $notes }
    ) {
      id
    }
  }
`;
export type UpdateClassRoomMutationFn = Apollo.MutationFunction<
  UpdateClassRoomMutation,
  UpdateClassRoomMutationVariables
>;

/**
 * __useUpdateClassRoomMutation__
 *
 * To run a mutation, you first call `useUpdateClassRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassRoomMutation, { data, loading, error }] = useUpdateClassRoomMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateClassRoomMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClassRoomMutation,
    UpdateClassRoomMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateClassRoomMutation,
    UpdateClassRoomMutationVariables
  >(UpdateClassRoomDocument, baseOptions);
}
export type UpdateClassRoomMutationHookResult = ReturnType<
  typeof useUpdateClassRoomMutation
>;
export type UpdateClassRoomMutationResult = Apollo.MutationResult<
  UpdateClassRoomMutation
>;
export type UpdateClassRoomMutationOptions = Apollo.BaseMutationOptions<
  UpdateClassRoomMutation,
  UpdateClassRoomMutationVariables
>;
export const DashboardCountDocument = gql`
  query dashboardCount($since: timestamptz!, $next: date!) {
    users_aggregate(where: { created_at: { _gte: $since } }) {
      aggregate {
        count
      }
    }
    field_trips_aggregate(
      where: {
        _and: { event_date: { _gte: "now()" } }
        event_date: { _lte: $next }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useDashboardCountQuery__
 *
 * To run a query within a React component, call `useDashboardCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardCountQuery({
 *   variables: {
 *      since: // value for 'since'
 *      next: // value for 'next'
 *   },
 * });
 */
export function useDashboardCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DashboardCountQuery,
    DashboardCountQueryVariables
  >
) {
  return Apollo.useQuery<DashboardCountQuery, DashboardCountQueryVariables>(
    DashboardCountDocument,
    baseOptions
  );
}
export function useDashboardCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardCountQuery,
    DashboardCountQueryVariables
  >
) {
  return Apollo.useLazyQuery<DashboardCountQuery, DashboardCountQueryVariables>(
    DashboardCountDocument,
    baseOptions
  );
}
export type DashboardCountQueryHookResult = ReturnType<
  typeof useDashboardCountQuery
>;
export type DashboardCountLazyQueryHookResult = ReturnType<
  typeof useDashboardCountLazyQuery
>;
export type DashboardCountQueryResult = Apollo.QueryResult<
  DashboardCountQuery,
  DashboardCountQueryVariables
>;
export const DashboardTripsDocument = gql`
  query dashboardTrips(
    $from: date = "now()"
    $to: date!
    $limit: Int = 5
    $status: String
  ) {
    field_trips(
      where: {
        _and: { event_date: { _gte: $from } }
        event_date: { _lte: $to }
        status: { _eq: $status }
      }
      limit: $limit
    ) {
      uid
      id
      title
      event_date
      created_at
      status
    }
  }
`;

/**
 * __useDashboardTripsQuery__
 *
 * To run a query within a React component, call `useDashboardTripsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardTripsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardTripsQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      limit: // value for 'limit'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useDashboardTripsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DashboardTripsQuery,
    DashboardTripsQueryVariables
  >
) {
  return Apollo.useQuery<DashboardTripsQuery, DashboardTripsQueryVariables>(
    DashboardTripsDocument,
    baseOptions
  );
}
export function useDashboardTripsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardTripsQuery,
    DashboardTripsQueryVariables
  >
) {
  return Apollo.useLazyQuery<DashboardTripsQuery, DashboardTripsQueryVariables>(
    DashboardTripsDocument,
    baseOptions
  );
}
export type DashboardTripsQueryHookResult = ReturnType<
  typeof useDashboardTripsQuery
>;
export type DashboardTripsLazyQueryHookResult = ReturnType<
  typeof useDashboardTripsLazyQuery
>;
export type DashboardTripsQueryResult = Apollo.QueryResult<
  DashboardTripsQuery,
  DashboardTripsQueryVariables
>;
export const DashboardTripsSummaryDocument = gql`
  query dashboardTripsSummary(
    $limit: Int = 5
    $userId: uuid_comparison_exp = {}
  ) {
    needsApproval: field_trips(
      where: {
        field_trip_approvals: { user_id: $userId, status: { _eq: "pending" } }
      }
      limit: $limit
      order_by: { event_date: desc }
    ) {
      uid
      id
      title
      event_date
      created_at
      status
    }
    needsApprovalCount: field_trips_aggregate(
      where: {
        field_trip_approvals: { user_id: $userId, status: { _eq: "pending" } }
      }
    ) {
      aggregate {
        count
      }
    }
    needsReflection: field_trips(
      where: {
        _and: {
          status: { _eq: "reflect" }
          field_trip_approvals: { user_id: $userId }
        }
      }
      limit: $limit
      order_by: { event_date: desc }
    ) {
      uid
      id
      title
      event_date
      created_at
      status
    }
    needsReflectionCount: field_trips_aggregate(
      where: {
        _and: {
          status: { _eq: "reflect" }
          field_trip_approvals: { user_id: $userId }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    completedTrips: field_trips(
      where: { _and: { status: { _eq: "complete" } } }
      limit: $limit
      order_by: { event_date: desc }
    ) {
      uid
      id
      title
      event_date
      created_at
      status
    }
    completedTripsCount: field_trips_aggregate(
      where: { _and: { status: { _eq: "complete" } } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useDashboardTripsSummaryQuery__
 *
 * To run a query within a React component, call `useDashboardTripsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardTripsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardTripsSummaryQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDashboardTripsSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DashboardTripsSummaryQuery,
    DashboardTripsSummaryQueryVariables
  >
) {
  return Apollo.useQuery<
    DashboardTripsSummaryQuery,
    DashboardTripsSummaryQueryVariables
  >(DashboardTripsSummaryDocument, baseOptions);
}
export function useDashboardTripsSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardTripsSummaryQuery,
    DashboardTripsSummaryQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    DashboardTripsSummaryQuery,
    DashboardTripsSummaryQueryVariables
  >(DashboardTripsSummaryDocument, baseOptions);
}
export type DashboardTripsSummaryQueryHookResult = ReturnType<
  typeof useDashboardTripsSummaryQuery
>;
export type DashboardTripsSummaryLazyQueryHookResult = ReturnType<
  typeof useDashboardTripsSummaryLazyQuery
>;
export type DashboardTripsSummaryQueryResult = Apollo.QueryResult<
  DashboardTripsSummaryQuery,
  DashboardTripsSummaryQueryVariables
>;
export const FindMeDocument = gql`
  query findMe {
    me {
      ...MyFields
      school {
        logo_url
        color_theme
        school_product_features {
          product_feature {
            feature_name
            feature_slug
          }
        }
      }
    }
  }
  ${MyFieldsFragmentDoc}
`;

/**
 * __useFindMeQuery__
 *
 * To run a query within a React component, call `useFindMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindMeQuery(
  baseOptions?: Apollo.QueryHookOptions<FindMeQuery, FindMeQueryVariables>
) {
  return Apollo.useQuery<FindMeQuery, FindMeQueryVariables>(
    FindMeDocument,
    baseOptions
  );
}
export function useFindMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindMeQuery, FindMeQueryVariables>
) {
  return Apollo.useLazyQuery<FindMeQuery, FindMeQueryVariables>(
    FindMeDocument,
    baseOptions
  );
}
export type FindMeQueryHookResult = ReturnType<typeof useFindMeQuery>;
export type FindMeLazyQueryHookResult = ReturnType<typeof useFindMeLazyQuery>;
export type FindMeQueryResult = Apollo.QueryResult<
  FindMeQuery,
  FindMeQueryVariables
>;
export const InsertSchoolDocument = gql`
  mutation insertSchool($object: schools_insert_input!) {
    insert_schools_one(object: $object) {
      id
    }
  }
`;
export type InsertSchoolMutationFn = Apollo.MutationFunction<
  InsertSchoolMutation,
  InsertSchoolMutationVariables
>;

/**
 * __useInsertSchoolMutation__
 *
 * To run a mutation, you first call `useInsertSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSchoolMutation, { data, loading, error }] = useInsertSchoolMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertSchoolMutation,
    InsertSchoolMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertSchoolMutation,
    InsertSchoolMutationVariables
  >(InsertSchoolDocument, baseOptions);
}
export type InsertSchoolMutationHookResult = ReturnType<
  typeof useInsertSchoolMutation
>;
export type InsertSchoolMutationResult = Apollo.MutationResult<
  InsertSchoolMutation
>;
export type InsertSchoolMutationOptions = Apollo.BaseMutationOptions<
  InsertSchoolMutation,
  InsertSchoolMutationVariables
>;
export const UpdateSchoolDocument = gql`
  mutation updateSchool($id: uuid!, $object: schools_set_input!) {
    update_schools_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`;
export type UpdateSchoolMutationFn = Apollo.MutationFunction<
  UpdateSchoolMutation,
  UpdateSchoolMutationVariables
>;

/**
 * __useUpdateSchoolMutation__
 *
 * To run a mutation, you first call `useUpdateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchoolMutation, { data, loading, error }] = useUpdateSchoolMutation({
 *   variables: {
 *      id: // value for 'id'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSchoolMutation,
    UpdateSchoolMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateSchoolMutation,
    UpdateSchoolMutationVariables
  >(UpdateSchoolDocument, baseOptions);
}
export type UpdateSchoolMutationHookResult = ReturnType<
  typeof useUpdateSchoolMutation
>;
export type UpdateSchoolMutationResult = Apollo.MutationResult<
  UpdateSchoolMutation
>;
export type UpdateSchoolMutationOptions = Apollo.BaseMutationOptions<
  UpdateSchoolMutation,
  UpdateSchoolMutationVariables
>;
export const ListSchoolsDocument = gql`
  query listSchools($limit: Int = 10, $offset: Int = 0) {
    schools(limit: $limit, offset: $offset) {
      id
      name
      city
      state
      street_address
      zip_code
      created_at
      school_type
      secondary_school_type
      users_aggregate {
        aggregate {
          count(columns: id)
        }
      }
      class_rooms_aggregate {
        aggregate {
          count(columns: id)
        }
      }
    }
    schools_aggregate {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useListSchoolsQuery__
 *
 * To run a query within a React component, call `useListSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSchoolsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListSchoolsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListSchoolsQuery,
    ListSchoolsQueryVariables
  >
) {
  return Apollo.useQuery<ListSchoolsQuery, ListSchoolsQueryVariables>(
    ListSchoolsDocument,
    baseOptions
  );
}
export function useListSchoolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListSchoolsQuery,
    ListSchoolsQueryVariables
  >
) {
  return Apollo.useLazyQuery<ListSchoolsQuery, ListSchoolsQueryVariables>(
    ListSchoolsDocument,
    baseOptions
  );
}
export type ListSchoolsQueryHookResult = ReturnType<typeof useListSchoolsQuery>;
export type ListSchoolsLazyQueryHookResult = ReturnType<
  typeof useListSchoolsLazyQuery
>;
export type ListSchoolsQueryResult = Apollo.QueryResult<
  ListSchoolsQuery,
  ListSchoolsQueryVariables
>;
export const ListAgreementQuestionsDocument = gql`
  query listAgreementQuestions(
    $schoolId: uuid!
    $type: String_comparison_exp = {}
  ) {
    agreement_questions(
      where: { _and: { school_id: { _eq: $schoolId }, type: $type } }
    ) {
      id
      label
      type
    }
  }
`;

/**
 * __useListAgreementQuestionsQuery__
 *
 * To run a query within a React component, call `useListAgreementQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAgreementQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAgreementQuestionsQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useListAgreementQuestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAgreementQuestionsQuery,
    ListAgreementQuestionsQueryVariables
  >
) {
  return Apollo.useQuery<
    ListAgreementQuestionsQuery,
    ListAgreementQuestionsQueryVariables
  >(ListAgreementQuestionsDocument, baseOptions);
}
export function useListAgreementQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAgreementQuestionsQuery,
    ListAgreementQuestionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ListAgreementQuestionsQuery,
    ListAgreementQuestionsQueryVariables
  >(ListAgreementQuestionsDocument, baseOptions);
}
export type ListAgreementQuestionsQueryHookResult = ReturnType<
  typeof useListAgreementQuestionsQuery
>;
export type ListAgreementQuestionsLazyQueryHookResult = ReturnType<
  typeof useListAgreementQuestionsLazyQuery
>;
export type ListAgreementQuestionsQueryResult = Apollo.QueryResult<
  ListAgreementQuestionsQuery,
  ListAgreementQuestionsQueryVariables
>;
export const SchoolInfoDocument = gql`
  query schoolInfo($schoolId: uuid!) {
    schools_by_pk(id: $schoolId) {
      id
      created_at
      color_theme
      logo_url
      montessori_affiliation
      name
      notes
      school_type
      secondary_school_type
      state
      street_address
      street_address_2
      updated_at
      zip_code
      city
      parent_permission_template
      chaperone_request_template
    }
  }
`;

/**
 * __useSchoolInfoQuery__
 *
 * To run a query within a React component, call `useSchoolInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolInfoQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useSchoolInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SchoolInfoQuery,
    SchoolInfoQueryVariables
  >
) {
  return Apollo.useQuery<SchoolInfoQuery, SchoolInfoQueryVariables>(
    SchoolInfoDocument,
    baseOptions
  );
}
export function useSchoolInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SchoolInfoQuery,
    SchoolInfoQueryVariables
  >
) {
  return Apollo.useLazyQuery<SchoolInfoQuery, SchoolInfoQueryVariables>(
    SchoolInfoDocument,
    baseOptions
  );
}
export type SchoolInfoQueryHookResult = ReturnType<typeof useSchoolInfoQuery>;
export type SchoolInfoLazyQueryHookResult = ReturnType<
  typeof useSchoolInfoLazyQuery
>;
export type SchoolInfoQueryResult = Apollo.QueryResult<
  SchoolInfoQuery,
  SchoolInfoQueryVariables
>;
export const InsertAgreementQuestionsDocument = gql`
  mutation insertAgreementQuestions(
    $objects: [agreement_questions_insert_input!]!
  ) {
    insert_agreement_questions(objects: $objects) {
      returning {
        id
        label
        type
      }
    }
  }
`;
export type InsertAgreementQuestionsMutationFn = Apollo.MutationFunction<
  InsertAgreementQuestionsMutation,
  InsertAgreementQuestionsMutationVariables
>;

/**
 * __useInsertAgreementQuestionsMutation__
 *
 * To run a mutation, you first call `useInsertAgreementQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertAgreementQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAgreementQuestionsMutation, { data, loading, error }] = useInsertAgreementQuestionsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertAgreementQuestionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertAgreementQuestionsMutation,
    InsertAgreementQuestionsMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertAgreementQuestionsMutation,
    InsertAgreementQuestionsMutationVariables
  >(InsertAgreementQuestionsDocument, baseOptions);
}
export type InsertAgreementQuestionsMutationHookResult = ReturnType<
  typeof useInsertAgreementQuestionsMutation
>;
export type InsertAgreementQuestionsMutationResult = Apollo.MutationResult<
  InsertAgreementQuestionsMutation
>;
export type InsertAgreementQuestionsMutationOptions = Apollo.BaseMutationOptions<
  InsertAgreementQuestionsMutation,
  InsertAgreementQuestionsMutationVariables
>;
export const DeleteAgreementQuestionDocument = gql`
  mutation deleteAgreementQuestion($id: uuid!) {
    delete_agreement_questions_by_pk(id: $id) {
      id
    }
  }
`;
export type DeleteAgreementQuestionMutationFn = Apollo.MutationFunction<
  DeleteAgreementQuestionMutation,
  DeleteAgreementQuestionMutationVariables
>;

/**
 * __useDeleteAgreementQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteAgreementQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAgreementQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAgreementQuestionMutation, { data, loading, error }] = useDeleteAgreementQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAgreementQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAgreementQuestionMutation,
    DeleteAgreementQuestionMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteAgreementQuestionMutation,
    DeleteAgreementQuestionMutationVariables
  >(DeleteAgreementQuestionDocument, baseOptions);
}
export type DeleteAgreementQuestionMutationHookResult = ReturnType<
  typeof useDeleteAgreementQuestionMutation
>;
export type DeleteAgreementQuestionMutationResult = Apollo.MutationResult<
  DeleteAgreementQuestionMutation
>;
export type DeleteAgreementQuestionMutationOptions = Apollo.BaseMutationOptions<
  DeleteAgreementQuestionMutation,
  DeleteAgreementQuestionMutationVariables
>;
export const ProductFeatureDocument = gql`
  query productFeature {
    product_features {
      feature_slug
      feature_name
      id
    }
  }
`;

/**
 * __useProductFeatureQuery__
 *
 * To run a query within a React component, call `useProductFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductFeatureQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductFeatureQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProductFeatureQuery,
    ProductFeatureQueryVariables
  >
) {
  return Apollo.useQuery<ProductFeatureQuery, ProductFeatureQueryVariables>(
    ProductFeatureDocument,
    baseOptions
  );
}
export function useProductFeatureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductFeatureQuery,
    ProductFeatureQueryVariables
  >
) {
  return Apollo.useLazyQuery<ProductFeatureQuery, ProductFeatureQueryVariables>(
    ProductFeatureDocument,
    baseOptions
  );
}
export type ProductFeatureQueryHookResult = ReturnType<
  typeof useProductFeatureQuery
>;
export type ProductFeatureLazyQueryHookResult = ReturnType<
  typeof useProductFeatureLazyQuery
>;
export type ProductFeatureQueryResult = Apollo.QueryResult<
  ProductFeatureQuery,
  ProductFeatureQueryVariables
>;
export const GetSchoolProductFeatureDocument = gql`
  query getSchoolProductFeature($school_id: uuid) {
    school_product_features(where: { school_id: { _eq: $school_id } }) {
      product_feature {
        feature_name
        feature_slug
        id
      }
    }
  }
`;

/**
 * __useGetSchoolProductFeatureQuery__
 *
 * To run a query within a React component, call `useGetSchoolProductFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolProductFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolProductFeatureQuery({
 *   variables: {
 *      school_id: // value for 'school_id'
 *   },
 * });
 */
export function useGetSchoolProductFeatureQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSchoolProductFeatureQuery,
    GetSchoolProductFeatureQueryVariables
  >
) {
  return Apollo.useQuery<
    GetSchoolProductFeatureQuery,
    GetSchoolProductFeatureQueryVariables
  >(GetSchoolProductFeatureDocument, baseOptions);
}
export function useGetSchoolProductFeatureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolProductFeatureQuery,
    GetSchoolProductFeatureQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetSchoolProductFeatureQuery,
    GetSchoolProductFeatureQueryVariables
  >(GetSchoolProductFeatureDocument, baseOptions);
}
export type GetSchoolProductFeatureQueryHookResult = ReturnType<
  typeof useGetSchoolProductFeatureQuery
>;
export type GetSchoolProductFeatureLazyQueryHookResult = ReturnType<
  typeof useGetSchoolProductFeatureLazyQuery
>;
export type GetSchoolProductFeatureQueryResult = Apollo.QueryResult<
  GetSchoolProductFeatureQuery,
  GetSchoolProductFeatureQueryVariables
>;
export const InsertSchoolProductFeatureDocument = gql`
  mutation insertSchoolProductFeature(
    $school_id: uuid
    $product_feature_id: uuid
  ) {
    insert_school_product_features(
      objects: {
        school_id: $school_id
        product_feature_id: $product_feature_id
      }
    ) {
      affected_rows
    }
  }
`;
export type InsertSchoolProductFeatureMutationFn = Apollo.MutationFunction<
  InsertSchoolProductFeatureMutation,
  InsertSchoolProductFeatureMutationVariables
>;

/**
 * __useInsertSchoolProductFeatureMutation__
 *
 * To run a mutation, you first call `useInsertSchoolProductFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSchoolProductFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSchoolProductFeatureMutation, { data, loading, error }] = useInsertSchoolProductFeatureMutation({
 *   variables: {
 *      school_id: // value for 'school_id'
 *      product_feature_id: // value for 'product_feature_id'
 *   },
 * });
 */
export function useInsertSchoolProductFeatureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertSchoolProductFeatureMutation,
    InsertSchoolProductFeatureMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertSchoolProductFeatureMutation,
    InsertSchoolProductFeatureMutationVariables
  >(InsertSchoolProductFeatureDocument, baseOptions);
}
export type InsertSchoolProductFeatureMutationHookResult = ReturnType<
  typeof useInsertSchoolProductFeatureMutation
>;
export type InsertSchoolProductFeatureMutationResult = Apollo.MutationResult<
  InsertSchoolProductFeatureMutation
>;
export type InsertSchoolProductFeatureMutationOptions = Apollo.BaseMutationOptions<
  InsertSchoolProductFeatureMutation,
  InsertSchoolProductFeatureMutationVariables
>;
export const DeleteSchoolProductFeatureDocument = gql`
  mutation deleteSchoolProductFeature(
    $product_feature_id: uuid
    $school_id: uuid
  ) {
    delete_school_product_features(
      where: {
        product_feature_id: { _eq: $product_feature_id }
        school_id: { _eq: $school_id }
      }
    ) {
      affected_rows
    }
  }
`;
export type DeleteSchoolProductFeatureMutationFn = Apollo.MutationFunction<
  DeleteSchoolProductFeatureMutation,
  DeleteSchoolProductFeatureMutationVariables
>;

/**
 * __useDeleteSchoolProductFeatureMutation__
 *
 * To run a mutation, you first call `useDeleteSchoolProductFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSchoolProductFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSchoolProductFeatureMutation, { data, loading, error }] = useDeleteSchoolProductFeatureMutation({
 *   variables: {
 *      product_feature_id: // value for 'product_feature_id'
 *      school_id: // value for 'school_id'
 *   },
 * });
 */
export function useDeleteSchoolProductFeatureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSchoolProductFeatureMutation,
    DeleteSchoolProductFeatureMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteSchoolProductFeatureMutation,
    DeleteSchoolProductFeatureMutationVariables
  >(DeleteSchoolProductFeatureDocument, baseOptions);
}
export type DeleteSchoolProductFeatureMutationHookResult = ReturnType<
  typeof useDeleteSchoolProductFeatureMutation
>;
export type DeleteSchoolProductFeatureMutationResult = Apollo.MutationResult<
  DeleteSchoolProductFeatureMutation
>;
export type DeleteSchoolProductFeatureMutationOptions = Apollo.BaseMutationOptions<
  DeleteSchoolProductFeatureMutation,
  DeleteSchoolProductFeatureMutationVariables
>;
export const FieldTripApproveDocument = gql`
  query fieldTripApprove($uid: uuid) {
    field_trips(where: { uid: { _eq: $uid } }) {
      ...ApproveFields
    }
  }
  ${ApproveFieldsFragmentDoc}
`;

/**
 * __useFieldTripApproveQuery__
 *
 * To run a query within a React component, call `useFieldTripApproveQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldTripApproveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldTripApproveQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useFieldTripApproveQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FieldTripApproveQuery,
    FieldTripApproveQueryVariables
  >
) {
  return Apollo.useQuery<FieldTripApproveQuery, FieldTripApproveQueryVariables>(
    FieldTripApproveDocument,
    baseOptions
  );
}
export function useFieldTripApproveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FieldTripApproveQuery,
    FieldTripApproveQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    FieldTripApproveQuery,
    FieldTripApproveQueryVariables
  >(FieldTripApproveDocument, baseOptions);
}
export type FieldTripApproveQueryHookResult = ReturnType<
  typeof useFieldTripApproveQuery
>;
export type FieldTripApproveLazyQueryHookResult = ReturnType<
  typeof useFieldTripApproveLazyQuery
>;
export type FieldTripApproveQueryResult = Apollo.QueryResult<
  FieldTripApproveQuery,
  FieldTripApproveQueryVariables
>;
export const FieldTripStudentsDocument = gql`
  query fieldTripStudents($tripId: Int) {
    field_trip_students(where: { field_trip_id: { _eq: $tripId } }) {
      id
      grace_courtesy_approval
      transportation_logistics_approval
      user {
        ...FieldTripStudentFields
      }
    }
  }
  ${FieldTripStudentFieldsFragmentDoc}
`;

/**
 * __useFieldTripStudentsQuery__
 *
 * To run a query within a React component, call `useFieldTripStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldTripStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldTripStudentsQuery({
 *   variables: {
 *      tripId: // value for 'tripId'
 *   },
 * });
 */
export function useFieldTripStudentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FieldTripStudentsQuery,
    FieldTripStudentsQueryVariables
  >
) {
  return Apollo.useQuery<
    FieldTripStudentsQuery,
    FieldTripStudentsQueryVariables
  >(FieldTripStudentsDocument, baseOptions);
}
export function useFieldTripStudentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FieldTripStudentsQuery,
    FieldTripStudentsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    FieldTripStudentsQuery,
    FieldTripStudentsQueryVariables
  >(FieldTripStudentsDocument, baseOptions);
}
export type FieldTripStudentsQueryHookResult = ReturnType<
  typeof useFieldTripStudentsQuery
>;
export type FieldTripStudentsLazyQueryHookResult = ReturnType<
  typeof useFieldTripStudentsLazyQuery
>;
export type FieldTripStudentsQueryResult = Apollo.QueryResult<
  FieldTripStudentsQuery,
  FieldTripStudentsQueryVariables
>;
export const UpdateFieldTripStudentDocument = gql`
  mutation updateFieldTripStudent(
    $id: Int!
    $object: field_trip_students_set_input!
  ) {
    update_field_trip_students_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
      field_trip {
        field_trip_students {
          stage
        }
      }
    }
  }
`;
export type UpdateFieldTripStudentMutationFn = Apollo.MutationFunction<
  UpdateFieldTripStudentMutation,
  UpdateFieldTripStudentMutationVariables
>;

/**
 * __useUpdateFieldTripStudentMutation__
 *
 * To run a mutation, you first call `useUpdateFieldTripStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFieldTripStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFieldTripStudentMutation, { data, loading, error }] = useUpdateFieldTripStudentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateFieldTripStudentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFieldTripStudentMutation,
    UpdateFieldTripStudentMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateFieldTripStudentMutation,
    UpdateFieldTripStudentMutationVariables
  >(UpdateFieldTripStudentDocument, baseOptions);
}
export type UpdateFieldTripStudentMutationHookResult = ReturnType<
  typeof useUpdateFieldTripStudentMutation
>;
export type UpdateFieldTripStudentMutationResult = Apollo.MutationResult<
  UpdateFieldTripStudentMutation
>;
export type UpdateFieldTripStudentMutationOptions = Apollo.BaseMutationOptions<
  UpdateFieldTripStudentMutation,
  UpdateFieldTripStudentMutationVariables
>;
export const FieldTripApprovalsDocument = gql`
  query fieldTripApprovals($tripId: Int!) {
    field_trip_approvals(where: { field_trip_id: { _eq: $tripId } }) {
      ...ApprovalUserFields
    }
  }
  ${ApprovalUserFieldsFragmentDoc}
`;

/**
 * __useFieldTripApprovalsQuery__
 *
 * To run a query within a React component, call `useFieldTripApprovalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldTripApprovalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldTripApprovalsQuery({
 *   variables: {
 *      tripId: // value for 'tripId'
 *   },
 * });
 */
export function useFieldTripApprovalsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FieldTripApprovalsQuery,
    FieldTripApprovalsQueryVariables
  >
) {
  return Apollo.useQuery<
    FieldTripApprovalsQuery,
    FieldTripApprovalsQueryVariables
  >(FieldTripApprovalsDocument, baseOptions);
}
export function useFieldTripApprovalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FieldTripApprovalsQuery,
    FieldTripApprovalsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    FieldTripApprovalsQuery,
    FieldTripApprovalsQueryVariables
  >(FieldTripApprovalsDocument, baseOptions);
}
export type FieldTripApprovalsQueryHookResult = ReturnType<
  typeof useFieldTripApprovalsQuery
>;
export type FieldTripApprovalsLazyQueryHookResult = ReturnType<
  typeof useFieldTripApprovalsLazyQuery
>;
export type FieldTripApprovalsQueryResult = Apollo.QueryResult<
  FieldTripApprovalsQuery,
  FieldTripApprovalsQueryVariables
>;
export const CreateFieldTripApprovalDocument = gql`
  mutation createFieldTripApproval(
    $object: field_trip_approvals_insert_input!
  ) {
    insert_field_trip_approvals_one(object: $object) {
      id
    }
  }
`;
export type CreateFieldTripApprovalMutationFn = Apollo.MutationFunction<
  CreateFieldTripApprovalMutation,
  CreateFieldTripApprovalMutationVariables
>;

/**
 * __useCreateFieldTripApprovalMutation__
 *
 * To run a mutation, you first call `useCreateFieldTripApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFieldTripApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFieldTripApprovalMutation, { data, loading, error }] = useCreateFieldTripApprovalMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateFieldTripApprovalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFieldTripApprovalMutation,
    CreateFieldTripApprovalMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateFieldTripApprovalMutation,
    CreateFieldTripApprovalMutationVariables
  >(CreateFieldTripApprovalDocument, baseOptions);
}
export type CreateFieldTripApprovalMutationHookResult = ReturnType<
  typeof useCreateFieldTripApprovalMutation
>;
export type CreateFieldTripApprovalMutationResult = Apollo.MutationResult<
  CreateFieldTripApprovalMutation
>;
export type CreateFieldTripApprovalMutationOptions = Apollo.BaseMutationOptions<
  CreateFieldTripApprovalMutation,
  CreateFieldTripApprovalMutationVariables
>;
export const HasParentDocument = gql`
  query hasParent($studentId: uuid) {
    users(where: { id: { _eq: $studentId } }) {
      last_name
      first_name
      id
      user_relationships_backward_aggregate(
        where: { from_user: { role: { _eq: parent } } }
      ) {
        aggregate {
          count
        }
      }
      user_relationships_forward_aggregate(
        where: { to_user: { role: { _eq: parent } } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`;

/**
 * __useHasParentQuery__
 *
 * To run a query within a React component, call `useHasParentQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasParentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasParentQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useHasParentQuery(
  baseOptions?: Apollo.QueryHookOptions<HasParentQuery, HasParentQueryVariables>
) {
  return Apollo.useQuery<HasParentQuery, HasParentQueryVariables>(
    HasParentDocument,
    baseOptions
  );
}
export function useHasParentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HasParentQuery,
    HasParentQueryVariables
  >
) {
  return Apollo.useLazyQuery<HasParentQuery, HasParentQueryVariables>(
    HasParentDocument,
    baseOptions
  );
}
export type HasParentQueryHookResult = ReturnType<typeof useHasParentQuery>;
export type HasParentLazyQueryHookResult = ReturnType<
  typeof useHasParentLazyQuery
>;
export type HasParentQueryResult = Apollo.QueryResult<
  HasParentQuery,
  HasParentQueryVariables
>;
export const CreateFieldTripDocument = gql`
  mutation createFieldTrip($object: field_trips_insert_input!) {
    insert_field_trips_one(object: $object) {
      uid
      id
    }
  }
`;
export type CreateFieldTripMutationFn = Apollo.MutationFunction<
  CreateFieldTripMutation,
  CreateFieldTripMutationVariables
>;

/**
 * __useCreateFieldTripMutation__
 *
 * To run a mutation, you first call `useCreateFieldTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFieldTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFieldTripMutation, { data, loading, error }] = useCreateFieldTripMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateFieldTripMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFieldTripMutation,
    CreateFieldTripMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateFieldTripMutation,
    CreateFieldTripMutationVariables
  >(CreateFieldTripDocument, baseOptions);
}
export type CreateFieldTripMutationHookResult = ReturnType<
  typeof useCreateFieldTripMutation
>;
export type CreateFieldTripMutationResult = Apollo.MutationResult<
  CreateFieldTripMutation
>;
export type CreateFieldTripMutationOptions = Apollo.BaseMutationOptions<
  CreateFieldTripMutation,
  CreateFieldTripMutationVariables
>;
export const UpdateFieldTripByIdDocument = gql`
  mutation updateFieldTripById($id: Int!, $object: field_trips_set_input!) {
    update_field_trips_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
      updated_at
      uid
    }
  }
`;
export type UpdateFieldTripByIdMutationFn = Apollo.MutationFunction<
  UpdateFieldTripByIdMutation,
  UpdateFieldTripByIdMutationVariables
>;

/**
 * __useUpdateFieldTripByIdMutation__
 *
 * To run a mutation, you first call `useUpdateFieldTripByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFieldTripByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFieldTripByIdMutation, { data, loading, error }] = useUpdateFieldTripByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateFieldTripByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFieldTripByIdMutation,
    UpdateFieldTripByIdMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateFieldTripByIdMutation,
    UpdateFieldTripByIdMutationVariables
  >(UpdateFieldTripByIdDocument, baseOptions);
}
export type UpdateFieldTripByIdMutationHookResult = ReturnType<
  typeof useUpdateFieldTripByIdMutation
>;
export type UpdateFieldTripByIdMutationResult = Apollo.MutationResult<
  UpdateFieldTripByIdMutation
>;
export type UpdateFieldTripByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateFieldTripByIdMutation,
  UpdateFieldTripByIdMutationVariables
>;
export const UpdateFieldTripDocument = gql`
  mutation updateFieldTrip($id: uuid!, $object: field_trips_set_input!) {
    update_field_trips(where: { uid: { _eq: $id } }, _set: $object) {
      returning {
        id
        updated_at
        uid
      }
    }
  }
`;
export type UpdateFieldTripMutationFn = Apollo.MutationFunction<
  UpdateFieldTripMutation,
  UpdateFieldTripMutationVariables
>;

/**
 * __useUpdateFieldTripMutation__
 *
 * To run a mutation, you first call `useUpdateFieldTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFieldTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFieldTripMutation, { data, loading, error }] = useUpdateFieldTripMutation({
 *   variables: {
 *      id: // value for 'id'
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateFieldTripMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFieldTripMutation,
    UpdateFieldTripMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateFieldTripMutation,
    UpdateFieldTripMutationVariables
  >(UpdateFieldTripDocument, baseOptions);
}
export type UpdateFieldTripMutationHookResult = ReturnType<
  typeof useUpdateFieldTripMutation
>;
export type UpdateFieldTripMutationResult = Apollo.MutationResult<
  UpdateFieldTripMutation
>;
export type UpdateFieldTripMutationOptions = Apollo.BaseMutationOptions<
  UpdateFieldTripMutation,
  UpdateFieldTripMutationVariables
>;
export const FieldTripStatusDocument = gql`
  query fieldTripStatus($uid: uuid!) {
    field_trips(where: { uid: { _eq: $uid } }) {
      id
      uid
      status
      title
      my_trip_data {
        id
        stage
      }
    }
  }
`;

/**
 * __useFieldTripStatusQuery__
 *
 * To run a query within a React component, call `useFieldTripStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldTripStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldTripStatusQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useFieldTripStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FieldTripStatusQuery,
    FieldTripStatusQueryVariables
  >
) {
  return Apollo.useQuery<FieldTripStatusQuery, FieldTripStatusQueryVariables>(
    FieldTripStatusDocument,
    baseOptions
  );
}
export function useFieldTripStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FieldTripStatusQuery,
    FieldTripStatusQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    FieldTripStatusQuery,
    FieldTripStatusQueryVariables
  >(FieldTripStatusDocument, baseOptions);
}
export type FieldTripStatusQueryHookResult = ReturnType<
  typeof useFieldTripStatusQuery
>;
export type FieldTripStatusLazyQueryHookResult = ReturnType<
  typeof useFieldTripStatusLazyQuery
>;
export type FieldTripStatusQueryResult = Apollo.QueryResult<
  FieldTripStatusQuery,
  FieldTripStatusQueryVariables
>;
export const FieldTripEnvisionDocument = gql`
  query fieldTripEnvision($uid: uuid) {
    field_trips(where: { uid: { _eq: $uid } }) {
      ...EnvisionFields
    }
  }
  ${EnvisionFieldsFragmentDoc}
`;

/**
 * __useFieldTripEnvisionQuery__
 *
 * To run a query within a React component, call `useFieldTripEnvisionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldTripEnvisionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldTripEnvisionQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useFieldTripEnvisionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FieldTripEnvisionQuery,
    FieldTripEnvisionQueryVariables
  >
) {
  return Apollo.useQuery<
    FieldTripEnvisionQuery,
    FieldTripEnvisionQueryVariables
  >(FieldTripEnvisionDocument, baseOptions);
}
export function useFieldTripEnvisionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FieldTripEnvisionQuery,
    FieldTripEnvisionQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    FieldTripEnvisionQuery,
    FieldTripEnvisionQueryVariables
  >(FieldTripEnvisionDocument, baseOptions);
}
export type FieldTripEnvisionQueryHookResult = ReturnType<
  typeof useFieldTripEnvisionQuery
>;
export type FieldTripEnvisionLazyQueryHookResult = ReturnType<
  typeof useFieldTripEnvisionLazyQuery
>;
export type FieldTripEnvisionQueryResult = Apollo.QueryResult<
  FieldTripEnvisionQuery,
  FieldTripEnvisionQueryVariables
>;
export const ListFieldTripDocument = gql`
  query listFieldTrip(
    $limit: Int = 20
    $offset: Int = 0
    $status: String_comparison_exp = {}
  ) {
    field_trips(
      limit: $limit
      offset: $offset
      order_by: { id: desc }
      where: { status: $status }
    ) {
      ...FieldTripListFields
      __typename
    }
    field_trips_aggregate(where: { status: $status }) {
      aggregate {
        count(columns: id)
        __typename
      }
      __typename
    }
  }
  ${FieldTripListFieldsFragmentDoc}
`;

/**
 * __useListFieldTripQuery__
 *
 * To run a query within a React component, call `useListFieldTripQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFieldTripQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFieldTripQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useListFieldTripQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListFieldTripQuery,
    ListFieldTripQueryVariables
  >
) {
  return Apollo.useQuery<ListFieldTripQuery, ListFieldTripQueryVariables>(
    ListFieldTripDocument,
    baseOptions
  );
}
export function useListFieldTripLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListFieldTripQuery,
    ListFieldTripQueryVariables
  >
) {
  return Apollo.useLazyQuery<ListFieldTripQuery, ListFieldTripQueryVariables>(
    ListFieldTripDocument,
    baseOptions
  );
}
export type ListFieldTripQueryHookResult = ReturnType<
  typeof useListFieldTripQuery
>;
export type ListFieldTripLazyQueryHookResult = ReturnType<
  typeof useListFieldTripLazyQuery
>;
export type ListFieldTripQueryResult = Apollo.QueryResult<
  ListFieldTripQuery,
  ListFieldTripQueryVariables
>;
export const RemoveFieldTripStudentDocument = gql`
  mutation removeFieldTripStudent($fieldTripId: uuid, $userId: uuid) {
    delete_field_trip_students(
      where: {
        _and: {
          field_trip: { uid: { _eq: $fieldTripId } }
          user_id: { _eq: $userId }
        }
      }
    ) {
      affected_rows
    }
  }
`;
export type RemoveFieldTripStudentMutationFn = Apollo.MutationFunction<
  RemoveFieldTripStudentMutation,
  RemoveFieldTripStudentMutationVariables
>;

/**
 * __useRemoveFieldTripStudentMutation__
 *
 * To run a mutation, you first call `useRemoveFieldTripStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFieldTripStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFieldTripStudentMutation, { data, loading, error }] = useRemoveFieldTripStudentMutation({
 *   variables: {
 *      fieldTripId: // value for 'fieldTripId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRemoveFieldTripStudentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFieldTripStudentMutation,
    RemoveFieldTripStudentMutationVariables
  >
) {
  return Apollo.useMutation<
    RemoveFieldTripStudentMutation,
    RemoveFieldTripStudentMutationVariables
  >(RemoveFieldTripStudentDocument, baseOptions);
}
export type RemoveFieldTripStudentMutationHookResult = ReturnType<
  typeof useRemoveFieldTripStudentMutation
>;
export type RemoveFieldTripStudentMutationResult = Apollo.MutationResult<
  RemoveFieldTripStudentMutation
>;
export type RemoveFieldTripStudentMutationOptions = Apollo.BaseMutationOptions<
  RemoveFieldTripStudentMutation,
  RemoveFieldTripStudentMutationVariables
>;
export const AddFieldTripStudentDocument = gql`
  mutation addFieldTripStudent($fieldTripId: Int, $userId: uuid) {
    insert_field_trip_students_one(
      object: { user_id: $userId, field_trip_id: $fieldTripId }
    ) {
      id
    }
  }
`;
export type AddFieldTripStudentMutationFn = Apollo.MutationFunction<
  AddFieldTripStudentMutation,
  AddFieldTripStudentMutationVariables
>;

/**
 * __useAddFieldTripStudentMutation__
 *
 * To run a mutation, you first call `useAddFieldTripStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFieldTripStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFieldTripStudentMutation, { data, loading, error }] = useAddFieldTripStudentMutation({
 *   variables: {
 *      fieldTripId: // value for 'fieldTripId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddFieldTripStudentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddFieldTripStudentMutation,
    AddFieldTripStudentMutationVariables
  >
) {
  return Apollo.useMutation<
    AddFieldTripStudentMutation,
    AddFieldTripStudentMutationVariables
  >(AddFieldTripStudentDocument, baseOptions);
}
export type AddFieldTripStudentMutationHookResult = ReturnType<
  typeof useAddFieldTripStudentMutation
>;
export type AddFieldTripStudentMutationResult = Apollo.MutationResult<
  AddFieldTripStudentMutation
>;
export type AddFieldTripStudentMutationOptions = Apollo.BaseMutationOptions<
  AddFieldTripStudentMutation,
  AddFieldTripStudentMutationVariables
>;
export const FieldTripParentPermissionDocument = gql`
  query fieldTripParentPermission($uid: uuid) {
    field_trips(where: { uid: { _eq: $uid } }) {
      ...PermissionSlip
      school {
        parent_permission_template
      }
    }
  }
  ${PermissionSlipFragmentDoc}
`;

/**
 * __useFieldTripParentPermissionQuery__
 *
 * To run a query within a React component, call `useFieldTripParentPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldTripParentPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldTripParentPermissionQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useFieldTripParentPermissionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FieldTripParentPermissionQuery,
    FieldTripParentPermissionQueryVariables
  >
) {
  return Apollo.useQuery<
    FieldTripParentPermissionQuery,
    FieldTripParentPermissionQueryVariables
  >(FieldTripParentPermissionDocument, baseOptions);
}
export function useFieldTripParentPermissionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FieldTripParentPermissionQuery,
    FieldTripParentPermissionQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    FieldTripParentPermissionQuery,
    FieldTripParentPermissionQueryVariables
  >(FieldTripParentPermissionDocument, baseOptions);
}
export type FieldTripParentPermissionQueryHookResult = ReturnType<
  typeof useFieldTripParentPermissionQuery
>;
export type FieldTripParentPermissionLazyQueryHookResult = ReturnType<
  typeof useFieldTripParentPermissionLazyQuery
>;
export type FieldTripParentPermissionQueryResult = Apollo.QueryResult<
  FieldTripParentPermissionQuery,
  FieldTripParentPermissionQueryVariables
>;
export const FieldTripChaperoneRequestDocument = gql`
  query fieldTripChaperoneRequest($uid: uuid) {
    field_trips(where: { uid: { _eq: $uid } }) {
      ...PermissionSlip
      school {
        chaperone_request_template
      }
    }
  }
  ${PermissionSlipFragmentDoc}
`;

/**
 * __useFieldTripChaperoneRequestQuery__
 *
 * To run a query within a React component, call `useFieldTripChaperoneRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldTripChaperoneRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldTripChaperoneRequestQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useFieldTripChaperoneRequestQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FieldTripChaperoneRequestQuery,
    FieldTripChaperoneRequestQueryVariables
  >
) {
  return Apollo.useQuery<
    FieldTripChaperoneRequestQuery,
    FieldTripChaperoneRequestQueryVariables
  >(FieldTripChaperoneRequestDocument, baseOptions);
}
export function useFieldTripChaperoneRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FieldTripChaperoneRequestQuery,
    FieldTripChaperoneRequestQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    FieldTripChaperoneRequestQuery,
    FieldTripChaperoneRequestQueryVariables
  >(FieldTripChaperoneRequestDocument, baseOptions);
}
export type FieldTripChaperoneRequestQueryHookResult = ReturnType<
  typeof useFieldTripChaperoneRequestQuery
>;
export type FieldTripChaperoneRequestLazyQueryHookResult = ReturnType<
  typeof useFieldTripChaperoneRequestLazyQuery
>;
export type FieldTripChaperoneRequestQueryResult = Apollo.QueryResult<
  FieldTripChaperoneRequestQuery,
  FieldTripChaperoneRequestQueryVariables
>;
export const FieldTripDetailDocument = gql`
  query fieldTripDetail($tripId: uuid) {
    field_trips(where: { uid: { _eq: $tripId } }) {
      cost_per_person
      created_at
      event_date
      expectations_rules
      id
      interest_area
      title
      total_cost
      transportation_options
      updated_at
      research_questions_goals
      owner {
        first_name
        last_name
        school {
          parent_permission_template
          chaperone_request_template
          logo_url
          id
        }
      }
      agenda
      field_trip_locations(order_by: { time: asc }) {
        time
        notes
        contact_info
        location_type
        location {
          address
          google_maps_url
          id
          location_point
          name
          notes
        }
        location_id
        id
      }
      field_trip_students {
        user {
          first_name
          last_name
          id
          user_relationships_forward(
            where: { to_user: { role: { _eq: parent } } }
          ) {
            to_user {
              id
              first_name
              last_name
              role
            }
          }
        }
        field_trip {
          field_trip_location_reviews {
            id
            rating
            field_trip_location_id
          }
          interest_area
          research_questions_goals
        }
        transportation_logistics_approval
        grace_courtesy_approval
        enjoyed_most
        gratitude_mail_agreement
        improvements
        share_experience
        share_experience_agreement
      }
      supply_list
    }
  }
`;

/**
 * __useFieldTripDetailQuery__
 *
 * To run a query within a React component, call `useFieldTripDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldTripDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldTripDetailQuery({
 *   variables: {
 *      tripId: // value for 'tripId'
 *   },
 * });
 */
export function useFieldTripDetailQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FieldTripDetailQuery,
    FieldTripDetailQueryVariables
  >
) {
  return Apollo.useQuery<FieldTripDetailQuery, FieldTripDetailQueryVariables>(
    FieldTripDetailDocument,
    baseOptions
  );
}
export function useFieldTripDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FieldTripDetailQuery,
    FieldTripDetailQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    FieldTripDetailQuery,
    FieldTripDetailQueryVariables
  >(FieldTripDetailDocument, baseOptions);
}
export type FieldTripDetailQueryHookResult = ReturnType<
  typeof useFieldTripDetailQuery
>;
export type FieldTripDetailLazyQueryHookResult = ReturnType<
  typeof useFieldTripDetailLazyQuery
>;
export type FieldTripDetailQueryResult = Apollo.QueryResult<
  FieldTripDetailQuery,
  FieldTripDetailQueryVariables
>;
export const FieldTripReflectsDocument = gql`
  query fieldTripReflects($tripId: uuid, $studentId: uuid, $schoolId: uuid) {
    field_trip_students(
      where: {
        user_id: { _eq: $studentId }
        _and: { field_trip: { uid: { _eq: $tripId } } }
      }
    ) {
      reflect_survey
      user_id
    }
    agreement_questions(
      where: { school_id: { _eq: $schoolId }, type: { _eq: "3" } }
    ) {
      label
      id
    }
  }
`;

/**
 * __useFieldTripReflectsQuery__
 *
 * To run a query within a React component, call `useFieldTripReflectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldTripReflectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldTripReflectsQuery({
 *   variables: {
 *      tripId: // value for 'tripId'
 *      studentId: // value for 'studentId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useFieldTripReflectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FieldTripReflectsQuery,
    FieldTripReflectsQueryVariables
  >
) {
  return Apollo.useQuery<
    FieldTripReflectsQuery,
    FieldTripReflectsQueryVariables
  >(FieldTripReflectsDocument, baseOptions);
}
export function useFieldTripReflectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FieldTripReflectsQuery,
    FieldTripReflectsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    FieldTripReflectsQuery,
    FieldTripReflectsQueryVariables
  >(FieldTripReflectsDocument, baseOptions);
}
export type FieldTripReflectsQueryHookResult = ReturnType<
  typeof useFieldTripReflectsQuery
>;
export type FieldTripReflectsLazyQueryHookResult = ReturnType<
  typeof useFieldTripReflectsLazyQuery
>;
export type FieldTripReflectsQueryResult = Apollo.QueryResult<
  FieldTripReflectsQuery,
  FieldTripReflectsQueryVariables
>;
export const StudentReflectQuestionDocument = gql`
  query studentReflectQuestion(
    $tripId: uuid
    $studentId: uuid
    $schoolId: uuid
  ) {
    field_trip_students(
      where: {
        user_id: { _eq: $studentId }
        _and: { field_trip: { uid: { _eq: $tripId } } }
      }
    ) {
      reflect_survey
      user_id
    }
  }
`;

/**
 * __useStudentReflectQuestionQuery__
 *
 * To run a query within a React component, call `useStudentReflectQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentReflectQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentReflectQuestionQuery({
 *   variables: {
 *      tripId: // value for 'tripId'
 *      studentId: // value for 'studentId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useStudentReflectQuestionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    StudentReflectQuestionQuery,
    StudentReflectQuestionQueryVariables
  >
) {
  return Apollo.useQuery<
    StudentReflectQuestionQuery,
    StudentReflectQuestionQueryVariables
  >(StudentReflectQuestionDocument, baseOptions);
}
export function useStudentReflectQuestionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StudentReflectQuestionQuery,
    StudentReflectQuestionQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    StudentReflectQuestionQuery,
    StudentReflectQuestionQueryVariables
  >(StudentReflectQuestionDocument, baseOptions);
}
export type StudentReflectQuestionQueryHookResult = ReturnType<
  typeof useStudentReflectQuestionQuery
>;
export type StudentReflectQuestionLazyQueryHookResult = ReturnType<
  typeof useStudentReflectQuestionLazyQuery
>;
export type StudentReflectQuestionQueryResult = Apollo.QueryResult<
  StudentReflectQuestionQuery,
  StudentReflectQuestionQueryVariables
>;
export const IsTripCompleteDocument = gql`
  query isTripComplete($tripId: uuid!, $status: String = "complete") {
    field_trips_aggregate(
      where: { _and: { uid: { _eq: $tripId }, status: { _neq: $status } } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useIsTripCompleteQuery__
 *
 * To run a query within a React component, call `useIsTripCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsTripCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsTripCompleteQuery({
 *   variables: {
 *      tripId: // value for 'tripId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useIsTripCompleteQuery(
  baseOptions?: Apollo.QueryHookOptions<
    IsTripCompleteQuery,
    IsTripCompleteQueryVariables
  >
) {
  return Apollo.useQuery<IsTripCompleteQuery, IsTripCompleteQueryVariables>(
    IsTripCompleteDocument,
    baseOptions
  );
}
export function useIsTripCompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsTripCompleteQuery,
    IsTripCompleteQueryVariables
  >
) {
  return Apollo.useLazyQuery<IsTripCompleteQuery, IsTripCompleteQueryVariables>(
    IsTripCompleteDocument,
    baseOptions
  );
}
export type IsTripCompleteQueryHookResult = ReturnType<
  typeof useIsTripCompleteQuery
>;
export type IsTripCompleteLazyQueryHookResult = ReturnType<
  typeof useIsTripCompleteLazyQuery
>;
export type IsTripCompleteQueryResult = Apollo.QueryResult<
  IsTripCompleteQuery,
  IsTripCompleteQueryVariables
>;
export const SetParentApprovalDocument = gql`
  mutation setParentApproval(
    $tripId: Int!
    $userId: uuid!
    $approved_at: timestamptz
    $status: String
  ) {
    update_field_trip_approvals(
      where: { field_trip_id: { _eq: $tripId }, user_id: { _eq: $userId } }
      _set: { status: $status, approved_at: $approved_at }
    ) {
      affected_rows
    }
  }
`;
export type SetParentApprovalMutationFn = Apollo.MutationFunction<
  SetParentApprovalMutation,
  SetParentApprovalMutationVariables
>;

/**
 * __useSetParentApprovalMutation__
 *
 * To run a mutation, you first call `useSetParentApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetParentApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setParentApprovalMutation, { data, loading, error }] = useSetParentApprovalMutation({
 *   variables: {
 *      tripId: // value for 'tripId'
 *      userId: // value for 'userId'
 *      approved_at: // value for 'approved_at'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetParentApprovalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetParentApprovalMutation,
    SetParentApprovalMutationVariables
  >
) {
  return Apollo.useMutation<
    SetParentApprovalMutation,
    SetParentApprovalMutationVariables
  >(SetParentApprovalDocument, baseOptions);
}
export type SetParentApprovalMutationHookResult = ReturnType<
  typeof useSetParentApprovalMutation
>;
export type SetParentApprovalMutationResult = Apollo.MutationResult<
  SetParentApprovalMutation
>;
export type SetParentApprovalMutationOptions = Apollo.BaseMutationOptions<
  SetParentApprovalMutation,
  SetParentApprovalMutationVariables
>;
export const FieldTripLocationsDocument = gql`
  query fieldTripLocations($tripId: uuid) {
    field_trip_locations(
      where: { field_trip: { uid: { _eq: $tripId } } }
      order_by: { time: asc }
    ) {
      id
      field_trip_id
      time
      location_type
      location {
        id
        name
        address
        location_point
        google_maps_url
      }
    }
  }
`;

/**
 * __useFieldTripLocationsQuery__
 *
 * To run a query within a React component, call `useFieldTripLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldTripLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldTripLocationsQuery({
 *   variables: {
 *      tripId: // value for 'tripId'
 *   },
 * });
 */
export function useFieldTripLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FieldTripLocationsQuery,
    FieldTripLocationsQueryVariables
  >
) {
  return Apollo.useQuery<
    FieldTripLocationsQuery,
    FieldTripLocationsQueryVariables
  >(FieldTripLocationsDocument, baseOptions);
}
export function useFieldTripLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FieldTripLocationsQuery,
    FieldTripLocationsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    FieldTripLocationsQuery,
    FieldTripLocationsQueryVariables
  >(FieldTripLocationsDocument, baseOptions);
}
export type FieldTripLocationsQueryHookResult = ReturnType<
  typeof useFieldTripLocationsQuery
>;
export type FieldTripLocationsLazyQueryHookResult = ReturnType<
  typeof useFieldTripLocationsLazyQuery
>;
export type FieldTripLocationsQueryResult = Apollo.QueryResult<
  FieldTripLocationsQuery,
  FieldTripLocationsQueryVariables
>;
export const LocationsByNameDocument = gql`
  query locationsByName($name: String, $limit: Int = 10) {
    locations(where: { name: { _ilike: $name } }, limit: $limit) {
      id
      google_maps_url
      address
      name
      location_point
    }
  }
`;

/**
 * __useLocationsByNameQuery__
 *
 * To run a query within a React component, call `useLocationsByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useLocationsByNameQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LocationsByNameQuery,
    LocationsByNameQueryVariables
  >
) {
  return Apollo.useQuery<LocationsByNameQuery, LocationsByNameQueryVariables>(
    LocationsByNameDocument,
    baseOptions
  );
}
export function useLocationsByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LocationsByNameQuery,
    LocationsByNameQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    LocationsByNameQuery,
    LocationsByNameQueryVariables
  >(LocationsByNameDocument, baseOptions);
}
export type LocationsByNameQueryHookResult = ReturnType<
  typeof useLocationsByNameQuery
>;
export type LocationsByNameLazyQueryHookResult = ReturnType<
  typeof useLocationsByNameLazyQuery
>;
export type LocationsByNameQueryResult = Apollo.QueryResult<
  LocationsByNameQuery,
  LocationsByNameQueryVariables
>;
export const CreateFieldTripLocationDocument = gql`
  mutation createFieldTripLocation(
    $object: field_trip_locations_insert_input!
  ) {
    insert_field_trip_locations_one(object: $object) {
      id
    }
  }
`;
export type CreateFieldTripLocationMutationFn = Apollo.MutationFunction<
  CreateFieldTripLocationMutation,
  CreateFieldTripLocationMutationVariables
>;

/**
 * __useCreateFieldTripLocationMutation__
 *
 * To run a mutation, you first call `useCreateFieldTripLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFieldTripLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFieldTripLocationMutation, { data, loading, error }] = useCreateFieldTripLocationMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateFieldTripLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFieldTripLocationMutation,
    CreateFieldTripLocationMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateFieldTripLocationMutation,
    CreateFieldTripLocationMutationVariables
  >(CreateFieldTripLocationDocument, baseOptions);
}
export type CreateFieldTripLocationMutationHookResult = ReturnType<
  typeof useCreateFieldTripLocationMutation
>;
export type CreateFieldTripLocationMutationResult = Apollo.MutationResult<
  CreateFieldTripLocationMutation
>;
export type CreateFieldTripLocationMutationOptions = Apollo.BaseMutationOptions<
  CreateFieldTripLocationMutation,
  CreateFieldTripLocationMutationVariables
>;
export const DeleteFieldTripLocationDocument = gql`
  mutation deleteFieldTripLocation($id: uuid) {
    delete_field_trip_locations(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type DeleteFieldTripLocationMutationFn = Apollo.MutationFunction<
  DeleteFieldTripLocationMutation,
  DeleteFieldTripLocationMutationVariables
>;

/**
 * __useDeleteFieldTripLocationMutation__
 *
 * To run a mutation, you first call `useDeleteFieldTripLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFieldTripLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFieldTripLocationMutation, { data, loading, error }] = useDeleteFieldTripLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFieldTripLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFieldTripLocationMutation,
    DeleteFieldTripLocationMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteFieldTripLocationMutation,
    DeleteFieldTripLocationMutationVariables
  >(DeleteFieldTripLocationDocument, baseOptions);
}
export type DeleteFieldTripLocationMutationHookResult = ReturnType<
  typeof useDeleteFieldTripLocationMutation
>;
export type DeleteFieldTripLocationMutationResult = Apollo.MutationResult<
  DeleteFieldTripLocationMutation
>;
export type DeleteFieldTripLocationMutationOptions = Apollo.BaseMutationOptions<
  DeleteFieldTripLocationMutation,
  DeleteFieldTripLocationMutationVariables
>;
export const FieldTripPlanDocument = gql`
  query fieldTripPlan($uid: uuid) {
    field_trips(where: { uid: { _eq: $uid } }) {
      ...PlanFields
    }
  }
  ${PlanFieldsFragmentDoc}
`;

/**
 * __useFieldTripPlanQuery__
 *
 * To run a query within a React component, call `useFieldTripPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldTripPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldTripPlanQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useFieldTripPlanQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FieldTripPlanQuery,
    FieldTripPlanQueryVariables
  >
) {
  return Apollo.useQuery<FieldTripPlanQuery, FieldTripPlanQueryVariables>(
    FieldTripPlanDocument,
    baseOptions
  );
}
export function useFieldTripPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FieldTripPlanQuery,
    FieldTripPlanQueryVariables
  >
) {
  return Apollo.useLazyQuery<FieldTripPlanQuery, FieldTripPlanQueryVariables>(
    FieldTripPlanDocument,
    baseOptions
  );
}
export type FieldTripPlanQueryHookResult = ReturnType<
  typeof useFieldTripPlanQuery
>;
export type FieldTripPlanLazyQueryHookResult = ReturnType<
  typeof useFieldTripPlanLazyQuery
>;
export type FieldTripPlanQueryResult = Apollo.QueryResult<
  FieldTripPlanQuery,
  FieldTripPlanQueryVariables
>;
export const FieldTripStudentReflectDocument = gql`
  query fieldTripStudentReflect($fieldTripId: uuid!, $studentId: uuid!) {
    field_trip_students(
      where: {
        _and: {
          user_id: { _eq: $studentId }
          field_trip: { uid: { _eq: $fieldTripId } }
        }
      }
    ) {
      ...FieldTripStudentReflectFields
    }
  }
  ${FieldTripStudentReflectFieldsFragmentDoc}
`;

/**
 * __useFieldTripStudentReflectQuery__
 *
 * To run a query within a React component, call `useFieldTripStudentReflectQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldTripStudentReflectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldTripStudentReflectQuery({
 *   variables: {
 *      fieldTripId: // value for 'fieldTripId'
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useFieldTripStudentReflectQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FieldTripStudentReflectQuery,
    FieldTripStudentReflectQueryVariables
  >
) {
  return Apollo.useQuery<
    FieldTripStudentReflectQuery,
    FieldTripStudentReflectQueryVariables
  >(FieldTripStudentReflectDocument, baseOptions);
}
export function useFieldTripStudentReflectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FieldTripStudentReflectQuery,
    FieldTripStudentReflectQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    FieldTripStudentReflectQuery,
    FieldTripStudentReflectQueryVariables
  >(FieldTripStudentReflectDocument, baseOptions);
}
export type FieldTripStudentReflectQueryHookResult = ReturnType<
  typeof useFieldTripStudentReflectQuery
>;
export type FieldTripStudentReflectLazyQueryHookResult = ReturnType<
  typeof useFieldTripStudentReflectLazyQuery
>;
export type FieldTripStudentReflectQueryResult = Apollo.QueryResult<
  FieldTripStudentReflectQuery,
  FieldTripStudentReflectQueryVariables
>;
export const FieldTripReflectDocument = gql`
  query fieldTripReflect($uid: uuid) {
    field_trips(where: { uid: { _eq: $uid } }) {
      ...FieldTripReflectFields
    }
  }
  ${FieldTripReflectFieldsFragmentDoc}
`;

/**
 * __useFieldTripReflectQuery__
 *
 * To run a query within a React component, call `useFieldTripReflectQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldTripReflectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldTripReflectQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useFieldTripReflectQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FieldTripReflectQuery,
    FieldTripReflectQueryVariables
  >
) {
  return Apollo.useQuery<FieldTripReflectQuery, FieldTripReflectQueryVariables>(
    FieldTripReflectDocument,
    baseOptions
  );
}
export function useFieldTripReflectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FieldTripReflectQuery,
    FieldTripReflectQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    FieldTripReflectQuery,
    FieldTripReflectQueryVariables
  >(FieldTripReflectDocument, baseOptions);
}
export type FieldTripReflectQueryHookResult = ReturnType<
  typeof useFieldTripReflectQuery
>;
export type FieldTripReflectLazyQueryHookResult = ReturnType<
  typeof useFieldTripReflectLazyQuery
>;
export type FieldTripReflectQueryResult = Apollo.QueryResult<
  FieldTripReflectQuery,
  FieldTripReflectQueryVariables
>;
export const AddLocationReviewDocument = gql`
  mutation addLocationReview(
    $field_trip_location_id: uuid!
    $field_trip_id: Int!
    $user_id: uuid!
    $rating: Int!
  ) {
    insert_field_trip_location_reviews_one(
      object: {
        field_trip_location_id: $field_trip_location_id
        field_trip_id: $field_trip_id
        user_id: $user_id
        rating: $rating
      }
      on_conflict: {
        constraint: field_trip_location_reviews_field_trip_id_user_id_field_trip_lo
        update_columns: rating
      }
    ) {
      id
    }
  }
`;
export type AddLocationReviewMutationFn = Apollo.MutationFunction<
  AddLocationReviewMutation,
  AddLocationReviewMutationVariables
>;

/**
 * __useAddLocationReviewMutation__
 *
 * To run a mutation, you first call `useAddLocationReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLocationReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLocationReviewMutation, { data, loading, error }] = useAddLocationReviewMutation({
 *   variables: {
 *      field_trip_location_id: // value for 'field_trip_location_id'
 *      field_trip_id: // value for 'field_trip_id'
 *      user_id: // value for 'user_id'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useAddLocationReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddLocationReviewMutation,
    AddLocationReviewMutationVariables
  >
) {
  return Apollo.useMutation<
    AddLocationReviewMutation,
    AddLocationReviewMutationVariables
  >(AddLocationReviewDocument, baseOptions);
}
export type AddLocationReviewMutationHookResult = ReturnType<
  typeof useAddLocationReviewMutation
>;
export type AddLocationReviewMutationResult = Apollo.MutationResult<
  AddLocationReviewMutation
>;
export type AddLocationReviewMutationOptions = Apollo.BaseMutationOptions<
  AddLocationReviewMutation,
  AddLocationReviewMutationVariables
>;
export const FieldTripLocationReviewsDocument = gql`
  query fieldTripLocationReviews($userId: uuid!, $tripId: Int!) {
    field_trip_location_reviews(
      where: {
        _and: { field_trip_id: { _eq: $tripId }, user_id: { _eq: $userId } }
      }
    ) {
      id
      rating
      field_trip_location_id
    }
  }
`;

/**
 * __useFieldTripLocationReviewsQuery__
 *
 * To run a query within a React component, call `useFieldTripLocationReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldTripLocationReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldTripLocationReviewsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      tripId: // value for 'tripId'
 *   },
 * });
 */
export function useFieldTripLocationReviewsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FieldTripLocationReviewsQuery,
    FieldTripLocationReviewsQueryVariables
  >
) {
  return Apollo.useQuery<
    FieldTripLocationReviewsQuery,
    FieldTripLocationReviewsQueryVariables
  >(FieldTripLocationReviewsDocument, baseOptions);
}
export function useFieldTripLocationReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FieldTripLocationReviewsQuery,
    FieldTripLocationReviewsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    FieldTripLocationReviewsQuery,
    FieldTripLocationReviewsQueryVariables
  >(FieldTripLocationReviewsDocument, baseOptions);
}
export type FieldTripLocationReviewsQueryHookResult = ReturnType<
  typeof useFieldTripLocationReviewsQuery
>;
export type FieldTripLocationReviewsLazyQueryHookResult = ReturnType<
  typeof useFieldTripLocationReviewsLazyQuery
>;
export type FieldTripLocationReviewsQueryResult = Apollo.QueryResult<
  FieldTripLocationReviewsQuery,
  FieldTripLocationReviewsQueryVariables
>;
export const FieldTripCompleteDocument = gql`
  mutation fieldTripComplete($tripId: uuid, $status: String) {
    update_field_trips(
      where: { uid: { _eq: $tripId } }
      _set: { status: $status }
    ) {
      affected_rows
    }
  }
`;
export type FieldTripCompleteMutationFn = Apollo.MutationFunction<
  FieldTripCompleteMutation,
  FieldTripCompleteMutationVariables
>;

/**
 * __useFieldTripCompleteMutation__
 *
 * To run a mutation, you first call `useFieldTripCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFieldTripCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fieldTripCompleteMutation, { data, loading, error }] = useFieldTripCompleteMutation({
 *   variables: {
 *      tripId: // value for 'tripId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useFieldTripCompleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FieldTripCompleteMutation,
    FieldTripCompleteMutationVariables
  >
) {
  return Apollo.useMutation<
    FieldTripCompleteMutation,
    FieldTripCompleteMutationVariables
  >(FieldTripCompleteDocument, baseOptions);
}
export type FieldTripCompleteMutationHookResult = ReturnType<
  typeof useFieldTripCompleteMutation
>;
export type FieldTripCompleteMutationResult = Apollo.MutationResult<
  FieldTripCompleteMutation
>;
export type FieldTripCompleteMutationOptions = Apollo.BaseMutationOptions<
  FieldTripCompleteMutation,
  FieldTripCompleteMutationVariables
>;
export const FieldTripReflectQuestionsDocument = gql`
  query fieldTripReflectQuestions(
    $school_id: uuid = ""
    $question_type: String = "3"
  ) {
    agreement_questions(
      where: {
        school: { id: { _eq: $school_id } }
        type: { _eq: $question_type }
      }
    ) {
      id
      label
    }
  }
`;

/**
 * __useFieldTripReflectQuestionsQuery__
 *
 * To run a query within a React component, call `useFieldTripReflectQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldTripReflectQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldTripReflectQuestionsQuery({
 *   variables: {
 *      school_id: // value for 'school_id'
 *      question_type: // value for 'question_type'
 *   },
 * });
 */
export function useFieldTripReflectQuestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FieldTripReflectQuestionsQuery,
    FieldTripReflectQuestionsQueryVariables
  >
) {
  return Apollo.useQuery<
    FieldTripReflectQuestionsQuery,
    FieldTripReflectQuestionsQueryVariables
  >(FieldTripReflectQuestionsDocument, baseOptions);
}
export function useFieldTripReflectQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FieldTripReflectQuestionsQuery,
    FieldTripReflectQuestionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    FieldTripReflectQuestionsQuery,
    FieldTripReflectQuestionsQueryVariables
  >(FieldTripReflectQuestionsDocument, baseOptions);
}
export type FieldTripReflectQuestionsQueryHookResult = ReturnType<
  typeof useFieldTripReflectQuestionsQuery
>;
export type FieldTripReflectQuestionsLazyQueryHookResult = ReturnType<
  typeof useFieldTripReflectQuestionsLazyQuery
>;
export type FieldTripReflectQuestionsQueryResult = Apollo.QueryResult<
  FieldTripReflectQuestionsQuery,
  FieldTripReflectQuestionsQueryVariables
>;
export const FindUsersDocument = gql`
  query findUsers(
    $limit: Int
    $offset: Int
    $roles: user_roles_enum_comparison_exp = {}
    $school_id: uuid_comparison_exp = {}
  ) {
    users(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: { role: $roles, school_id: $school_id }
    ) {
      ...UserFields
    }
    users_aggregate(where: { role: $roles, school_id: $school_id }) {
      aggregate {
        count(columns: id)
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;

/**
 * __useFindUsersQuery__
 *
 * To run a query within a React component, call `useFindUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      roles: // value for 'roles'
 *      school_id: // value for 'school_id'
 *   },
 * });
 */
export function useFindUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<FindUsersQuery, FindUsersQueryVariables>
) {
  return Apollo.useQuery<FindUsersQuery, FindUsersQueryVariables>(
    FindUsersDocument,
    baseOptions
  );
}
export function useFindUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindUsersQuery,
    FindUsersQueryVariables
  >
) {
  return Apollo.useLazyQuery<FindUsersQuery, FindUsersQueryVariables>(
    FindUsersDocument,
    baseOptions
  );
}
export type FindUsersQueryHookResult = ReturnType<typeof useFindUsersQuery>;
export type FindUsersLazyQueryHookResult = ReturnType<
  typeof useFindUsersLazyQuery
>;
export type FindUsersQueryResult = Apollo.QueryResult<
  FindUsersQuery,
  FindUsersQueryVariables
>;
export const UsersDocument = gql`
  subscription users($limit: Int, $offset: Int) {
    users(limit: $limit, offset: $offset, order_by: { created_at: desc }) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;

/**
 * __useUsersSubscription__
 *
 * To run a query within a React component, call `useUsersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUsersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersSubscription({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useUsersSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    UsersSubscription,
    UsersSubscriptionVariables
  >
) {
  return Apollo.useSubscription<UsersSubscription, UsersSubscriptionVariables>(
    UsersDocument,
    baseOptions
  );
}
export type UsersSubscriptionHookResult = ReturnType<
  typeof useUsersSubscription
>;
export type UsersSubscriptionResult = Apollo.SubscriptionResult<
  UsersSubscription
>;
export const CreateUsersDocument = gql`
  mutation createUsers($input: [CreateUserInput!]!) {
    createUsers(input: $input) {
      id
    }
  }
`;
export type CreateUsersMutationFn = Apollo.MutationFunction<
  CreateUsersMutation,
  CreateUsersMutationVariables
>;

/**
 * __useCreateUsersMutation__
 *
 * To run a mutation, you first call `useCreateUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUsersMutation, { data, loading, error }] = useCreateUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUsersMutation,
    CreateUsersMutationVariables
  >
) {
  return Apollo.useMutation<CreateUsersMutation, CreateUsersMutationVariables>(
    CreateUsersDocument,
    baseOptions
  );
}
export type CreateUsersMutationHookResult = ReturnType<
  typeof useCreateUsersMutation
>;
export type CreateUsersMutationResult = Apollo.MutationResult<
  CreateUsersMutation
>;
export type CreateUsersMutationOptions = Apollo.BaseMutationOptions<
  CreateUsersMutation,
  CreateUsersMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($id: uuid!, $input: users_set_input!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $input) {
      first_name
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    baseOptions
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult = Apollo.MutationResult<
  UpdateUserMutation
>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UserRelationshipTypesDocument = gql`
  query userRelationshipTypes {
    user_relationship_types {
      id
      forward
      backward
      details
    }
  }
`;

/**
 * __useUserRelationshipTypesQuery__
 *
 * To run a query within a React component, call `useUserRelationshipTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRelationshipTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRelationshipTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserRelationshipTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserRelationshipTypesQuery,
    UserRelationshipTypesQueryVariables
  >
) {
  return Apollo.useQuery<
    UserRelationshipTypesQuery,
    UserRelationshipTypesQueryVariables
  >(UserRelationshipTypesDocument, baseOptions);
}
export function useUserRelationshipTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserRelationshipTypesQuery,
    UserRelationshipTypesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UserRelationshipTypesQuery,
    UserRelationshipTypesQueryVariables
  >(UserRelationshipTypesDocument, baseOptions);
}
export type UserRelationshipTypesQueryHookResult = ReturnType<
  typeof useUserRelationshipTypesQuery
>;
export type UserRelationshipTypesLazyQueryHookResult = ReturnType<
  typeof useUserRelationshipTypesLazyQuery
>;
export type UserRelationshipTypesQueryResult = Apollo.QueryResult<
  UserRelationshipTypesQuery,
  UserRelationshipTypesQueryVariables
>;
export const FindUserRelationshipsDocument = gql`
  query findUserRelationships($userId: uuid!) {
    user_relationship(
      where: {
        _or: [
          { from_user_id: { _eq: $userId } }
          { to_user_id: { _eq: $userId } }
        ]
      }
    ) {
      id
      from_user {
        id
        first_name
        last_name
        role
      }
      to_user {
        id
        first_name
        last_name
        role
      }
      user_relationship_type {
        forward
        backward
        details
      }
    }
  }
`;

/**
 * __useFindUserRelationshipsQuery__
 *
 * To run a query within a React component, call `useFindUserRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserRelationshipsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFindUserRelationshipsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindUserRelationshipsQuery,
    FindUserRelationshipsQueryVariables
  >
) {
  return Apollo.useQuery<
    FindUserRelationshipsQuery,
    FindUserRelationshipsQueryVariables
  >(FindUserRelationshipsDocument, baseOptions);
}
export function useFindUserRelationshipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindUserRelationshipsQuery,
    FindUserRelationshipsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    FindUserRelationshipsQuery,
    FindUserRelationshipsQueryVariables
  >(FindUserRelationshipsDocument, baseOptions);
}
export type FindUserRelationshipsQueryHookResult = ReturnType<
  typeof useFindUserRelationshipsQuery
>;
export type FindUserRelationshipsLazyQueryHookResult = ReturnType<
  typeof useFindUserRelationshipsLazyQuery
>;
export type FindUserRelationshipsQueryResult = Apollo.QueryResult<
  FindUserRelationshipsQuery,
  FindUserRelationshipsQueryVariables
>;
export const CreateUserRelationshipDocument = gql`
  mutation createUserRelationship($object: user_relationship_insert_input!) {
    insert_user_relationship_one(object: $object) {
      id
    }
  }
`;
export type CreateUserRelationshipMutationFn = Apollo.MutationFunction<
  CreateUserRelationshipMutation,
  CreateUserRelationshipMutationVariables
>;

/**
 * __useCreateUserRelationshipMutation__
 *
 * To run a mutation, you first call `useCreateUserRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserRelationshipMutation, { data, loading, error }] = useCreateUserRelationshipMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateUserRelationshipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserRelationshipMutation,
    CreateUserRelationshipMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateUserRelationshipMutation,
    CreateUserRelationshipMutationVariables
  >(CreateUserRelationshipDocument, baseOptions);
}
export type CreateUserRelationshipMutationHookResult = ReturnType<
  typeof useCreateUserRelationshipMutation
>;
export type CreateUserRelationshipMutationResult = Apollo.MutationResult<
  CreateUserRelationshipMutation
>;
export type CreateUserRelationshipMutationOptions = Apollo.BaseMutationOptions<
  CreateUserRelationshipMutation,
  CreateUserRelationshipMutationVariables
>;
export const DeleteUserRelationshipDocument = gql`
  mutation deleteUserRelationship($id: uuid!) {
    delete_user_relationship_by_pk(id: $id) {
      id
    }
  }
`;
export type DeleteUserRelationshipMutationFn = Apollo.MutationFunction<
  DeleteUserRelationshipMutation,
  DeleteUserRelationshipMutationVariables
>;

/**
 * __useDeleteUserRelationshipMutation__
 *
 * To run a mutation, you first call `useDeleteUserRelationshipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserRelationshipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserRelationshipMutation, { data, loading, error }] = useDeleteUserRelationshipMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserRelationshipMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserRelationshipMutation,
    DeleteUserRelationshipMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteUserRelationshipMutation,
    DeleteUserRelationshipMutationVariables
  >(DeleteUserRelationshipDocument, baseOptions);
}
export type DeleteUserRelationshipMutationHookResult = ReturnType<
  typeof useDeleteUserRelationshipMutation
>;
export type DeleteUserRelationshipMutationResult = Apollo.MutationResult<
  DeleteUserRelationshipMutation
>;
export type DeleteUserRelationshipMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserRelationshipMutation,
  DeleteUserRelationshipMutationVariables
>;
export const UsersCountDocument = gql`
  query usersCount($roles: [user_roles_enum!]) {
    users_aggregate(where: { role: { _in: $roles } }) {
      aggregate {
        count(columns: id)
      }
    }
  }
`;

/**
 * __useUsersCountQuery__
 *
 * To run a query within a React component, call `useUsersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersCountQuery({
 *   variables: {
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useUsersCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UsersCountQuery,
    UsersCountQueryVariables
  >
) {
  return Apollo.useQuery<UsersCountQuery, UsersCountQueryVariables>(
    UsersCountDocument,
    baseOptions
  );
}
export function useUsersCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersCountQuery,
    UsersCountQueryVariables
  >
) {
  return Apollo.useLazyQuery<UsersCountQuery, UsersCountQueryVariables>(
    UsersCountDocument,
    baseOptions
  );
}
export type UsersCountQueryHookResult = ReturnType<typeof useUsersCountQuery>;
export type UsersCountLazyQueryHookResult = ReturnType<
  typeof useUsersCountLazyQuery
>;
export type UsersCountQueryResult = Apollo.QueryResult<
  UsersCountQuery,
  UsersCountQueryVariables
>;
export const CurrentUserNamesDocument = gql`
  query currentUserNames($userName: String!) {
    user_names(where: { username: { _eq: $userName } }) {
      username
    }
  }
`;

/**
 * __useCurrentUserNamesQuery__
 *
 * To run a query within a React component, call `useCurrentUserNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserNamesQuery({
 *   variables: {
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function useCurrentUserNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserNamesQuery,
    CurrentUserNamesQueryVariables
  >
) {
  return Apollo.useQuery<CurrentUserNamesQuery, CurrentUserNamesQueryVariables>(
    CurrentUserNamesDocument,
    baseOptions
  );
}
export function useCurrentUserNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserNamesQuery,
    CurrentUserNamesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CurrentUserNamesQuery,
    CurrentUserNamesQueryVariables
  >(CurrentUserNamesDocument, baseOptions);
}
export type CurrentUserNamesQueryHookResult = ReturnType<
  typeof useCurrentUserNamesQuery
>;
export type CurrentUserNamesLazyQueryHookResult = ReturnType<
  typeof useCurrentUserNamesLazyQuery
>;
export type CurrentUserNamesQueryResult = Apollo.QueryResult<
  CurrentUserNamesQuery,
  CurrentUserNamesQueryVariables
>;
export const InsertVirtualProposalTripDocument = gql`
  mutation insertVirtualProposalTrip($topic: String!, $question: String!) {
    insert_virtual_trip_proposals(
      objects: { topic: $topic, question: $question }
    ) {
      affected_rows
    }
  }
`;
export type InsertVirtualProposalTripMutationFn = Apollo.MutationFunction<
  InsertVirtualProposalTripMutation,
  InsertVirtualProposalTripMutationVariables
>;

/**
 * __useInsertVirtualProposalTripMutation__
 *
 * To run a mutation, you first call `useInsertVirtualProposalTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertVirtualProposalTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertVirtualProposalTripMutation, { data, loading, error }] = useInsertVirtualProposalTripMutation({
 *   variables: {
 *      topic: // value for 'topic'
 *      question: // value for 'question'
 *   },
 * });
 */
export function useInsertVirtualProposalTripMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertVirtualProposalTripMutation,
    InsertVirtualProposalTripMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertVirtualProposalTripMutation,
    InsertVirtualProposalTripMutationVariables
  >(InsertVirtualProposalTripDocument, baseOptions);
}
export type InsertVirtualProposalTripMutationHookResult = ReturnType<
  typeof useInsertVirtualProposalTripMutation
>;
export type InsertVirtualProposalTripMutationResult = Apollo.MutationResult<
  InsertVirtualProposalTripMutation
>;
export type InsertVirtualProposalTripMutationOptions = Apollo.BaseMutationOptions<
  InsertVirtualProposalTripMutation,
  InsertVirtualProposalTripMutationVariables
>;
export const InsertVirtualTripPlanDocument = gql`
  mutation insertVirtualTripPlan(
    $event_date: timestamptz!
    $event_details: String!
    $title: String
  ) {
    insert_virtual_trip_plans(
      objects: {
        event_date: $event_date
        event_details: $event_details
        title: $title
      }
    ) {
      affected_rows
    }
  }
`;
export type InsertVirtualTripPlanMutationFn = Apollo.MutationFunction<
  InsertVirtualTripPlanMutation,
  InsertVirtualTripPlanMutationVariables
>;

/**
 * __useInsertVirtualTripPlanMutation__
 *
 * To run a mutation, you first call `useInsertVirtualTripPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertVirtualTripPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertVirtualTripPlanMutation, { data, loading, error }] = useInsertVirtualTripPlanMutation({
 *   variables: {
 *      event_date: // value for 'event_date'
 *      event_details: // value for 'event_details'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useInsertVirtualTripPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertVirtualTripPlanMutation,
    InsertVirtualTripPlanMutationVariables
  >
) {
  return Apollo.useMutation<
    InsertVirtualTripPlanMutation,
    InsertVirtualTripPlanMutationVariables
  >(InsertVirtualTripPlanDocument, baseOptions);
}
export type InsertVirtualTripPlanMutationHookResult = ReturnType<
  typeof useInsertVirtualTripPlanMutation
>;
export type InsertVirtualTripPlanMutationResult = Apollo.MutationResult<
  InsertVirtualTripPlanMutation
>;
export type InsertVirtualTripPlanMutationOptions = Apollo.BaseMutationOptions<
  InsertVirtualTripPlanMutation,
  InsertVirtualTripPlanMutationVariables
>;
export const VirtualProposalTripsDocument = gql`
  query virtualProposalTrips($limit: Int, $offset: Int) {
    virtual_trip_proposals(
      limit: $limit
      offset: $offset
      order_by: { id: desc }
    ) {
      question
      id
      topic
      created_at
      user {
        first_name
        last_name
        school {
          name
        }
      }
    }
    virtual_trip_proposals_aggregate {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useVirtualProposalTripsQuery__
 *
 * To run a query within a React component, call `useVirtualProposalTripsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVirtualProposalTripsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualProposalTripsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useVirtualProposalTripsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    VirtualProposalTripsQuery,
    VirtualProposalTripsQueryVariables
  >
) {
  return Apollo.useQuery<
    VirtualProposalTripsQuery,
    VirtualProposalTripsQueryVariables
  >(VirtualProposalTripsDocument, baseOptions);
}
export function useVirtualProposalTripsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VirtualProposalTripsQuery,
    VirtualProposalTripsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    VirtualProposalTripsQuery,
    VirtualProposalTripsQueryVariables
  >(VirtualProposalTripsDocument, baseOptions);
}
export type VirtualProposalTripsQueryHookResult = ReturnType<
  typeof useVirtualProposalTripsQuery
>;
export type VirtualProposalTripsLazyQueryHookResult = ReturnType<
  typeof useVirtualProposalTripsLazyQuery
>;
export type VirtualProposalTripsQueryResult = Apollo.QueryResult<
  VirtualProposalTripsQuery,
  VirtualProposalTripsQueryVariables
>;
export const VirtualPlanTripsDocument = gql`
  query virtualPlanTrips(
    $limit: Int
    $offset: Int
    $sortById: order_by = desc
    $sortByEventDate: order_by
  ) {
    virtual_trip_plans(
      limit: $limit
      offset: $offset
      order_by: { id: $sortById, event_date: $sortByEventDate }
    ) {
      event_date
      event_details
      created_at
      title
      id
      user {
        first_name
        last_name
        school {
          name
        }
      }
    }
    virtual_trip_plans_aggregate {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useVirtualPlanTripsQuery__
 *
 * To run a query within a React component, call `useVirtualPlanTripsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVirtualPlanTripsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVirtualPlanTripsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sortById: // value for 'sortById'
 *      sortByEventDate: // value for 'sortByEventDate'
 *   },
 * });
 */
export function useVirtualPlanTripsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    VirtualPlanTripsQuery,
    VirtualPlanTripsQueryVariables
  >
) {
  return Apollo.useQuery<VirtualPlanTripsQuery, VirtualPlanTripsQueryVariables>(
    VirtualPlanTripsDocument,
    baseOptions
  );
}
export function useVirtualPlanTripsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VirtualPlanTripsQuery,
    VirtualPlanTripsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    VirtualPlanTripsQuery,
    VirtualPlanTripsQueryVariables
  >(VirtualPlanTripsDocument, baseOptions);
}
export type VirtualPlanTripsQueryHookResult = ReturnType<
  typeof useVirtualPlanTripsQuery
>;
export type VirtualPlanTripsLazyQueryHookResult = ReturnType<
  typeof useVirtualPlanTripsLazyQuery
>;
export type VirtualPlanTripsQueryResult = Apollo.QueryResult<
  VirtualPlanTripsQuery,
  VirtualPlanTripsQueryVariables
>;
export const SchoolsDocument = gql`
  query schools {
    schools {
      id
      name
    }
  }
`;

/**
 * __useSchoolsQuery__
 *
 * To run a query within a React component, call `useSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSchoolsQuery(
  baseOptions?: Apollo.QueryHookOptions<SchoolsQuery, SchoolsQueryVariables>
) {
  return Apollo.useQuery<SchoolsQuery, SchoolsQueryVariables>(
    SchoolsDocument,
    baseOptions
  );
}
export function useSchoolsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SchoolsQuery, SchoolsQueryVariables>
) {
  return Apollo.useLazyQuery<SchoolsQuery, SchoolsQueryVariables>(
    SchoolsDocument,
    baseOptions
  );
}
export type SchoolsQueryHookResult = ReturnType<typeof useSchoolsQuery>;
export type SchoolsLazyQueryHookResult = ReturnType<typeof useSchoolsLazyQuery>;
export type SchoolsQueryResult = Apollo.QueryResult<
  SchoolsQuery,
  SchoolsQueryVariables
>;
export const CreateVirtualTripDocument = gql`
  mutation createVirtualTrip($object: virtual_trips_insert_input!) {
    insert_virtual_trips_one(
      object: $object
      on_conflict: {
        constraint: virtual_trips_pkey
        update_columns: [
          topic
          meeting_link
          event_date
          description
          notes
          all_schools
        ]
      }
    ) {
      topic
    }
  }
`;
export type CreateVirtualTripMutationFn = Apollo.MutationFunction<
  CreateVirtualTripMutation,
  CreateVirtualTripMutationVariables
>;

/**
 * __useCreateVirtualTripMutation__
 *
 * To run a mutation, you first call `useCreateVirtualTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVirtualTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVirtualTripMutation, { data, loading, error }] = useCreateVirtualTripMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateVirtualTripMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateVirtualTripMutation,
    CreateVirtualTripMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateVirtualTripMutation,
    CreateVirtualTripMutationVariables
  >(CreateVirtualTripDocument, baseOptions);
}
export type CreateVirtualTripMutationHookResult = ReturnType<
  typeof useCreateVirtualTripMutation
>;
export type CreateVirtualTripMutationResult = Apollo.MutationResult<
  CreateVirtualTripMutation
>;
export type CreateVirtualTripMutationOptions = Apollo.BaseMutationOptions<
  CreateVirtualTripMutation,
  CreateVirtualTripMutationVariables
>;
export const DeleteVirtualPlanTripsDocument = gql`
  mutation deleteVirtualPlanTrips($planTripId: Int!) {
    delete_virtual_trip_plans(where: { id: { _eq: $planTripId } }) {
      affected_rows
    }
  }
`;
export type DeleteVirtualPlanTripsMutationFn = Apollo.MutationFunction<
  DeleteVirtualPlanTripsMutation,
  DeleteVirtualPlanTripsMutationVariables
>;

/**
 * __useDeleteVirtualPlanTripsMutation__
 *
 * To run a mutation, you first call `useDeleteVirtualPlanTripsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVirtualPlanTripsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVirtualPlanTripsMutation, { data, loading, error }] = useDeleteVirtualPlanTripsMutation({
 *   variables: {
 *      planTripId: // value for 'planTripId'
 *   },
 * });
 */
export function useDeleteVirtualPlanTripsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVirtualPlanTripsMutation,
    DeleteVirtualPlanTripsMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteVirtualPlanTripsMutation,
    DeleteVirtualPlanTripsMutationVariables
  >(DeleteVirtualPlanTripsDocument, baseOptions);
}
export type DeleteVirtualPlanTripsMutationHookResult = ReturnType<
  typeof useDeleteVirtualPlanTripsMutation
>;
export type DeleteVirtualPlanTripsMutationResult = Apollo.MutationResult<
  DeleteVirtualPlanTripsMutation
>;
export type DeleteVirtualPlanTripsMutationOptions = Apollo.BaseMutationOptions<
  DeleteVirtualPlanTripsMutation,
  DeleteVirtualPlanTripsMutationVariables
>;
export const DeleteVirtualProposalTripsDocument = gql`
  mutation deleteVirtualProposalTrips($proposalTripId: Int!) {
    delete_virtual_trip_proposals(where: { id: { _eq: $proposalTripId } }) {
      affected_rows
    }
  }
`;
export type DeleteVirtualProposalTripsMutationFn = Apollo.MutationFunction<
  DeleteVirtualProposalTripsMutation,
  DeleteVirtualProposalTripsMutationVariables
>;

/**
 * __useDeleteVirtualProposalTripsMutation__
 *
 * To run a mutation, you first call `useDeleteVirtualProposalTripsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVirtualProposalTripsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVirtualProposalTripsMutation, { data, loading, error }] = useDeleteVirtualProposalTripsMutation({
 *   variables: {
 *      proposalTripId: // value for 'proposalTripId'
 *   },
 * });
 */
export function useDeleteVirtualProposalTripsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVirtualProposalTripsMutation,
    DeleteVirtualProposalTripsMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteVirtualProposalTripsMutation,
    DeleteVirtualProposalTripsMutationVariables
  >(DeleteVirtualProposalTripsDocument, baseOptions);
}
export type DeleteVirtualProposalTripsMutationHookResult = ReturnType<
  typeof useDeleteVirtualProposalTripsMutation
>;
export type DeleteVirtualProposalTripsMutationResult = Apollo.MutationResult<
  DeleteVirtualProposalTripsMutation
>;
export type DeleteVirtualProposalTripsMutationOptions = Apollo.BaseMutationOptions<
  DeleteVirtualProposalTripsMutation,
  DeleteVirtualProposalTripsMutationVariables
>;
export const UpComingTripsDocument = gql`
  query upComingTrips(
    $limit: Int
    $dateRange: timestamptz_comparison_exp = {}
    $offset: Int
    $sortEventDate: order_by = asc
    $sortCreatedDate: order_by
  ) {
    virtual_trips(
      where: { event_date: $dateRange }
      order_by: { event_date: $sortEventDate, created_at: $sortCreatedDate }
      offset: $offset
      limit: $limit
    ) {
      all_schools
      event_date
      id
      meeting_link
      topic
      created_at
      description
      virtual_trip_images {
        image_link
        id
      }
      virtual_trip_schools {
        school {
          name
          id
        }
      }
    }
    virtual_trips_aggregate(where: { event_date: $dateRange }) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useUpComingTripsQuery__
 *
 * To run a query within a React component, call `useUpComingTripsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpComingTripsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpComingTripsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      dateRange: // value for 'dateRange'
 *      offset: // value for 'offset'
 *      sortEventDate: // value for 'sortEventDate'
 *      sortCreatedDate: // value for 'sortCreatedDate'
 *   },
 * });
 */
export function useUpComingTripsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UpComingTripsQuery,
    UpComingTripsQueryVariables
  >
) {
  return Apollo.useQuery<UpComingTripsQuery, UpComingTripsQueryVariables>(
    UpComingTripsDocument,
    baseOptions
  );
}
export function useUpComingTripsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UpComingTripsQuery,
    UpComingTripsQueryVariables
  >
) {
  return Apollo.useLazyQuery<UpComingTripsQuery, UpComingTripsQueryVariables>(
    UpComingTripsDocument,
    baseOptions
  );
}
export type UpComingTripsQueryHookResult = ReturnType<
  typeof useUpComingTripsQuery
>;
export type UpComingTripsLazyQueryHookResult = ReturnType<
  typeof useUpComingTripsLazyQuery
>;
export type UpComingTripsQueryResult = Apollo.QueryResult<
  UpComingTripsQuery,
  UpComingTripsQueryVariables
>;
export const GetVirtualTripByIdDocument = gql`
  query getVirtualTripById($id: Int!) {
    virtual_trips_by_pk(id: $id) {
      description
      event_date
      id
      meeting_link
      notes
      title
      topic
      all_schools
      virtual_trip_images {
        image_link
        id
      }
      virtual_trip_schools {
        school {
          name
          id
        }
      }
    }
  }
`;

/**
 * __useGetVirtualTripByIdQuery__
 *
 * To run a query within a React component, call `useGetVirtualTripByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVirtualTripByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVirtualTripByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVirtualTripByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVirtualTripByIdQuery,
    GetVirtualTripByIdQueryVariables
  >
) {
  return Apollo.useQuery<
    GetVirtualTripByIdQuery,
    GetVirtualTripByIdQueryVariables
  >(GetVirtualTripByIdDocument, baseOptions);
}
export function useGetVirtualTripByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVirtualTripByIdQuery,
    GetVirtualTripByIdQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetVirtualTripByIdQuery,
    GetVirtualTripByIdQueryVariables
  >(GetVirtualTripByIdDocument, baseOptions);
}
export type GetVirtualTripByIdQueryHookResult = ReturnType<
  typeof useGetVirtualTripByIdQuery
>;
export type GetVirtualTripByIdLazyQueryHookResult = ReturnType<
  typeof useGetVirtualTripByIdLazyQuery
>;
export type GetVirtualTripByIdQueryResult = Apollo.QueryResult<
  GetVirtualTripByIdQuery,
  GetVirtualTripByIdQueryVariables
>;
export const DeleteVirtualTripDocument = gql`
  mutation deleteVirtualTrip($id: Int!) {
    delete_virtual_trip_images(where: { virtual_trip_id: { _eq: $id } }) {
      affected_rows
    }
    delete_virtual_trip_schools(where: { virtual_trip_id: { _eq: $id } }) {
      affected_rows
    }
    delete_virtual_trips_by_pk(id: $id) {
      id
    }
  }
`;
export type DeleteVirtualTripMutationFn = Apollo.MutationFunction<
  DeleteVirtualTripMutation,
  DeleteVirtualTripMutationVariables
>;

/**
 * __useDeleteVirtualTripMutation__
 *
 * To run a mutation, you first call `useDeleteVirtualTripMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVirtualTripMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVirtualTripMutation, { data, loading, error }] = useDeleteVirtualTripMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVirtualTripMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVirtualTripMutation,
    DeleteVirtualTripMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteVirtualTripMutation,
    DeleteVirtualTripMutationVariables
  >(DeleteVirtualTripDocument, baseOptions);
}
export type DeleteVirtualTripMutationHookResult = ReturnType<
  typeof useDeleteVirtualTripMutation
>;
export type DeleteVirtualTripMutationResult = Apollo.MutationResult<
  DeleteVirtualTripMutation
>;
export type DeleteVirtualTripMutationOptions = Apollo.BaseMutationOptions<
  DeleteVirtualTripMutation,
  DeleteVirtualTripMutationVariables
>;
export const ProposalTripsTopicsDocument = gql`
  query proposalTripsTopics {
    virtual_trip_proposals_topic {
      count
      topic
    }
    virtual_trip_proposals_topic_aggregate {
      aggregate {
        sum {
          count
        }
      }
    }
  }
`;

/**
 * __useProposalTripsTopicsQuery__
 *
 * To run a query within a React component, call `useProposalTripsTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProposalTripsTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProposalTripsTopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProposalTripsTopicsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProposalTripsTopicsQuery,
    ProposalTripsTopicsQueryVariables
  >
) {
  return Apollo.useQuery<
    ProposalTripsTopicsQuery,
    ProposalTripsTopicsQueryVariables
  >(ProposalTripsTopicsDocument, baseOptions);
}
export function useProposalTripsTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProposalTripsTopicsQuery,
    ProposalTripsTopicsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ProposalTripsTopicsQuery,
    ProposalTripsTopicsQueryVariables
  >(ProposalTripsTopicsDocument, baseOptions);
}
export type ProposalTripsTopicsQueryHookResult = ReturnType<
  typeof useProposalTripsTopicsQuery
>;
export type ProposalTripsTopicsLazyQueryHookResult = ReturnType<
  typeof useProposalTripsTopicsLazyQuery
>;
export type ProposalTripsTopicsQueryResult = Apollo.QueryResult<
  ProposalTripsTopicsQuery,
  ProposalTripsTopicsQueryVariables
>;
export const DeleteVirtualTripSchoolDocument = gql`
  mutation deleteVirtualTripSchool($tripId: Int!, $schoolId: uuid!) {
    delete_virtual_trip_schools(
      where: {
        virtual_trip_id: { _eq: $tripId }
        school_id: { _eq: $schoolId }
      }
    ) {
      affected_rows
    }
  }
`;
export type DeleteVirtualTripSchoolMutationFn = Apollo.MutationFunction<
  DeleteVirtualTripSchoolMutation,
  DeleteVirtualTripSchoolMutationVariables
>;

/**
 * __useDeleteVirtualTripSchoolMutation__
 *
 * To run a mutation, you first call `useDeleteVirtualTripSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVirtualTripSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVirtualTripSchoolMutation, { data, loading, error }] = useDeleteVirtualTripSchoolMutation({
 *   variables: {
 *      tripId: // value for 'tripId'
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useDeleteVirtualTripSchoolMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVirtualTripSchoolMutation,
    DeleteVirtualTripSchoolMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteVirtualTripSchoolMutation,
    DeleteVirtualTripSchoolMutationVariables
  >(DeleteVirtualTripSchoolDocument, baseOptions);
}
export type DeleteVirtualTripSchoolMutationHookResult = ReturnType<
  typeof useDeleteVirtualTripSchoolMutation
>;
export type DeleteVirtualTripSchoolMutationResult = Apollo.MutationResult<
  DeleteVirtualTripSchoolMutation
>;
export type DeleteVirtualTripSchoolMutationOptions = Apollo.BaseMutationOptions<
  DeleteVirtualTripSchoolMutation,
  DeleteVirtualTripSchoolMutationVariables
>;
export const DeleteVirtualTripImagesDocument = gql`
  mutation deleteVirtualTripImages($tripId: Int!) {
    delete_virtual_trip_images(where: { virtual_trip_id: { _eq: $tripId } }) {
      affected_rows
    }
  }
`;
export type DeleteVirtualTripImagesMutationFn = Apollo.MutationFunction<
  DeleteVirtualTripImagesMutation,
  DeleteVirtualTripImagesMutationVariables
>;

/**
 * __useDeleteVirtualTripImagesMutation__
 *
 * To run a mutation, you first call `useDeleteVirtualTripImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVirtualTripImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVirtualTripImagesMutation, { data, loading, error }] = useDeleteVirtualTripImagesMutation({
 *   variables: {
 *      tripId: // value for 'tripId'
 *   },
 * });
 */
export function useDeleteVirtualTripImagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVirtualTripImagesMutation,
    DeleteVirtualTripImagesMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteVirtualTripImagesMutation,
    DeleteVirtualTripImagesMutationVariables
  >(DeleteVirtualTripImagesDocument, baseOptions);
}
export type DeleteVirtualTripImagesMutationHookResult = ReturnType<
  typeof useDeleteVirtualTripImagesMutation
>;
export type DeleteVirtualTripImagesMutationResult = Apollo.MutationResult<
  DeleteVirtualTripImagesMutation
>;
export type DeleteVirtualTripImagesMutationOptions = Apollo.BaseMutationOptions<
  DeleteVirtualTripImagesMutation,
  DeleteVirtualTripImagesMutationVariables
>;
