import React from "react";
import Card from "@material-ui/core/Card";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import { useParams } from "react-router-dom";
import { QuestionTypeEnum, QUESTIONS } from "components/school/consts";
import { FieldTripParams } from "./consts";
import {
  useFindMeQuery,
  useFieldTripDetailQuery,
  useFieldTripReflectsQuery,
  useStudentReflectQuestionQuery,
  useSetParentApprovalMutation,
  useFieldTripApprovalsQuery,
} from "generated/graphql";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Slider, { Mark } from "@material-ui/core/Slider";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Chip from "@material-ui/core/Chip";
import Icon from "@material-ui/core/Icon";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CloseIcon from "@material-ui/icons/Close";
import Rating from "@material-ui/lab/Rating";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";

import {
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineContent,
  Timeline,
  Autocomplete,
} from "@material-ui/lab";
import { Ratings } from "components/school/consts";
import { DateTime } from "luxon";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ErrorToast from "components/toast/ErrorToast";
import printCss from "./print_css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ...printCss,
    paper: {
      margin: "0 15px",
      padding: "30px",
    },
    paperLocation: {
      padding: "6px 16px",
    },
    detailGroup: {
      marginBottom: "20px",
      maxWidth: 630,
      "& [class*='MuiTypography-body1']": {
        color: "#777",
      },
    },
    wrapper: {
      padding: "0 15px",
    },
    label: {
      width: 40,
      whiteSpace: "break-spaces",
      lineHeight: 1.1,
      fontSize: "13px",
    },
    reflectRow: {
      padding: "10px 15px",
      backgroundColor: "#f9f9f9",
      borderRadius: "5px",
      height: "100%",
    },
    sliderWrapper: {
      width: "100%",
      paddingLeft: 10,
      paddingRight: 50,
    },
    fontColor: {
      color: "#333",
    },
    customListStyle: {
      "& .MuiListItem-gutters": {
        paddingLeft: 0,
        paddingBottom: 0,
        alignItems: "flex-start",
      },
      "& .MuiListItemIcon-root": {
        minWidth: "35px",
        marginTop: "2px",
      },
    },
    specialBox: {
      backgroundColor: "#f9f9f9",
      paddingTop: 20,
      width: "calc(100% + 30px)",
      margin: "0 -15px",
    },
    sectionSpace: {
      padding: "40px 15px",
      "& hr": {
        opacity: 0.2,
      },
    },
    tripTimeLine: {
      padding: "20px 15px",
      backgroundColor: "#f9f9f9",
      borderRadius: "5px",
    },
    chip: {
      marginRight: "3px",
      marginBottom: "3px",
      backgroundColor: "#e6e6e6",
      height: 30,
      borderRadius: 5,
    },
    tableWrapper: {
      padding: "0 20px",
      "& [class*='MuiTableCell-head']": {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    closeButton: {
      padding: "6px",
    },
    schoolNoteStyle: {
      whiteSpace: "pre-line",
    },
    ratingStyle: {
      fontSize: "18px",
      marginTop: "5px",
    },
    printBtn: {
      marginRight: "15px",
    },
    iconHover: {
      "&:hover": {
        color: "#CCC",
      },
    },
    printOnly: {
      display: "none !important",
    },
    ellipsisContent: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      "@media print": {
        overflow: "unset",
        textOverflow: "unset",
        whiteSpace: "unset",
      },
    },
    oddEvenStyle: {
      "& li:first-child": {
        "& [class*='MuiTimelineSeparator-root']": {
          "& [class*='MuiTimelineConnector-root']:first-child": {
            backgroundColor: "transparent",
          },
        },
      },
      "& li:last-child": {
        "& [class*='MuiTimelineSeparator-root']": {
          "& [class*='MuiTimelineConnector-root']:last-child": {
            backgroundColor: "transparent",
          },
        },
      },
      "& li:nth-child(odd)": {
        "& [class*='MuiTimelineItem-content']": {
          "& [class*='MuiGrid-container']": {
            flexFlow: "row",
            justifyContent: "flex-end",
            "& .actionColumn": {
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-end",
              textAlign: "right",
            },
          },
        },
      },
      "& li:nth-child(even)": {
        "& [class*='MuiTimelineSeparator-root']": {
          marginLeft: "1px",
        },
        "& [class*='MuiTimelineItem-content']": {
          "& [class*='MuiGrid-container']": {
            flexFlow: "row-reverse",
            justifyContent: "flex-end",
            "& .actionColumn": {
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-start",
              textAlign: "left",
            },
          },
        },
      },
    },
    locationNoteStyle: {
      fontSize: 12,
      color: "#999",
      whiteSpace: "pre-line",
    },
    locationContactStyle: {
      fontSize: 12,
      color: "#999",
      whiteSpace: "pre-line",
    },
    locationAddress: {
      fontSize: 15,
      lineHeight: 1.2,
      marginBottom: 5,
    },
    slider: {
      "& [class*='MuiSlider-mark']": {
        width: 2,
        height: 2,
        opacity: 0.5,
        backgroundColor: theme.palette.primary.dark,
      },
      "& [class*='MuiSlider-rail']": {
        backgroundColor: theme.palette.primary.light,
      },
      "& [class*='MuiSlider-thumb']": {
        backgroundColor: theme.palette.primary.light,
        width: "12px",
        height: "12px",
        marginTop: "-5px",
        marginLeft: "-6px",
      },
      "& [class*='MuiSlider-markLabel']": {
        color: "#000",
        background: "none",
      },
    },
    spinner: {
      height: "100vh",
    },
  })
);
const iconMap = {
  location: <LocationOnIcon />,
  food: <FastfoodIcon />,
};
interface CheckProps {
  value?: boolean | null;
  agreementType?: any;
  title: String;
}
interface StarRatingProps {
  locationId?: string;
  locationTitle: string;
}
export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}
interface LabelProps {
  label: string;
}

export default function FieldTripDetail() {
  const classes = useStyles();
  const params = useParams<FieldTripParams>();
  const {
    data: meData,
    loading: meDataLoading,
    error: meDataError,
  } = useFindMeQuery();
  const {
    data: tripData,
    loading,
    error: fieldTripError,
  } = useFieldTripDetailQuery({
    variables: {
      tripId: params.tripId,
    },
  });

  if (loading) {
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        className={classes.spinner}
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (fieldTripError) {
    return (
      <ErrorToast
        error={fieldTripError}
        processCustomError={() =>
          `Invalid Field Trip - ${fieldTripError.message}`
        }
      />
    );
  }

  const tripDetail = tripData?.field_trips[0];

  const marks: Array<Mark> = Ratings.map((r) => ({
    value: r.value,
    label: r.hiddenLabel ? <></> : <LabelComponent label={r.label} />,
  }));

  function LabelComponent(props: LabelProps) {
    const { label } = props;
    const classes = useStyles();

    return <div className={classes.label}>{label}</div>;
  }

  function StarRating(props: StarRatingProps) {
    const { locationId, locationTitle } = props;

    const classes = useStyles();

    let sumRating: any = [0];

    const ss = tripDetail?.field_trip_students[0].field_trip.field_trip_location_reviews
      .filter((f) => f.field_trip_location_id == locationId)
      .map((s) => sumRating.push(s.rating));

    function average(av: any) {
      return av.reduce((a: any, b: any) => a + b) / av.length || 0;
    }

    return (
      <div>
        <Tooltip title={`${locationTitle} Average Rating`} placement="bottom">
          <div style={{ display: "inline-block" }}>
            <Rating
              className={classes.ratingStyle}
              value={average(sumRating)}
              precision={0.5}
              disabled={true}
            />
          </div>
        </Tooltip>
      </div>
    );
  }

  function Check(props: CheckProps) {
    const [open, setOpen] = React.useState(false);
    const { value, agreementType, title } = props;

    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    const getFilteredQuestions = QUESTIONS?.filter(
      (q) => q.type === agreementType
    ).map((q) => {
      return (
        <List className={classes.customListStyle}>
          <ListItem>
            <ListItemIcon>
              <CheckBoxIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary={q.label} />
          </ListItem>
        </List>
      );
    });

    return value ? (
      <>
        <Tooltip title={`View ${title}`} placement="top">
          <Icon
            onClick={handleClickOpen}
            className={`fa fa-check-circle ${classes.iconHover}`}
            color="primary"
            style={{ fontSize: 30, cursor: "pointer" }}
          />
        </Tooltip>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title">
            <Grid container flex-direaction="row" justify="space-between">
              {title}
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>{getFilteredQuestions}</Typography>
          </DialogContent>
        </Dialog>
      </>
    ) : (
      <>
        <Tooltip title={`Does not agree with ${title}`} placement="top">
          <Icon
            className="fa fa-times-circle"
            color="secondary"
            style={{ fontSize: 30 }}
          />
        </Tooltip>
      </>
    );
  }

  function SchoolNotes(props: any) {
    const { content, title, studentName } = props;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    const updatedContent = content.replace("{student_name}", studentName);

    function printContent() {
      //@ts-ignore
      var divContents = document.getElementById("printContent").innerHTML;
      var printWindow = window.open("", "", "height=450,width=700");
      //@ts-ignore
      printWindow.document.write("<html><head><title></title>");
      //@ts-ignore
      printWindow.document.write(
        "</head><body style='white-space: pre-line'; font-family: sans-serif;>"
      );
      //@ts-ignore
      printWindow.document.write(divContents);
      //@ts-ignore
      printWindow.document.write("</body></html>");
      //@ts-ignore
      printWindow.document.close();
      //@ts-ignore
      printWindow.print();
    }
    return (
      <>
        <IconButton onClick={handleClickOpen}>
          <DescriptionOutlinedIcon />
        </IconButton>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title">
            <Grid container flex-direaction="row" justify="space-between">
              {title}
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent dividers id="printContent">
            <img
              className={classes.printOnly}
              src={tripDetail?.owner.school?.logo_url || undefined}
              style={{ margin: "0 auto", display: "block" }}
            />
            <h3 className={classes.printOnly}>{title}</h3>
            <Typography className={classes.schoolNoteStyle}>
              {updatedContent}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="default"
              autoFocus
              onClick={printContent}
              className={classes.printBtn}
            >
              <PrintRoundedIcon
                style={{ fontSize: "20px", marginRight: "5px" }}
              />
              Print
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  function StudentReflection() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    const {
      data: reflectData,
      error: studentReflectError,

      loading: reflectionLoading,
    } = useFieldTripReflectsQuery({
      variables: {
        tripId: params.tripId,
        schoolId: tripDetail?.owner.school?.id,
      },
    });

    if (reflectionLoading) {
      return (
        <Grid container alignItems="center" justify="center">
          <CircularProgress />
        </Grid>
      );
    }
    if (studentReflectError) {
      return (
        <ErrorToast
          error={studentReflectError}
          processCustomError={() =>
            `Invalid Field Trip - ${studentReflectError.message}`
          }
        />
      );
    }

    const getAllReflect = reflectData?.field_trip_students.map((s) => {
      return s.reflect_survey ? Object.entries(s.reflect_survey) : [];
    });

    function ReflectionQuestionAvarage(props: any) {
      const { qId } = props;

      let sum: any = [0];

      const ref = getAllReflect?.map((s) => {
        return s.filter((f) => f[0] == qId).map((q) => sum.push(q[1]));
      });

      function reflectAvg(av: any) {
        return av.reduce((a: any, b: any) => a + b) / av.length || 0;
      }

      return (
        <Slider
          defaultValue={reflectAvg(sum)}
          aria-labelledby="discrete-slider"
          disabled={true}
          marks={marks}
          valueLabelDisplay="off"
          step={1}
          track={false}
          min={0}
          max={6}
          className={classes.slider}
        />
      );
    }

    function StudetnReflectionPopup() {
      const {
        data: studentReflection,
        refetch,
      } = useStudentReflectQuestionQuery({
        variables: {
          schoolId: tripDetail?.owner.school?.id,
          tripId: params.tripId,
          studentId: "",
        },
      });

      const changeStudentContext = async (studentId: any) => {
        const { data } = await refetch({
          schoolId: tripDetail?.owner.school?.id,
          tripId: params.tripId,
          studentId: `${studentId}`,
        });
      };

      function ReflectionQuestionPopup(props: any) {
        const { qId } = props;
        const ee = studentReflection?.field_trip_students[0].reflect_survey
          ? Object.entries(
              studentReflection?.field_trip_students[0].reflect_survey
            )
          : [];

        //@ts-ignore
        const arr: Array<number> = ee
          .filter((f) => f[0] == qId)
          .map((q) => q[1]);

        return (
          <Slider
            defaultValue={arr || 0}
            aria-labelledby="discrete-slider"
            disabled={true}
            marks={marks}
            valueLabelDisplay="off"
            step={1}
            track={false}
            min={0}
            max={6}
            className={classes.slider}
          />
        );
      }

      return (
        <Dialog
          onClose={handleClose}
          aria-labelledby="individual_student_reflection"
          open={open}
          maxWidth="lg"
          fullWidth={true}
        >
          <DialogTitle id="individual_student_reflection">
            <Grid container flex-direaction="row" justify="space-between">
              Individual Student Reflection
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent dividers id="printContent">
            <Autocomplete
              id="selectStudentPopup"
              size="small"
              value={undefined}
              disableClearable={true}
              //@ts-ignore
              options={
                tripDetail?.field_trip_students.map((s) => ({
                  value: s.user.id,
                  label: s.user?.first_name + " " + s.user?.last_name,
                })) || undefined
              }
              onChange={(event, value) => {
                changeStudentContext(value.value);
              }}
              getOptionLabel={(option) => option.label}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"Select Student"}
                  variant="outlined"
                />
              )}
            />
            <br />
            <br />
            <Grid container spacing={3}>
              {reflectData?.agreement_questions.map((question) => (
                <Grid item xs={6} key={question.label}>
                  <div className={classes.reflectRow}>
                    <Typography className={classes.fontColor}>
                      {question.label}
                    </Typography>
                    <div className={classes.sliderWrapper}>
                      <ReflectionQuestionPopup qId={question.id} />
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
        </Dialog>
      );
    }
    return (
      <>
        <Grid item xs={12} className="reflectWrapper">
          <div className={classes.wrapper}>
            <div className={classes.detailGroup}>
              <Typography style={{ marginBottom: "20px" }} variant={"h6"}>
                Student Reflection
              </Typography>

              <Button
                variant="contained"
                color="secondary"
                onClick={handleClickOpen}
                className="hide-print"
              >
                Individual Student Reflection
              </Button>
              <StudetnReflectionPopup />
            </div>
            <div className="reflectionWrapper">
              <div>
                <Grid container spacing={3} className="printOnly-row">
                  {reflectData?.agreement_questions.map((question) => (
                    <Grid
                      item
                      xs={6}
                      key={question.label}
                      className="printOnly-cell"
                    >
                      <div className={classes.reflectRow}>
                        <Typography className={classes.fontColor}>
                          {question.label}
                        </Typography>
                        <div className={classes.sliderWrapper}>
                          <ReflectionQuestionAvarage qId={question.id} />
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
          </div>
        </Grid>

        <div className={classes.sectionSpace}></div>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.tableWrapper}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Student</TableCell>
                      <TableCell>What did you enjoy the most</TableCell>
                      <TableCell>What would you do differently</TableCell>
                      <TableCell>
                        What you will create to share your learning experience
                      </TableCell>
                      <TableCell align="center">Mail Agreement</TableCell>
                      <TableCell align="center">
                        Share your learning experience
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tripDetail?.field_trip_students.map((st) => (
                      <TableRow key={st.user.id}>
                        <TableCell>
                          <Tooltip
                            title={`${st.user.first_name} ${st.user.last_name}`}
                            placement="top-start"
                          >
                            <div
                              style={{ maxWidth: 120 }}
                              className={`${classes.ellipsisContent}`}
                            >
                              {st.user.first_name} {st.user.last_name}
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip
                            title={`${st.enjoyed_most}`}
                            placement="top-start"
                          >
                            <div
                              style={{ maxWidth: 200 }}
                              className={`${classes.ellipsisContent}`}
                            >
                              {st.enjoyed_most}
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Tooltip
                            title={`${st.improvements}`}
                            placement="top-start"
                          >
                            <div
                              style={{ maxWidth: 200 }}
                              className={`${classes.ellipsisContent}`}
                            >
                              {st.improvements}
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          {st?.share_experience
                            ? st?.share_experience.map((ex: any) => {
                                return (
                                  <Chip
                                    className={classes.chip}
                                    label={`${ex}`}
                                  />
                                );
                              })
                            : ""}
                        </TableCell>
                        <TableCell align="center">
                          {st?.gratitude_mail_agreement ? (
                            <Tooltip
                              title="Agreed with mail agreement"
                              placement="top"
                            >
                              <Icon
                                className={`fa fa-check-circle ${classes.iconHover}`}
                                color="primary"
                                style={{ fontSize: 30, cursor: "pointer" }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title="Does not agreed with mail agreement"
                              placement="top"
                            >
                              <Icon
                                className="fa fa-times-circle"
                                color="secondary"
                                style={{ fontSize: 30 }}
                              />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {st?.share_experience_agreement ? (
                            <Tooltip
                              title="Agreed to Share your learning experience"
                              placement="top"
                            >
                              <Icon
                                className={`fa fa-check-circle ${classes.iconHover}`}
                                color="primary"
                                style={{ fontSize: 30, cursor: "pointer" }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title="Does not agree to Share your learning experience"
                              placement="top"
                            >
                              <Icon
                                className="fa fa-times-circle"
                                color="secondary"
                                style={{ fontSize: 30 }}
                              />
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }

  function fullPagePrint() {
    window.print();
  }

  function SetParentApproval(props: any) {
    const { tripId, userId } = props;
    const [confirmDialog, setconfirmDialog] = React.useState(false);

    const {
      loading: approvalDataLoading,
      data: approvalData,
    } = useFieldTripApprovalsQuery({
      variables: {
        tripId: tripId,
      },
    });

    const [
      setParentApproval,
      { loading, error: setParentApprovalError },
    ] = useSetParentApprovalMutation({ errorPolicy: "all" });

    if (
      approvalData === undefined ||
      approvalData?.field_trip_approvals.length === 0
    ) {
      return <></>;
    }
    if (approvalDataLoading) {
      return <CircularProgress />;
    }
    return (
      <>
        <ErrorToast
          error={setParentApprovalError}
          processCustomError={() =>
            `Unable to Grant Approval - ${setParentApprovalError?.message}`
          }
        />
        <Button
          variant="contained"
          color="secondary"
          style={{ marginLeft: 16 }}
          onClick={() => setconfirmDialog(true)}
          className="hide-print"
          disabled={
            approvalData.field_trip_approvals[0].status === "approved" ||
            loading
          }
        >
          Grant Approval
        </Button>

        <Dialog
          onClose={() => setconfirmDialog(false)}
          aria-labelledby="grant-permission-dialog"
          open={confirmDialog}
          maxWidth="xs"
          fullWidth={false}
        >
          <DialogContent dividers id="grant-permission-dialog">
            <Typography variant="h6">
              Are you sure do you want to grant approval
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              autoFocus
              onClick={() => setconfirmDialog(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={async () => {
                const setApproval = await setParentApproval({
                  variables: {
                    tripId: tripId,
                    userId: userId,
                    status: "approved",
                    approved_at: "now()",
                  },
                  refetchQueries: ["fieldTripApprovals"],
                });

                if (!setApproval.errors) {
                  setconfirmDialog(false);
                }
              }}
              color="primary"
            >
              Grant Approval
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return (
    <Card className={classes.root} id="print_page">
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12}>
          <div className="mainLogoWrapper">
            {tripDetail?.owner.school?.logo_url ? (
              <img src={tripDetail?.owner.school?.logo_url} />
            ) : null}
          </div>
        </Grid>
        <Grid item xs={6}>
          <CardHeader title={`Field Trip - ${tripDetail?.title}`} />
          <SetParentApproval
            tripId={tripDetail?.id}
            userId={meData?.me[0].id}
          />
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-start"
            className="mainEventData"
          >
            <DateRangeIcon />
            <Typography variant={"h5"}>
              {tripDetail?.event_date
                ? DateTime.fromISO(tripDetail?.event_date).toFormat("DD")
                : "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <div className={classes.wrapper}>
            <div className={classes.detailGroup}>
              <Typography variant={"h6"}>Trip Owner</Typography>
              <Typography>{`${tripDetail?.owner.first_name} ${tripDetail?.owner.last_name}`}</Typography>
            </div>
            <div className={classes.detailGroup}>
              <Typography variant={"h6"}>Interest Area</Typography>
              <Typography>{tripDetail?.interest_area}</Typography>
            </div>
            <div className={classes.detailGroup}>
              <Typography variant={"h6"}>
                Research Questions and Goals
              </Typography>
              <Typography>{tripDetail?.research_questions_goals}</Typography>
            </div>
            <div className={classes.detailGroup}>
              <Typography variant={"h6"}>Agenda</Typography>
              <Typography>{tripDetail?.agenda}</Typography>
            </div>
            <div className={classes.detailGroup}>
              <Typography variant={"h6"}>Expectations / Rules</Typography>
              <Typography>{tripDetail?.expectations_rules}</Typography>
            </div>
            <div className={classes.detailGroup}>
              <Typography variant={"h6"}>Cost Per Person</Typography>
              <Typography>
                {tripDetail?.cost_per_person
                  ? tripDetail?.cost_per_person
                  : "N/A"}
              </Typography>
            </div>
          </div>
        </Grid>

        <Grid item xs={6}>
          <div className={classes.wrapper}>
            <div className={classes.detailGroup}>
              <Typography variant={"h6"}>Students</Typography>

              {tripDetail?.field_trip_students.map((s) => {
                return (
                  <Chip
                    className={classes.chip}
                    label={`${s.user.first_name} ${s.user.last_name}`}
                  />
                );
              })}
            </div>

            <div className={classes.detailGroup}>
              <Typography variant={"h6"}>Transportation Options</Typography>
              {tripDetail?.transportation_options
                ? //@ts-ignore
                  tripDetail?.transportation_options.map((transport) => (
                    <Chip className={classes.chip} label={transport} />
                  ))
                : ""}
            </div>
            <div className={classes.detailGroup}>
              <Typography variant={"h6"}>Supply List</Typography>
              {tripDetail?.supply_list
                ? //@ts-ignore
                  tripDetail?.supply_list.map((supply) => (
                    <Chip className={classes.chip} label={supply} />
                  ))
                : ""}
            </div>
            <div className={classes.detailGroup}>
              <Typography variant={"h6"}>Event Date</Typography>
              <Typography>
                <DateRangeIcon
                  style={{
                    fontSize: "16px",
                    marginBottom: "-2px",
                    marginRight: "3px",
                  }}
                />
                {tripDetail?.event_date
                  ? DateTime.fromISO(tripDetail?.event_date).toFormat("DD")
                  : "N/A"}
              </Typography>
            </div>
            <div className={classes.detailGroup}>
              <Typography variant={"h6"}>Total Cost</Typography>
              <Typography>
                {tripDetail?.total_cost ? tripDetail?.total_cost : "N/A"}
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>

      <div className={classes.sectionSpace}>
        <hr />
      </div>

      {/* Location Timeline  */}

      <Grid item xs={12} className="timeLinesWrapper">
        <div className={classes.wrapper}>
          <div className={classes.detailGroup}>
            <Typography variant={"h6"}>Location Timeline</Typography>
          </div>

          <div className={classes.tripTimeLine}>
            <Timeline align="alternate" className={classes.oddEvenStyle}>
              {tripDetail?.field_trip_locations?.map(
                ({
                  location_id,
                  time,
                  id,
                  location_type,
                  notes,
                  contact_info,
                  location: { address, name, google_maps_url },
                }) => (
                  <TimelineItem className="timeLineItem">
                    <TimelineOppositeContent className="timeLineOposit"></TimelineOppositeContent>
                    <TimelineSeparator className="timeLineSeperator">
                      <TimelineConnector />

                      <IconButton
                        size="small"
                        onClick={() => window.open(`${google_maps_url}`)}
                      >
                        <TimelineDot color="primary">
                          {
                            //@ts-ignore
                            iconMap[`${location_type}`] || <MoreHorizIcon />
                          }
                        </TimelineDot>
                      </IconButton>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent className="timeLineContent">
                      <Paper elevation={3} className={classes.paperLocation}>
                        <Grid container spacing={1}>
                          <Grid item lg={6} md={12}>
                            <Typography
                              variant="h6"
                              component="h1"
                              style={{ lineHeight: 1, marginBottom: 5 }}
                            >
                              {name}
                            </Typography>

                            <Typography className={classes.locationAddress}>
                              {address}
                            </Typography>
                            <Typography className={classes.locationNoteStyle}>
                              {notes}
                            </Typography>
                            <Typography
                              className={classes.locationContactStyle}
                            >
                              {contact_info}
                            </Typography>

                            <StarRating locationId={id} locationTitle={name} />
                          </Grid>
                          <Grid item lg={6} md={12} container>
                            <div className="actionColumn">
                              <div>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {DateTime.fromISO(time).toFormat("ff")}
                                </Typography>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </Paper>
                    </TimelineContent>
                  </TimelineItem>
                )
              )}
            </Timeline>
          </div>
        </div>
      </Grid>

      <div className={classes.sectionSpace}></div>

      <div className={classes.tableWrapper}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Student</TableCell>
                <TableCell align="center">Transporation Agreement</TableCell>
                <TableCell align="center">
                  Grace and Courtesy Agreement
                </TableCell>
                <TableCell>Parent</TableCell>
                <TableCell align="center">Chaperone</TableCell>
                <TableCell>Parent Permission Slip</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tripDetail?.field_trip_students.map((student) => {
                return (
                  <TableRow key={student.user.first_name}>
                    <TableCell>{`${student.user.first_name} ${student.user.last_name}`}</TableCell>
                    <TableCell align="center">
                      {
                        <Check
                          value={student?.transportation_logistics_approval}
                          agreementType={QuestionTypeEnum.transportation}
                          title="Transportation and Logistics"
                        />
                      }
                    </TableCell>
                    <TableCell align="center">
                      <div>
                        {
                          <Check
                            value={student?.grace_courtesy_approval}
                            title="Grace and Courtsey"
                            agreementType={QuestionTypeEnum.grace_courtsey}
                          />
                        }
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>
                        {student?.user.user_relationships_forward.map((p) => (
                          <div>{`${p.to_user.first_name} ${p.to_user.last_name}`}</div>
                        ))}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      <SchoolNotes
                        content={
                          tripDetail.owner.school?.chaperone_request_template
                        }
                        title="Chaperone"
                      ></SchoolNotes>
                    </TableCell>
                    <TableCell>
                      <SchoolNotes
                        content={
                          tripDetail.owner.school?.parent_permission_template
                        }
                        title="Parent Permission Slip"
                        studentName={`${student.user.first_name} ${student.user.last_name}`}
                      ></SchoolNotes>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className={classes.sectionSpace}>
        <hr />
      </div>

      <StudentReflection />

      <Grid item xs={12}>
        <div style={{ padding: "30px 20px 0px 20px", textAlign: "right" }}>
          <Button
            variant="contained"
            className="hide-print"
            color="secondary"
            onClick={fullPagePrint}
          >
            <PrintRoundedIcon
              style={{ fontSize: "20px", marginRight: "5px" }}
            />
            Print Trip Details
          </Button>
        </div>
      </Grid>

      <div className={classes.sectionSpace}></div>
    </Card>
  );
}
