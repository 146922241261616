import React, { FunctionComponent, useEffect } from "react";

interface RedirectProps {
  url?: string;
}

export const Redirect: FunctionComponent<RedirectProps> = ({ url }) => {
  useEffect(() => {
    //@ts-ignore
    window.location.href = url;
  });

  return <></>;
};

export default Redirect;
