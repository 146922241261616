import React from "react";
import { Grid, Typography } from "@material-ui/core";
import classes from "*.module.css";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import LinearProgress, {
  LinearProgressProps,
} from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import InputField from "components/form/Input";
import SelectForm from "components/form/Select";
import TextArea from "components/form/TextArea";
import { FormProvider, useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import {
  useInsertVirtualProposalTripMutation,
  Virtual_Trip_Proposals_Insert_Input,
  useInsertVirtualTripPlanMutation,
  Virtual_Trip_Plans_Insert_Input,
  useFindMeQuery,
  useProposalTripsTopicsQuery,
  User_Roles_Enum,
} from "../../generated/graphql";
import { DateTime } from "luxon";
//@ts-ignore
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { virtualTripTopics } from "./Const";
import ErrorToast from "components/toast/ErrorToast";
import ToastMessage from "./ToastMessage";
import { Redirect } from "components/Redirect";
import VirtualTrips from "./VirtualTrips";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
  //@ts-ignore
  createStyles({
    root: {
      maxWidth: "100%",
    },
    virtaulTripLogo: {
      "& img": {
        display: "block",
        margin: "0 auto",
      },
    },
    bgGreen: {
      background: "#CCDEA5",
      borderRadius: 25,
      padding: "1.2rem",
    },
    virtaulTripText: {
      fontSize: 26,
      fontWeight: 700,
      textAlign: "center",
      color: "#18566A",
    },
    tripTopicList: {
      paddingTop: 25,
    },
    borderBox: {
      border: "10px solid #1B8DB1",
      padding: "1.125rem",
      height: "100%",
    },
    styleHeading: {
      fontWeight: 700,
      color: "#0D4050",
      marginTop: "1.4rem",
      marginBottom: "1.1rem",
    },
    topicImage: {
      position: "relative",
      bottom: -6,
    },
    virtaulTrendingText: {
      color: "#18566A",
      textAlign: "left",
      fontSize: 26,
      fontWeight: 700,
      marginBottom: "1.2rem",
    },
    topicName: {
      lineHeight: "1.2",
      marginBottom: 2,
      fontSize: 16,
    },
    formsBgWhite: {
      background: "#FFF",
      padding: "1px 12px 5px 12px",
      borderRadius: "5px",
      marginBottom: "15px",
    },
  })
);

interface ProgressProps extends LinearProgressProps {
  icon: string;
}

export default function ShareTrips() {
  const classes = useStyles();

  const { data: meData } = useFindMeQuery();
  const me = meData?.me[0];

  const loggedParent = me?.role === User_Roles_Enum.Parent ? true : false;

  const {
    data: tripsCount,
    error: tripsCountError,
    loading: tripsCountLoading,
  } = useProposalTripsTopicsQuery();

  const virtualTripEnabled = meData?.me[0].school?.school_product_features
    .map((m) => m.product_feature.feature_slug)
    .includes("virtual-field-trip");

  if (meData?.me[0]) {
    if (!virtualTripEnabled) {
      return <Redirect url="/dashboard" />;
    }
  }

  const topicSum: number =
    //@ts-ignore
    tripsCount?.virtual_trip_proposals_topic_aggregate.aggregate?.sum?.count;

  let totalTopicCount = 0;

  if (topicSum) {
    totalTopicCount = topicSum;
  }

  function TripTopicList() {
    function getCount(value: any) {
      const ss = tripsCount?.virtual_trip_proposals_topic
        .filter((f) => f.topic === value)
        .map((m) => m.count);
      return ss !== undefined && ss.length > 0 ? ss[0] : 0;
    }
    if (tripsCountLoading) {
      return (
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      );
    }
    return (
      <>
        <div className={classes.tripTopicList}>
          <Typography className={classes.virtaulTrendingText}>
            Trending Topics
          </Typography>
          {virtualTripTopics.map((t) => (
            <LinearProgressWithLabel
              key={t.value}
              //@ts-ignore
              value={Math.round((getCount(t.value) / totalTopicCount) * 100)}
              title={`${t.label}`}
              icon={`${t.icon}`}
            />
          ))}
        </div>
      </>
    );
  }

  function LinearProgressWithLabel(props: ProgressProps) {
    const { title, value, icon } = props;

    return (
      <Box display="flex" alignItems="center" marginBottom={2}>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            <img
              className={classes.topicImage}
              src={`/images/icons/${icon}.svg`}
            />
          </Typography>
        </Box>
        <Box width="100%" mr={1}>
          <Typography className={classes.topicName}>{title}</Typography>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35} marginBottom={-3}>
          <Typography variant="body2" color="textSecondary">
            {value}%
          </Typography>
        </Box>
      </Box>
    );
  }

  function ProposeTrip() {
    const [succes, setSuccess] = React.useState(false);
    const schema = yup.object().shape({
      topic: yup.string().required("Topic is required"),
      question: yup.string().required("Question is required"),
    });

    const [
      insertProposalTrip,
      { loading, error: insertProposalTripError },
    ] = useInsertVirtualProposalTripMutation({ errorPolicy: "all" });

    const form = useForm<Virtual_Trip_Proposals_Insert_Input>({
      resolver: yupResolver(schema),
    });

    const { handleSubmit } = form;

    const onSubmit = async (data: Virtual_Trip_Proposals_Insert_Input) => {
      setSuccess(false);
      const { question, topic } = data;
      if (question && topic) {
        const { errors, data: createdData } = await insertProposalTrip({
          variables: {
            question: question,
            topic: topic,
          },
          refetchQueries: ["proposalTripsTopics"],
        });
        if (!errors) {
          form.reset();
          setSuccess(true);
        }
      }
    };

    return (
      <>
        <Typography variant="h5" className={classes.styleHeading}>
          Propose a New Virtual Trip
        </Typography>

        <ErrorToast
          error={tripsCountError}
          processCustomError={() =>
            `Unable to Load data - ${tripsCountError?.message}`
          }
        />
        <ErrorToast
          error={insertProposalTripError}
          processCustomError={() =>
            `Unable to insert trip - ${insertProposalTripError?.message}`
          }
        />
        {succes ? (
          <ToastMessage message="Propose Trip Successfully Submitted" />
        ) : null}
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.formsBgWhite}>
              <SelectForm
                name={"topic"}
                label={"Select a Topic"}
                //@ts-ignore
                options={virtualTripTopics.map((topic) => ({
                  value: topic.value,
                  label: topic.label,
                }))}
              />

              <TextArea
                name="question"
                label="Questions... What are you wondering about?"
              />
            </div>
            <Grid container justify="flex-end">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                Submit
              </Button>
            </Grid>
          </form>
        </FormProvider>
      </>
    );
  }

  function PlanTrip() {
    const [succes, setSuccess] = React.useState(false);

    const schema = yup.object().shape({
      event_date: yup.string().required("Event date is required"),
      event_details: yup.string().required("Event details are required"),
      title: yup.string().required("Event title is required"),
    });

    const [
      insertTripPlan,
      { loading, error: insertTripPlanError },
    ] = useInsertVirtualTripPlanMutation({ errorPolicy: "all" });

    const form = useForm<Virtual_Trip_Plans_Insert_Input>({
      resolver: yupResolver(schema),
    });

    const { handleSubmit } = form;

    const onSubmit = async (data: Virtual_Trip_Plans_Insert_Input) => {
      setSuccess(false);
      const { event_date, event_details, title } = data;
      const estTime = DateTime.fromISO(event_date);

      if (estTime && event_details) {
        const { errors, data: createdData } = await insertTripPlan({
          variables: {
            event_date: estTime.toString(),
            event_details: event_details,
            title: title,
          },
        });
        if (!errors) {
          form.reset();
          setSuccess(true);
        }
      }
    };

    return (
      <>
        <ErrorToast
          error={insertTripPlanError}
          processCustomError={() =>
            `Unable to insert trip - ${insertTripPlanError?.message}`
          }
        />
        {succes ? (
          <ToastMessage message="Shared Trip Successfully Submitted" />
        ) : null}

        <Typography variant="h5" className={classes.styleHeading}>
          Plan and Share a Trip
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ marginBottom: 20 }}
        >
          Would you like to design, facilitate, and share your own trip with our
          community? Please share your details at least two weeks in advance of
          your trip:
        </Typography>

        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.formsBgWhite}>
              <div>
                <InputField
                  name="name"
                  label="Your Name"
                  value={`${me?.first_name} ${me?.last_name}`}
                  disabled={true}
                />
              </div>
              <div>
                <InputField
                  name="school"
                  label="School"
                  value={`${me?.school?.name}`}
                  disabled={true}
                />
              </div>
              <div>
                <InputField name="title" label="Title" />
              </div>
              <div>
                <InputField
                  name="event_date"
                  label={`Date & Time (${
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                  })`}
                  type="datetime-local"
                />
              </div>
              <div>
                <TextArea name="event_details" label="Event Detail" />
              </div>
            </div>
            <Grid container justify="flex-end">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                Submit
              </Button>
            </Grid>
          </form>
        </FormProvider>
      </>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={!loggedParent ? 3 : 4} xs={12}>
          <div>
            <Typography className={classes.virtaulTripText}>
              Virtual Trips
            </Typography>
          </div>
          <div className={classes.virtaulTripLogo}>
            <img src="/images/virtual-trip-150x150.png" />
          </div>

          <TripTopicList />
        </Grid>

        <Grid item lg={!loggedParent ? 9 : 8} xs={12}>
          <Grid container spacing={2} className={classes.bgGreen}>
            <Grid item lg={!loggedParent ? 6 : 12} md={12}>
              <div className={classes.borderBox}>
                <VirtualTrips />
              </div>
            </Grid>
            {!loggedParent && (
              <Grid item lg={6} md={12}>
                <div>
                  <div className={classes.borderBox}>
                    <ProposeTrip />
                  </div>
                </div>
                <br />
                <div>
                  <div className={classes.borderBox}>
                    <PlanTrip />
                  </div>
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
