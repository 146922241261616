import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { FormProvider, useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import InputField from "components/form/Input";
import Alert from "@material-ui/lab/Alert";
//@ts-ignore
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Amplify, { Auth } from "aws-amplify";
import { Link, useHistory } from "react-router-dom";
import AuthLayout from "./AuthLayout";
import { username_regx } from "utils";

Amplify.configure({
  Auth: {
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: "USER_PASSWORD_AUTH",
    storage: localStorage,
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      maxWidth: 320,
      width: "100%",
      "& button": {
        boxShadow: "none !important",
        borderRadius: 3,
        padding: "8px 16px",
      },
      "& [class*='MuiInputBase-root']": {
        borderRadius: 3,
      },
    },
    inputStyle: {},
    switchFormBtn: {
      cursor: "pointer",
      display: "inline-block",
      fontSize: 14,
      color: theme.palette.grey[500],
      textDecoration: "none",
      opacity: 0.8,
      "&:hover": {
        opacity: 1,
      },
    },
  })
);

type loginForm = {
  username: string;
  password: string;
};

type error = {
  name?: string;
  message?: string;
  code?: string;
};

const SignInschema = yup.object().shape({
  username: yup
    .string()
    .required("Username is required")
    .matches(username_regx, "Invalid username"),

  password: yup.string().required("Password is required"),
});

const ChangePswSchema = yup.object().shape({
  new_password: yup
    .string()
    .required("New Password is required")
    .min(8, "Password must have minimum 8 characters"),
  confirm_password: yup
    .string()
    //@ts-ignore
    .oneOf([yup.ref("new_password"), null], "Passwords must match"),
});

export default function Login() {
  const [newUser, setNewUser] = React.useState(null);
  const history = useHistory();
  Auth.currentSession().then((res) => {
    if (res) {
      history.push("/select_trip_type");
    }
  });

  function SignIn() {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<error>({});

    const form = useForm<loginForm>({
      resolver: yupResolver(SignInschema),
    });

    const { handleSubmit } = form;

    const onSubmit = async (data: loginForm) => {
      setLoading(true);
      setError({});
      try {
        const user = await Auth.signIn(data.username, data.password);
        if (
          user.challengeName &&
          user.challengeName === "NEW_PASSWORD_REQUIRED"
        ) {
          setNewUser(user);
        } else {
          window.location.replace("/select_trip_type");
        }
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    };

    return (
      <>
        <div className={classes.formWrapper}>
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography
                variant="body1"
                color="textSecondary"
                gutterBottom={true}
                style={{ marginBottom: 10 }}
              >
                Sign in with your username and password
              </Typography>
              <div className={classes.inputStyle}>
                <InputField name="username" label="Username" />
              </div>
              <div className={classes.inputStyle}>
                <InputField name="password" label="Password" type="password" />
              </div>

              <Link
                to="/forgot-password"
                className={classes.switchFormBtn}
                style={{ marginBottom: 10 }}
              >
                Forgot your password?
              </Link>
              {error?.message && (
                <Alert severity="error" style={{ marginBottom: 10 }}>
                  {error?.message}
                </Alert>
              )}

              <Button
                variant="contained"
                fullWidth={true}
                type="submit"
                color="primary"
                disabled={loading}
              >
                {loading ? "Please wait..." : "Login"}
              </Button>
            </form>
          </FormProvider>
        </div>
      </>
    );
  }

  function SetNewPassword() {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<error>({});

    const form = useForm({
      resolver: yupResolver(ChangePswSchema),
    });

    const { handleSubmit } = form;

    const onSubmit = async (data: any) => {
      setLoading(true);
      const { new_password } = data;

      if (newUser) {
        const newPassword = await Auth.completeNewPassword(
          newUser,
          new_password
        )
          .then((user) => {
            window.location.replace("/select_trip_type");
          })
          .catch((e) => {
            setError(e);
            setLoading(false);
            console.log(e);
          });
      }
    };
    return (
      <>
        <div className={classes.formWrapper}>
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography
                variant="h6"
                color="textSecondary"
                gutterBottom={true}
                style={{ marginBottom: 10 }}
              >
                Change Password
              </Typography>

              <Typography
                variant="body2"
                color="textSecondary"
                gutterBottom={true}
                style={{ marginBottom: 20, lineHeight: 1.2 }}
              >
                Please enter your new password below.
              </Typography>
              <div className={classes.inputStyle}>
                <InputField
                  name="new_password"
                  label="New Password"
                  type="password"
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <InputField
                  name="confirm_password"
                  label="Enter New Password Again"
                  type="password"
                />
              </div>

              {error?.message && (
                <Alert severity="error" style={{ marginBottom: 10 }}>
                  {error?.message}
                </Alert>
              )}

              <Button
                variant="contained"
                fullWidth={true}
                type="submit"
                color="primary"
                disabled={loading}
              >
                {loading ? "Please wait..." : "Change Password"}
              </Button>
            </form>
          </FormProvider>
        </div>
      </>
    );
  }

  return <AuthLayout>{newUser ? <SetNewPassword /> : <SignIn />}</AuthLayout>;
}
