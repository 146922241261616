import React from "react";
import { useParams } from "react-router-dom";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useFieldTripChaperoneRequestQuery } from "generated/graphql";
import { FieldTripParams } from "./consts";
import { CircularProgress, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      whiteSpace: "pre-line",
      padding: theme.spacing(3),
    },
  })
);

export default function ChaperoneRequest() {
  const classes = useStyles();
  const params = useParams<FieldTripParams>();
  const { loading, data } = useFieldTripChaperoneRequestQuery({
    variables: {
      uid: params.tripId,
    },
  });

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} justify="center">
      <Grid item lg={5} xs={12} className={classes.content}>
        {data?.field_trips[0].school.chaperone_request_template}
      </Grid>
    </Grid>
  );
}
