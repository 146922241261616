export const virtualTripTopics = [
  {
    value: "Art_Fine_and_Performing_Arts",
    label: "Art (Fine and Performing Arts)",
    icon: "Art_Fine_and_Performing_Arts",
  },
  {
    value: "Botany_Plant_Sciences",
    label: "Botany (Plant Sciences)",
    icon: "Botany_Plant_Sciences",
  },
  {
    value: "cooking_and_culinary_arts",
    label: "Cooking and Culinary Arts",
    icon: "cooking_and_culinary_arts",
  },
  {
    value: "economics_and_finance",
    label: "Economics and Finance",
    icon: "economics_and_finance",
  },
  {
    value: "engineering",
    label: "Engineering",
    icon: "engineering",
  },
  {
    value: "games",
    label: "Games",
    icon: "games",
  },
  {
    value: "history",
    label: "History",
    icon: "history",
  },
  {
    value: "languages",
    label: "Languages",
    icon: "languages",
  },
  {
    value: "math",
    label: "Math",
    icon: "math",
  },
  {
    value: "music",
    label: "Music",
    icon: "music",
  },
  {
    value: "science",
    label: "Science",
    icon: "science",
  },
  {
    value: "sports",
    label: "Sports",
    icon: "sports",
  },
  {
    value: "technology",
    label: "Technology",
    icon: "technology",
  },
  {
    value: "zoology_animal_sciences",
    label: "Zoology (Animal Sciences)",
    icon: "zoology_animal_sciences",
  },
];
