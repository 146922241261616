import React, { useState, FunctionComponent } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";
import ThemeContext, { themeMap, ThemeKey } from "./theme";

interface ThemeHandlerProps {}

const ThemeHandler: FunctionComponent<ThemeHandlerProps> = ({ children }) => {
  const [theme, setTheme] = useState<ThemeKey>("mft");

  const updateTheme = (themeKey: ThemeKey) => {
    console.log(themeKey);
    setTheme(themeKey);
    // setTheme(color);;
    //   const newTheme = _.merge(theme, {
    //     palette: {
    //       [key]: {
    //         main: color,
    //       }
    //     }
    //   });
    //   console.log(newTheme);
    //   // setTheme(());
    //   //@ts-ignore
    //   setTheme(currentTheme => _.merge(currentTheme, {
    //     palette: {
    //       [key]: {
    //         main: color,
    //       }
    //     }
    //   }));
    // };
  };

  return (
    <ThemeContext.Provider
      value={{
        currentTheme: theme,
        updateTheme: (themeKey: ThemeKey) => updateTheme(themeKey),
      }}
    >
      <MuiThemeProvider theme={themeMap[theme]}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeHandler;
