import React from "react";
import { Machine } from "xstate";

import Envision from "./Envision";
import Plan from "./Plan";
import Approve from "./Approve";
import Reflect from "./Reflect";
import Share from "./Share";
import Complete from "./Complete";

export const tripStateMachine = (initialState: string) =>
  Machine({
    id: "trip",
    initial: initialState,
    context: {
      step: 0,
    },
    states: {
      envision: {
        on: {
          next: "plan",
          self: "envision",
        },
        meta: {
          label: "Envision",
          index: 0,
          component: Envision,
        },
      },
      plan: {
        on: {
          next: "approve",
          back: "envision",
          envision: "envision",
        },
        meta: {
          label: "Plan",
          index: 1,
          component: Plan,
        },
      },
      approve: {
        on: {
          next: "reflect",
          back: "plan",
          plan: "plan",
          envision: "envision",
        },
        meta: {
          label: "Approve",
          index: 2,
          component: Approve,
        },
      },
      reflect: {
        on: {
          next: "share",
          back: "approve",
          approve: "approve",
          plan: "plan",
          envision: "envision",
        },
        meta: {
          label: "Reflect",
          index: 3,
          component: Reflect,
        },
      },
      share: {
        on: {
          next: "complete",
          back: "reflect",
          reflect: "reflect",
          approve: "approve",
          plan: "plan",
          envision: "envision",
        },
        meta: {
          label: "Share",
          index: 4,
          component: Share,
        },
      },
      complete: {
        type: "final",
        meta: {
          label: "Complete",
          index: 5,
          component: Complete,
        },
      },
    },
  });

const init: any = null;
export const MachineContext = React.createContext(init);
