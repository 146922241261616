import React from "react";
import Login from "../user/Login";
import Logout from "../user/Logout";
import LoginScreen from "../login/Login";
import ForgotPassword from "../login/ForgotPassword";
import { Switch, Route } from "react-router-dom";
import "../../App.css";

export const guestRoutes = [
  { path: "/cognito-callback", component: Login },
  { path: "/logout", component: Logout },
  { path: "/new_password", component: null },
  { path: "/login", component: LoginScreen },
  { path: "/forgot-password", component: ForgotPassword },
];

export default function GuestLayout() {
  return (
    <section id="wrapper" className="login-register">
      <div className="login-box login-sidebar">
        <div className="white-box">
          <div className="form-horizontal form-material">
            {/* <div className={"text-center db"}>
              <img src={"/images/mft-logo-text.jpg"} alt={"Logo"} width={300} />
            </div> */}

            <Switch>
              {guestRoutes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  component={route.component}
                />
              ))}
            </Switch>
          </div>
        </div>
      </div>
    </section>
  );
}
