import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import classes from "*.module.css";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import VirtualTrips from "./VirtualTrips";
import Tooltip from "@material-ui/core/Tooltip";
import {
  useFindMeQuery,
  useVirtualProposalTripsQuery,
  useVirtualPlanTripsQuery,
  useDeleteVirtualPlanTripsMutation,
  useDeleteVirtualProposalTripsMutation,
  Order_By,
} from "../../generated/graphql";

import ErrorToast from "components/toast/ErrorToast";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List/List";
import Pagination from "@material-ui/lab/Pagination";
import ToastMessage from "./ToastMessage";
import VirtualTripPopup from "./VirtualTripPopup";
import CreateVirtualTripForm from "./CreateVirtualTripForm";
import GetVirtualTripTitle from "./VirtualTripTitle";
import { DateTime } from "luxon";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItem from "@material-ui/core/ListItem";

const useStyles = makeStyles((theme: Theme) =>
  //@ts-ignore
  createStyles({
    root: {
      maxWidth: "100%",
    },
    bgGreen: {
      background: "#CCDEA5",
      borderRadius: 25,
      padding: "1.2rem",
    },
    borderBox: {
      border: "10px solid #1B8DB1",
      padding: "1.125rem",
      height: "100%",
    },
    tripItemRow: {
      paddingBottom: 12,
      borderBottom: "1px solid #EFEFEF",
      marginBottom: 12,
      overflow: "hidden",
      cursor: "pointer",
      transition: "all 0.5s",
      "& :hover h4": {
        color: theme.palette.primary.main,
      },
    },
    tripItemContent: {
      width: "100%",
      overflow: "hidden",
      "& h4": {
        fontSize: "1.1rem",
        fontWeight: 700,
        lineHeight: "1rem",
        marginTop: 0,
        marginBottom: 10,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        height: "1.2rem",
      },
      "& p": {
        fontSize: "0.9rem",
        lineHeight: "0.8rem",
        marginTop: 0,
        color: "#666666",
        marginBottom: 6,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        height: "0.99rem",
      },
    },
    tripItemAction: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    btnIconWhite: {
      backgroundColor: "#FFF",
      width: 20,
      height: 20,
      "& svg": {
        fontSize: "18px",
      },
      "& :hover svg": {
        color: theme.palette.warning.dark,
      },
    },
    dateStyle: {
      fontSize: "0.9rem !important",
      color: "#888888 !important",
      marginBottom: "6px !important",
    },
    tripOwner: {
      fontSize: 14,
      marginBottom: 8,
      color: "#1B8DB1 !important",
    },
    styleHeading: {
      fontWeight: 700,
      color: "#0D4050",
      marginTop: "20px",
      marginBottom: "20px",
    },
    author: {
      paddingTop: "1rem",
      color: "#999",
    },
    dialogHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& div": {
        lineHeight: 1.2,
      },
    },
    adminVirtualTripHeader: {
      "& img": {
        maxWidth: 85,
        marginRight: 15,
      },
      "& div:nth-child(1)": {
        display: "flex",
        alignItems: "center",
        paddingBottom: "0.75rem",
        "& span": {
          fontSize: 30,
          fontWeight: 700,
          color: "#18566A",
          display: "inline-block",
        },
      },
    },
    pagination: {
      "& ul": {
        justifyContent: "flex-end",
      },
    },
    modelStyle: {
      width: "100%",
    },
    sortStyle: {
      display: "flex",
      justifyContent: "flex-end",
    },
    sortTextStyle: {
      fontWeight: 400,
      fontSize: 14,
      "& p": {
        margin: 0,
      },
      "& span": {
        opacity: 0.75,
      },
    },
  })
);

interface propsalTripProps {
  dateTime: Date | null;
  id: number;
  question: string;
  topic: string;
  name: string;
  school: string;
}
interface planTripProps {
  dateTime: Date | null;
  created_at: Date | null;
  id: number;
  eventDetails: string;
  name: string;
  school: string;
  title: string | undefined | null;
}

const options = ["Created Date", "Event Date"];

export default function CreateVirtualTrip() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [succes, setSucces] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const handleClickOpen = () => {
    setOpenPopup(true);
  };
  const { data: meData } = useFindMeQuery();
  const me = meData?.me[0];

  function GetDuration(props: any) {
    const { date } = props;
    return <>{DateTime.fromISO(date).toRelative()}</>;
  }

  function DateConvert(props: any) {
    if (props.date) {
      return (
        <>
          {DateTime.fromISO(`${props.date}`).toLocaleString(
            //  @ts-ignore
            DateTime.DATETIME_FULL
          )}
        </>
      );
    }
    return <></>;
  }

  function ProposalTrips(props: propsalTripProps) {
    const { dateTime, id, question, topic, name, school } = props;
    const [open, setOpen] = useState(false);
    const [succes, setSuccess] = useState(false);

    const [virtualPopup, virtualPopupOpen] = useState(false);

    const [
      deleteProposalTrip,
      { error: deleteProposalTripError, loading: loadingProposalTripDelete },
    ] = useDeleteVirtualProposalTripsMutation({ errorPolicy: "all" });

    const handleVirtualPopupOpen = () => {
      virtualPopupOpen(true);
    };
    const handleVirtualPopupClose = () => {
      virtualPopupOpen(false);
    };

    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    return (
      <>
        <ErrorToast
          error={deleteProposalTripError}
          processCustomError={() =>
            `Unable to delete trip - ${deleteProposalTripError?.message}`
          }
        />

        {succes ? (
          <ToastMessage message="Proposed Trip Successfully Deleted" />
        ) : null}

        <Grid
          container
          spacing={0}
          wrap="nowrap"
          className={classes.tripItemRow}
        >
          <div
            className={classes.tripItemContent}
            onClick={handleVirtualPopupOpen}
          >
            <h4>
              <GetVirtualTripTitle topic={topic} />
            </h4>
            <p>{question}</p>
            <p className={classes.tripOwner}>
              {name} - {school}
            </p>
            <p className={classes.dateStyle}>
              <GetDuration date={dateTime} />
            </p>
          </div>
          <div className={classes.tripItemAction}>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={handleClickOpen}
                className={classes.btnIconWhite}
                disabled={loadingProposalTripDelete}
              >
                <DeleteIcon fontSize="small" color="disabled" />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>

        <Dialog
          onClose={handleVirtualPopupClose}
          aria-labelledby={`${id}-${topic}`}
          open={virtualPopup}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle id={`${id}-${topic}`}>
            <div className={classes.dialogHeader}>
              <div>
                <GetVirtualTripTitle topic={topic} />
              </div>

              <IconButton aria-label="close" onClick={handleVirtualPopupClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent dividers>
            <Typography variant="body1">{question}</Typography>
            <Typography variant="body2" className={classes.author}>
              {name} - <DateConvert date={dateTime} />
            </Typography>
          </DialogContent>
        </Dialog>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to delete{" "}
              <b>
                <GetVirtualTripTitle topic={topic} />
              </b>
              ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              color="primary"
              onClick={async () => {
                setSuccess(false);
                const data = await deleteProposalTrip({
                  variables: {
                    proposalTripId: id,
                  },
                  refetchQueries: ["virtualProposalTrips"],
                });
                setOpen(false);

                if (
                  data.data?.delete_virtual_trip_proposals?.affected_rows === 1
                ) {
                  setSuccess(true);
                }
              }}
            >
              Yes
            </Button>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  function PlanTrips(props: planTripProps) {
    const {
      dateTime,
      eventDetails,
      title,
      id,
      name,
      school,
      created_at,
    } = props;
    const [open, setOpen] = useState(false);
    const [succes, setSucces] = useState(false);
    const [virtualPopup, virtualPopupOpen] = useState(false);

    const [
      deletePlanTrip,
      { error: deletePlanTripError, loading: loadingPlanTripDelete },
    ] = useDeleteVirtualPlanTripsMutation({ errorPolicy: "all" });

    const handleVirtualPopupOpen = () => {
      virtualPopupOpen(true);
    };
    const handleVirtualPopupClose = () => {
      virtualPopupOpen(false);
    };

    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    return (
      <>
        <ErrorToast
          error={deletePlanTripError}
          processCustomError={() =>
            `Unable to delete trip - ${deletePlanTripError?.message}`
          }
        />

        {succes ? (
          <ToastMessage message="Plan Trip Successfully Deleted" />
        ) : null}

        <Grid
          container
          spacing={0}
          wrap="nowrap"
          className={classes.tripItemRow}
        >
          <div
            className={classes.tripItemContent}
            onClick={handleVirtualPopupOpen}
          >
            <h4>{title ? title : null}</h4>
            <p>{eventDetails}</p>
            <p className={classes.tripOwner}>
              {name} - {school}
            </p>
            <p style={{ fontSize: "0.8rem", marginBottom: 2 }}>
              Created {DateTime.fromISO(`${created_at}`).toRelative()}
            </p>
            <p className={classes.dateStyle}>
              <DateConvert date={dateTime} />
            </p>
          </div>
          <div className={classes.tripItemAction}>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={handleClickOpen}
                className={classes.btnIconWhite}
                disabled={loadingPlanTripDelete}
              >
                <DeleteIcon fontSize="small" color="disabled" />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>

        <Dialog
          onClose={handleVirtualPopupClose}
          aria-labelledby={`${id}-${title}`}
          open={virtualPopup}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle id={`${id}-${title}`}>
            <div className={classes.dialogHeader}>
              <div>{title}</div>

              <IconButton aria-label="close" onClick={handleVirtualPopupClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent dividers>
            <Typography variant="body1">{eventDetails}</Typography>
            <Typography variant="body2" className={classes.author}>
              {name}
            </Typography>
            <Typography
              variant="body2"
              className={classes.author}
              style={{ paddingTop: 0 }}
            >
              {school} - <DateConvert date={dateTime} />
            </Typography>
          </DialogContent>
        </Dialog>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to delete <b>{title}</b>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              color="primary"
              onClick={async () => {
                setSucces(false);
                const data = await deletePlanTrip({
                  variables: {
                    planTripId: id,
                  },
                  refetchQueries: ["virtualPlanTrips"],
                });
                setOpen(false);
                if (data.data?.delete_virtual_trip_plans?.affected_rows === 1) {
                  setSucces(true);
                }
              }}
            >
              Yes
            </Button>
            <Button onClick={handleClose} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  function ProposalTripList() {
    const classes = useStyles();

    const itemsPerPage = 5;
    const {
      data: proposalTripData,
      loading: proposalTripLoading,
      error: proposalTripError,
      refetch: reftechProposalData,
    } = useVirtualProposalTripsQuery({
      variables: {
        limit: itemsPerPage,
      },
    });

    const aggregateCount =
      proposalTripData?.virtual_trip_proposals_aggregate.aggregate?.count;
    const [page, setPage] = React.useState(1);
    //@ts-ignore
    const noOfPages = Math.ceil(aggregateCount / itemsPerPage);

    const handleChange = (e: any, v: any) => {
      setPage(v);
      if (v) {
        reftechProposalData({
          offset: (v - 1) * itemsPerPage,
          limit: itemsPerPage,
        });
      }
    };

    if (proposalTripLoading) {
      return (
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      );
    }
    return (
      <div>
        <ErrorToast
          error={proposalTripError}
          processCustomError={() =>
            `Unable to load proposal trips - ${proposalTripError?.message}`
          }
        />
        <Grid container>
          <Typography variant="h5" className={classes.styleHeading}>
            Proposed Trips
          </Typography>
        </Grid>
        <List>
          {proposalTripData?.virtual_trip_proposals.map((m) => (
            <ProposalTrips
              key={m.id}
              dateTime={m.created_at}
              id={m.id}
              question={m.question}
              topic={m.topic}
              name={`${m.user.first_name} ${m.user.last_name}`}
              school={`${m.user.school?.name}`}
            />
          ))}
        </List>

        <Box component="span">
          <Pagination
            //@ts-ignore
            count={noOfPages}
            page={page}
            onChange={handleChange}
            defaultPage={1}
            color="standard"
            size="small"
            showFirstButton
            showLastButton
            className={classes.pagination}
          />
        </Box>
      </div>
    );
  }

  function PlanTripList(props: any) {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const itemsPerPage = 5;
    const {
      data: planTripData,
      loading: planTripLoading,
      error: planTripError,
      refetch: reftechPlanData,
    } = useVirtualPlanTripsQuery({
      variables: {
        limit: 5,
        sortById: Order_By.Desc,
      },
    });

    const aggregateCount =
      planTripData?.virtual_trip_plans_aggregate.aggregate?.count;
    const [page, setPage] = React.useState(1);
    //@ts-ignore
    const noOfPages = Math.ceil(aggregateCount / itemsPerPage);

    const handleChange = (e: any, v: any) => {
      setPage(v);
      if (v) {
        reftechPlanData({
          offset: (v - 1) * itemsPerPage,
          limit: itemsPerPage,
        });
      }
    };

    const handleMenuItemClick = (
      event: React.MouseEvent<HTMLElement>,
      index: number
    ) => {
      setSelectedIndex(index);
      setAnchorEl(null);

      if (index === 0) {
        reftechPlanData({
          limit: itemsPerPage,
          sortById: Order_By.Desc,
          sortByEventDate: null,
        });
      } else {
        reftechPlanData({
          limit: itemsPerPage,
          sortById: null,
          sortByEventDate: Order_By.Desc,
        });
      }
    };

    if (planTripLoading) {
      return (
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      );
    }
    return (
      <div>
        <ErrorToast
          error={planTripError}
          processCustomError={() =>
            `Unable to load plan trips - ${planTripError?.message}`
          }
        />
        <Grid container spacing={1} alignItems="center">
          <Grid item md={6}>
            <Typography variant="h5" className={classes.styleHeading}>
              Shared Trips
            </Typography>
          </Grid>
          <Grid item md={6}>
            <div className={classes.sortStyle}>
              <div className={classes.root}>
                <List component="nav" aria-label="Device settings">
                  <ListItem
                    button
                    aria-haspopup="true"
                    aria-controls="lock-menu"
                    aria-label="when device is locked"
                    onClick={handleClickListItem}
                  >
                    <div className={classes.sortTextStyle}>
                      <p>
                        <span>Sort By</span> {options[selectedIndex]}
                      </p>
                    </div>
                  </ListItem>
                </List>
                <Menu
                  id="lock-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      //disabled={index === 0}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </div>
          </Grid>
        </Grid>

        <List>
          {planTripData?.virtual_trip_plans.map((m) => (
            <PlanTrips
              key={m.id}
              dateTime={m.event_date}
              eventDetails={m.event_details}
              title={m.title}
              id={m.id}
              name={`${m.user.first_name} ${m.user.last_name}`}
              school={`${m.user.school?.name}`}
              created_at={m.created_at}
            />
          ))}
        </List>

        <Box component="span">
          <Pagination
            //@ts-ignore
            count={noOfPages}
            page={page}
            onChange={handleChange}
            defaultPage={1}
            color="standard"
            size="small"
            showFirstButton
            showLastButton
            className={classes.pagination}
          />
        </Box>
      </div>
    );
  }

  return (
    <>
      {succes ? (
        <ToastMessage message="Virtual trip successfully added" />
      ) : null}

      <Grid container spacing={2} className={classes.bgGreen}>
        <Grid
          item
          xs={12}
          justify="space-between"
          alignItems="center"
          container
          className={classes.adminVirtualTripHeader}
        >
          <div>
            <img src="/images/virtual-trip-150x150.png" />
            <span>Virtual Field Trip</span>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleClickOpen}
            >
              Create a Virtual Trip
            </Button>
          </div>
        </Grid>
        <Grid item lg={4} xs={12}>
          <div className={classes.borderBox}>
            <VirtualTrips />
          </div>
        </Grid>
        <Grid item lg={4} xs={12}>
          <div className={classes.borderBox}>
            <ProposalTripList />
          </div>
        </Grid>
        <Grid item lg={4} xs={12}>
          <div className={classes.borderBox}>
            <PlanTripList />
          </div>
        </Grid>
      </Grid>
      <VirtualTripPopup
        title="Create a Virtual Trip"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        setSucces={setSucces}
      >
        <CreateVirtualTripForm
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          setSucces={setSucces}
        />
      </VirtualTripPopup>
    </>
  );
}
