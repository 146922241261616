import React from "react";
import { useLocation, Redirect } from "react-router-dom";

import Alert from "../messages/Alert";

export default function Login(props) {
  const location = useLocation();

  if (location.hash) {
    const params = new URLSearchParams(location.hash.substring(1));
    if (params.get("id_token")) {
      //localStorage.setItem("token", params.get("id_token"));
      //localStorage.setItem("access_token", params.get("access_token"));
      return <Redirect to="/select_trip_type" />;
    }
  }

  return (
    <form>
      <Alert message={"Authentication Error"} type={"danger"} />
    </form>
  );
}
