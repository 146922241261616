import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Switch, Route, useLocation } from "react-router-dom";
import Content from "components/test/Content";
import NavBar from "components/nav/NavBar";
import NavBarFull from "components/nav/NavBarFullWidth";
import SideBar from "components/sidebar/SideBar";
import CreateSchool from "components/school/CreateSchool";
import ListUsers from "components/user/ListUsers";
import CreateFieldTrip from "components/trip/CreateFieldTrip";
import Dashboard from "components/dashboard";
import ListTrips from "components/trip/List";
import ListSchools from "components/school/ListSchools";
import ViewFieldTrip from "components/trip/View";
import ThemeHandler from "../theme/ThemeHandler";
import { useFindMeQuery, User_Roles_Enum } from "../../generated/graphql";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid } from "@material-ui/core";
import UpdateTheme from "components/theme/UpdateTheme";
import ThemeContext, { ThemeKey } from "components/theme/theme";
import FieldTripDetail from "components/trip/FieldTripDetail";
import ShareTrips from "components/virtualTrips/ShareTrips";
import CreateVirtualTrip from "components/virtualTrips/CreateVirtualTrip";
import SelectTripType from "components/virtualTrips/SelectTripType";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      backgroundColor: "#f0f2f5",
      "@media print": {
        "& [class*='toolbar']": {
          display: "none",
        },
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      minHeight: "100vh",
      overflow: "hidden",
      "@media print": {
        padding: 0,
      },
    },
    spinner: {
      height: "100vh",
    },
  })
);

export default function PermanentDrawerLeft() {
  const classes = useStyles();
  const currentPath = useLocation();
  const { loading, error, data } = useFindMeQuery();

  const colorTheme = data?.me[0].school?.color_theme;
  const logoUrl = data?.me[0].school?.logo_url;

  const fieldTripProduct = data?.me[0].school?.school_product_features
    ? data?.me[0].school?.school_product_features
    : [];

  let fullWidthAppBar = false;

  const isPhysicalTrip = Object.values(
    fieldTripProduct.map((m) => m.product_feature.feature_slug)
  ).includes("field-trip");

  if (data?.me[0].role !== User_Roles_Enum.SystemAdmin) {
    if (!isPhysicalTrip || currentPath.pathname === "/select_trip_type") {
      fullWidthAppBar = true;
    }
  }

  if (loading) {
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        className={classes.spinner}
      >
        <CircularProgress color="primary" />
      </Grid>
    );
  }

  return (
    <ThemeHandler>
      <UpdateTheme colorTheme={colorTheme as ThemeKey} />
      <div className={classes.root}>
        {fullWidthAppBar ? <NavBarFull /> : <NavBar />}

        <SideBar logoUrl={logoUrl} />

        <main className={classes.content}>
          <div className={classes.toolbar} />

          <Switch>
            <Route path="/user" component={Content} />
            <Route path="/create_school" component={CreateSchool} />
            <Route path="/list_users" component={ListUsers} />
            <Route path="/create_field_trip" component={CreateFieldTrip} />
            <Route
              path="/edit_field_trip/:tripId"
              component={CreateFieldTrip}
            />
            <Route path="/view_field_trip/:tripId" component={ViewFieldTrip} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/list_field_trips" component={ListTrips} />
            <Route path="/list_schools" component={ListSchools} />
            <Route path="/edit_school/:schoolId" component={CreateSchool} />
            <Route
              path="/field_trip_detail/:tripId"
              component={FieldTripDetail}
            />
            <Route path="/share_virtual_trip/" component={ShareTrips} />
            <Route path="/create_virtual_trip/" component={CreateVirtualTrip} />
            <Route path="/select_trip_type/" component={SelectTripType} />
          </Switch>
        </main>
      </div>
    </ThemeHandler>
  );
}
