import React from "react";
import { Link, LinkProps, useLocation } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

interface ListItemLinkProps {
  to: string;
  text: string;
  icon: any;
}

export default function ListItemLink(props: ListItemLinkProps) {
  const { icon, text, to } = props;
  const location = useLocation();

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, Omit<LinkProps, "to">>((itemProps, ref) => (
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem
        button
        component={renderLink}
        selected={to === location.pathname}
      >
        {icon ? (
          <ListItemIcon style={{ minWidth: 40 }}>{icon}</ListItemIcon>
        ) : null}
        <ListItemText primary={text} />
      </ListItem>
    </li>
  );
}
