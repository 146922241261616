import React, { FunctionComponent } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Label from "components/form/Label";
import AddIcon from "@material-ui/icons/Add";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  QuestionProps,
  QuestionType,
  QUESTIONS,
  QuestionTypeEnum,
} from "./consts";
import { unionWith } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    inputRoot: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
    input: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  })
);

interface CheckboxListProps extends QuestionType {
  title: string;
  hideAdd?: boolean;
  questions?: Array<QuestionProps>;
  addQuestion?: (questions: QuestionProps) => void;
  deleteQuestion?: (id: string) => void;
  showDefault?: boolean;
}

const mergeDefaultQuestions = (
  questions: Array<QuestionProps> = [],
  type: QuestionTypeEnum
) =>
  unionWith(
    questions,
    QUESTIONS.filter((q) => q.type === type),
    (a, b) => {
      return a.label === b.label && a.type === b.type;
    }
  );

export const CheckboxList: FunctionComponent<CheckboxListProps> = ({
  type,
  title,
  hideAdd,
  questions,
  addQuestion,
  deleteQuestion,
  showDefault = true,
}) => {
  const classes = useStyles();

  const questionList =
    (showDefault ? mergeDefaultQuestions(questions, type) : questions) || [];

  const handleToggle = (question: QuestionProps) => () => {
    if (question.id === undefined && addQuestion) {
      //  insert question
      addQuestion(question);
      return;
    }

    if (question.id !== undefined && deleteQuestion) {
      //  remove question
      deleteQuestion(question.id);
    }
  };

  const newItem = React.createRef<HTMLInputElement>();

  return (
    <Label title={title}>
      <List className={classes.root}>
        {questionList
          .filter((q) => q.type === type)
          .map((question) => {
            const { label, id } = question;
            const labelId = `checkbox-list-label-${label}`;

            return (
              <ListItem
                key={label}
                role={undefined}
                dense
                button
                onClick={handleToggle(question)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={id !== undefined}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={label} />
              </ListItem>
            );
          })}

        {!hideAdd && (
          <Paper component="form" className={classes.inputRoot}>
            <IconButton className={classes.iconButton} aria-label="menu">
              <AddIcon />
            </IconButton>
            <InputBase
              className={classes.input}
              placeholder="New Item"
              inputRef={newItem}
            />
            <IconButton
              onClick={() =>
                addQuestion &&
                newItem?.current?.value &&
                addQuestion({ label: newItem?.current?.value, type: type })
              }
              className={classes.iconButton}
            >
              <CheckCircleIcon />
            </IconButton>
          </Paper>
        )}
      </List>
    </Label>
  );
};

interface AgreementListProps extends QuestionType {
  title: string;
  questions?: Array<QuestionProps>;
  isAgreed?: boolean;
  onAgreementChange: (v: boolean) => void;
  disabled?: boolean;
}

export const AgreementList: FunctionComponent<AgreementListProps> = ({
  type,
  title,
  questions,
  isAgreed,
  onAgreementChange,
  disabled,
}) => {
  const questionList = questions || [];
  const [selections, setSelections] = React.useState(
    isAgreed ? questionList.map((q) => q.id) : []
  );
  const classes = useStyles();
  const questionListSize = questionList.length;

  const handleToggle = (question: QuestionProps) => () => {
    if (isAgreed || disabled) {
      return;
    }
    const currentIndex = selections.indexOf(question.id);
    const newChecked = [...selections];
    if (currentIndex === -1) {
      newChecked.push(question.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    if (questionListSize === newChecked.length) {
      onAgreementChange(true);
    }

    setSelections(newChecked);
  };

  return (
    <Label title={title}>
      <List className={classes.root}>
        {questionList
          .filter((q) => q.type === type)
          .map((question) => {
            const { label, id } = question;
            const labelId = `checkbox-list-label-${label}`;

            return (
              <ListItem
                key={label}
                role={undefined}
                dense
                button
                onClick={handleToggle(question)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={isAgreed ? true : selections.indexOf(id) !== -1}
                    disabled={disabled || isAgreed}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={label} />
              </ListItem>
            );
          })}
      </List>
    </Label>
  );
};

export default CheckboxList;
