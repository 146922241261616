import React, { FunctionComponent } from "react";
import MaterialTable, { Column } from "material-table";

import ErrorToast from "components/toast/ErrorToast";

import {
  useCreateClassRoomMutation,
  useUpdateClassRoomMutation,
  FindClassRoomsQuery,
  Class_Rooms,
} from "generated/graphql";

interface TableState {
  schoolId?: string;
  data?: FindClassRoomsQuery;
}

const TableRows: React.FC<TableState> = (props) => {
  const tableRef = React.createRef();

  const { schoolId, data } = props;

  const [
    createClassRoomMutation,
    { loading: createLoading, error: createClassRoomError },
  ] = useCreateClassRoomMutation({ errorPolicy: "all" });

  const [
    updateClassRoomMutation,
    { loading: updateLoading, error: updateClassRoomError },
  ] = useUpdateClassRoomMutation({ errorPolicy: "all" });

  const insertClassRoom = async (newData: Class_Rooms) => {
    await createClassRoomMutation({
      variables: {
        object: {
          school_id: schoolId,
          name: newData.name,
          notes: newData.notes,
        },
      },
      refetchQueries: ["findClassRooms"],
    });
  };

  const updateClassRoom = async (
    newData: Class_Rooms,
    oldData: Class_Rooms | undefined
  ) => {
    const { id, name, notes } = newData;
    await updateClassRoomMutation({
      variables: {
        id: id,
        name: name,
        notes: notes,
      },
      refetchQueries: ["findClassRooms"],
    });
  };

  let columns: Array<Column<Class_Rooms>> = [
    { title: "Id", field: "id", hidden: true },
    { title: "Name", field: "name" },
    { title: "Notes", field: "notes" },
    {
      title: "Users",
      field: "users_aggregate.aggregate.count",
      editable: "never",
    },
  ];

  return (
    <>
      <ErrorToast
        error={createClassRoomError}
        processCustomError={() =>
          `Unable to create classroom - ${createClassRoomError?.message}`
        }
      />
      <ErrorToast
        error={updateClassRoomError}
        processCustomError={() =>
          `Unable to update classroom - ${updateClassRoomError?.message}`
        }
      />

      <MaterialTable
        tableRef={tableRef}
        title={""}
        isLoading={createLoading || updateLoading}
        options={{
          search: false,
          addRowPosition: "first",
          actionsCellStyle: { textAlign: "center" },
          actionsColumnIndex: -1,
          loadingType: "overlay",
          paging: false,
        }}
        editable={{
          onRowAdd: insertClassRoom,
          onRowUpdate: (newData, oldData) => updateClassRoom(newData, oldData),
        }}
        columns={columns}
        //@ts-ignore
        data={data?.class_rooms.map((o) => ({ ...o })) || []}
      />
    </>
  );
};

interface ListClassRoomProps {
  schoolId?: string;
  classRoomData?: FindClassRoomsQuery;
}

const ListClassRooms: FunctionComponent<ListClassRoomProps> = ({
  schoolId,
  classRoomData,
}) => {
  return <TableRows schoolId={schoolId} data={classRoomData} />;
};

export default ListClassRooms;
