import React from "react";
import Card from "@material-ui/core/Card";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Envision from "./Envision";
import Plan from "./Plan";
import Approve from "./Approve";
import Reflect from "./Reflect";
import Share from "./Share";
import { useParams } from "react-router-dom";
import { FieldTripParams } from "./consts";
import { useFieldTripStatusQuery } from "generated/graphql";
import { CircularProgress, Paper } from "@material-ui/core";
import { Grid } from "@material-ui/core";

import { MachineContext } from "./state";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      margin: "0 30px",
      padding: "30px",
    },
  })
);

export default function View() {
  const classes = useStyles();
  const params = useParams<FieldTripParams>();
  const { data, loading } = useFieldTripStatusQuery({
    variables: {
      uid: params.tripId,
    },
  });

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <Card className={classes.root}>
      <CardHeader title={`Field Trip - ${data?.field_trips[0].title}`} />
      <CardContent>
        <MachineContext.Provider value={{ send: () => true, readOnly: true }}>
          <Paper className={classes.paper} elevation={2}>
            <Envision />

            <Plan />

            <Approve />

            <Reflect />

            <Share />
          </Paper>
        </MachineContext.Provider>
      </CardContent>
    </Card>
  );
}
