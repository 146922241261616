import classes from "*.module.css";
import { Link, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { DateTime } from "luxon";
import React from "react";
import { useGetVirtualTripByIdQuery } from "../../generated/graphql";
import GetVirtualTripTitle from "./VirtualTripTitle";

const useStyles = makeStyles((theme: Theme) =>
  //@ts-ignore
  createStyles({
    root: {
      maxWidth: "100%",
    },
    wrapper: {
      width: "100%",
    },
    descriptionGroup: {
      marginBottom: 20,
      "& h6": {
        fontSize: 18,
        backgroundColor: "#efefef",
        padding: "0.5rem",
        borderRadius: "5px",
        marginBottom: 8,
      },
      "& p": {
        padding: "0 0.5rem",
        color: theme.palette.grey[600],
      },
    },
    imageList: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "5px",
      "& > div": {
        width: "50%",
        height: 200,
        backgroundSize: "cover !important",
        backgroundPosition: "center !important",
        border: `1px solid ${theme.palette.grey[200]}`,
        marginBottom: "5px",
        borderRadius: "3px",
      },
    },
  })
);

export default function VirtualTripDetail(props: any) {
  const classes = useStyles();
  const { addOrEdit } = props;

  const { data, loading } = useGetVirtualTripByIdQuery({
    variables: {
      id: props.addOrEdit.id,
    },
  });

  if (loading) {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }
  const tripData = data?.virtual_trips_by_pk;
  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.descriptionGroup}>
          <Typography variant="h6">Topic</Typography>
          <Typography variant="body2">
            <GetVirtualTripTitle topic={tripData?.topic} />
          </Typography>
        </div>

        <div className={classes.descriptionGroup}>
          <Typography variant="h6">Meeting Link</Typography>
          <Typography variant="body2">
            <Link target="_blank" href={tripData?.meeting_link}>
              {tripData?.meeting_link}
            </Link>
          </Typography>
        </div>

        <div className={classes.descriptionGroup}>
          <Typography variant="h6">Date & Time</Typography>
          <Typography variant="body2">
            {DateTime.fromISO(`${tripData?.event_date}`).toFormat("ff")}
          </Typography>
        </div>
        <div className={classes.descriptionGroup}>
          <Typography variant="h6">Description</Typography>
          <Typography variant="body2">{tripData?.description}</Typography>
        </div>

        <div className={classes.descriptionGroup}>
          <Typography variant="h6">Notes</Typography>
          <Typography variant="body2">{tripData?.notes}</Typography>
        </div>

        <div className={classes.descriptionGroup}>
          <Typography variant="h6">Images</Typography>
          <div className={classes.imageList}>
            {tripData?.virtual_trip_images.map((image) => (
              <div
                key={image.id}
                style={{ background: `url(${image.image_link})` }}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
