import React from "react";
import { Redirect } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import { useFindMeQuery, User_Roles_Enum } from "../../generated/graphql";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  //@ts-ignore
  createStyles({
    root: {
      maxWidth: "100%",
    },
    bgGreen: {
      background: "#CCDEA5",
      borderRadius: 25,
      padding: "1.2rem",
      minHeight: "calc(100vh - 8rem)",
    },
    title: {
      fontSize: "2.2rem",
      color: "#18566A",
      fontWeight: 700,
      textAlign: "center",
      textDecoration: "none",
    },
    subTitle: {
      fontSize: "1.4rem",
      color: "#18566A",
      fontWeight: 700,
      textAlign: "center",
      marginBottom: "1.5rem",
      textDecoration: "none",
    },
    tripLogo: {
      maxWidth: "100%",
      height: "auto",
      display: "block",
      margin: "0 auto",
    },
    tripTypesList: {
      "& [class*='tripTypeWrapper']": {
        borderBottom: "1px solid #efefef",
      },
      "& :first-child [class*='tripTypeWrapper']": {
        borderRight: "1px solid #efefef",
      },
    },
    tripTypeWrapper: {
      paddingTop: "2rem",
      paddingBottom: "2rem",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& a": {
        display: "inline-block",
        padding: "2rem 2rem",
        borderRadius: 20,
        transition: "all 0.5s",
        textDecoration: "none",
      },
      "& a:hover": {
        backgroundColor: "#bacc95",
      },
    },
    linkDisabled: {
      opacity: 0.65,
      cursor: "not-allowed",
    },
    bottomLinks: {
      paddingTop: "1.2rem",
      textAlign: "center",
      "& a": {
        fontSize: 20,
        color: "#18566A",
      },
    },
  })
);

export default function SelectTripType() {
  const classes = useStyles();

  const { data: meData } = useFindMeQuery();
  const me = meData?.me[0];
  const schoolFeatures = meData?.me[0].school?.school_product_features || [];

  if (me?.role === User_Roles_Enum.SystemAdmin) {
    return <Redirect to={`/dashboard`} />;
  }
  const links = [
    {
      slug: "field-trip",
      title: "Going Out?",
      subTitle: "Start My Trip",
      link: "/dashboard",
      imagePath: "/images/FieldTrip.png",
    },
    {
      slug: "virtual-field-trip",
      title: "Staying in?",
      subTitle: "Join a Virtual Trip",
      link: "/share_virtual_trip",
      imagePath: "/images/virtualTrip.png",
    },
  ];

  function BottomLinks() {
    if (
      me?.role === User_Roles_Enum.SchoolAdmin ||
      me?.role === User_Roles_Enum.Teacher
    ) {
      return (
        <>
          <div className={classes.bottomLinks}>
            <Link to="/list_users" style={{ textDecoration: "none" }}>
              View and update users
            </Link>
          </div>
        </>
      );
    }
    return <></>;
  }
  function LinkItem(props: any) {
    const { slug, link, children } = props;

    const getLink = schoolFeatures
      ?.filter((f) => f.product_feature.feature_slug === slug)
      .map(() => {
        return link;
      });

    return (
      <>
        <Link
          to={getLink[0]}
          className={getLink.length === 0 ? classes.linkDisabled : ""}
        >
          {children}
        </Link>
      </>
    );
  }
  return (
    <>
      <Grid
        container
        spacing={0}
        className={classes.bgGreen}
        direction="column"
        justify="space-evenly"
      >
        <Grid container spacing={0} className={classes.tripTypesList}>
          {links.map((item) => (
            <Grid item sm={6} xs={12} key={item.slug}>
              <div className={classes.tripTypeWrapper}>
                <LinkItem slug={item.slug} link={item.link}>
                  <Typography className={classes.title}>
                    {item.title}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {item.subTitle}
                  </Typography>
                  <img className={classes.tripLogo} src={item.imagePath} />
                </LinkItem>
              </div>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={0}>
          <Grid item sm={12}>
            <BottomLinks />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
