export enum QuestionTypeEnum {
  transportation,
  grace_courtsey,
  reflect_ratings,
  reflect_questions,
}

export interface QuestionProps extends QuestionType {
  id?: string;
  label: string;
  key?: number;
}

export interface QuestionType {
  type: QuestionTypeEnum;
}

export const QUESTIONS: Array<QuestionProps> = [
  {
    type: QuestionTypeEnum.transportation,
    label: "We can name the overall trip arrival and departure times.",
  },
  {
    type: QuestionTypeEnum.transportation,
    label:
      "We are able to show at which train or bus stop we will arrive and depart on each leg of the journey.",
  },
  {
    type: QuestionTypeEnum.transportation,
    label:
      "We can describe the parts of the journey through which we will walk.",
  },
  {
    type: QuestionTypeEnum.transportation,
    label:
      "If traveling to a library, museum or other destination with a floor plan, we know the layout of the building.",
  },
  {
    type: QuestionTypeEnum.transportation,
    label: "We can describe all of the supplies we will need in detail.",
  },
  {
    type: QuestionTypeEnum.transportation,
    label: "We know how much money, if any, will be needed for this trip.",
  },
  {
    type: QuestionTypeEnum.transportation,
    label:
      "We have a plan for remembering the supplies and items we will need from school and home.",
  },
  {
    type: QuestionTypeEnum.grace_courtsey,
    label: "We have practiced how to ask a stranger for directions.",
  },
  {
    type: QuestionTypeEnum.grace_courtsey,
    label: "We know how to ask an adult for clarification if we are confused.",
  },
  {
    type: QuestionTypeEnum.grace_courtsey,
    label:
      "We have a plan to make sure our group stays together and anyone who is separated from the group knows what to do.",
  },
  {
    type: QuestionTypeEnum.grace_courtsey,
    label:
      "We can explain the grace and courtesy to use when meeting or passing others on the sidewalk",
  },
  {
    type: QuestionTypeEnum.grace_courtsey,
    label:
      "We have discussed social expectations for the visit location (governmental institution, healthcare facility, gallery, courthouse, place of worship, restaurant)",
  },
  {
    type: QuestionTypeEnum.reflect_questions,
    label: "We learned something new",
  },
  {
    type: QuestionTypeEnum.reflect_questions,
    label: "We found answers to our questions",
  },
  {
    type: QuestionTypeEnum.reflect_questions,
    label:
      "We are motivated to complete our lesson, research study, or project",
  },
  {
    type: QuestionTypeEnum.reflect_questions,
    label:
      "We were able to use the directions we planned in advance to travel to and from the trip location",
  },
  {
    type: QuestionTypeEnum.reflect_questions,
    label:
      "We were able to find the information or experience we were looking for after arriving at our location",
  },
  {
    type: QuestionTypeEnum.reflect_questions,
    label:
      "We were able to practice grace and courtesy / citizenship with other people in our community",
  },
  {
    type: QuestionTypeEnum.reflect_questions,
    label: "We recommend this learning experience to other students",
  },
  {
    type: QuestionTypeEnum.reflect_questions,
    label: "We are eager to participate in another student trip",
  },
  {
    type: QuestionTypeEnum.reflect_questions,
    label:
      "This opportunity was helpful for our classroom lesson, research study, or project",
  },
];

export const ReflectQuestions = [
  {
    type: QuestionTypeEnum.reflect_ratings,
    label: "We learned something new",
    key: 1,
  },
  {
    type: QuestionTypeEnum.reflect_ratings,
    label: "We found answers to our questions",
    key: 2,
  },
  {
    type: QuestionTypeEnum.reflect_ratings,
    label:
      "We are motivated to complete our lesson, research study, or project",
    key: 3,
  },
  {
    type: QuestionTypeEnum.reflect_ratings,
    label:
      "We were able to use the directions we planned in advance to travel to and from the trip location",
    key: 4,
  },
  {
    type: QuestionTypeEnum.reflect_ratings,
    label:
      "We were able to find the information or experience we were looking for after arriving at our location",
    key: 5,
  },
  {
    type: QuestionTypeEnum.reflect_ratings,
    label:
      "We were able to practice grace and courtesy / citizenship with other people in our community",
    key: 6,
  },
  {
    type: QuestionTypeEnum.reflect_ratings,
    label: "We recommend this learning experience to other students",
    key: 7,
  },
  {
    type: QuestionTypeEnum.reflect_ratings,
    label: "We are eager to participate in another student trip",
    key: 8,
  },
  {
    type: QuestionTypeEnum.reflect_ratings,
    label:
      "This opportunity was helpful for our classroom lesson, research study, or project",
    key: 9,
  },
];

export interface SchoolIdParams {
  schoolId?: string;
  users?: string;
}

interface RatingProps {
  value: number;
  label: string;
  hiddenLabel?: boolean;
}

export const Ratings: Array<RatingProps> = [
  { value: 0, label: "N/A" },
  { value: 1, label: "Strongly Disagree" },
  { value: 2, label: "Disagree", hiddenLabel: true },
  { value: 3, label: "Somewhat Disagree", hiddenLabel: true },
  { value: 4, label: "Somewhat Agree", hiddenLabel: true },
  { value: 5, label: "Agree", hiddenLabel: true },
  { value: 6, label: "Strongly Agree" },
];

export const LearningOptionsList = [
  {
    value: "Artwork",
    label:
      "Artwork (a painting, sculpture, sketch, or other artistic expression)",
  },
  {
    value: "Creative writing",
    label:
      'Creative writing (a novel, poem, short story, or "Who Am I?" / "What Am I?" story)',
  },
  { value: "Game or quiz", label: "Game or quiz" },
  { value: "History timeline", label: "History timeline" },
  { value: "How-to demonstration", label: "How-to demonstration" },
  {
    value: "Learning material for the classroom",
    label: "Learning material for the classroom",
  },
  {
    value: "Math/geometry problem or proof",
    label: "Math/geometry problem or proof",
  },
  {
    value: "Music (sheet music, a new song/tune)",
    label: "Music (sheet music, a new song/tune)",
  },
  { value: "Recipe", label: "Recipe" },
  { value: "Report", label: "Report" },
  { value: "Science experiment", label: "Science experiment" },
];

export const motessoriAffiliations = [
  "Association Montessori Internationale",
  "American Montessori Society",
  "Other Montessori Affiliation",
];

export const parentPermissionTemplate = `
Dear Parent or Guardian,

Learning beyond the classroom is an integral part of our curriculum. Your child is part of a group of children that is requesting permission to leave campus to support their academic work. This may be for a field study or community-service learning trip.

Field studies and community service-learning trips are different from standard field trips. Only a small group of children leave campus rather than the entire class or the entire grade level. These trips help children develop the skills they need for college and the 21st century workplace, including critical-thinking, problem-solving, goal-setting, planning, and strong communication skills. They help children formulate their long-term vision of how they would like to contribute to their society and world while building citizenship and social networking abilities.

Please indicate permission for your child to attend the trip below.

{student_name} has permission to attend the Field Study trip explained above. I acknowledge that I will not seek to have the School District or the chaperone held liable in the event that any accident, injury, loss of property or any other circumstance or incident occurs during or as a result of my child’s participation in the field study trip. This release of liability includes accident, injury, loss, or damages to the child, as well as, to other individuals or property which may result from the child’s participation in the event. I hereby release and agree to hold harmless the School District, its officials, agents and employees, and volunteer chaperones from any claims arising out of my child’s participation in the event(s). Further, I give my permission for my child to receive emergency medical treatment.

Parent/Guardian Signature (type name):

In an emergency, please contact:

By clicking APPROVE, I agree that my typed signature will be the electronic representation of my signature for all acknowledgments and releases related to this trip.
`;

export const chaperoneRequestTemplate = `
“When a child goes out, it is the world itself that offers itself to him/her. Let us take the child out to
show him/her real things instead of making objects which represent ideas and closing them in
cupboards.”

- Maria Montessori


Dear Parent, Guardian, or School-based Staff Member,

Thank you for your interest in chaperoning a field study or community service-learning trip for our students. All chaperones are expected to read/review, confirm, and follow through on specific trip guidelines. After reading the guidelines below, please confirm your ability to chaperone and agreement to follow through on our guidelines.

GENERAL DESCRIPTION

Our student-led trips, originally called “Going Out” by Maria Montessori, are an essential aspect of our program. They are very different from the traditional class field trip. The children “go out” in small groups as an extension of their research and to gain experiences in the world outside the classroom. The children develop independence, resourcefulness, and self-confidence through functioning capably in the larger world without adult intervention.

Field Studies are planned in full by the students who are going. Students plan these trips when they need to find more information on a topic of interest. Students might plan to go to the library to find books on a specific topic; they might go to an art exhibit that is showcasing pieces by an artist they are studying; there may be a local specialist that they can interview to obtain more information on a topic. The students involved are responsible for planning the entire trip, which includes (but is not limited to): contacting the destination of the Field Study, scheduling the time and date, collecting any money necessary for the Field Study, coordinating a chaperone, mapping out the route from the school to the destination, etc.

Ideally, children doing these Field Studies would be going on their own by walking or taking public transportation to their destination. However, in our time and place, safety dictates the adaptation of adult accompaniment. The adult’s role is to remain focused and observant while refraining from participating or interfering. The adult only intervenes on three occasions:
- to ensure the children’s safety,
- to interrupt unsafe or inappropriate behavior and return the children to school immediately, and
- to observe, record, and transmit the experience to the teacher.

In order to set up each member of the researching group for success in practicing independence and in order to empower the group as a whole, some schools will prefer scheduling their trip with a chaperone that is not one of the children’s parents.

BEFORE LEAVING

The children should be prepared to:
- Carry all that they will need – notebooks, pencils, sketchpads, money, ID, guidebooks, etc. – in a backpack or bag by themselves
- Handle all money transactions
- Conduct all communications for the group
- Know what behavior is expected of them before leaving the classroom
- If driving in a vehicle: direct the driver to the destination through a map or written directions
- If taking public transportation: mapped out the route and have all money prepared.
- Stop, look, and listen before crossing at parking lots, driveways, and streets
- Stay within sight of the adult at all times

IN TRANSIT

If taking a vehicle, children must remain seated with a seat belt on at all times while driving. If taking public transportation, children should remain seated while bus/train is moving. Children should not be using loud, boisterous voices while on the trip. If in a car with any kind of electronic device or media player, it is to be turned off for the entire trip. Student use of electronics (smart phones, tablets, handheld video game consoles) is not allowed. Cell phones are valuable in the case a trip-related call needs to be made, but should not be in use while driving.


RULES FOR RIDING IN CARS

- Each passenger will only board and exit from the curbside of the vehicle.
- Each passenger will take a seat and use the seatbelt.
- While the vehicle is moving, the children will remain seated and belted.
- No objects to be passed around the car, as this could cause children to become excited or be a distraction to the driver.
- Conversations will be held in soft voices.
- No electronics will be used.
- >No food or drinks will be consumed in the car except water.
- All objects (lunch boxes, bags, papers, etc.) and hands will be kept away from open windows.
</p>

RULES FOR TAKING PUBLIC TRANSPORTATION

- All children will only board and exit together.
- Each passenger will occupy an appropriate seat.
- While in transit, the children will remain seated.
- Conversations will be held in soft voices.
- No electronics will be used.
- No food or drinks will be consumed while in transit except water.
- All objects (lunch boxes, bags, papers, etc.) and hands will be kept away from open windows.


UPON ARRIVAL

After parking the car but before unloading, unbolting, or opening any doors, the adult listens while the children establish a plan for exiting the car safely. The children should be prepared to pay the parking attendant or put coins in the meter. If using public transportation, the children should be prepared to stand up and exit prior to arriving at their desired stop. They need to decide on which door they will be exiting from before arriving. All children should stay together.

Approaching a parking lot or street, the children stand approximately two feet back from the curb and wait until it is safe to cross. All children should remain close together. The adult stays behind the children, watching that all their behavior is safe and appropriate, that the environment is safe, and that any adult interaction with them is safe and appropriate.

AT THE DESTINATION

The role of the chaperone at this point is one of active observation and alert self-restraint. The chaperone must remain focused on the children, their safety, and appropriate behavior. It is important to stay about ten paces behind the children to help them rely upon their own resources on the trip.

The children should be prepared to pay entrance fees and find their own way using maps, directions, and guidebooks. They should be the ones that seek out the contact person, if there is one, and introduce themselves. The children can point out the chaperone and explain that s/he will be there only for safety and transportation. The chaperone remains about ten paces back in order to reinforce the children’s independence.

Any time the children have questions during the trip, they find adults other than the chaperone to help them. If the children forget and ask the chaperone a question, he/she can remind the students of the reason he/she is there.

At no time may the students have permission to extend or recreate their trip agenda. Visits to gift shops and opportunities to purchase souvenirs, snacks, and other items are not to be allowed unless they are an approved part of the group’s planned agenda.

In the case of unsafe or inappropriate behavior, the chaperone makes no comments about the behavior and gives no guidance. He/she only returns immediately to school with the children, saying, “I see that we must return to school right now.” Once the group has returned to the school, guidance is given by the teacher, who helps the chaperone and children discuss how to have a more successful trip next time.

RETURNING TO THE SCHOOL

On the drive/ride back to school, all safety rules and expectations are the same. The children are encouraged to reflect with each other on their trip. The chaperone is also welcome to share thoughts and observations with the students, as well as ask them about their favorite parts.

These trips are such amazing and unique opportunities for our students to gain independence and self-confidence. We are grateful for your involvement in making these important trips possible in our community!

I am interested in chaperoning this trip and have complete understanding of my role and responsibilities. I understand I will receive a confirmation note if it is possible for me to chaperone this trip.

Thank you for your support!
By clicking APPROVE, I agree that my typed signature will be the electronic representation of my signature for all acknowledgments and releases related to this trip.

`;

export const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
