import React from "react";
import { useHistory, Link } from "react-router-dom";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import GridItem from "components/grid/GridItem.js";
import GridContainer from "components/grid/GridContainer.js";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import {
  useListFieldTripQuery,
  FieldTripListFieldsFragment,
} from "../../generated/graphql";
import Box from "@material-ui/core/Box";
import LinearProgress, {
  LinearProgressProps,
} from "@material-ui/core/LinearProgress";
import styles from "assets/jss/material-dashboard-react/components/tasksStyle.js";
import MaterialTable, { Column, Query } from "material-table";
import { progressMap } from "./consts";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  //@ts-ignore
  createStyles({
    ...styles,
    root: {
      maxWidth: "100%",
    },
    headerColumn: {
      fontWeight: 500,
    },
  })
);

function TripProgress(props: LinearProgressProps & { status: string }) {
  if (props.status in progressMap) {
    const { percentage, label } = progressMap[props.status];
    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2" color="textSecondary">
            {label}
          </Typography>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">{`${Math.round(
              percentage
            )}%`}</Typography>
          </Box>
        </Box>

        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" value={percentage} />
        </Box>
      </Box>
    );
  }
  return <></>;
}

const columns: Array<Column<FieldTripListFieldsFragment>> = [
  { title: "Id", field: "uid", hidden: true },
  {
    title: "Title",
    field: "title",
    render: (data) => {
      return (
        <Link
          to={{
            pathname: `/edit_field_trip/${data.uid}`,
          }}
        >
          {data.title}
        </Link>
      );
    },
  },
  {
    title: "Owner",
    field: "owner",
    render: (data) => `${data.owner.first_name} ${data.owner.last_name}`,
  },
  { title: "Event Date", field: "event_date", type: "date" },
  { title: "Created Date", field: "created_at", type: "date" },
  {
    title: "Progress",
    field: "status",
    render: (data) => <TripProgress status={data.status} />,
  },
];

const DEFAULT_PAGE_SIZE = 10;

export default function FieldTripGrid() {
  const classes = useStyles();

  const { refetch, loading } = useListFieldTripQuery({
    variables: {
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
    },
  });

  const remoteData = async (query: Query<FieldTripListFieldsFragment>) => {
    const tripsData = await refetch({
      limit: query.pageSize,
      offset: query.page * query.pageSize,
    });
    return Promise.resolve({
      data: tripsData.data?.field_trips.map((o) => ({ ...o })) || [],
      page: query.page,
      totalCount: tripsData.data?.field_trips_aggregate.aggregate?.count || 0,
    });
  };

  const history = useHistory();

  return (
    <Card className={classes.root}>
      <CardHeader title="Field Trips" />

      <CardContent>
        <MaterialTable
          title={""}
          isLoading={loading}
          data={remoteData}
          columns={columns}
          options={{
            search: false,
            pageSize: DEFAULT_PAGE_SIZE,
            // pageSizeOptions: [10, 25, 50, 100],
            addRowPosition: "first",
            emptyRowsWhenPaging: false,
            actionsCellStyle: { textAlign: "center" },
            actionsColumnIndex: -1,
            loadingType: "overlay",
          }}
        />
      </CardContent>
    </Card>
  );
}

export function ListFieldTrips() {
  const classes = useStyles();

  const { data } = useListFieldTripQuery({
    variables: {
      limit: 100,
    },
  });

  const history = useHistory();

  return (
    <Card className={classes.root}>
      <CardHeader title="Field Trips" />

      <CardContent>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.headerColumn}>Title</TableCell>
                  <TableCell className={classes.headerColumn}>
                    Interest Area
                  </TableCell>
                  <TableCell className={classes.headerColumn}>Owner</TableCell>
                  <TableCell className={classes.headerColumn}>
                    Trip Date
                  </TableCell>
                  <TableCell className={classes.headerColumn}>
                    Create Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.field_trips.map((trip) => (
                  <TableRow key={trip.uid} className={classes.tableRow}>
                    <TableCell>{trip.title}</TableCell>
                    <TableCell>{trip.interest_area}</TableCell>
                    <TableCell>
                      {`${trip.owner.first_name} ${trip.owner.last_name}`}
                    </TableCell>
                    <TableCell>{trip.event_date}</TableCell>
                    <TableCell>{trip.created_at}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </GridItem>
        </GridContainer>
      </CardContent>
    </Card>
  );
}
