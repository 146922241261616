import React, { useContext } from "react";
import Label from "../Label";
import ThemeContext, { ThemeKey } from "components/theme/theme";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { themeMap } from "components/theme/theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: "20px",
    },
    paper: {
      height: 40,
      width: 40,
      cursor: "pointer",
    },
    control: {
      padding: theme.spacing(2),
    },
    colorPickWrapper: {
      height: "100%",
      "& > div": {
        height: "100%",
      },
      "& [class*='MuiInputBase-root']": {
        height: "100%",
      },
    },
  })
);

interface ColorPickerProps {
  setColorTheme: React.Dispatch<React.SetStateAction<any>>;
}

export default function ColorPicker(props: ColorPickerProps) {
  const classes = useStyles();
  const theme = useContext(ThemeContext);

  const { setColorTheme } = props;

  return (
    <div className={classes.colorPickWrapper}>
      <Label title="Color Theme">
        {/* <TwitterPicker triangle="hide" onChange={color => theme.updateTheme(color)}/> */}
        <Grid container className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Grid container justify="center" spacing={0}>
              {Object.keys(themeMap).map((key) => (
                <Grid key={key} item>
                  <Paper
                    className={classes.paper}
                    style={{
                      backgroundColor:
                        themeMap[key as ThemeKey].palette.primary.main,
                    }}
                    onClick={() => {
                      theme.updateTheme(key as ThemeKey);
                      setColorTheme(key as ThemeKey);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Label>
    </div>
  );
}
