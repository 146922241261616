import React, { useContext, FunctionComponent } from "react";
import ThemeContext, { ThemeKey } from "components/theme/theme";

interface ColorThemeProps {
  colorTheme: ThemeKey;
}

const UpdateTheme: FunctionComponent<ColorThemeProps> = ({ colorTheme }) => {
  const theme = useContext(ThemeContext);
  React.useEffect(() => {
    theme.updateTheme(colorTheme as ThemeKey);
  }, [colorTheme]);

  return null;
};

export default UpdateTheme;
